import { ActivityIndicator, Button, Text } from 'react-native-paper';
import styled from 'styled-components/native';

import { colors, fontsFamily, fontsWeight } from '@ioupie/shared/constants';
import { themefy } from '@ioupie/shared/utils';

import { BlockButtonProps } from './block-button.models';

declare type Sizeable = Pick<BlockButtonProps, 'reduced'>;
declare type ColorMode = Pick<BlockButtonProps, 'blacken' | 'secondary' | 'disabled' | 'small' | 'autoWidth'>;

export const AreaView = styled.View<ColorMode & Sizeable & { readonly style?: any }>`
  align-self: center;
  background-color: ${(props) => {
    if (props.blacken) {
      return themefy({
        light: '#000',
        dark: '#ddd',
      })(props);
    }

    return props.secondary
      ? themefy({
          light: colors.light.background,
          dark: colors.dark.background,
        })(props)
      : themefy({
          light: colors.light.primary,
          dark: colors.dark.primary,
        })(props);
  }};
  height: ${({ small }) => (small ? '40px' : '50px')};
  justify-content: center;
  margin: 5px;
  opacity: ${(props) => {
    return props.disabled ? 0.5 : 1;
  }};
  width: ${({ autoWidth, reduced }) => {
    return autoWidth ? 'auto' : reduced ? '48%' : '90%';
  }};
`;

export const AreaButton = styled(Button)<ColorMode>`
  align-self: center;
  background-color: ${(props) => {
    if (props.blacken) {
      return themefy({
        light: '#000',
        dark: '#ddd',
      })(props);
    }

    return props.secondary
      ? themefy({
          light: colors.light.background,
          dark: colors.dark.background,
        })(props)
      : themefy({
          light: colors.light.primary,
          dark: colors.dark.primary,
        })(props);
  }};
  height: 100%;
  justify-content: center;
  width: 100%;
`;

export const ButtonText = styled(Text)<ColorMode>`
  color: ${(props) => {
    if (props.blacken) {
      return themefy({
        light: '#ddd',
        dark: '#1c1c1c',
      })(props);
    }

    return themefy({
      light: colors.light.text,
      dark: colors.dark.text2,
    });
  }};
  font-family: ${fontsFamily.regular};
  font-size: 16px;
  font-weight: ${fontsWeight.regular};
`;

export const Loader = styled(ActivityIndicator)`
  align-self: center;
  height: 100%;
  justify-content: center;
  width: 100%;
`;
