import { observer } from 'mobx-react-lite';
import { Fragment } from 'react';
import { useTranslation } from 'react-i18next';

import { useOrdersStore } from '@ioupie/hooks';

import { InvolvedPayment } from './involved-payment';
import * as S from './payment-details.styles';

/**
 * @function PaymentDetailsContainer
 */
export default observer(() => {
  const [t] = useTranslation();
  const ordersStore = useOrdersStore();

  const { selectedOrderHistory } = ordersStore;
  const { payment } = selectedOrderHistory ?? {};
  const { involvedPayments = [] } = payment ?? {};

  return ordersStore.hasPaymentInformation ? (
    <S.PaymentDetailsContainer>
      <S.PaymentDetailsTitle>{t('containers.orders.track-order.payment-details.title')}</S.PaymentDetailsTitle>
      {involvedPayments.map((paymentInformation) => (
        <InvolvedPayment
          key={`${paymentInformation.paymentMethodId}_${String(paymentInformation?.confirmedDate)}`}
          singlePaymentInformation={paymentInformation}
        />
      ))}
    </S.PaymentDetailsContainer>
  ) : (
    <Fragment />
  );
});
