import styled from 'styled-components/native';

import { statusBarHeight } from '@ioupie/shared/constants';

export default styled.SafeAreaView`
  flex: 1;
  flex-grow: 1;
  height: 100%;
  padding-top: ${statusBarHeight}px;
  width: 100%;
`;
