import * as SecureStore from 'expo-secure-store';
import { injectable } from 'inversify';

import { STORAGE_KEYS } from '@ioupie/shared/constants';
import { Optional } from '@ioupie/shared/models';

import { type StorageService } from './storage.service';

@injectable()
export class SecureStorageService implements StorageService {
  public async persistData<T>(key: STORAGE_KEYS, item: Optional<T>): Promise<void> {
    const normalizedKey = this.normalizeKey(key);
    const persistItem: Optional<string> = JSON.stringify(item);

    await SecureStore.setItemAsync(normalizedKey, persistItem);
  }

  public async retrieveData<T>(key: STORAGE_KEYS): Promise<Optional<T>> {
    const normalizedKey = this.normalizeKey(key);
    const item: Optional<string> = await SecureStore.getItemAsync(normalizedKey);
    // eslint-disable-next-line @typescript-eslint/no-unsafe-return
    return item ? JSON.parse(item) : undefined;
  }

  public async deleteData(key: STORAGE_KEYS): Promise<void> {
    const normalizedKey = this.normalizeKey(key);
    await SecureStore.deleteItemAsync(normalizedKey);
  }

  private normalizeKey(key: string): string {
    return key.replace(/[^a-zA-Z0-9.\-_]/gim, '-');
  }
}
