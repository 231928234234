import { DateTime } from 'luxon';

import { IANA_ZONE } from '../constants';

export const dateAsISO = (date: Date): string => {
  const wrappedDate = DateTime.fromJSDate(date);
  // calling weekday without setting a time can lead to incorrect results depending on timezone.
  wrappedDate.setZone(IANA_ZONE);

  return wrappedDate.toISO();
};

export const dateAsNormalizedString = (date: Date): string => {
  const wrappedDate = DateTime.fromJSDate(date);
  // calling weekday without setting a time can lead to incorrect results depending on timezone.
  wrappedDate.setZone(IANA_ZONE);

  return wrappedDate.toFormat('yyyy-MM-dd');
};

export const today = (): string => {
  const wrappedDate = DateTime.now();
  // calling weekday without setting a time can lead to incorrect results depending on timezone.
  wrappedDate.setZone(IANA_ZONE);

  return wrappedDate.toFormat('yyyy-MM-dd');
};

export const tomorrow = (): string => {
  // add one to today
  const wrappedDate = DateTime.now().plus({ days: 1 });
  // calling weekday without setting a time can lead to incorrect results depending on timezone.
  wrappedDate.setZone(IANA_ZONE);

  return wrappedDate.toFormat('yyyy-MM-dd');
};

export const todayAfterDays = (days: number): string => {
  // add delta days
  const wrappedDate = DateTime.now().plus({ days });
  // calling weekday without setting a time can lead to incorrect results depending on timezone.
  wrappedDate.setZone(IANA_ZONE);

  return wrappedDate.toFormat('yyyy-MM-dd');
};

export const dateAfterDays = (date: Date, days: number): string => {
  // add delta days
  const wrappedDate = DateTime.fromJSDate(date).plus({ days });
  // calling weekday without setting a time can lead to incorrect results depending on timezone.
  wrappedDate.setZone(IANA_ZONE);

  return wrappedDate.toFormat('yyyy-MM-dd');
};

export const dateAfterBusinessDays = (date: Date, days: number, blockedDates: readonly DateTime[] = []): string => {
  const blockListByDate = blockedDates.map((unavailableDate) => unavailableDate.ordinal);

  // calling weekday without setting a time can lead to incorrect results depending on timezone.
  let targetDate = DateTime.fromJSDate(date).plus({ days }).setZone(IANA_ZONE);

  for (
    let currentDate = DateTime.fromJSDate(date);
    currentDate.ordinal <= targetDate.ordinal;
    currentDate = currentDate.plus({ days: 1 })
  ) {
    if (isWeekend(currentDate.toJSDate()) || blockListByDate.includes(currentDate.ordinal)) {
      targetDate = targetDate.plus({ days: 1 });
    }
  }

  return targetDate.toFormat('yyyy-MM-dd');
};

export const getDaysBefore = (day: number, month: number, year: number): readonly Date[] => {
  return new Array(day)
    .fill('')
    .map((_, index: number) => new Date(year, month, index + 1))
    .filter((date: Date) => date.getMonth() === month);
};

export const getPreviousDays = (date: Date): readonly Date[] =>
  getDaysBefore(date.getDate(), date.getMonth(), date.getFullYear());

export const getDaysInMonth = (month: number, year: number): readonly Date[] => getDaysBefore(31, month, year);

export const getDaysFrom = (day: number, month: number, year: number): readonly Date[] =>
  getDaysBefore(31, month, year).filter((_, index: number) => index + 1 >= day);

export const getFollowingDays = (date: Date): readonly Date[] =>
  getDaysFrom(date.getDate(), date.getMonth(), date.getFullYear());

export const isWeekend = (date: Date): boolean => {
  const wrappedDate = DateTime.fromJSDate(date);
  // calling weekday without setting a time can lead to incorrect results depending on timezone.
  wrappedDate.setZone(IANA_ZONE);
  const day = wrappedDate.get('weekday');

  // 6 = Saturday, 7 = Sunday
  return day === 6 || day === 7;
};

export const allYearsSince = (since: number, date = new Date()): readonly number[] =>
  Array.from({ length: date.getFullYear() - since }, (_, index) => since + 1 + index);

/**
 * Lists the next days available
 *
 * @param { Date } date the reference date to calculate the next days
 * @param {number} days the desired number of days
 * @param {DateTime[]} blockedDates the dates to be excluded
 * @param {number} offset for recursion purpose
 * @returns {DateTime[]} Returns a DateTime list with the next days available
 */
export const getPreviewDates = (
  date: Date = new Date(),
  days: number = 0,
  blockedDates: readonly DateTime[] = [],
): readonly DateTime[] => {
  const blockListByDate = blockedDates.map((unavailableDate) => unavailableDate.ordinal);

  let possibleDates: readonly DateTime[] = [];
  for (let i = 0, offset = 0; i < days; i++) {
    let proposedDate = DateTime.fromJSDate(date).plus({ days: i + offset });
    while (blockListByDate.includes(proposedDate.ordinal)) {
      offset++;
      proposedDate = DateTime.fromJSDate(date).plus({ days: i + offset });
    }

    possibleDates = [...possibleDates, proposedDate];
  }

  return possibleDates;
};

/**
 * Gets the next business day from the received date as a parameter.
 *
 * @param {DateTime} date the date used as reference
 * @returns {DateTime} the next business day
 */
export const getNextBusinessDay = (date = DateTime.now()): DateTime => {
  const fixedDate = date.toLocal();
  // 6 = Saturday, 7 = Sunday
  const daysToAdd = fixedDate.weekday === 6 ? 2 : fixedDate.weekday === 7 ? 1 : 0;
  return date.plus({ days: daysToAdd });
};

/**
 * Convert date to DateTime type.
 *
 * @param {Date} date the date to be converted.
 * If the date is already of type DateTime, just return.
 * If the date is of type string, must be an ISO.
 * If the date is of type number, must be milliseconds.
 * @returns {DateTime} the date with DateTime type.
 */
export const normalizeToDateTime = (date: string | number | DateTime): DateTime => {
  if (DateTime.isDateTime(date)) return date;
  return typeof date === 'string' ? DateTime.fromISO(date) : DateTime.fromMillis(date);
};

/**
 * Gets the current date 18 years ago
 *
 * @returns {Date} Today's date 18 years ago
 */
export const getLatestLegalAgeDate = (): Date => {
  return new Date(new Date().setFullYear(new Date().getFullYear() - 18));
};
