export enum MOBILE_PROTOCOLS {
  IOUPIE = 'ioupie',
  IOUPIE_ANDROID = 'com.ioupie.client.reactnative',
  IOUPIE_IOS = 'com.ioupie.client.ios.native',
}

export enum MOBILE_HOSTS {
  IOUPIE_COM_BR = 'ioupie.com.br',
}

export enum MOBILE_PATH {
  LINKING = 'linking',
}

export const deepLinkingNavBase = `${MOBILE_PROTOCOLS.IOUPIE}://${MOBILE_HOSTS.IOUPIE_COM_BR}/${MOBILE_PATH.LINKING}`;
