import { memo } from 'react';

import { UserData } from '@ioupie/containers/user-data';
import { useCurrentRouteFocus, useHeaderStore, useNavigationStore } from '@ioupie/hooks';
import { routes } from '@ioupie/shared/constants';

import * as S from './my-profile.styles';
/**
 * @function MyProfilePage
 */
export default memo(() => {
  const headerStore = useHeaderStore();
  const navigationStore = useNavigationStore();

  useCurrentRouteFocus(routes.pages.profile.my_profile, () => {
    headerStore.allowNavigateBack(true);
    headerStore.removeHeaderShadow(false);
    headerStore.changeTitle('pages.profile.my-profile.title');
    headerStore.changeSubtitle('');
    headerStore.onClickBack(() => navigationStore.dispatchGoBack());
  });

  return (
    <S.ProfileContainer>
      <UserData />
    </S.ProfileContainer>
  );
});
