import { injectable } from 'inversify';
import { Appearance, ColorSchemeName } from 'react-native';

@injectable()
export class AppearanceService {
  // eslint-disable-next-line @typescript-eslint/require-await
  public async getUserAppearance(): Promise<ColorSchemeName> {
    return Appearance.getColorScheme();
  }
}
