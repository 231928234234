import { createStackNavigator } from '@react-navigation/stack';
import { memo } from 'react';

import { AddCreditCard, PaymentMethodsList } from '@ioupie/pages/payment';
import { routes } from '@ioupie/shared/constants';

const Stack = createStackNavigator();

/**
 * @function PaymentStack
 */
export default memo(() => {
  return (
    <Stack.Navigator initialRouteName={routes.pages.payment.methods_list} screenOptions={{ headerShown: false }}>
      <Stack.Screen name={routes.pages.payment.methods_list} component={PaymentMethodsList} />
      <Stack.Screen name={routes.pages.payment.add_credit_card} component={AddCreditCard} />
    </Stack.Navigator>
  );
});
