import { observer } from 'mobx-react-lite';
import { Fragment, useCallback, useState } from 'react';
import { useTranslation } from 'react-i18next';

import { BlockButton } from '@ioupie/components/buttons';
import { Dialog, ErrorSnackbar } from '@ioupie/components/custom';
import { Icon } from '@ioupie/components/gallery';
import { Portal } from '@ioupie/components/layout';
import { Bold, HelperText, Text } from '@ioupie/components/typography';
import { useAddressStore, useAuthStore, useNavigationStore, useOrdersStore, useShopsStore } from '@ioupie/hooks';
import { BUSINESS_AREA_OPTIONS, routes, THANK_TYPE } from '@ioupie/shared/constants';

import * as S from './create-order.styles';

/**
 * @function CreateOrderContainer
 */
export default observer(() => {
  const [t] = useTranslation();

  const shopsStore = useShopsStore();
  const ordersStore = useOrdersStore();
  const addressStore = useAddressStore();
  const authStore = useAuthStore();
  const navigationStore = useNavigationStore();

  const [displayAddressConfirmationDialog, setDisplayAddressConfirmationDialog] = useState(false);

  const { selectionInfo, shopProvider, coupon, couponInformation } = shopsStore;
  const { minimumDays, totalPrice, totalSelected } = selectionInfo;
  const { metadata } = shopProvider ?? {};
  const { minProducts = 0, minValue } = metadata ?? {};
  const { amount: minAmount = 0, currencyCode = '' } = minValue ?? {};
  const { eligible = false, totalDiscount, orderGrandTotal } = couponInformation ?? {};
  const { amount: couponDiscount = 0 } = totalDiscount ?? {};
  const { amount: totalAmount = 0 } = orderGrandTotal ?? {};

  const ticket = shopsStore.createOrderTicket();

  const couponLabel = t('containers.orders.create-order.coupon.title');
  const couponPlaceholder = t('containers.orders.create-order.coupon.placeholder');

  const confirmConfiguredAddress = useCallback(() => {
    if (!addressStore.selectedAddressInfo) {
      navigationStore.dispatchNavigation({
        stack: routes.stacks.address,
      });
      return;
    }

    setDisplayAddressConfirmationDialog(true);
  }, []);

  const navigateToAddressListPage = useCallback(() => {
    navigationStore.dispatchNavigation({
      stack: routes.stacks.address,
    });
    setDisplayAddressConfirmationDialog(false);
  }, []);

  const requestCouponValidation = useCallback(async (): Promise<void> => {
    if (ticket && coupon) {
      await shopsStore.validateCoupon(ticket, coupon);
    }
  }, [coupon, ticket]);

  const requestOrderCreation = useCallback(async (): Promise<void> => {
    setDisplayAddressConfirmationDialog(false);

    if (!authStore.haveAuthorizationData) {
      authStore.setShowAuthModal(true);
      return;
    }

    if (ticket) {
      await ordersStore.createServiceOrder(ticket);

      if (ordersStore.errors.length === 0) {
        shopsStore.clearCoupon();
        shopsStore.clearSelectedProducts();

        const thankType = {
          [BUSINESS_AREA_OPTIONS.DELIVERY]: THANK_TYPE.TICKET_ACTIVATED,
          [BUSINESS_AREA_OPTIONS.LOCKER]: THANK_TYPE.TICKET_GENERATED,
        };
        ordersStore.changeThankType(thankType[ticket.type]);
        navigationStore.dispatchNavigation({
          stack: routes.stacks.orders,
          screen: routes.pages.orders.thank,
        });
      }
    }
  }, [ticket]);

  const typeBasedAction = async (): Promise<void> => {
    if (ticket) {
      const upcomingAction = {
        [BUSINESS_AREA_OPTIONS.DELIVERY]: confirmConfiguredAddress,
        [BUSINESS_AREA_OPTIONS.LOCKER]: requestOrderCreation,
      };
      await upcomingAction[ticket.type]();
    }
  };

  const {
    streetAddress = '',
    houseNumber = '',
    district = '',
    neighborhood = '',
    state = '',
    addressAddon = '',
    zipCode = '',
  } = addressStore.selectedAddressInfo ?? {};

  return (
    <Fragment>
      <S.CreateOrderContainer>
        <S.CouponContainer>
          <S.CouponField>
            <S.CouponText
              autoCapitalize="characters"
              label={couponLabel}
              placeholder={couponPlaceholder}
              value={coupon}
              onChangeText={(couponCode: string) => shopsStore.setCoupon(couponCode)}
            />
            <S.CouponButton
              loading={shopsStore.loadingCoupon}
              onPress={requestCouponValidation}
              disabled={shopsStore.loadingCoupon}>
              {t('containers.orders.create-order.coupon.apply-button')}
            </S.CouponButton>
          </S.CouponField>
          <HelperText type="error" visible={Boolean(shopsStore.couponErrors)}>
            {shopsStore.couponErrors}
          </HelperText>
        </S.CouponContainer>

        <S.OrderDetailLine>
          <Bold>{t('containers.orders.create-order.items.description')}</Bold>
          <Bold>{t('containers.orders.create-order.items.value', { totalSelected })}</Bold>
        </S.OrderDetailLine>
        <S.OrderDetailLine>
          <Bold>{t('containers.orders.create-order.min-days.description')}</Bold>
          <Bold>{t('containers.orders.create-order.min-days.value', { minDays: minimumDays })}</Bold>
        </S.OrderDetailLine>
        {eligible && (
          <S.OrderDetailLine>
            <Bold>{t('containers.orders.create-order.coupon.coupon')}</Bold>
            <Bold>
              {`- ${t('containers.orders.create-order.coupon.value', {
                price: couponDiscount.toFixed(2),
              })}
              `}
            </Bold>
          </S.OrderDetailLine>
        )}
        <S.OrderDetailLine>
          <Bold>{t('containers.orders.create-order.total.description')}</Bold>
          <Bold>
            {t('containers.orders.create-order.total.value', {
              price: totalAmount ? totalAmount.toFixed(2) : totalPrice.toFixed(2),
            })}
          </Bold>
        </S.OrderDetailLine>

        {!shopsStore.isShopOrderValid && (
          <S.ProviderNotEligibleMessage>
            {!shopsStore.isShopAreaAvailable && (
              <S.InvalidMessage>
                <Icon name="map-marker-off-outline" size={28} />
                <S.InvalidMessageText>
                  {t('containers.orders.create-order.provider-not-eligible-label')}
                </S.InvalidMessageText>
              </S.InvalidMessage>
            )}
            {!shopsStore.isShopMinProductsOrderValid && minProducts > 0 && (
              <S.InvalidMessage>
                <Icon name="cart-off" size={28} />
                <S.InvalidMessageText>
                  {t('containers.orders.create-order.provider-minimum-selection', { minProducts })}
                </S.InvalidMessageText>
              </S.InvalidMessage>
            )}
            {!shopsStore.isShopMinPriceOrderValid && minAmount > 0 && (
              <S.InvalidMessage>
                <Icon name="currency-usd-off" size={28} />
                <S.InvalidMessageText>
                  {t('containers.orders.create-order.provider-minimum-amount', { currencyCode, minAmount })}
                </S.InvalidMessageText>
              </S.InvalidMessage>
            )}
          </S.ProviderNotEligibleMessage>
        )}
        <BlockButton
          loading={ordersStore.loading}
          text={t('containers.orders.create-order.submit')}
          onPress={typeBasedAction}
          disabled={!shopsStore.isShopOrderValid || shopsStore.loadingCoupon}
        />
      </S.CreateOrderContainer>

      <Portal>
        <Dialog.View
          visible={displayAddressConfirmationDialog}
          onDismiss={() => setDisplayAddressConfirmationDialog(false)}>
          <Dialog.Title>{t('containers.orders.create-order.address-dialog.title')}</Dialog.Title>
          <Dialog.Content>
            <Text>
              {streetAddress}, {houseNumber}
            </Text>
            <Text>
              {neighborhood}, {district}/{state}
            </Text>
            <Text>{zipCode}</Text>
            {Boolean(addressAddon) && <S.DialogAddressAddon>{addressAddon}</S.DialogAddressAddon>}
          </Dialog.Content>
          <Dialog.Actions>
            <S.DialogButtonColumn>
              <S.DialogButton
                secondary
                text={t('containers.orders.create-order.address-dialog.change-address-label')}
                onPress={() => navigateToAddressListPage()}
              />
              <S.DialogButton
                text={t('containers.orders.create-order.address-dialog.next-label')}
                onPress={requestOrderCreation}
              />
            </S.DialogButtonColumn>
          </Dialog.Actions>
        </Dialog.View>
      </Portal>

      <ErrorSnackbar errors={ordersStore.errors} onDismiss={() => ordersStore.clearErrors()} />
    </Fragment>
  );
});
