import { observer } from 'mobx-react-lite';
import { Fragment, useCallback, useEffect, useState } from 'react';
import { useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { Keyboard, View } from 'react-native';

import { Button } from '@ioupie/components/buttons';
import { AreaInput } from '@ioupie/components/inputs';
import { Text } from '@ioupie/components/typography';
import { useUserStore } from '@ioupie/hooks';

import { DeleteAccountDialog } from './delete-account-dialog';
import { DeleteAccountForm } from './delete-account.models';
import * as S from './delete-account.styles';

/**
 * @function DeleteAccountContainer
 */
export default observer(() => {
  const [t] = useTranslation();
  const userStore = useUserStore();

  const [showDeleteButton, setShowDeleteButton] = useState(false);

  const { isDeleteAccountDialogOpen = false } = userStore ?? {};

  const { register, handleSubmit, setValue, watch } = useForm<DeleteAccountForm>({
    defaultValues: {
      feedback: '',
    },
  });

  useEffect(() => {
    register('feedback');
  }, [register]);

  const onSubmitForm = useCallback((form: DeleteAccountForm) => {
    Keyboard.dismiss();
    userStore.showDeleteAccountDialog();
    setShowDeleteButton(false);

    return userStore.deleteUser(form.feedback);
  }, []);

  const FeedbackSection = (
    <Fragment>
      <S.FeedbackTitle>{t('forms.delete-account.feedback.label1')}</S.FeedbackTitle>
      <AreaInput
        placeholder={t('forms.delete-account.feedback.placeholder')}
        value={watch('feedback')}
        onChangeText={(text) => {
          setValue('feedback', text);
        }}
      />
    </Fragment>
  );

  return (
    <View>
      <S.DeleteAccountButton onPress={() => setShowDeleteButton(!showDeleteButton)}>
        {t('forms.delete-account.delete-button')}
      </S.DeleteAccountButton>
      {showDeleteButton && (
        <S.DeleteAccountBox>
          {FeedbackSection}
          <Button mode="text" onPress={handleSubmit(onSubmitForm)} disabled={!watch('feedback')}>
            {t('forms.delete-account.button.submit')}
          </Button>
        </S.DeleteAccountBox>
      )}
      {isDeleteAccountDialogOpen && <DeleteAccountDialog />}
    </View>
  );
});
