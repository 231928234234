import { DateTime } from 'luxon';
import { observer } from 'mobx-react-lite';
import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { TouchableOpacity } from 'react-native';

import { Calendar } from '@ioupie/components/custom';
import { ScrollView } from '@ioupie/components/layout';
import { useNavigationStore, useOrdersStore } from '@ioupie/hooks';
import { CALENDAR_DATE_FORMAT, ORDERING_STEPS, routes } from '@ioupie/shared/constants';
import {
  dateAfterDays,
  getFollowingDays,
  getPreviousDays,
  isWeekend,
  todayAfterDays,
} from '@ioupie/shared/utils/date.utils';

import * as S from './calendar.styles';

/**
 * @function CalendarContainer
 */
export default observer(() => {
  const [t] = useTranslation();
  const ordersStore = useOrdersStore();
  const navigationStore = useNavigationStore();

  const { selectedOrderHistory, scheduledOrderDate = '', scheduleDates } = ordersStore;
  const { minimumDays = 0 } = selectedOrderHistory ?? {};
  const { endDate, nonBusinessDays, unavailablePeriods } = scheduleDates ?? {};

  const [chosenDate, setChosenDate] = useState(scheduledOrderDate);

  // // Get todays value
  const currentDate = new Date();
  // If the day which the service is being generated is an weekend,
  // do not account it as an working day.
  const append = isWeekend(currentDate) ? 0 : 1;
  // The sum of today with the maximum working day, taking in account
  // if today is a weekend or not.
  const dateAfter = new Date(todayAfterDays(minimumDays + append));
  // If the resulting date is on the next month
  const hasMonthChange = currentDate.getMonth() < dateAfter.getMonth();
  // Get the weekends between today and the target date
  const followingWeekends = getFollowingDays(currentDate)
    .filter(isWeekend)
    .filter((followingWeekend) => hasMonthChange || followingWeekend.getDate() <= dateAfter.getDate());
  // If the month has changed, get all weekends from next month
  const weekendsBefore = hasMonthChange ? getPreviousDays(dateAfter).filter(isWeekend) : [];
  // The total of weekends, the ones in the current month
  // with the ones into the next month
  const numberOfWeekends = followingWeekends.length + weekendsBefore.length;
  // Sum up the target date with the weekends
  const calculatedDate = dateAfterDays(dateAfter, numberOfWeekends);
  // Normalize the calculated date
  const futureDate = DateTime.fromJSDate(new Date(calculatedDate));
  // Convert it to string, as calendar only works with strings
  const blockingDate = futureDate.toFormat(CALENDAR_DATE_FORMAT);
  // The date to be shown when the calendar bootstrap
  const bootstrapDate = currentDate.getMonth() !== futureDate.month ? blockingDate : undefined;

  const formatDates = (timestampDates: readonly number[]) =>
    timestampDates.map((timestampDate) => DateTime.fromMillis(timestampDate).toFormat('yyyy-MM-dd'));

  const handleScheduleButton = (): void => {
    const isoDate = DateTime.fromISO(chosenDate).toString();
    ordersStore.setScheduleOrderDate(isoDate);
    ordersStore.changeOrderingStep(ORDERING_STEPS.SCHEDULE);
  };

  const handleHelpButton = (): void =>
    navigationStore.dispatchNavigation({
      stack: routes.stacks.help,
      screen: routes.pages.help.help,
    });

  return (
    <ScrollView>
      <S.DeliveryScheduleContainer>
        <S.LabelTitle>{t('containers.orders.ordering.calendar.title')}</S.LabelTitle>
        <Calendar
          maxDate={DateTime.fromMillis(endDate).toISODate()}
          startDate={DateTime.fromISO(chosenDate).toISODate()}
          disableWeekends
          currentDate={bootstrapDate}
          disableDatesUntil={blockingDate}
          onDateChange={setChosenDate}
          nonBusinessDays={formatDates(nonBusinessDays)}
          unavailablePeriods={formatDates(unavailablePeriods)}
        />
        <S.ContinueButton
          onPress={handleScheduleButton}
          text={t('containers.orders.ordering.calendar.next-button')}
          disabled={chosenDate === ''}
        />
        <TouchableOpacity onPress={handleHelpButton}>
          <S.OtherDate black>{t('containers.orders.ordering.calendar.help-button')}</S.OtherDate>
        </TouchableOpacity>
      </S.DeliveryScheduleContainer>
    </ScrollView>
  );
});
