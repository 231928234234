import styled from 'styled-components/native';

import { colors } from '@ioupie/shared/constants';
import { themefy } from '@ioupie/shared/utils';

export default styled.SafeAreaView`
  background-color: ${themefy({
    light: colors.light.background,
    dark: colors.dark.background,
  })};
  flex: 1;
  flex-grow: 1;
  height: 100%;
  position: relative;
  width: 100%;
`;
