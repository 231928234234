import Constants from 'expo-constants';
import { memo } from 'react';
import { useTranslation } from 'react-i18next';

import {
  DarkModeSwitcher,
  LanguageSelector,
  LocationSwitcher,
  PushNotificationSwitcher,
} from '@ioupie/containers/settings';
import { useAuthStore, useCurrentRouteFocus, useHeaderStore, useNavigationStore } from '@ioupie/hooks';
import { routes } from '@ioupie/shared/constants';

import * as S from './settings.styles';
/**
 * @function SettingsPage
 */
export default memo(() => {
  const [t] = useTranslation();
  const headerStore = useHeaderStore();
  const authStore = useAuthStore();
  const navigationStore = useNavigationStore();

  useCurrentRouteFocus(routes.pages.settings.settings, () => {
    headerStore.allowNavigateBack(true);
    headerStore.removeHeaderShadow(false);
    headerStore.changeTitle('pages.settings.title');
    headerStore.changeSubtitle('');
    headerStore.onClickBack(() => navigationStore.dispatchGoBack());
  });

  return (
    <S.SettingsContainer>
      <S.LabelTitle>{t('containers.settings.permissions.title')}</S.LabelTitle>
      <LocationSwitcher />
      {authStore.haveAuthorizationData && <PushNotificationSwitcher />}
      <S.SectionDivider />
      <S.LabelTitle>{t('containers.settings.look-and-feel.title')}</S.LabelTitle>
      <DarkModeSwitcher />
      <S.SectionDivider />
      <S.LabelTitle>{t('containers.settings.language.title')}</S.LabelTitle>
      <LanguageSelector />
      <S.SectionDivider />
      <S.VersionString>
        {t('containers.settings.version.label', { version: Constants.expoConfig?.version })}
      </S.VersionString>
    </S.SettingsContainer>
  );
});
