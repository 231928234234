export declare type BaseEntity = {
  readonly id: string;
  readonly entityType: string;

  readonly addressId?: string;

  // Address - Deprecated
  readonly name: string;
  readonly street: string;
  readonly number: string;
  readonly zipCode: string;
  readonly city: string;
  readonly state: string;
  readonly searchString: string;

  readonly latitude: number;
  readonly longitude: number;

  readonly creationTimestamp: string;
  readonly lastModificationTimestamp: string;
};

export const EntityType = Object.freeze({
  LAUNDRY: 'LAUNDRY',
  LOCKER: 'LOCKER',
  SERVICE_PROVIDER: 'SERVICE_PROVIDER',
});
