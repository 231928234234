import { memo } from 'react';
import { useTranslation } from 'react-i18next';

import { BlockButton } from '@ioupie/components/buttons';
import { Dialog } from '@ioupie/components/custom';
import { ActionsBar, Portal } from '@ioupie/components/layout';
import { Text } from '@ioupie/components/typography';

import { RemoveConfirmationModalProps } from './remove-confirmation-modal.models';

/**
 * @function RemoveConfirmationModalComponent
 */
export default memo(({ showModal, onShowModal, onRemoveTicketPressed, orderNumber }: RemoveConfirmationModalProps) => {
  const [t] = useTranslation();

  const modalTranslationPath = 'containers.orders.orders-list.order-history-item.remove-confirmation-modal';
  const title = t(`${modalTranslationPath}.title`);
  const warning = t(`${modalTranslationPath}.warning`, { orderNumber });
  const remove = t(`${modalTranslationPath}.remove`);
  const cancel = t(`${modalTranslationPath}.cancel`);

  return (
    <Portal>
      <Dialog.View onDismiss={() => onShowModal(false)} visible={showModal}>
        <Dialog.Title>{title}</Dialog.Title>
        <Dialog.Content>
          <Text>{warning}</Text>
        </Dialog.Content>
        <Dialog.Actions>
          <ActionsBar>
            <BlockButton reduced secondary text={cancel} onPress={() => onShowModal(false)} />
            <BlockButton reduced text={remove} onPress={onRemoveTicketPressed} />
          </ActionsBar>
        </Dialog.Actions>
      </Dialog.View>
    </Portal>
  );
});
