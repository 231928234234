import { observer } from 'mobx-react-lite';
import { Fragment, useCallback, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { FlatList, ListRenderItemInfo } from 'react-native';

import { ErrorSnackbar, List, Loader, NoContent } from '@ioupie/components/custom';
import { AuthModal } from '@ioupie/containers/auth';
import { useAuthStore, useLockersStore, useNavigationStore, useOrdersStore } from '@ioupie/hooks';
import { ORDER_STATUS, ORDER_STEPS, routes } from '@ioupie/shared/constants';
import { ServiceOrder } from '@ioupie/shared/models';

import { OrderHistoryItem } from './order-history-item';

/**
 * @function OrdersListContainer
 */
export default observer(() => {
  const [t] = useTranslation();
  const ordersStore = useOrdersStore();
  const authStore = useAuthStore();
  const lockersStore = useLockersStore();
  const navigationStore = useNavigationStore();

  // fetch lockers to manage locker tickets
  // bypass in case lockers tab has not been visited yet
  useEffect(() => {
    if (lockersStore.lockers.length <= 0) {
      // eslint-disable-next-line no-void
      void lockersStore.fetchAllLockers();
    }
  }, []);

  const navigateToTrackOrder = useCallback(async (orderHistory: ServiceOrder) => {
    const hasPaymentChangesToAccept = orderHistory.status === ORDER_STATUS.PENDING_PAYMENT;

    await ordersStore.trackOrderHistory(orderHistory);

    if (hasPaymentChangesToAccept) {
      ordersStore.changeStep(ORDER_STEPS.FINISH_ORDER);
    }
    navigationStore.dispatchNavigation({
      stack: routes.stacks.orders,
      screen: routes.pages.orders.track_order,
    });
  }, []);

  const handleRemoveTicket = async (id: string): Promise<void> => {
    await ordersStore.removeTicket(id);
    await ordersStore.fetchUserOrdersHistory();
  };

  const orderKeyExtractor = useCallback((orderHistory: ServiceOrder) => orderHistory.id, []);

  const renderOrderHistoryListItem = useCallback((info: ListRenderItemInfo<ServiceOrder>) => {
    const { item: orderHistory } = info;

    return (
      <OrderHistoryItem
        orderHistory={orderHistory}
        onOrderHistoryPressed={() => navigateToTrackOrder(orderHistory)}
        onRemoveTicketPressed={() => handleRemoveTicket(orderHistory.id)}
      />
    );
  }, []);

  return (
    <Fragment>
      {ordersStore.filteredOrdersHistory.length > 0 ? (
        <List.Section>
          <FlatList<ServiceOrder>
            data={ordersStore.filteredOrdersHistory}
            keyExtractor={orderKeyExtractor}
            renderItem={renderOrderHistoryListItem}
          />
        </List.Section>
      ) : (
        <NoContent
          show={ordersStore.filteredOrdersHistory.length === 0 && !ordersStore.loading}
          iconName="emoticon-cry-outline"
          title={t('containers.orders.orders-list.no-content.title')}
          subtitle={t('containers.orders.orders-list.no-content.label')}
        />
      )}
      {!authStore.haveAuthorizationData && <AuthModal dismissable={false} />}
      <Loader show={ordersStore.loading} message={t('containers.orders.orders-list.loading')} />
      <ErrorSnackbar errors={ordersStore.errors} onDismiss={() => ordersStore.clearErrors()} />
    </Fragment>
  );
});
