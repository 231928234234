import { routes } from '@ioupie/models-commons';
import { observer } from 'mobx-react-lite';
import { useCallback } from 'react';
import { useTranslation } from 'react-i18next';

import { BlockButton } from '@ioupie/components/buttons';
import { Modal } from '@ioupie/components/custom';
import { useNavigationStore, useOrdersStore } from '@ioupie/hooks';

import * as S from './reject-modal.styles';

/**
 * @function RejectionModalContainer
 */
export default observer(() => {
  const [t] = useTranslation();
  const ordersStore = useOrdersStore();
  const navigationStore = useNavigationStore();

  const { showRejectionModal = false } = ordersStore;

  const modalTranslationPath = 'containers.orders.track-order.finish-order-stepper.finish-order.reject-modal';
  const title = t(`${modalTranslationPath}.title`);
  const warning = t(`${modalTranslationPath}.warning`);
  const reject = t(`${modalTranslationPath}.reject`);
  const cancel = t(`${modalTranslationPath}.cancel`);

  const dismissRejectionModal = useCallback(() => {
    ordersStore.setShowRejectionModal(false);
  }, []);

  const navigateToHelpPage = useCallback(() => {
    ordersStore.setShowRejectionModal(false);
    navigationStore.dispatchNavigation({
      stack: routes.stacks.help,
      screen: routes.pages.help.help,
    });
  }, []);

  return (
    <Modal dismissable visible={showRejectionModal} onDismiss={dismissRejectionModal}>
      <S.ModalContent>
        <S.ModalTitle>{title}</S.ModalTitle>
        <S.ModalWarning>{warning}</S.ModalWarning>
        <S.ModalActions>
          <BlockButton reduced secondary text={cancel} onPress={dismissRejectionModal} />
          <BlockButton reduced text={reject} onPress={navigateToHelpPage} />
        </S.ModalActions>
      </S.ModalContent>
    </Modal>
  );
});
