import { KeyboardAwareScrollView } from 'react-native-keyboard-aware-scroll-view';
import styled from 'styled-components/native';

export default styled(KeyboardAwareScrollView)`
  flex: 1;
  flex-basis: auto;
  flex-grow: 1;
  flex-shrink: 1;
  width: 100%;
`;
