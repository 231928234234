import { lighten } from 'polished';
import { Platform } from 'react-native';
import styled from 'styled-components/native';

import { UniversalImage } from '@ioupie/components/gallery';
import { Text } from '@ioupie/components/typography';
import { colors, fontsFamily, fontsWeight } from '@ioupie/shared/constants';
import { themefy } from '@ioupie/shared/utils';

export const PaymentContainer = styled.View`
  margin: 0 16px;
`;

export const PaymentHeader = styled.View`
  align-items: center;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  margin: 0 0 8px 0;
`;

export const PaymentTitle = styled(Text)`
  color: ${themefy({
    light: colors.light.title,
    dark: colors.dark.title,
  })};
  font-family: ${fontsFamily.bold};
  font-size: 16px;
  font-weight: ${fontsWeight.bold};
  margin: 16px 0 10px;
`;

export const PaymentMethodContainer = styled.View`
  align-items: center;
  flex-direction: row;
  justify-content: space-between;
`;

export const MethodTypeContainer = styled.View`
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  justify-content: space-around;
`;

type Selectable = {
  readonly selected?: boolean;
};

export const MethodImage = styled(UniversalImage)`
  flex: ${Platform.OS === 'web' ? '1' : 'none'};
  min-height: ${Platform.OS === 'web' ? '25px' : 'auto'};
  min-width: ${Platform.OS === 'web' ? '80px' : 'auto'};
`;

export const MethodDetailsContainer = styled.TouchableOpacity<Selectable>`
  background: ${themefy({
    light: colors.light.background,
    dark: colors.dark.background,
  })};
  border: 1px solid
    ${themefy({
      light: lighten(0.5, colors.light.secondary),
      dark: colors.dark.secondary,
    })};
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
  border-radius: 7px;
  padding: 5px 10px;
  margin: 0px 10px;
  opacity: ${(props) => {
    if (props.selected) {
      return '1';
    }

    return '0.4';
  }};
`;

export const MethodLabelsContainer = styled.View`
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  margin-left: 16px;
`;

export const MethodType = styled(Text)`
  font-size: 15px;
  margin-bottom: 5px;
`;

export const MethodTypeInfo = styled(Text)`
  margin: 0;
`;

export const SecurityCodeBlock = styled.View`
  flex: 1;
  margin-left: 10px;
`;

export const SecurityCodeInput = styled.View`
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
`;

export const SecurityCodeLabel = styled(Text)`
  font-family: ${fontsFamily.bold};
  font-size: 15px;
  font-weight: ${fontsWeight.bold};
`;

export const EstimatedAmount = styled.View`
  flex-direction: row;
  justify-content: space-between;
  margin: 0 0 8px;
`;

export const EstimatedText = styled(Text)`
  font-size: 15px;
`;
