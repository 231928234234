import { memo } from 'react';

import { FormView, ScrollView } from '@ioupie/components/layout';
import { ZipCodeForm } from '@ioupie/containers/location';
import { useAddressStore, useCurrentRouteFocus, useHeaderStore, useNavigationStore } from '@ioupie/hooks';
import { routes } from '@ioupie/shared/constants';

/**
 * @function AddNewAddressPage
 */
export default memo(() => {
  const headerStore = useHeaderStore();
  const addressStore = useAddressStore();
  const navigationStore = useNavigationStore();

  useCurrentRouteFocus(routes.pages.address.add_new_address, () => {
    headerStore.allowNavigateBack(true);
    headerStore.removeHeaderShadow(false);
    headerStore.changeTitle('pages.address.addresses-list.title');
    // headerStore.changeSubtitle('pages.address.addresses-list.subtitle');
    headerStore.onClickBack(() => {
      addressStore.clearZipCodeInfo();
      navigationStore.dispatchGoBack();
    });
  });

  return (
    <ScrollView>
      <FormView>
        <ZipCodeForm />
      </FormView>
    </ScrollView>
  );
});
