import { lighten, darken } from 'polished';
import styled from 'styled-components/native';

import { BlockButton, IconButton } from '@ioupie/components/buttons';
import { Icon } from '@ioupie/components/gallery';
import { TextInput } from '@ioupie/components/inputs';
import { Title } from '@ioupie/components/typography';
import { colors, fontsFamily, fontsWeight } from '@ioupie/shared/constants';
import { themefy } from '@ioupie/shared/utils';

export const AddressContainer = styled.View`
  background-color: ${themefy({
    light: lighten(0.45, colors.light.tertiary),
    dark: darken(0.5, colors.dark.tertiary),
  })};
  border-radius: 15px;
  justify-content: space-between;
  margin: 3% 3% 1%;
  max-height: 400px;
  padding: 2%;
  width: 94%;
`;

export const AddressWrapper = styled.View`
  flex-direction: row;
  width: 100%;
`;

export const AddressText = styled(TextInput)`
  width: 80%;
`;

export const AddressTitle = styled(Title)`
  color: ${themefy({
    light: colors.light.text,
    dark: colors.dark.text,
  })};
  font-family: ${fontsFamily.bold};
  font-weight: ${fontsWeight.bold};
`;

export const AddressLabel = styled(Title)`
  font-family: ${fontsFamily.bold};
  font-weight: ${fontsWeight.bold};
  font-size: 16px;
`;

export const AddressButton = styled(IconButton)`
  width: 20%;
`;

export const GpsLocationWrapper = styled.View`
  border-top-color: ${lighten(0.1, colors.light.text2)};
  border-top-width: 1px;
  flex-direction: column;
  margin-top: 15px;
  padding-top: 15px;
  width: 100%;
`;

export const GpsLocationButton = styled(BlockButton)`
  width: 100%;
`;

export const AddressDisplayWrapper = styled.View`
  align-items: center;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  padding: 1%;
  width: 100%;
`;

export const AddressDisplayWrapperTitleAndSubtitle = styled.View`
  display: flex;
  flex-grow: 2;
  flex-direction: column;
  justify-content: space-around;
  padding: 2px 5px 2px;
`;

export const AddressDisplayWrapperTextFields = styled.View`
  display: flex;
  flex-direction: column;
  width: 80%;
`;

export const AddressDisplayText = styled(Title)`
  flex-grow: 2;
  font-family: ${fontsFamily.regular};
  font-size: 15px;
  font-weight: ${fontsWeight.regular};
  line-height: 22px;
  margin: 0;
`;

export const AddressPrimaryIcon = styled(Icon)`
  color: ${themefy({
    light: lighten(0.2, colors.light.text),
    dark: darken(0.2, colors.dark.text),
  })};
  margin: 0;
`;

export const AddressSecondaryIcon = styled(Icon)`
  color: ${themefy({
    light: lighten(0.1, colors.light.text),
    dark: darken(0.1, colors.dark.text),
  })};
  margin: 0 3% 0 0;
`;

export const AddressEditIcon = styled(Icon)`
  color: ${themefy({
    light: lighten(0.1, colors.light.tertiary),
    dark: darken(0.1, colors.dark.tertiary),
  })};
`;

export const AddressDisplayTitle = styled(Title)`
  color: ${themefy({
    light: colors.light.secondary,
    dark: colors.dark.secondary,
  })};
  font-family: ${fontsFamily.bold};
  font-size: 16px;
  font-weight: ${fontsWeight.bold};
  line-height: 22px;
  margin: 0;
  padding: 0;
`;
