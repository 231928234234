import styled from 'styled-components/native';

import { Text } from '@ioupie/components/typography';

export const ThankMessage = styled.View`
  align-items: center;
  flex-direction: column;
  flex-grow: 2;
  justify-content: center;
`;

// export const ThankMessage = styled.View`
//   align-items: center;
//   flex-direction: column;
//   height: 77%;
//   justify-content: center;
//   min-height: 77%;
//   margin: 10px;
// `;

export const TitleMessage = styled(Text)`
  font-size: 24px;
  font-weight: bold;
  margin-bottom: 32px;
`;

export const TextMessage = styled(Text)`
  font-size: 20px;
  margin: 0 40px 20px;
  text-align: center;
`;

export const ButtonActions = styled.View`
  margin-bottom: 20px;
`;
