import styled from 'styled-components/native';

import { List } from '@ioupie/components/custom';
import { Text } from '@ioupie/components/typography';

export const PaymentListContainer = styled(List.Section)`
  height: 70%;
  min-height: 70%;
`;

export const CenterTitle = styled.View`
  align-items: center;
  justify-content: center;
  margin: 6px 0;
  padding: 10px 0;
  width: 100%;
`;

export const CardDetails = styled(List.Message)`
  padding-left: 10px;
  margin-top: -5px;
`;

export const NoCardMessage = styled(Text)`
  margin: 40px 0;
  text-align: center;
`;
