import { observer } from 'mobx-react-lite';
import { Fragment, useCallback, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { View } from 'react-native';

import { BlockButton } from '@ioupie/components/buttons';
import { Dialog, ErrorSnackbar, Loader } from '@ioupie/components/custom';
import { ActionsBar, Portal } from '@ioupie/components/layout';
import { Text } from '@ioupie/components/typography';
import { useAddressStore, useHeaderStore, useLockersStore, useNavigationStore, useOrdersStore } from '@ioupie/hooks';
import { COMPARTMENT_TYPE, LOCKER_STATUS, ORDER_STEPS, ORDER_TRACKING_SCREENS, routes } from '@ioupie/shared/constants';

import * as S from './withdrawn.styles';

/**
 * @function UnlockLockerContainer
 */
export default observer(() => {
  const [t] = useTranslation();
  const headerStore = useHeaderStore();
  const ordersStore = useOrdersStore();
  const lockersStore = useLockersStore();
  const addressStore = useAddressStore();
  const navigationStore = useNavigationStore();

  const [touchedOpen, setTouchedOpen] = useState(false);
  const [openConfirmModal, setOpenConfirmModal] = useState(false);
  const [buttonLoading, setButtonLoading] = useState(false);

  const { lockerProvider } = lockersStore;
  const { selectedOrderHistory, loading: orderLoading } = ordersStore;
  const { id: serviceOrderId = '', outboundDetails } = selectedOrderHistory ?? {};
  const { lockerId = '', compartment } = outboundDetails ?? {};
  const { identifier = 0, type = COMPARTMENT_TYPE.SHOWCASE } = compartment ?? {};

  headerStore.onClickBack(() => navigationStore.dispatchGoBack());

  const navigateToNpsEvaluation = useCallback(() => {
    ordersStore.setDetailsOfNewOrder(true);
    ordersStore.changeStep(ORDER_STEPS.APPROVED);
    navigationStore.dispatchNavigation({
      stack: routes.stacks.orders,
      screen: routes.pages.orders.nps_evaluation,
    });
  }, []);

  ordersStore.logOrderTrackingNavigation(ORDER_TRACKING_SCREENS.PICKUP);

  const handleOpenCompartment = async (): Promise<void> => {
    setButtonLoading(true);

    // First, verify if user is next to the locker
    const lockerInRadius = await addressStore.isNextToLocker(lockerProvider);

    if (!lockerInRadius) {
      ordersStore.showFarFromLocker();
    }

    if (lockerInRadius && outboundDetails) {
      setTouchedOpen(true);

      await Promise.all([
        lockersStore.openLockerCompartment(outboundDetails),
        ordersStore.finishServiceOrder(selectedOrderHistory),
      ]);
    }

    setButtonLoading(false);
  };

  const withdrawnOrder = (): void => {
    setButtonLoading(true);
    setOpenConfirmModal(false);
    setTouchedOpen(false);
    setButtonLoading(false);
    navigateToNpsEvaluation();
  };

  const onModalOpen = useCallback(() => {
    setOpenConfirmModal(true);
  }, []);

  const onModalClose = useCallback(() => {
    setOpenConfirmModal(false);
  }, []);

  useEffect(() => {
    // eslint-disable-next-line no-void
    void lockersStore.selectLockerProvider(lockerId);
  }, [lockerId]);

  const isLockerAvailable = lockerProvider && lockerProvider.status === LOCKER_STATUS.AVAILABLE;
  const description = touchedOpen
    ? 'containers.orders.track-order.withdrawn.descriptionOpened'
    : 'containers.orders.track-order.withdrawn.descriptionClosed';
  const openButtonLabel = touchedOpen
    ? 'containers.orders.track-order.withdrawn.reopen'
    : 'containers.orders.track-order.withdrawn.open';
  const compartmentLabel =
    type === COMPARTMENT_TYPE.SHOWCASE
      ? t('containers.orders.track-order.withdrawn.showcase')
      : t('containers.orders.track-order.withdrawn.door');

  return (
    <Fragment>
      <View>
        <S.CompartmentTitle>
          {t('containers.orders.track-order.withdrawn.message', { compartmentLabel })}
        </S.CompartmentTitle>
        <S.LockerNumberStyle>
          {orderLoading ? <Loader show={orderLoading} /> : <S.CompartmentNumber>{identifier}</S.CompartmentNumber>}
        </S.LockerNumberStyle>
      </View>
      <S.UnlockLockerContainer>
        <S.Description>{t(description, { compartmentLabel })}</S.Description>
        <S.ActionButtons>
          <BlockButton
            reduced={touchedOpen}
            secondary={touchedOpen}
            disabled={!serviceOrderId || buttonLoading || !isLockerAvailable}
            text={t(openButtonLabel, { compartmentLabel })}
            onPress={handleOpenCompartment}
            loading={buttonLoading}
          />
          {touchedOpen && (
            <BlockButton
              reduced
              disabled={buttonLoading}
              text={t('containers.orders.track-order.withdrawn.finish')}
              onPress={onModalOpen}
              loading={buttonLoading}
            />
          )}
        </S.ActionButtons>
        {!isLockerAvailable && (
          <S.LockerUnavailable>
            {t('containers.orders.track-order.withdrawn.lockerUnavailableDescription')}
          </S.LockerUnavailable>
        )}
      </S.UnlockLockerContainer>
      <ErrorSnackbar errors={ordersStore.errors} onDismiss={() => ordersStore.clearErrors()} />

      <Portal>
        <Dialog.View dismissable visible={openConfirmModal}>
          <Dialog.Title>{t('containers.orders.track-order.withdrawn.confirm.title')}</Dialog.Title>
          <Dialog.Content>
            <Text>{t('containers.orders.track-order.withdrawn.confirm.message', { compartmentLabel })}</Text>
          </Dialog.Content>
          <Dialog.Actions>
            <ActionsBar>
              <BlockButton
                secondary
                reduced
                text={t('containers.orders.track-order.withdrawn.confirm.cancel')}
                onPress={onModalClose}
              />
              <BlockButton
                reduced
                text={t('containers.orders.track-order.withdrawn.confirm.conclude')}
                onPress={withdrawnOrder}
              />
            </ActionsBar>
          </Dialog.Actions>
        </Dialog.View>
      </Portal>
    </Fragment>
  );
});
