import * as amplitude from '@amplitude/analytics-react-native';
import firebase from '@react-native-firebase/app';
import rudderStack from '@rudderstack/rudder-sdk-react-native';
import { activateKeepAwakeAsync } from 'expo-keep-awake';
import * as Notifications from 'expo-notifications';
import { inject, injectable, postConstruct } from 'inversify';
import { action, makeObservable, observable, runInAction } from 'mobx';
import { Platform } from 'react-native';
import appsFlyer from 'react-native-appsflyer';

import { VALUE_TYPES, type ServiceFlags } from '@ioupie/shared/constants';

@injectable()
export class BootstrapStore {
  @observable public appReady: boolean = false;

  public constructor(@inject(VALUE_TYPES.SERVICE_FLAGS) private readonly serviceFlags: ServiceFlags) {}

  @postConstruct()
  public init(): void {
    makeObservable(this);
  }

  @action
  public async loadApplication(): Promise<void> {
    try {
      if (__DEV__ && Platform.OS !== 'web') {
        await activateKeepAwakeAsync();
      }

      Notifications.setNotificationHandler({
        handleNotification: async () => this.notificationHandler(),
      });

      const granted = await this.tryFetchTrackingPermissions();

      if (granted) {
        await Promise.all([this.initFirebase(), this.initAppsFlyer(), this.initAmplitude(), this.initRudderStack()]);
      }

      runInAction(() => {
        this.appReady = true;
      });
    } catch (error) {
      console.log('Bootstrap failed', error);
      runInAction(() => {
        // some operations won't work on web mode anyways, so check if should load the app....
        this.appReady = Platform.OS === 'web';
        // if it crashes to load... it crashes...
      });
    }
  }

  private async tryFetchTrackingPermissions() {
    try {
      // dynamically import to not crash web bundles
      const { requestTrackingPermissionsAsync } = await import('expo-tracking-transparency');
      const { granted } = await requestTrackingPermissionsAsync();

      return granted;
    } catch (error) {
      // expo native module for transparency is broken for web
      return Platform.OS === 'web';
    }
  }

  public async notificationHandler(): Promise<Notifications.NotificationBehavior> {
    return Promise.resolve({
      shouldShowAlert: true,
      shouldPlaySound: true,
      shouldSetBadge: true,
    });
  }

  public async initFirebase(): Promise<void> {
    if (!this.serviceFlags.analytics.firebase.enable) {
      return Promise.resolve();
    }

    try {
      if (firebase.apps.length > 0) {
        // already initialized, no need to bootstrap
        return;
      }

      await firebase.initializeApp({
        apiKey: 'AIzaSyBYFY2TDNmuBCeAdEt7L4C5gWLeLv-KZKk',
        authDomain: 'ioupie-mobile-app-expo.firebaseapp.com',
        databaseURL: 'https://ioupie-mobile-app-expo.firebaseio.com',
        projectId: 'ioupie-mobile-app-expo',
        storageBucket: 'ioupie-mobile-app-expo.appspot.com',
        messagingSenderId: '349829330475',
        appId: '1:349829330475:web:efad375b5fb93052e85c6e',
        measurementId: 'G-RD8R39FK19',
      });
    } catch (error) {
      console.log('Could not initialize Firebase SDK', error);
    }
  }

  public async initAppsFlyer(): Promise<void> {
    if (!this.serviceFlags.analytics.appsFlyer.enable) {
      return Promise.resolve();
    }

    try {
      await appsFlyer.initSdk({
        isDebug: __DEV__,
        devKey: 'L6xnAm2NPVsK6sycczxS6f',
        appId: 'id1495466238', // iOS only
        onInstallConversionDataListener: true,
        onDeepLinkListener: true,
        timeToWaitForATTUserAuthorization: 30,
      });
    } catch (error) {
      console.log('Could not initialize AppsFlyer SDK', error);
    }
  }

  public async initRudderStack(): Promise<void> {
    if (!this.serviceFlags.analytics.rudderStack.enable) {
      return Promise.resolve();
    }

    try {
      await rudderStack.setup('2ZVbZiIijRq0GpUpuWhmjwYUURX', {
        dataPlaneUrl: 'https://ioupietecvdkbu.dataplane.rudderstack.com',
        trackAppLifecycleEvents: true,
        recordScreenViews: true,
        collectDeviceId: true,
        autoCollectAdvertId: true,
      });
    } catch (error) {
      console.log('Could not initialize Rudderstack SDK', error);
    }
  }

  public async initAmplitude(): Promise<void> {
    if (!this.serviceFlags.analytics.amplitude.enable) {
      return Promise.resolve();
    }

    try {
      await amplitude.init('46f31b2d7e63e925f0f49d80ae52b7e1', undefined, {
        defaultTracking: true,
        trackingOptions: { appSetId: true, adid: true, idfv: true },
      }).promise;
    } catch (error) {
      console.log('Could not initialize Amplitude SDK', error);
    }
  }
}
