import { memo } from 'react';

import { BodyView } from '@ioupie/components/layout';
import { DeleteCreditCard, PaymentList } from '@ioupie/containers/payment';
import { useCurrentRouteFocus, useHeaderStore, useNavigationStore } from '@ioupie/hooks';
import { routes } from '@ioupie/shared/constants';

/**
 * @function PaymentMethodsListPage
 */
export default memo(() => {
  const headerStore = useHeaderStore();
  const navigationStore = useNavigationStore();

  useCurrentRouteFocus(routes.pages.payment.methods_list, () => {
    headerStore.allowNavigateBack(true);
    headerStore.removeHeaderShadow(false);
    headerStore.changeTitle('pages.payment-methods-list.title');
    headerStore.changeSubtitle('pages.payment-methods-list.subtitle');
    headerStore.onClickBack(() => navigationStore.dispatchGoBack());
  });

  return (
    <BodyView>
      <PaymentList />
      <DeleteCreditCard />
    </BodyView>
  );
});
