import { NavigationContainer } from '@react-navigation/native';
import { observer } from 'mobx-react-lite';
import { Children, Fragment } from 'react';

import { useNavigationStore, useSettingsStore } from '@ioupie/hooks';

// import { navigationLinks } from './navigation.links';
import { NavigationProps } from './navigation.props';
import { NavigationDarkTheme, NavigationLightTheme } from './navigation.styles';

/**
 * @function NavigationProvider
 */
export default observer(({ children }: NavigationProps) => {
  const settingsStore = useSettingsStore();
  const navigationStore = useNavigationStore();

  const NavigationTheme = settingsStore.darkMode ? NavigationDarkTheme : NavigationLightTheme;

  return (
    <NavigationContainer
      ref={navigationStore.navigationRef}
      theme={NavigationTheme}
      // linking={navigationLinks}
      onStateChange={(state) => navigationStore.updateNavigationState(state)}>
      <Fragment>{Children.toArray(children)}</Fragment>
    </NavigationContainer>
  );
});
