import styled from 'styled-components/native';

import { Bold, Subheading, Text } from '@ioupie/components/typography';
import { colors, fontsFamily, fontsWeight } from '@ioupie/shared/constants';
import { themefy } from '@ioupie/shared/utils';

export const DeliveryScheduleContainer = styled.View`
  margin: 10px 10px 20px;
`;

export const LabelTitle = styled(Text)`
  font-family: ${fontsFamily.bold};
  font-weight: ${fontsWeight.bold};
  font-size: 16px;
  padding: 10px;
  text-align: left;
`;

export const NoPaddingSectionContainer = styled.View`
  background-color: ${themefy({
    light: colors.light.background,
    dark: colors.dark.background,
  })};
  margin: 0 0 10px;
  min-height: 100px;
`;

export const SectionContainer = styled.View`
  background-color: ${themefy({
    light: colors.light.background,
    dark: colors.dark.background,
  })};
  margin: 0 0 10px;
  min-height: 100px;
  padding: 15px;
`;

export const SectionFlexContainer = styled.View`
  align-items: center;
  display: flex;
  flex: 1;
  flex-flow: row wrap;
  justify-content: space-around;
  margin: 5px 0 0;
`;

export const SectionTitle = styled(Text)`
  font-family: ${fontsFamily.bold};
  font-size: 16px;
  font-weight: ${fontsWeight.bold};
  text-align: left;
`;

export const SectionText = styled(Text)`
  flex-grow: 4;
  font-size: 15px;
  text-align: left;
  width: 70%;
`;

export const SharedLockerDisclaimer = styled(Bold)`
  background-color: ${themefy({
    light: colors.light.background,
    dark: colors.dark.background,
  })};
  margin-bottom: 15px;
  padding: 10px 15px;
  text-align: center;
`;

export const OtherDate = styled(Text)`
  border-top-color: ${themefy({
    light: colors.light.text2,
    dark: colors.dark.text2,
  })};
  border-top-width: 1px;
  color: ${themefy({
    light: colors.light.text,
    dark: colors.dark.text,
  })};
  text-align: center;
  text-decoration: underline;
  margin-top: 15px;
  padding-top: 15px;
`;

export const PaymentBreakdownSection = styled.View`
  border-bottom-color: ${themefy({
    light: colors.light.text2,
    dark: colors.dark.text2,
  })};
  border-bottom-width: 1px;
  flex-direction: column;
  justify-content: space-around;
  margin: 0 15px 10px;
  padding: 0 0 15px;
`;

export const PaymentBreakdownEntry = styled.View`
  display: flex;
  flex-flow: row wrap;
  justify-content: space-between;
  margin: 0 0 5px;
`;

export const PreviousPaymentsContainer = styled.View`
  margin: 10px 0 0;
`;

export const PaymentBreakdownText = styled(Text)`
  font-size: 15px;
`;

export const PaymentBreakdownSecondaryText = styled(Text)`
  color: ${themefy({
    light: colors.light.text2,
    dark: colors.dark.text2,
  })};
  font-size: 15px;
`;

export const PaymentBreakdownBoldText = styled(Text)`
  font-family: ${fontsFamily.bold};
  font-size: 15px;
  font-weight: ${fontsWeight.bold};
`;
