import { AxiosRequestConfig } from 'axios';
import { injectable } from 'inversify';
import { compose } from 'lodash/fp';
import queryString from 'query-string';

import { defaultHeaders, queryClient, restClient } from '@ioupie/client';
import { PATH_PARAMS, QUERY_PARAMS } from '@ioupie/shared/constants';
import { PathParams, QueryParams, RequestHeaders, UrlEndpoint } from '@ioupie/shared/models';
import { isEnumOf, isString, safeObjectLookup } from '@ioupie/shared/utils';

@injectable()
export class RestService {
  private readonly pathParamsFilter = isEnumOf(PATH_PARAMS);
  private readonly queryParamsFilter = isEnumOf(QUERY_PARAMS);

  // public readonly client = restClient;
  // public readonly query = queryClient;

  public async get<T>(urlEndpoint: UrlEndpoint, extraHeaders?: RequestHeaders, staleTime?: number): Promise<T> {
    const urlWithAttributes = this.urlBuilder(urlEndpoint);
    const headers = this.headersBuilder(extraHeaders);

    return await queryClient.fetchQuery({
      queryKey: [urlEndpoint],
      queryFn: async () => {
        const response = await restClient.get<T>(urlWithAttributes, headers);
        return response.data;
      },
      staleTime,
    });
  }

  public async post<T, R>(
    urlEndpoint: UrlEndpoint,
    payload?: T,
    extraHeaders?: RequestHeaders,
    staleTime?: number,
  ): Promise<R> {
    const urlWithAttributes = this.urlBuilder(urlEndpoint);
    const headers = this.headersBuilder(extraHeaders);

    return await queryClient.fetchQuery({
      queryKey: [urlEndpoint, payload],
      queryFn: async () => {
        const response = await restClient.post<R>(urlWithAttributes, payload, headers);
        return response.data;
      },
      staleTime,
    });
  }

  public async put<T, R>(
    urlEndpoint: UrlEndpoint,
    payload?: T,
    extraHeaders?: RequestHeaders,
    staleTime?: number,
  ): Promise<R> {
    const urlWithAttributes = this.urlBuilder(urlEndpoint);
    const headers = this.headersBuilder(extraHeaders);

    return await queryClient.fetchQuery({
      queryKey: [urlEndpoint, payload],
      queryFn: async () => {
        const response = await restClient.put<R>(urlWithAttributes, payload, headers);
        return response.data;
      },
      staleTime,
    });
  }

  public async delete<R>(urlEndpoint: UrlEndpoint, extraHeaders?: RequestHeaders, staleTime?: number): Promise<R> {
    const urlWithAttributes = this.urlBuilder(urlEndpoint);
    const headers = this.headersBuilder(extraHeaders);

    return await queryClient.fetchQuery({
      queryKey: [urlEndpoint],
      queryFn: async () => {
        const response = await restClient.delete<R>(urlWithAttributes, headers);
        return response.data;
      },
      staleTime,
    });
  }

  private headersBuilder(extraHeaders: RequestHeaders = {}): AxiosRequestConfig {
    return {
      ...defaultHeaders,
      headers: extraHeaders,
      // timeout: 10000,
    };
  }

  private urlBuilder(urlEndpoint: UrlEndpoint): string {
    if (isString(urlEndpoint)) {
      return urlEndpoint;
    }

    const urlPatcher = compose(
      (url: string) => this.buildUrlWithParams(url, urlEndpoint.pathParams),
      (url: string) => this.buildUrlWithQueries(url, urlEndpoint.queryParams),
    );

    return urlPatcher(urlEndpoint.url);
  }

  private buildUrlWithParams(url: string = '', params: PathParams = {}): string {
    return Object.keys(params)
      .filter(this.pathParamsFilter)
      .reduce((patched, path) => {
        const pathData = safeObjectLookup(params, path) ?? '';
        return patched.replace(path, pathData);
      }, url);
  }

  private buildUrlWithQueries(url: string = '', queries: QueryParams = {}): string {
    return Object.keys(queries)
      .filter(this.queryParamsFilter)
      .reduce((patched, query) => {
        const queryData = safeObjectLookup(queries, query) ?? {};
        return patched.replace(query, queryString.stringify(queryData, { arrayFormat: 'comma' }));
      }, url);
  }
}
