import { lighten, darken } from 'polished';
import styled from 'styled-components/native';

import { List } from '@ioupie/components/custom';
import { Text, Title } from '@ioupie/components/typography';
import { colors } from '@ioupie/shared/constants';
import { themefy } from '@ioupie/shared/utils';

export const ListContainer = styled(List.Section)`
  height: 70%;
  min-height: 70%;
`;

export const ListTitle = styled(Title)`
  margin: 20px;
`;

export const ListDescription = styled(Text)`
  margin: 0 20px 20px;
`;

export const ListEntryTitle = styled(Title)`
  font-size: 16px;
`;

export const ListEntryDescription = styled(Text)`
  font-size: 14px;
  padding: 2px 0;
`;

export const ListEntryAddon = styled(Text)`
  color: ${themefy({ light: lighten(0.4, colors.light.text), dark: darken(0.2, colors.dark.text) })};
  font-size: 14px;
  padding: 5px 0;
`;
