export const fontsFamily = Object.freeze({
  bold: 'montserrat-bold',
  semibold: 'montserrat-semibold',
  medium: 'montserrat-medium',
  regular: 'montserrat-regular',
  light: 'montserrat-light',
  thin: 'montserrat-thin',
} as const);

export const fontsWeight = Object.freeze({
  bold: '600',
  semibold: '600',
  medium: '500',
  regular: '400',
  light: '300',
  thin: '100',
} as const);
