export declare type BasePaymentMethod = {
  readonly id: string;
  readonly userId: string;
  readonly methodType: string;
  readonly status: string;
  readonly creationTimestamp: Date | string;
  readonly lastModificationDate?: Date | string;
  readonly setPreferredAt?: number;
  readonly metadata?: PaymentMethodMetadata;
};

export declare type CreditCardData = {
  readonly preferred: boolean;
  readonly cardNumber: string;
  readonly expirationMonth: string;
  readonly expirationYear: string;
  readonly cardHolderName: string;
  readonly issuer: string;
  readonly lastDigits: string;
};

export declare type PaymentMethodMetadata = {
  readonly pii: string;
  readonly platform: string;
  readonly identifier?: PaymentMethodIdentifierMetadata;
  readonly method?: PaymentMethodMetadataMethod;
};

export declare type PaymentMethodMetadataMethod = {
  readonly issuer?: string;
  readonly lastDigits?: string;
};

export declare type PaymentMethodIdentifierMetadata = {
  readonly paymentMethod: string;
  readonly setupIntent: string;
};

export declare type PaymentMethod = BasePaymentMethod & Partial<CreditCardData> & object;

export declare type PaymentPlatformIdentifier = {
  readonly platform: string;
  readonly identifier: Record<string, string>;
  readonly method?: Record<string, string>;
  readonly pii?: string;
};

export const PaymentPlatform = Object.freeze({
  cash: 'cash',
  inter: 'inter',
  ioupie: 'ioupie',
  mercadopago: 'mercadopago',
  rede: 'rede',
  stripe: 'stripe',
});
