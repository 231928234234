import { memo } from 'react';

import { OutlinedButtonProps } from './outlined-button.models';
import * as S from './outlined-button.styles';

/**
 * @function OutlinedButtonComponent
 */
export default memo((props: OutlinedButtonProps) => {
  const { onPress, active = false, disabled = false, text = '' } = props;

  return (
    <S.AreaButton mode="outlined" onPress={onPress} disabled={disabled} active={active}>
      <S.ButtonText>{text}</S.ButtonText>
    </S.AreaButton>
  );
});
