/* eslint-disable no-void */
import analytics from '@react-native-firebase/analytics';
import { inject, injectable } from 'inversify';

import { AnalyticsConstants, AnalyticsEvents, VALUE_TYPES, type ServiceFlags } from '@ioupie/shared/constants';
import { type UserProfile } from '@ioupie/shared/models';

import { type AnalyticsService } from './analytics.service';
import { BaseAnalyticsService } from './base-analytics.service';

@injectable()
export class FirebaseAnalyticsService extends BaseAnalyticsService implements AnalyticsService {
  public constructor(@inject(VALUE_TYPES.SERVICE_FLAGS) private readonly serviceFlags: ServiceFlags) {
    super();
  }

  public async setUser(userId: string) {
    if (!this.serviceFlags.analytics.firebase.enable) {
      return Promise.resolve();
    }

    try {
      await analytics().setUserId(userId);
    } catch (error) {
      console.error(`G4 user [${userId}] error`, error);
    }
  }

  public async setUserProperties(userProps: UserProfile) {
    if (!this.serviceFlags.analytics.firebase.enable) {
      return Promise.resolve();
    }

    try {
      const stringUserProps = this.convert(userProps);

      await analytics().setUserProperties(stringUserProps);
    } catch (error) {
      console.error(`G4 user [${userProps?.subject}] properties error`, error);
    }
  }

  public async setScreen(screen: string, metaData: Record<string, string> = {}) {
    if (!this.serviceFlags.analytics.firebase.enable) {
      return Promise.resolve();
    }

    try {
      const screenView = this.prefixNativeEventName('screen_view');
      const eventPayload = this.buildScreenPayload(screen, metaData);

      await analytics().logEvent(screenView, eventPayload);
    } catch (error) {
      console.error(`G4 screen [${screen}] error`, error);
    }
  }

  public async trackEvent(event: AnalyticsEvents, eventData: Record<string, any> = {}) {
    if (!this.serviceFlags.analytics.firebase.enable) {
      return Promise.resolve();
    }

    try {
      const prefixedEventName = this.prefixEventName(AnalyticsConstants.Firebase[event]);

      await analytics().logEvent(prefixedEventName, eventData);
    } catch (error) {
      console.error(`G4 event [${event}] error`, error);
    }
  }

  public async trackUnprefixedEvent(event: AnalyticsEvents, eventData: Record<string, any> = {}) {
    if (!this.serviceFlags.analytics.firebase.enable) {
      return Promise.resolve();
    }

    try {
      const prefixedEventName = this.prefixNativeEventName(AnalyticsConstants.Firebase[event]);

      await analytics().logEvent(prefixedEventName, eventData);
    } catch (error) {
      console.error(`G4 native event [${event}] error`, error);
    }
  }
}
