import { Fragment, memo, ReactElement, useCallback, useState } from 'react';
import { useTranslation } from 'react-i18next';

import { RadioButton } from '@ioupie/components/custom';
import { Divider, ScrollView } from '@ioupie/components/layout';
import { Bold } from '@ioupie/components/typography';
import { CARPET_SHAPES } from '@ioupie/shared/constants';
import { CarpetMetadata, ProductData } from '@ioupie/shared/models';
import { isEnumOf, safeObjectLookup } from '@ioupie/shared/utils';

import * as S from './carpet.styles';
import { RectangleCarpet } from './rectangle';
import { RoundCarpet } from './round';

export default memo(CarpetCustom);

type CarpetCustomProps = {
  readonly customizingCarpet: ProductData;
  readonly onSendCarpetSize: (metadata: CarpetMetadata) => void;
};

function CarpetCustom({ customizingCarpet, onSendCarpetSize }: CarpetCustomProps) {
  const [t] = useTranslation();
  const [carpetShape, setCarpetShape] = useState(CARPET_SHAPES.RECTANGLE);

  const onCarpetShapeSelection = useCallback((value: string): void => {
    const isCarpetShape = isEnumOf(CARPET_SHAPES);

    if (isCarpetShape(value)) {
      setCarpetShape(value);
    }
  }, []);

  const carpetFragments: Record<CARPET_SHAPES, ReactElement> = {
    [CARPET_SHAPES.RECTANGLE]: (
      <RectangleCarpet customizingCarpet={customizingCarpet} onSendCarpetSize={onSendCarpetSize} />
    ),
    [CARPET_SHAPES.ROUND]: <RoundCarpet customizingCarpet={customizingCarpet} onSendCarpetSize={onSendCarpetSize} />,
  };

  return (
    <ScrollView>
      <S.CarpetImageContainer>
        <S.CarpetImage image={customizingCarpet.image} />
      </S.CarpetImageContainer>
      <S.TitleBox>
        <Bold>{t('containers.catalog.shop-catalog.custom.carpet-shape.title')}</Bold>
      </S.TitleBox>
      <RadioButton.Group onValueChange={onCarpetShapeSelection} value={carpetShape}>
        <RadioButton.Item
          mode="android"
          value={CARPET_SHAPES.RECTANGLE}
          label={t('containers.catalog.shop-catalog.custom.carpet-shape.rectangle-shape')}
        />
        <RadioButton.Item
          mode="android"
          value={CARPET_SHAPES.ROUND}
          label={t('containers.catalog.shop-catalog.custom.carpet-shape.round-shape')}
        />
      </RadioButton.Group>
      <Divider />
      {safeObjectLookup(carpetFragments, carpetShape) ?? <Fragment />}
    </ScrollView>
  );
}
