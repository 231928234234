import { inject, injectable, postConstruct } from 'inversify';
import { action, computed, makeObservable, observable, runInAction } from 'mobx';

import { ErrorService, RestService, type AnalyticsService } from '@ioupie/services';
import { PATH_PARAMS, SERVICE_TYPES, STORE_TYPES, endpoints } from '@ioupie/shared/constants';
import type {
  DeleteUserPayload,
  EditUserProfile,
  ErrorMessages,
  UrlEndpoint,
  User,
  UserProfile,
} from '@ioupie/shared/models';

import { AuthStore } from './auth.store';

@injectable()
export class UserStore {
  @inject(SERVICE_TYPES.REST)
  private readonly restService: RestService;
  @inject(SERVICE_TYPES.ANALYTICS.COMPOSITE)
  private readonly analyticsService: AnalyticsService;
  @inject(SERVICE_TYPES.ERROR)
  private readonly errorService: ErrorService;

  @inject(STORE_TYPES.AUTH)
  private readonly authStore: AuthStore;
  @observable public isDeleteAccountDialogOpen: boolean = false;

  @observable public loading: boolean = false;
  @observable public errors: ErrorMessages = [];

  @observable public profileData?: UserProfile;

  @postConstruct()
  public init(): void {
    makeObservable(this);
  }

  @action
  public clearErrors(): void {
    this.errors = [];
  }

  @action
  public showDeleteAccountDialog(): void {
    this.isDeleteAccountDialogOpen = true;
  }

  @action
  public closeDeleteAccountDialog(): void {
    this.isDeleteAccountDialogOpen = false;
  }

  @action
  public async fetchUserData(): Promise<void> {
    this.loading = true;

    try {
      const urlEndpoint: UrlEndpoint = {
        url: endpoints.portaria.user.info,
        pathParams: { [PATH_PARAMS.USER_ID]: this.authStore.username },
      };

      const response = await this.restService.get<UserProfile>(urlEndpoint, this.authStore.buildAuthHeaders());
      this.analyticsService.setUserProperties(response);

      runInAction(() => {
        this.loading = false;
        this.profileData = response;
      });
    } catch (error) {
      runInAction(() => {
        this.loading = false;
        this.errors = this.errorService.wrapApiError(error);
      });
    }
  }

  @action
  public async updateUserData(editUserProfile: EditUserProfile): Promise<void> {
    this.loading = true;

    try {
      if (!this.profileData) {
        throw new Error('No profile available!');
      }

      const urlEndpoint: UrlEndpoint = {
        url: endpoints.portaria.user.update,
      };

      const payload: User = {
        ...editUserProfile,
        username: this.profileData.email,
        email: this.profileData.email,
      };

      await this.restService.put<User, unknown>(urlEndpoint, payload, this.authStore.buildAuthHeaders());

      runInAction(() => {
        this.loading = false;
      });
    } catch (error) {
      runInAction(() => {
        this.loading = false;
        this.errors = this.errorService.wrapApiError(error);
      });
    }
  }

  @action
  public async deleteUser(feedback: string): Promise<void> {
    this.loading = true;

    try {
      if (!this.profileData) {
        throw new Error('No profile available!');
      }

      const urlEndpoint: UrlEndpoint = {
        url: endpoints.portaria.user.removal,
      };

      const payload: DeleteUserPayload = {
        username: this.profileData.email,
        feedback,
      };

      await this.restService.post<DeleteUserPayload, unknown>(urlEndpoint, payload, this.authStore.buildAuthHeaders());

      runInAction(() => {
        this.loading = false;
      });
    } catch (error) {
      runInAction(() => {
        this.loading = false;
        this.errors = this.errorService.wrapApiError(error);
      });
    }
  }

  @computed
  public get editUserData(): EditUserProfile {
    const {
      allergen = false,
      allergenInfo = '',
      cpf = '',
      familyName = '',
      name = '',
      perfume = false,
      pwd = false,
      phoneNumber: fullPhoneNumber = '',
      birthDate,
    } = this.profileData ?? {};

    const phoneAreaCode = fullPhoneNumber.slice(3, 5);
    const phoneNumber = fullPhoneNumber.slice(5);

    return {
      allergen,
      allergenInfo,
      cpf,
      familyName,
      name,
      perfume,
      pwd,
      phoneAreaCode,
      phoneNumber,
      birthDate,
    };
  }
}
