import { observer } from 'mobx-react-lite';
import { Fragment, useCallback } from 'react';
import { useTranslation } from 'react-i18next';
import { FlatList, ListRenderItemInfo } from 'react-native';

import { ErrorSnackbar, List, Loader } from '@ioupie/components/custom';
import { useShopsStore } from '@ioupie/hooks';
import { SHOP_STEPS } from '@ioupie/shared/constants';
import { CatalogItem } from '@ioupie/shared/models';

/**
 * @function CategoriesStepComponent
 */
export default observer(() => {
  const [t] = useTranslation();
  const shopsStore = useShopsStore();

  const onCategorySelect = useCallback((catalog: CatalogItem) => {
    shopsStore.selectCategory(catalog.groupId);
    shopsStore.changeStep(SHOP_STEPS.PRODUCTS);
  }, []);

  const catalogKeyExtractor = useCallback((catalog: CatalogItem) => catalog.groupId, []);

  const renderCatalogListItem = useCallback((info: ListRenderItemInfo<CatalogItem>) => {
    const { item: catalog } = info;
    return (
      <List.Item
        onPress={() => onCategorySelect(catalog)}
        left={<List.Image size="medium" image={catalog.image} />}
        body={<List.Message title={catalog.name} subtitle={catalog.description} />}
        right={<List.Icon size="medium" icon="chevron-right" />}
      />
    );
  }, []);

  return (
    <Fragment>
      <List.Section>
        <FlatList<CatalogItem>
          data={Object.values(shopsStore.productsCatalog)}
          keyExtractor={catalogKeyExtractor}
          renderItem={renderCatalogListItem}
        />
      </List.Section>
      <Loader show={shopsStore.loading} message={t('containers.catalog.shop-catalog.categories.loading')} />
      <ErrorSnackbar errors={shopsStore.errors} onDismiss={() => shopsStore.clearErrors()} />
    </Fragment>
  );
});
