import styled from 'styled-components/native';

import { safeObjectLookup } from '@ioupie/shared/utils';

export declare type LegendSpaceSizes = 'small' | 'medium' | 'large';

declare type LegendProps = {
  readonly spaceLeft?: LegendSpaceSizes;
  readonly spaceRight?: LegendSpaceSizes;
};

const sizesInPixels: Record<LegendSpaceSizes, number> = {
  small: 60,
  medium: 80,
  large: 100,
};

export default styled.View<LegendProps>`
  margin-left: ${({ spaceLeft }) => {
    const size = safeObjectLookup(sizesInPixels, spaceLeft) ?? 20;
    return `${size}px`;
  }};
  margin-right: ${({ spaceRight }) => {
    const size = safeObjectLookup(sizesInPixels, spaceRight) ?? 20;
    return `${size}px`;
  }};
  padding-left: 12px;
  padding-right: 12px;
`;
