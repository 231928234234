import { darken, lighten } from 'polished';
import styled from 'styled-components/native';

import { UniversalImage } from '@ioupie/components/gallery';
import { colors, fontsFamily, fontsWeight } from '@ioupie/shared/constants';
import { themefy } from '@ioupie/shared/utils';

export const LockerInformationContainer = styled.View`
  display: flex;
  flex-flow: column;
  justify-content: space-around;
  padding: 10px 0 10px 10px;
`;

export const LockerImage = styled(UniversalImage)`
  border-radius: 50px;
  height: 90px;
  width: 90px;
`;

export const LockerName = styled.Text`
  color: ${themefy({
    light: colors.light.text,
    dark: colors.dark.text,
  })};
  font-family: ${fontsFamily.semibold};
  font-size: 16px;
  font-weight: ${fontsWeight.semibold};
  margin-bottom: 5px;
`;

export const LockerAddress = styled.Text`
  color: ${themefy({
    light: colors.light.text,
    dark: colors.dark.text,
  })};
  font-family: ${fontsFamily.regular};
  font-size: 14px;
  font-weight: ${fontsWeight.regular};
  padding: 0 0 4px;
`;

export const LockerAvailableDoorsCount = styled.Text`
  color: #5fb205;
  font-family: ${fontsFamily.regular};
  font-size: 14px;
  font-weight: ${fontsWeight.regular};
  padding: 3px 0;
`;

export const LockerRestrictedLabel = styled.Text`
  color: ${themefy({
    light: lighten(0.4, colors.light.text),
    dark: darken(0.2, colors.dark.text),
  })};
  font-family: ${fontsFamily.regular};
  font-size: 12px;
  font-weight: ${fontsWeight.regular};
  padding: 3px 0;
`;
