import { darken, lighten } from 'polished';
import styled from 'styled-components/native';

import { UniversalImage } from '@ioupie/components/gallery';
import { Title } from '@ioupie/components/typography';
import { colors, fontsFamily, fontsWeight } from '@ioupie/shared/constants';
import { themefy } from '@ioupie/shared/utils';

export const PaymentDetailsContainer = styled.View`
  border-top-color: ${themefy({
    light: lighten(0.6, colors.light.text),
    dark: darken(0.5, colors.dark.text),
  })};
  border-top-width: 3px;
  margin: 10px;
  padding: 10px;
`;

export const PaymentDetailsTitle = styled(Title)`
  color: ${themefy({
    light: colors.light.text,
    dark: colors.dark.text,
  })};
  font-family: ${fontsFamily.bold};
  font-size: 16px;
  font-weight: ${fontsWeight.bold};
`;

export const PixHeader = styled.TouchableOpacity`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;
  margin: 10px 30px;
`;

export const PixFooter = styled.View`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  margin: 10px 0px;
`;

export const PixCodeContainer = styled.View`
  display: flex;
  align-items: center;
`;

export const PixCode = styled(UniversalImage)`
  width: 200px;
  height: 200px;
`;
