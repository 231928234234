import styled from 'styled-components/native';

import { BlockButton } from '@ioupie/components/buttons';
import { Divider } from '@ioupie/components/layout';

export const Separator = styled(Divider)`
  margin: 16px 0;
`;

export const NextButton = styled(BlockButton)`
  margin: 16px 0;
`;

export const SecurePaymentContainer = styled.View`
  margin-top: 15px;
`;
