import { DateTime } from 'luxon';
import { observer } from 'mobx-react-lite';
import { useTranslation } from 'react-i18next';

import ioupieIcon from '@ioupie/assets/icon.png';
import { useNavigationStore, useOrdersStore, useSettingsStore } from '@ioupie/hooks';
import { CUR_LOCATE, DF_LOCALE, IANA_ZONE, ORDER_STEPS, routes } from '@ioupie/shared/constants';

import { ApprovedMessage } from './approved-message';
import { RejectedMessage } from './rejected-message';
import * as S from './thank-step.styles';

/**
 * @function ThankStepComponent
 */
export default observer(() => {
  const [t] = useTranslation();
  const ordersStore = useOrdersStore();
  const settingsStore = useSettingsStore();
  const navigationStore = useNavigationStore();

  const { selectedOrderHistory, scheduledOrderDate = '' } = ordersStore;
  const { grandTotal } = selectedOrderHistory ?? {};
  const { amount = 0 } = grandTotal ?? {};

  const totalPrice = `${t(CUR_LOCATE)} ${amount.toFixed(2)}`;

  const wrappedDate = DateTime.fromISO(scheduledOrderDate);
  wrappedDate.setZone(IANA_ZONE);
  const scheduledDate = wrappedDate.toFormat(t(DF_LOCALE));

  const handleGoHome = async (): Promise<void> => {
    navigationStore.dispatchNavigation({
      stack: routes.stacks.orders,
      screen: routes.pages.orders.home,
    });
    if (!settingsStore.pushNotificationPermissionAlreadyRequested) {
      await settingsStore.requestPushNotificationPermission();
    }
  };

  return (
    <S.Background source={ioupieIcon}>
      <S.ThankStepContainer>
        {ordersStore.orderStep === ORDER_STEPS.REJECTED ? (
          <RejectedMessage action={handleGoHome} />
        ) : (
          <ApprovedMessage totalPrice={totalPrice} scheduledDate={scheduledDate} action={handleGoHome} />
        )}
      </S.ThankStepContainer>
    </S.Background>
  );
});
