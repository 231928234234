import { observer } from 'mobx-react-lite';
import { useTranslation } from 'react-i18next';

import { RadioButton } from '@ioupie/components/custom';
import { Bold } from '@ioupie/components/typography';
import { useOrdersStore, useTheme } from '@ioupie/hooks';
import { ORDER_STATUS_CATEGORY, colors } from '@ioupie/shared/constants';

import * as S from './orders-category-filter.styles';

/**
 * @function OrdersCategoryFilterContainer
 */
export default observer(() => {
  const [t] = useTranslation();
  const ordersStore = useOrdersStore();
  const theme = useTheme();

  return (
    <S.FilterContainer>
      <S.FilterHeader onPress={() => ordersStore.toggleShowCategoryFilter()}>
        <S.FilterIcon
          color={theme.mode === 'dark' ? colors.dark.text : colors.light.text}
          size={15}
          name={ordersStore.showCategoriesFilter ? 'chevron-up' : 'chevron-down'}
        />
        <Bold>
          {t(
            ordersStore.showCategoriesFilter
              ? 'containers.orders.orders-category-filter.hide'
              : 'containers.orders.orders-category-filter.show',
          )}
        </Bold>
      </S.FilterHeader>
      {ordersStore.showCategoriesFilter && (
        <S.RadioContainer>
          <RadioButton.Group
            onValueChange={(value) => ordersStore.onStatusCategorySelection(value)}
            value={ordersStore.categoriesFilterValue}>
            <RadioButton.Item
              mode="android"
              value={ORDER_STATUS_CATEGORY.ALL}
              label={t('containers.orders.orders-category-filter.categories.all')}
            />
            <RadioButton.Item
              mode="android"
              value={ORDER_STATUS_CATEGORY.PENDING}
              label={t('containers.orders.orders-category-filter.categories.pending')}
            />
            <RadioButton.Item
              mode="android"
              value={ORDER_STATUS_CATEGORY.DONE}
              label={t('containers.orders.orders-category-filter.categories.done')}
            />
          </RadioButton.Group>
        </S.RadioContainer>
      )}
    </S.FilterContainer>
  );
});
