import styled from 'styled-components/native';

import { TextInput } from '@ioupie/components/inputs';
import { Divider } from '@ioupie/components/layout';
import { Small, Text } from '@ioupie/components/typography';
import { colors } from '@ioupie/shared/constants';
import { themefy } from '@ioupie/shared/utils';

export const Container = styled.View`
  flex: 1;
  padding: 20px;
`;

export const CenterTitle = styled.View`
  align-items: center;
  justify-content: center;
  margin: 0 0 15px;
  width: 100%;
`;

export const LinkText = styled(Small)`
  color: #0645ad;
  font-weight: bold;
  padding: 5px 12px;
`;

export const PhoneContainer = styled.View`
  flex-direction: row;
  justify-content: space-between;
  width: 100%;
`;

export const PhoneAreaInput = styled(TextInput)`
  width: 25%;
`;

export const PhoneNumberInput = styled(TextInput)`
  width: 70%;
`;

export const AllergenInfoBox = styled.View`
  background-color: ${themefy({
    light: colors.light.background,
    dark: colors.dark.background,
  })};
  padding: 10px;
  margin: 10px 0;
`;

export const Separator = styled(Divider)`
  margin: 20px 0;
`;

export const AboutAccount = styled(Text)`
  font-size: 16px;
  padding: 0 0 5px;
  margin: 0 0 16px;
`;
