import { observer } from 'mobx-react-lite';
import { Fragment, ReactElement, useEffect, useMemo } from 'react';

import { IoupieView } from '@ioupie/components/custom/ioupie-view';
import { FormView } from '@ioupie/components/layout';
import { useAuthStore, useHeaderStore, useNavigationStore } from '@ioupie/hooks';
import { routes, USER_STEPS } from '@ioupie/shared/constants';
import { Optional } from '@ioupie/shared/models';
import { safeObjectLookup } from '@ioupie/shared/utils';

import { CheckUser } from './check-user';
import { ForgotPassword } from './forgot-password';
import { SignIn } from './sign-in';
import { SignOut } from './sign-out';
import { SignUp } from './sign-up';
import { TwoFactor } from './two-factor';

/**
 * @function AccountContainer
 */
export default observer(() => {
  const headerStore = useHeaderStore();
  const authStore = useAuthStore();
  const navigationStore = useNavigationStore();

  const headerBackActionsPerStep: Record<USER_STEPS, Optional<CallableFunction>> = useMemo(
    () => ({
      [USER_STEPS.AUTO_LOGON]: undefined,
      [USER_STEPS.CHECK_USERNAME]: undefined,
      [USER_STEPS.DO_SIGN_IN]: () => {
        authStore.clearUsername();
        authStore.changeStep(USER_STEPS.CHECK_USERNAME);
        headerStore.changeSubtitle('');
      },
      [USER_STEPS.DO_SIGN_UP]: () => {
        authStore.clearUsername();
        authStore.changeStep(USER_STEPS.CHECK_USERNAME);
        headerStore.changeSubtitle('');
      },
      [USER_STEPS.DO_SIGN_OUT]: undefined,
      [USER_STEPS.FORGOT_PASSWORD]: () => {
        authStore.changeStep(USER_STEPS.DO_SIGN_IN);
        headerStore.changeSubtitle(authStore.username);
      },
      [USER_STEPS.NEED_HELP]: undefined,
      [USER_STEPS.TWO_FACTOR]: () => {
        authStore.clearUsername();
        authStore.changeStep(USER_STEPS.CHECK_USERNAME);
        headerStore.changeSubtitle('');
      },
    }),
    [],
  );

  const actionsToTakePerStep: Record<USER_STEPS, CallableFunction> = useMemo(
    () => ({
      [USER_STEPS.AUTO_LOGON]: () => {
        navigationStore.dispatchNavigation({
          stack: routes.stacks.orders,
        });
        authStore.changeStep(USER_STEPS.DO_SIGN_OUT, false);
      },
      [USER_STEPS.CHECK_USERNAME]: () => {
        headerStore.changeSubtitle('');
      },
      [USER_STEPS.DO_SIGN_IN]: () => {
        headerStore.changeSubtitle(authStore.username);
      },
      [USER_STEPS.DO_SIGN_UP]: () => {
        headerStore.changeSubtitle('containers.account.sign-up.header.subtitle');
      },
      [USER_STEPS.DO_SIGN_OUT]: () => {
        headerStore.changeSubtitle('');
      },
      [USER_STEPS.FORGOT_PASSWORD]: () => {
        headerStore.changeSubtitle('containers.account.forgot-password.header.subtitle');
      },
      [USER_STEPS.NEED_HELP]: () => undefined,
      [USER_STEPS.TWO_FACTOR]: () => {
        headerStore.changeSubtitle(authStore.username);
      },
    }),
    [],
  );

  const containerPerStep: Record<USER_STEPS, ReactElement> = useMemo(
    () => ({
      [USER_STEPS.AUTO_LOGON]: <Fragment />,
      [USER_STEPS.CHECK_USERNAME]: (
        <IoupieView>
          <CheckUser />
        </IoupieView>
      ),
      [USER_STEPS.DO_SIGN_IN]: (
        <IoupieView>
          <SignIn />
        </IoupieView>
      ),
      [USER_STEPS.DO_SIGN_UP]: (
        <FormView>
          <SignUp />
        </FormView>
      ),
      [USER_STEPS.DO_SIGN_OUT]: (
        <IoupieView>
          <SignOut />
        </IoupieView>
      ),
      [USER_STEPS.FORGOT_PASSWORD]: (
        <FormView>
          <ForgotPassword />
        </FormView>
      ),
      [USER_STEPS.NEED_HELP]: <Fragment />,
      [USER_STEPS.TWO_FACTOR]: (
        <IoupieView>
          <TwoFactor />
        </IoupieView>
      ),
    }),
    [],
  );

  useEffect(() => {
    // configure the header
    const headerBackAction = safeObjectLookup(headerBackActionsPerStep, authStore.userStep);
    headerStore.onClickBack(headerBackAction);

    // side effects per state
    const actionsToTake = safeObjectLookup(actionsToTakePerStep, authStore.userStep);
    if (actionsToTake) actionsToTake();
  }, [authStore.userStep]);

  return safeObjectLookup(containerPerStep, authStore.userStep) ?? <Fragment />;
});
