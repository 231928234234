import { observer } from 'mobx-react-lite';
import { useCallback } from 'react';
import { useTranslation } from 'react-i18next';

import { useOrdersStore } from '@ioupie/hooks';

import * as S from './orders-filter.styles';

/**
 * @function OrdersFilterContainer
 */
export default observer(() => {
  const [t] = useTranslation();
  const ordersStore = useOrdersStore();

  const doNothing = useCallback((): void => undefined, []);

  return (
    <S.FilterContainer>
      <S.SearchTextInput
        autoCapitalize="none"
        returnKeyType="done"
        keyboardType="numeric"
        label={t('containers.orders.orders-filter.label')}
        placeholder={t('containers.orders.orders-filter.placeholder')}
        value={ordersStore.filterQuery}
        onChangeText={(query: string) => ordersStore.setFilterQuery(query)}
      />
      <S.SearchIconButton icon="magnify" size={30} disabled onPress={doNothing} />
    </S.FilterContainer>
  );
});
