import styled from 'styled-components/native';

import { Text } from '@ioupie/components/typography';
import { colors, fontsFamily, fontsWeight } from '@ioupie/shared/constants';
import { themefy } from '@ioupie/shared/utils';

export const SummaryTitle = styled(Text)`
  color: ${themefy({
    light: colors.light.title,
    dark: colors.dark.title,
  })};
  font-family: ${fontsFamily.bold};
  font-size: 16px;
  font-weight: ${fontsWeight.bold};
  margin: 16px 16px 0;
`;

export const SelectionInfoContainer = styled.View`
  margin: 10px 16px;
`;
