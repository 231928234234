import { Money } from '../promotions';

export declare type Coupon = {
  readonly redemptionKey: string;
  readonly couponId: string;
  readonly status: keyof typeof CouponStatus;
  readonly startsAt: number;
  readonly endsAt?: number;
  readonly restrictions: CouponRestriction;
  readonly limits: CouponLimits;
  readonly strategy: CouponStrategy; // FIXME
  readonly displayAttributes: any; // FIXME
  readonly createdBy?: string;
  readonly description?: string;
};

export declare type CouponRestrictionValue = string | readonly string[] | number | Money;

export declare type CouponRestriction = {
  readonly type: string;
  readonly value?: CouponRestrictionValue;
  readonly operation?: keyof typeof LogicalOperator;
  readonly criteria?: readonly any[];
};

// Limits should be checked by a batch job that runs every hour to flip the coupon state.
export declare type CouponLimits = {
  readonly totalUsages: number;
  readonly totalBudget: Money;
  readonly orderBudget: Money;
};

export declare type CouponStrategy = {
  readonly granularity: keyof typeof CouponGranularity;
  readonly type: keyof typeof CouponStrategyType;
  readonly value: number | Money;
  readonly funding?: readonly CouponFunding[];
};

export declare type CouponFunding = {
  readonly entity: string;
  readonly weight: number;
  readonly cap: Money;
};

export enum CouponStatus {
  ACTIVE = 'ACTIVE',
  EXPIRED = 'EXPIRED',
  INACTIVE = 'INACTIVE',
}

export const CouponGranularity = Object.freeze({
  LINE_ITEM: 'LINE_ITEM',
  ORDER: 'ORDER',
});

export const CouponStrategyType = Object.freeze({
  FIXED_AMOUNT: 'FIXED_AMOUNT',
  PERCENTAGE: 'PERCENTAGE',
});

export enum LogicalOperator {
  AND = 'AND',
  OR = 'OR',
  NOT = 'NOT',
}
