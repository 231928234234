import { injectable } from 'inversify';

@injectable()
export abstract class BaseAnalyticsService {
  protected buildScreenPayload(screen: string, metaData: Record<string, string> = {}) {
    return {
      screen_name: screen,
      // screen_class: screen,
      ...metaData,
    } as const;
  }

  protected prefixEventName(eventName: string): string {
    return __DEV__ ? `Sandbox_${eventName}`.substring(0, 40) : eventName;
  }

  protected prefixNativeEventName(eventName: string): string {
    return __DEV__ ? `Sandbox_${eventName}`.substring(0, 40) : eventName;
  }

  protected convert(data: Record<string, unknown> = {}): Record<string, string> {
    return Object.keys(data).reduce(
      (total, key) => {
        // eslint-disable-next-line security/detect-object-injection
        const value = data[key];
        // do check like this so empty strings and booleans are still converted
        // eslint-disable-next-line no-null/no-null
        if (value !== undefined && value !== null) {
          return { ...total, [key]: JSON.stringify(value) };
        } else {
          return total;
        }
      },
      {} as Record<string, string>,
    );
  }
}
