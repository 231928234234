import xml from 'fast-xml-parser';
import { Fragment, memo, useCallback } from 'react';
import { isWebUri } from 'valid-url';

import noImage from '@ioupie/assets/fallback/no-image.png';
import { isNumber } from '@ioupie/shared/utils';

import { Svg, SvgUri } from '../svg';
import { UniversalImageProps } from './universal-image.models';
import * as S from './universal-image.styles';

/**
 * @function UniversalImageComponent
 */
export default memo(({ image, style, width, height, emptyImage = false }: UniversalImageProps) => {
  const isSvgUri = useCallback((uri: string): boolean => {
    return /\.(svg)$/gim.test(uri);
  }, []);

  // if it's a number, it can only be a regular image
  if (isNumber(image)) {
    return <S.RemoteImg source={image} style={style} width={width} height={height} />;
  }

  // this lib returns an object in case of failures
  if (xml.XMLValidator.validate(image) === true) {
    return <Svg xml={image} style={style} width={width} height={height} />;
  }

  // clear the remote uri, returns undefined if not an uri
  const untaintedUri = isWebUri(image);

  if (untaintedUri) {
    // if it's a svg url
    if (isSvgUri(untaintedUri)) {
      return <SvgUri uri={untaintedUri} source={{ uri: untaintedUri }} style={style} width={width} height={height} />;
    }

    // otherwise it's a remote image url
    return (
      <S.RemoteImg uri={untaintedUri} source={{ uri: untaintedUri }} style={style} width={width} height={height} />
    );
  }

  if (emptyImage) {
    // no images for the current component, use the fallback
    return <S.RemoteImg uri={noImage} source={noImage} style={style} width={width} height={height} />;
  }

  // can be a base 64 image - try to use the image directly
  if (image !== '') {
    return <S.RemoteImg uri={image} source={{ uri: image }} style={style} width={width} height={height} />;
  }

  // malformed image url -> bypass
  return <Fragment />;
});
