import { observer } from 'mobx-react-lite';
import { useTranslation } from 'react-i18next';

import { BlockButton } from '@ioupie/components/buttons';
import { Dialog } from '@ioupie/components/custom';
import { ActionsBar, Portal } from '@ioupie/components/layout';
import { Text } from '@ioupie/components/typography';
import { useNavigationStore, useUserStore } from '@ioupie/hooks';

/**
 * @function DeleteAccountDialogContainer
 */
export default observer(() => {
  const [t] = useTranslation();
  const userStore = useUserStore();
  const navigationStore = useNavigationStore();

  const closeDialog = () => {
    userStore.closeDeleteAccountDialog();
    navigationStore.dispatchGoBack();
  };

  return (
    <Portal>
      <Dialog.View
        dismissable
        visible={userStore.isDeleteAccountDialogOpen}
        onDismiss={() => userStore.closeDeleteAccountDialog()}>
        <Dialog.Title>{t('forms.delete-account.delete-account-dialog.title')}</Dialog.Title>
        <Dialog.Content>
          <Text>{t('forms.delete-account.delete-account-dialog.ok')}</Text>
          <Text>{t('forms.delete-account.delete-account-dialog.process-started')}</Text>
          <Text>{t('forms.delete-account.delete-account-dialog.send-email')}</Text>
        </Dialog.Content>
        <Dialog.Actions>
          <ActionsBar>
            <BlockButton text={t('forms.delete-account.delete-account-dialog.close')} onPress={closeDialog} />
          </ActionsBar>
        </Dialog.Actions>
      </Dialog.View>
    </Portal>
  );
});
