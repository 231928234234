import { observer } from 'mobx-react-lite';
import { useEffect } from 'react';
import { useTranslation } from 'react-i18next';

import { ScrollView } from '@ioupie/components/layout';
import { useOrdersStore, useShopsStore } from '@ioupie/hooks';
import { AnalyticsEvents, BUSINESS_AREA_OPTIONS, ORDERING_STEPS } from '@ioupie/shared/constants';

import { Address } from '../address';
import { Schedule } from '../schedule';
import { Summary } from '../summary';
import * as S from './schedule-step.styles';

/**
 * @function PaymentContainer
 */
export default observer(() => {
  const [t] = useTranslation();
  const shopsStore = useShopsStore();
  const ordersStore = useOrdersStore();

  useEffect(() => {
    ordersStore.recordInAppEventForJourney(AnalyticsEvents.INITIATE_CHECKOUT);
  }, []);

  return (
    <ScrollView>
      <Summary />
      <Address />
      <Schedule />
      <S.NextButton
        text={t('containers.orders.ordering.next-button')}
        onPress={() => ordersStore.changeOrderingStep(ORDERING_STEPS.PAYMENT)}
        disabled={
          !ordersStore.scheduledOrderDate ||
          (shopsStore.shopBusinessArea === BUSINESS_AREA_OPTIONS.DELIVERY && !ordersStore.selectedPickupDate)
        }
      />
    </ScrollView>
  );
});
