export enum BUSINESS_AREA_OPTIONS {
  DELIVERY = 'DELIVERY',
  LOCKER = 'LOCKER',
}

export enum BUSINESS_SEGMENTS_OPTIONS {
  GOODS = 'GOODS',
  LAUNDRY = 'LAUNDRY',
  PET = 'PET',
}

export enum BUSINESS_SEGMENT_TYPE {
  LAUNDRY = 'LAUNDRY',
  PET = 'PET',
  LOCKERS = 'LOCKERS',
}

export enum BUSINESS_PARTNER_STATUS {
  ACTIVE = 'ACTIVE',
  COMING_SOON = 'COMING_SOON',
  INACTIVE = 'INACTIVE',
  PENDING_VALIDATION = 'PENDING_VALIDATION',
  TEMPORARILY_UNAVAILABLE = 'TEMPORARILY_UNAVAILABLE',
}

export const BUSINESS_SEGMENT_TYPE_TRANSLATIONS: Record<BUSINESS_SEGMENT_TYPE, string> = {
  [BUSINESS_SEGMENT_TYPE.LAUNDRY]: 'constants.segment-type.laundry',
  [BUSINESS_SEGMENT_TYPE.PET]: 'constants.segment-type.pet-shop',
  [BUSINESS_SEGMENT_TYPE.LOCKERS]: 'constants.segment-type.lockers',
} as const;
