import { inject, injectable, postConstruct } from 'inversify';
import { action, computed, makeObservable, observable, runInAction } from 'mobx';

import { EncodeService, ErrorService, RestService } from '@ioupie/services';
import {
  BUSINESS_AREA_OPTIONS,
  BUSINESS_SEGMENTS_OPTIONS,
  BUSINESS_SEGMENT_TYPE,
  QUERY_PARAMS,
  SERVICE_TYPES,
  endpoints,
} from '@ioupie/shared/constants';
import { type BusinessSegmentMap, type ErrorMessages, type LockerProviders } from '@ioupie/shared/models';
import { DataFoundByRadiusPayload, type FindProvidersPayload } from '@ioupie/shared/payloads';

@injectable()
export class SegmentsStore {
  @inject(SERVICE_TYPES.REST)
  private readonly restService: RestService;
  @inject(SERVICE_TYPES.ENCODE)
  private readonly encodeService: EncodeService;
  @inject(SERVICE_TYPES.ERROR)
  private readonly errorService: ErrorService;

  @observable public loading: boolean = false;
  @observable public errors: ErrorMessages = [];

  @observable public lockersData: readonly LockerProviders[] = [];

  @observable public businessSegmentMap: BusinessSegmentMap = {
    LAUNDRY: { type: BUSINESS_SEGMENT_TYPE.LAUNDRY, providers: [] },
    PET: { type: BUSINESS_SEGMENT_TYPE.PET, providers: [] },
    LOCKERS: { type: BUSINESS_SEGMENT_TYPE.LOCKERS, providers: [] },
  };

  @postConstruct()
  public init(): void {
    makeObservable(this);
  }

  @action
  public clearErrors(): void {
    this.errors = [];
  }

  @action
  public async fetchSegments(segmentsFilters: FindProvidersPayload): Promise<void> {
    this.loading = true;
    try {
      const {
        LOCKERS = [],
        LAUNDRY: { providers: laundryProviders = [] } = {},
        PET: { providers: petProviders = [] } = {},
      } = await this.restService.get<DataFoundByRadiusPayload>({
        url: endpoints.portaria.providers.find,
        queryParams: {
          [QUERY_PARAMS.ADDRESS_BASE64]: { q: this.encodeService.encodePayloadToBase64(segmentsFilters) },
        },
      });

      const lockerSegments = LOCKERS.map((locker) => ({
        ...locker,
        segment: BUSINESS_SEGMENTS_OPTIONS.LAUNDRY,
        area: BUSINESS_AREA_OPTIONS.LOCKER,
        corporateName: locker.name,
        name: locker.name,
        address: locker.address.street, // revisit this later
      }));

      const laundrySegments = laundryProviders.map((laundry) => ({
        ...laundry,
        segment: BUSINESS_SEGMENTS_OPTIONS.LAUNDRY,
        area: BUSINESS_AREA_OPTIONS.DELIVERY,
      }));

      // empty for now
      const petSegments = true
        ? []
        : petProviders.map((pet) => ({
            ...pet,
            segment: BUSINESS_SEGMENTS_OPTIONS.PET,
            area: BUSINESS_AREA_OPTIONS.DELIVERY,
          }));

      runInAction(() => {
        this.lockersData = LOCKERS;
        this.businessSegmentMap = {
          LAUNDRY: { type: BUSINESS_SEGMENT_TYPE.LAUNDRY, providers: laundrySegments },
          PET: { type: BUSINESS_SEGMENT_TYPE.PET, providers: petSegments },
          LOCKERS: { type: BUSINESS_SEGMENT_TYPE.LOCKERS, providers: lockerSegments },
        };
        this.loading = false;
      });
    } catch (error) {
      runInAction(() => {
        this.errors = this.errorService.wrapApiError(error);
        this.loading = false;
      });
    }
  }

  @computed
  public get hasProviders(): boolean {
    return Object.values(this.businessSegmentMap).filter((segment) => segment.providers.length > 0).length > 0;
  }

  @computed
  public get hasLockers(): boolean {
    return !this.loading && this.lockersData.length > 0;
  }
}
