import styled from 'styled-components/native';

import { UniversalImage } from '@ioupie/components/gallery';
import { Text } from '@ioupie/components/typography';
import { colors } from '@ioupie/shared/constants';
import { themefy } from '@ioupie/shared/utils';

export const EntityContainer = styled.View`
  flex-direction: row;
  align-items: center;
`;

export const EntityImage = styled(UniversalImage)`
  border-radius: 50px;
  height: 50px;
  margin: 0 0 0 35px;
  width: 50px;
`;

export const EntityInformation = styled.View`
  flex-direction: column;
  margin: 16px;
`;

export const EntityName = styled(Text)`
  color: ${themefy({
    light: colors.light.title,
    dark: colors.dark.title,
  })};
  font-size: 15px;
  padding: 2px 0;
`;

export const EntityDetails = styled(Text)`
  color: ${themefy({
    light: colors.light.text3,
    dark: colors.dark.text3,
  })};
  font-size: 13px;
  padding: 2px 0;
`;
