import { useLocalObservable } from 'mobx-react-lite';

import { container } from '@ioupie/injection';
import { STORE_TYPES } from '@ioupie/shared/constants';
import { SettingsStore } from '@ioupie/stores';

export const useSettingsStore = (): SettingsStore => {
  return useLocalObservable(() => {
    return container.get(STORE_TYPES.SETTINGS);
  });
};
