import { types } from 'credit-card-type';
import amex from 'payment-icons/min/flat/amex.svg';
import generic from 'payment-icons/min/flat/default.svg';
import diners from 'payment-icons/min/flat/diners.svg';
import discover from 'payment-icons/min/flat/discover.svg';
import elo from 'payment-icons/min/flat/elo.svg';
import hipercard from 'payment-icons/min/flat/hipercard.svg';
import jcb from 'payment-icons/min/flat/jcb.svg';
import maestro from 'payment-icons/min/flat/maestro.svg';
import mastercard from 'payment-icons/min/flat/mastercard.svg';
import unionpay from 'payment-icons/min/flat/unionpay.svg';
import visa from 'payment-icons/min/flat/visa.svg';

import pix from '@ioupie/assets/misc/pix.svg';

export declare type CardTypes = typeof types[keyof typeof types];

export const CREDIT_CARD_LOGOS: Record<CardTypes, string> = {
  'american-express': amex,
  'diners-club': diners,
  discover,
  elo,
  hiper: generic,
  hipercard,
  jcb,
  maestro,
  mastercard,
  mir: generic,
  unionpay,
  visa,
} as const;

export const PIX_LOGO: string = pix;
