import { Button, Text } from 'react-native-paper';
import styled from 'styled-components/native';

import { colors, fontsFamily, fontsWeight } from '@ioupie/shared/constants';
import { themefy } from '@ioupie/shared/utils';

import { OutlinedButtonProps } from './outlined-button.models';

declare type ColorMode = Pick<OutlinedButtonProps, 'active' | 'disabled'>;

export const AreaButton = styled(Button)<ColorMode>`
  border: 1px solid
    ${(props) => {
      if (props.active) {
        return themefy({
          light: colors.light.primary,
          dark: colors.dark.primary,
        })(props);
      } else {
        return themefy({
          light: colors.light.text2,
          dark: colors.dark.text2,
        })(props);
      }
    }};
  border-radius: 32px;
  height: 40px;
  margin: 8px 0;
  width: auto;
`;

export const ButtonText = styled(Text)<ColorMode>`
  color: ${themefy({
    light: colors.light.text,
    dark: colors.dark.text,
  })};
  font-family: ${fontsFamily.regular};
  font-size: 12px;
  font-weight: ${fontsWeight.regular};
`;
