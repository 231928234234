import styled from 'styled-components/native';

import { Icon } from '@ioupie/components/gallery';
import { colors, fontsFamily, fontsWeight } from '@ioupie/shared/constants';
import { safeObjectLookup, themefy } from '@ioupie/shared/utils';

import { CHIP_COLOR, CONTAINER_COLOR, TEXT_COLOR } from './chip.constants';

declare type ChipWithColor = {
  readonly chipColor?: CHIP_COLOR;
};

export const ChipContainer = styled.View<ChipWithColor>`
  align-items: flex-start;
  background-color: ${({ chipColor = CHIP_COLOR.GREY }) => safeObjectLookup(CONTAINER_COLOR, chipColor)};
  display: flex;
  flex-flow: row;
  margin: 1% 0;
  padding: 2% 3%;
`;

export const ChipIcon = styled(Icon)`
  color: ${themefy({
    light: colors.light.text,
    dark: colors.dark.text,
  })};
  margin: 0 1.5% 0 1%;
`;

export const ChipText = styled.Text<ChipWithColor>`
  color: ${({ chipColor = CHIP_COLOR.GREY }) => safeObjectLookup(TEXT_COLOR, chipColor)};
  font-family: ${fontsFamily.regular};
  font-size: 12px;
  font-weight: ${fontsWeight.regular};
`;
