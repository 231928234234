import styled from 'styled-components/native';

import { Text } from '@ioupie/components/typography';
import { colors } from '@ioupie/shared/constants';
import { themefy } from '@ioupie/shared/utils';

export const DeleteAccountBox = styled.View`
  background-color: ${themefy({
    light: colors.light.background,
    dark: colors.dark.background,
  })};
  padding: 10px;
  margin: 8px 0 20px;
`;

export const DeleteAccountButton = styled(Text)`
  color: ${themefy({
    light: colors.light.link,
    dark: colors.dark.link,
  })};
  margin: 16px 0;
`;

export const FeedbackTitle = styled(Text)`
  margin: 0 0 8px;
`;
