import { memo } from 'react';

import { DeliveryDataProps } from './delivery-data.models';
import * as S from './delivery-data.styles';
import { DeliveryInfo } from './delivery-info';

export default memo(({ leftInfo, rightInfo }: DeliveryDataProps) => (
  <S.InfoContainer>
    {leftInfo && <DeliveryInfo data={leftInfo} />}
    {rightInfo && <DeliveryInfo data={rightInfo} />}
  </S.InfoContainer>
));
