import { DateTime } from 'luxon';
import { observer } from 'mobx-react-lite';
import { Fragment } from 'react';
import { useTranslation } from 'react-i18next';

import { Divider } from '@ioupie/components/layout';
import { useLockersStore, useOrdersStore, useShopsStore } from '@ioupie/hooks';
import { BUSINESS_AREA_OPTIONS, ORDERING_STEPS } from '@ioupie/shared/constants';

import { EntityInfo } from './entity-info';
import { SelectionInfo } from './selection-info';
import * as S from './summary.styles';

/**
 * @function SummaryContainer
 */
export default observer(() => {
  const [t] = useTranslation();
  const shopsStore = useShopsStore();
  const ordersStore = useOrdersStore();
  const lockersStore = useLockersStore();

  const { shopProvider, selectionInfo } = shopsStore ?? {};
  const { image: laundryImage = '', name: laundryName = '' } = shopProvider ?? {};
  const { minimumDays, totalSelected } = selectionInfo ?? {};

  const { lockerProvider } = lockersStore ?? {};
  const { image: lockerImage = '', name: lockerName = '', address = '' } = lockerProvider ?? {};

  const isLocker = shopsStore.shopBusinessArea === BUSINESS_AREA_OPTIONS.LOCKER;
  const isSummaryStep = ordersStore.orderingStep === ORDERING_STEPS.SUMMARY;
  const isScheduleStep = ordersStore.orderingStep === ORDERING_STEPS.SCHEDULE;
  const details = t(`containers.orders.ordering.summary.order-by-${isLocker ? 'locker' : 'delivery'}`);

  const formattedDate = (orderDate: string) => {
    const locale = t('constants.locale.locale');
    const dateFormat = t('containers.orders.ordering.dates.delivery-format');
    return DateTime.fromISO(orderDate).setLocale(locale).toFormat(dateFormat);
  };

  const totalItemsLabel =
    totalSelected === 1
      ? 'containers.orders.ordering.summary.total-item'
      : 'containers.orders.ordering.summary.total-items';

  return (
    <Fragment>
      <S.SummaryTitle>{t('containers.orders.ordering.summary.title')}</S.SummaryTitle>
      <EntityInfo image={laundryImage} name={laundryName} details={details} />
      <Divider />
      {isLocker && (
        <Fragment>
          <EntityInfo image={lockerImage} name={lockerName} details={address} />
          <Divider />
        </Fragment>
      )}
      {!isSummaryStep && (
        <S.SelectionInfoContainer>
          <SelectionInfo
            subject={t('containers.orders.ordering.summary.selected-items')}
            content={t(totalItemsLabel, { totalSelected })}
          />
          {!isScheduleStep && ordersStore.scheduledOrderDate ? (
            <SelectionInfo
              subject={t('containers.orders.ordering.summary.delivery')}
              content={formattedDate(ordersStore.scheduledOrderDate)}
            />
          ) : (
            <SelectionInfo
              subject={t('containers.orders.ordering.summary.min-days')}
              content={t('containers.orders.ordering.summary.min-days-value', { minimumDays })}
            />
          )}
        </S.SelectionInfoContainer>
      )}
      <Divider />
    </Fragment>
  );
});
