import { memo } from 'react';

import { useTheme } from '@ioupie/hooks';
import { colors } from '@ioupie/shared/constants';

import { BlockButtonProps } from './block-button.models';
import * as S from './block-button.styles';

/**
 * @function BlockButtonComponent
 */
export default memo((props: BlockButtonProps) => {
  const {
    onPress,
    style = {},
    blacken = false,
    secondary = false,
    disabled = false,
    loading = false,
    reduced = false,
    small = false,
    autoWidth = false,
    text = '',
  } = props;
  const theme = useTheme();

  const loaderColor = blacken
    ? colors.light.background
    : theme.mode === 'dark'
    ? colors.dark.text2
    : colors.light.text2;

  return (
    <S.AreaView
      blacken={blacken}
      reduced={reduced}
      secondary={secondary}
      style={style}
      disabled={disabled}
      small={small}
      autoWidth={autoWidth}>
      {loading ? (
        <S.Loader animating={loading} color={loaderColor} />
      ) : (
        <S.AreaButton blacken={blacken} secondary={secondary} onPress={onPress} disabled={disabled}>
          <S.ButtonText blacken={blacken} secondary={secondary}>
            {text}
          </S.ButtonText>
        </S.AreaButton>
      )}
    </S.AreaView>
  );
});
