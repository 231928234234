import { darken, lighten } from 'polished';
import { View } from 'react-native';
import styled from 'styled-components/native';

import { Icon, UniversalImage } from '@ioupie/components/gallery';
import { Text, Title } from '@ioupie/components/typography';
import { colors, fontsFamily, fontsWeight } from '@ioupie/shared/constants';
import { themefy } from '@ioupie/shared/utils';

export const Container = styled(View)`
  padding: 20px;
`;

export const ShopDetailsImage = styled(UniversalImage)`
  border-radius: 35px;
  flex-shrink: 0;
  height: 70px;
  width: 70px;
`;

export const ProviderHeader = styled(View)`
  align-items: center;
  flex: 1;
  flex-flow: row nowrap;
  margin-bottom: 15px;
`;

export const ProviderHeaderDetails = styled(View)`
  flex-shrink: 1;
  margin-left: 15px;
`;

export const ProviderTitle = styled(Text)`
  font-family: ${fontsFamily.bold};
  font-size: 18px;
  font-weight: ${fontsWeight.bold};
  margin-bottom: 5px;
`;

export const SubtitleWithoutDivider = styled(Title)`
  font-family: ${fontsFamily.bold};
  font-size: 16px;
  font-weight: ${fontsWeight.bold};
`;

export const Subtitle = styled(SubtitleWithoutDivider)`
  border-top-color: #ccc;
  border-top-width: 1px;
  margin-top: 15px;
  padding-top: 10px;
`;

export const DetailText = styled(Text)`
  padding: 5px 0;
`;

export const NotActiveBannerContainer = styled(View)`
  background: ${themefy({
    light: lighten(0.7, colors.light.text),
    dark: darken(0.6, colors.dark.text),
  })};
  padding: 10px;
`;

export const NotActiveBannerHeader = styled(View)`
  align-items: center;
  flex: 1;
  flex-flow: row nowrap;
`;

export const PaddedIcon = styled(Icon)`
  padding-right: 5px;
`;

export const ProviderEligibilityLabel = styled(View)`
  align-items: center;
  border-left-width: 5px;
  flex: 1;
  flex-flow: row nowrap;
  margin-bottom: 5px;
  padding: 5px 5px 5px 10px;
`;

export const ProviderEligibleLabel = styled(ProviderEligibilityLabel)`
  border-left-color: #228016;
`;

export const ProviderNotEligibleLabel = styled(ProviderEligibilityLabel)`
  border-left-color: #d61c1c;
`;

export const ProviderEligibleText = styled(Text)`
  color: #29a11a;
  padding-left: 5px;
`;

export const ProviderNotEligibleText = styled(Text)`
  color: #d61c1c;
  padding-left: 5px;
`;

export const ProviderResponsibilityDisclaimer = styled(Text)`
  border-top-color: #ccc;
  border-top-width: 1px;
  color: ${themefy({
    light: lighten(0.3, colors.light.text),
    dark: darken(0.3, colors.dark.text),
  })};
  font-size: 13px;
  margin-top: 15px;
  padding-top: 15px;
`;
