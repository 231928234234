import styled from 'styled-components/native';

export const CounterContainer = styled.View`
  align-items: center;
  flex: 1;
  flex-direction: row;
  justify-content: space-between;
  margin: 0;
  max-width: 90px;
  padding: 0;
`;
