import { yupResolver } from '@hookform/resolvers/yup';
import { observer } from 'mobx-react-lite';
import { Fragment, useCallback, useEffect } from 'react';
import { useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { Keyboard, View, TouchableOpacity } from 'react-native';
import * as yup from 'yup';

import { BlockButton } from '@ioupie/components/buttons';
import { ErrorSnackbar, UserStep } from '@ioupie/components/custom';
import { TextInput } from '@ioupie/components/inputs';
import { TooltipBar } from '@ioupie/components/layout';
import { Bold, HelperText, Text, Title } from '@ioupie/components/typography';
import { useAuthStore } from '@ioupie/hooks';
import { debounceOneSecond } from '@ioupie/shared/utils';

import { TwoFactorForm } from './two-factor.models';

/**
 * @function TwoFactorStepComponent
 */
export default observer(() => {
  const [t] = useTranslation();
  const authStore = useAuthStore();

  const twoFactorSchema: yup.SchemaOf<TwoFactorForm> = yup.object().shape({
    confirmationCode: yup
      .string()
      .required(t('forms.two-factor.code.errors.required'))
      .length(6, ({ length }) => t('forms.two-factor.code.errors.length', { length })),
  });

  const {
    register,
    handleSubmit,
    setValue,
    formState: { errors, isValid },
  } = useForm<TwoFactorForm>({
    defaultValues: {
      confirmationCode: '',
    },
    resolver: yupResolver(twoFactorSchema),
  });

  useEffect(() => {
    register('confirmationCode');
  }, [register]);

  const onSubmitValidForm = useCallback((form: TwoFactorForm) => {
    Keyboard.dismiss();
    return authStore.confirmAccount(form.confirmationCode);
  }, []);

  const TwoFactorContent = (
    <View>
      <Title>{t('forms.two-factor.title')}</Title>
      <Text>{t('forms.two-factor.instruction')}</Text>
      <TextInput
        keyboardType="numeric"
        maxLength={6}
        error={Boolean(errors.confirmationCode)}
        label={t('forms.two-factor.code.label')}
        placeholder={t('forms.two-factor.code.placeholder')}
        onChangeText={debounceOneSecond((text) => {
          setValue('confirmationCode', text, { shouldValidate: true });
        })}
      />
      <HelperText type="error" visible={Boolean(errors.confirmationCode)}>
        {errors.confirmationCode?.message ?? ''}
      </HelperText>
    </View>
  );

  const TwoFactorActions = (
    <BlockButton
      blacken
      disabled={!isValid}
      loading={authStore.loading}
      text={t('forms.two-factor.submit')}
      onPress={handleSubmit(onSubmitValidForm)}
    />
  );

  const TwoFactorTooltip = (
    <TooltipBar center>
      <TouchableOpacity onPress={() => authStore.resendVerificationCode()}>
        <Bold black>{t('containers.account.two-factor.resend')}</Bold>
      </TouchableOpacity>
    </TooltipBar>
  );

  return (
    <Fragment>
      <UserStep content={TwoFactorContent} actions={TwoFactorActions} tooltip={TwoFactorTooltip} />
      <ErrorSnackbar errors={authStore.errors} onDismiss={() => authStore.clearErrors()} />
    </Fragment>
  );
});
