import styled from 'styled-components/native';

import { TextInput } from '../text-input';

export const DateContainer = styled.View`
  flex-direction: row;
  justify-content: center;
  align-items: center;
`;

export const ErrorsContainer = styled.View`
  flex-direction: column;
  justify-content: flex-start;
`;

export const DayInput = styled(TextInput)`
  padding: 2px;
  width: 25%;
`;

export const MonthInput = styled(TextInput)`
  padding: 2px;
  width: 25%;
`;

export const YearInput = styled(TextInput)`
  padding: 2px;
  width: 25%;
`;
