import { Fragment, memo, useCallback } from 'react';
import { useTranslation } from 'react-i18next';

import { Chip, CHIP_COLOR, List } from '@ioupie/components/custom';
import { useLockersStore, useNavigationStore, useShopsStore } from '@ioupie/hooks';
import { BUSINESS_AREA_OPTIONS, BUSINESS_SEGMENTS_OPTIONS, LOCKER_STATUS, routes } from '@ioupie/shared/constants';

import { LockerEntryProps } from './locker-entry.models';
import * as S from './locker-entry.styles';

/**
 * @function LockersEntryComponent
 */
export default memo(({ locker }: LockerEntryProps) => {
  const [t] = useTranslation();
  const navigationStore = useNavigationStore();
  const lockersStore = useLockersStore();
  const shopsStore = useShopsStore();

  const selectAndNavigateToProviders = useCallback(() => {
    if (locker.status !== LOCKER_STATUS.AVAILABLE) {
      return;
    }

    lockersStore.selectLockerProvider(locker.id);
    shopsStore.setShopSegment(BUSINESS_SEGMENTS_OPTIONS.LAUNDRY); // laundry only currently
    shopsStore.setShopBusinessArea(BUSINESS_AREA_OPTIONS.LOCKER);
    navigationStore.dispatchNavigation({
      stack: routes.stacks.orders,
      screen: routes.pages.orders.providers,
      loggingMetaData: {
        locker: locker.id,
      },
    });
  }, [locker.id]);

  return (
    <Fragment>
      <List.Item
        onPress={selectAndNavigateToProviders}
        left={<S.LockerImage image={locker.image} height={90} width={90} />}
        body={
          <S.LockerInformationContainer>
            <S.LockerName>{locker.name}</S.LockerName>
            <S.LockerAddress>{locker.address}</S.LockerAddress>
            {locker.restricted && (
              <S.LockerRestrictedLabel>
                {t('containers.lockers.lockers-list.locker-entry.restricted')}
              </S.LockerRestrictedLabel>
            )}
            {locker.status !== undefined && locker.status !== LOCKER_STATUS.AVAILABLE ? (
              <Chip
                content={t(`containers.lockers.lockers-list.locker-entry.status.${locker.status}`)}
                iconName="tools"
                iconSize={18}
                chipColor={CHIP_COLOR.GREY}
              />
            ) : locker.availableDoors > 0 ? (
              <S.LockerAvailableDoorsCount>
                {t('containers.lockers.lockers-list.locker-entry.availableDoors', { num: locker.availableDoors })}
              </S.LockerAvailableDoorsCount>
            ) : (
              <Chip
                content={t('containers.lockers.lockers-list.locker-entry.lockerFull')}
                iconName="tools"
                iconSize={18}
                chipColor={CHIP_COLOR.RED}
              />
            )}
          </S.LockerInformationContainer>
        }
        right={locker.status === LOCKER_STATUS.AVAILABLE && <List.Icon size="medium" icon="chevron-right" />}
      />
    </Fragment>
  );
});
