import styled from 'styled-components/native';

import { Icon } from '@ioupie/components/gallery';
import { Text } from '@ioupie/components/typography';
import { colors, fontsFamily, fontsWeight } from '@ioupie/shared/constants';
import { themefy } from '@ioupie/shared/utils';

export const AttentionBox = styled.View`
  align-items: center;
  background-color: ${themefy({
    light: colors.light.background,
    dark: colors.dark.background,
  })};
  border-color: ${themefy({
    light: colors.light.primary,
    dark: colors.dark.primary,
  })};
  border-left-width: 7px;
  border-width: 2px;
  flex-direction: row;
  margin: 0 15px 0;
  padding: 10px;
`;

export const AttentionBoxIcon = styled(Icon)`
  margin-right: 16px;
`;

export const AttentionBoxText = styled.View`
  flex: 1;
`;

export const Title = styled(Text)`
  font-family: ${fontsFamily.bold};
  font-size: 16px;
  font-weight: ${fontsWeight.bold};
  margin-bottom: 5px;
`;

export const Label = styled(Text)`
  font-family: ${fontsFamily.regular};
  font-size: 15px;
  font-weight: ${fontsWeight.regular};
`;
