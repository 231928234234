import { memo } from 'react';

import { IconButton } from '@ioupie/components/buttons';
import { Bold } from '@ioupie/components/typography';

import { CounterProps } from './counter.models';
import * as S from './counter.styles';

/**
 * @function CounterComponent
 */
export default memo((props: CounterProps) => {
  const { value = 0, onAdd, onSub, preventNegatives = false, iconColor = '', highlight = false } = props;

  const blockSub = preventNegatives && value <= 0;

  return (
    <S.CounterContainer>
      <IconButton icon="minus-circle-outline" onPress={onSub} disabled={blockSub} iconColor={iconColor} />
      <Bold highlight={highlight}>{value.toString()}</Bold>
      <IconButton icon="plus-circle-outline" onPress={onAdd} iconColor={iconColor} />
    </S.CounterContainer>
  );
});
