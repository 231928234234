import { memo } from 'react';

import { AttentionBoxProps } from './attention-box.models';
import * as S from './attention-box.styles';

/**
 * @function AttentionBoxComponent
 */
export default memo((props: AttentionBoxProps) => {
  const { icon, title, text } = props;

  return (
    <S.AttentionBox>
      {icon && <S.AttentionBoxIcon name={icon} size={32} />}
      <S.AttentionBoxText>
        {title && <S.Title>{title}</S.Title>}
        <S.Label>{text}</S.Label>
      </S.AttentionBoxText>
    </S.AttentionBox>
  );
});
