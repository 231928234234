import { memo } from 'react';

import { ListItemProps } from './item.models';
import * as S from './item.styles';

/**
 * @function ListMessageComponent
 */
export default memo(
  ({
    body,
    left,
    right,
    top,
    bottom,
    showBorder = true,
    alignTop = false,
    highlight = false,
    onPress,
    onLongPress,
  }: ListItemProps) => (
    <S.ItemContainer showBorder={showBorder} highlight={highlight}>
      {top && <S.TopContent>{top}</S.TopContent>}
      <S.ItemTouchableArea disabled={!onPress && !onLongPress} onPress={onPress} onLongPress={onLongPress}>
        <S.ItemContent>
          {left && <S.LeftContent alignTop={alignTop}>{left}</S.LeftContent>}
          {body && <S.BodyContent>{body}</S.BodyContent>}
          {right && <S.RightContent>{right}</S.RightContent>}
        </S.ItemContent>
      </S.ItemTouchableArea>
      {bottom && <S.BottomContent>{bottom}</S.BottomContent>}
    </S.ItemContainer>
  ),
);
