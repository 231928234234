import { Inner, Optional } from '../models/common.models';

/**
 * By a given index, find the corresponding element into the array.
 *
 * @function safeArrayLookup
 * @template T The type of the array and the returned value type.
 * @param {Array<T>} array The array where the lookup will occur.
 * @param {number} index The index where the data is located.
 * @returns {Optional} The found element, undefined otherwise.
 */
export const safeArrayLookup = <T>(array: readonly T[], index: number): Optional<T> => {
  const normalizedIndex = index || 0;
  const normalizedArray = array || [];

  return normalizedArray[Number(normalizedIndex)];
};

/**
 * By a given key, find the corresponding element into the object.
 *
 * @function safeObjectLookup
 * @template T The type of the element at the object's key.
 * @template K A key of the received object.
 * @param {T} [object] The object where the lookup will occur.
 * @param {K} [key] The object key where the data is located.
 * @returns {Optional} The found element, undefined otherwise.
 */
export const safeObjectLookup = <T extends Inner<T>, K extends keyof T>(
  object: Optional<T>,
  key: Optional<K>,
): Optional<T[K]> => {
  if (!key || !object) {
    return undefined;
  }

  const hasProperty = Object.prototype.hasOwnProperty.call(object, key);

  // eslint-disable-next-line @typescript-eslint/no-unnecessary-type-assertion
  return hasProperty ? object[key as K] : undefined;
};
