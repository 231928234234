import { memo } from 'react';

import { BodyView } from '@ioupie/components/layout';
import { AuthModal } from '@ioupie/containers/auth';
import { OrdersCategoryFilter, OrdersFilter, OrdersList } from '@ioupie/containers/orders';
import { useCurrentRouteFocus, useHeaderStore, useNavigationStore, useOrdersStore } from '@ioupie/hooks';
import { routes } from '@ioupie/shared/constants';

/**
 * @function MyOrdersTab
 */
export default memo(() => {
  const headerStore = useHeaderStore();
  const ordersStore = useOrdersStore();
  const navigationStore = useNavigationStore();

  useCurrentRouteFocus(routes.tabs.home.my_orders, () => {
    headerStore.allowNavigateBack(false);
    headerStore.removeHeaderShadow(false);
    headerStore.changeTitle('pages.home.my-orders-title');
    headerStore.changeSubtitle('');

    // desperate times requires desperate measures.. sorry bro ¯\_(ヅ)_/¯
    // eslint-disable-next-line no-void
    void ordersStore.fetchUserOrdersHistory();

    navigationStore.sendNavigationAnalytics(`${routes.pages.orders.home}`, routes.tabs.home.my_orders);
  });

  return (
    <BodyView>
      <OrdersFilter />
      <OrdersCategoryFilter />
      <OrdersList />
      <AuthModal dismissable={false} />
    </BodyView>
  );
});
