import styled from 'styled-components/native';

import { colors, statusBarHeight } from '@ioupie/shared/constants';
import { themefy } from '@ioupie/shared/utils';

export const FranchisingView = styled.SafeAreaView`
  background-color: ${themefy({
    light: colors.light.primary,
    dark: colors.dark.primary,
  })};
  flex: 1;
  flex-grow: 1;
  height: 100%;
  padding: ${statusBarHeight}px 16px;
  position: relative;
  width: 100%;
`;
