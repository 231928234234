import { TypeGuard } from '../../models/function.models';
import { isString, isBoolean, isNumber, isObject, isError } from './primitive-type-guard.functions';

export const isArrayOf =
  <T>(typeGuard: TypeGuard<T>) =>
  (data: unknown): data is readonly T[] =>
    Array.isArray(data) && data.every(typeGuard);

export const isArrayOfStrings = isArrayOf(isString);

export const isArrayOfNumbers = isArrayOf(isNumber);

export const isArrayOfBooleans = isArrayOf(isBoolean);

export const isArrayOfObjects = isArrayOf(isObject);

export const isArrayOfErrors = isArrayOf(isError);
