export declare type DigitalContract = {
  readonly contractId: string;
  readonly serviceProviderId: string;
  readonly type: string;
  readonly signers: readonly DigitalContractSignerMetadata[];
  readonly externalId: string;
  readonly externalUrl?: string;
  readonly internalUrl?: string;
  readonly status: string;
  readonly completionTimestamp?: string;
  readonly creationTimestamp: string;
  readonly lastModificationTimestamp: string;
};

export declare type DigitalContractSignerMetadata = {
  readonly email: string;
  readonly externalId: string;
  readonly requestedTimestamp?: string;
  readonly signedTimestamp?: string;
};

export const DigitalContractStatus = Object.freeze({
  CANCELLED: 'CANCELLED',
  FINISHED: 'FINISHED',
  PENDING: 'PENDING',
});
