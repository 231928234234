import { observer } from 'mobx-react-lite';
import { Fragment, useEffect } from 'react';
import { useFormContext } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { KeyboardAvoidingView, Platform } from 'react-native';

import { Button } from '@ioupie/components/buttons';
import { Loader } from '@ioupie/components/custom';
import { Icon } from '@ioupie/components/gallery';
import { useAuthStore, useNavigationStore, useOrdersStore, usePaymentStore, useShopsStore } from '@ioupie/hooks';
import {
  BUSINESS_AREA_OPTIONS,
  CREDIT_CARD_LOGOS,
  PAYMENT_METHOD_TYPES,
  PIX_LOGO,
  routes,
} from '@ioupie/shared/constants';
import { safeObjectLookup } from '@ioupie/shared/utils';

import { PaymentMethodForm, PaymentProps } from './payment.models';
import * as S from './payment.styles';

/**
 * @function PaymentContainer
 */
export default observer(({ readOnly }: PaymentProps) => {
  const [t] = useTranslation();

  const authStore = useAuthStore();
  const shopsStore = useShopsStore();
  const ordersStore = useOrdersStore();
  const paymentStore = usePaymentStore();
  const navigationStore = useNavigationStore();

  const { selectedPaymentMethod, loading = false } = paymentStore;
  const { issuer = '', lastDigits = '' } = selectedPaymentMethod ?? {};

  const logo = safeObjectLookup(CREDIT_CARD_LOGOS, selectedPaymentMethod?.issuer) ?? '';
  const isDelivery = shopsStore.shopBusinessArea === BUSINESS_AREA_OPTIONS.DELIVERY;
  const noCreditCardAvailable = !issuer || issuer.length === 0;
  const isCreditMethod =
    paymentStore.selectedPaymentType === PAYMENT_METHOD_TYPES.CREDIT && paymentStore.hasPaymentMethods;

  const formMethods = useFormContext<PaymentMethodForm>();

  const navigateToPaymentList = (): void => {
    navigationStore.dispatchNavigation({
      stack: routes.stacks.payment,
      screen: routes.pages.payment.methods_list,
    });
  };

  useEffect(() => {
    // eslint-disable-next-line no-void
    void paymentStore.fetchPaymentMethods();
  }, []);

  useEffect(() => {
    // eslint-disable-next-line no-void
    void paymentStore.fetchPaymentMethods();
  }, [authStore.haveAuthorizationData]);

  useEffect(() => {
    if (selectedPaymentMethod) {
      formMethods && formMethods.setValue('method', selectedPaymentMethod.id, { shouldValidate: true });
    }
  }, [selectedPaymentMethod]);

  const EstimatedAmount = (
    <S.EstimatedAmount>
      <S.EstimatedText>{t('containers.orders.ordering.payment.total-estimated')}</S.EstimatedText>
      <S.EstimatedText>
        {t('containers.orders.ordering.payment.estimated-value', {
          price: ordersStore.orderPrice.toFixed(2),
        })}
      </S.EstimatedText>
    </S.EstimatedAmount>
  );

  const CardPaymentMethodDetails =
    !readOnly || paymentStore.selectedPaymentType === PAYMENT_METHOD_TYPES.CREDIT ? (
      <S.MethodDetailsContainer
        disabled={readOnly}
        onPress={() => {
          if (paymentStore.hasPaymentMethods) {
            paymentStore.changePaymentType(PAYMENT_METHOD_TYPES.CREDIT);
          } else {
            navigateToPaymentList();
          }
        }}
        selected={isCreditMethod}>
        {logo ? (
          <S.MethodImage image={logo} width={44} height={44} />
        ) : (
          <Icon name="credit-card-edit-outline" size={36} />
        )}
        <S.MethodLabelsContainer>
          <S.MethodType>{t('containers.orders.ordering.payment.method-type.credit-card')}</S.MethodType>
          {paymentStore.hasPaymentMethods && (
            <S.MethodTypeInfo>{`${t('containers.orders.ordering.payment.card-final')} ${lastDigits}`}</S.MethodTypeInfo>
          )}
        </S.MethodLabelsContainer>
      </S.MethodDetailsContainer>
    ) : (
      <Fragment />
    );

  const PixPaymentMethodDetails =
    !readOnly || paymentStore.selectedPaymentType === PAYMENT_METHOD_TYPES.PIX ? (
      <S.MethodDetailsContainer
        disabled={readOnly}
        onPress={() => paymentStore.changePaymentType(PAYMENT_METHOD_TYPES.PIX)}
        selected={!isCreditMethod}>
        <S.MethodImage image={PIX_LOGO} width={88} height={44} />
      </S.MethodDetailsContainer>
    ) : (
      <Fragment />
    );

  return (
    <S.PaymentContainer>
      <KeyboardAvoidingView behavior={Platform.OS === 'ios' ? 'padding' : 'height'}>
        {loading ? (
          <Loader show={loading} />
        ) : (
          <Fragment>
            <S.PaymentHeader>
              <S.PaymentTitle>{t('containers.orders.ordering.payment.title')}</S.PaymentTitle>
              {noCreditCardAvailable && isCreditMethod && (
                <Button mode="text" onPress={navigateToPaymentList}>
                  {t('containers.orders.ordering.payment.add-card')}
                </Button>
              )}
              {!readOnly && !noCreditCardAvailable && isCreditMethod && (
                <Button mode="text" onPress={navigateToPaymentList}>
                  {t('containers.orders.ordering.payment.change-button')}
                </Button>
              )}
            </S.PaymentHeader>
            {readOnly && EstimatedAmount}
            <S.MethodTypeContainer>
              {PixPaymentMethodDetails}
              {CardPaymentMethodDetails}
            </S.MethodTypeContainer>
          </Fragment>
        )}
      </KeyboardAvoidingView>
    </S.PaymentContainer>
  );
});
