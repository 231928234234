import { observer } from 'mobx-react-lite';
import { Fragment, useEffect, ReactNode } from 'react';

import {
  FarFromLocker,
  FinishOrderStepper,
  OrderDetails,
  UnlockLocker,
  Withdrawn,
} from '@ioupie/containers/orders/track-order';
import { useCurrentRouteFocus, useHeaderStore, useLockersStore, useOrdersStore } from '@ioupie/hooks';
import { BUSINESS_AREA_OPTIONS, ORDER_STATUS, routes } from '@ioupie/shared/constants';

/**
 * @function TrackOrderPage
 * @todo FIXME observer in a page
 */
export default observer(() => {
  const headerStore = useHeaderStore();
  const ordersStore = useOrdersStore();
  const lockersStore = useLockersStore();

  const { selectedOrderHistory } = ordersStore;
  const {
    status = ORDER_STATUS.DRAFT,
    type = BUSINESS_AREA_OPTIONS.DELIVERY,
    inboundDetails,
    payment,
  } = selectedOrderHistory ?? {};
  const { lockerId = '' } = inboundDetails ?? {};
  const { involvedPayments = [] } = payment ?? {};

  const isLocker = type === BUSINESS_AREA_OPTIONS.LOCKER;
  const pendingPaymentOnAcceptedOrder =
    involvedPayments.length > 1 && ordersStore.hasInvolvedPaymentWithAuthorizationStatus;

  useEffect(() => {
    // eslint-disable-next-line no-void
    void lockersStore.selectLockerProvider(lockerId);
  }, [lockerId]);

  useCurrentRouteFocus(routes.pages.orders.track_order, () => {
    headerStore.allowNavigateBack(true);
    headerStore.removeHeaderShadow(false);
    headerStore.changeTitle('pages.track-order.title');
    headerStore.changeSubtitle('pages.track-order.subtitle', {
      orderNumber: ordersStore.selectedOrderHistory?.orderNumber ?? '',
    });
  });

  const orderOption: Record<string, ReactNode> = {
    [ORDER_STATUS.PENDING_PAYMENT]: pendingPaymentOnAcceptedOrder ? <OrderDetails /> : <FinishOrderStepper />,
    [ORDER_STATUS.PENDING_PROVIDER_ACKNOWLEDGEMENT]: isLocker ? <UnlockLocker /> : <OrderDetails />,
    [ORDER_STATUS.PENDING_CUSTOMER_PICKUP]: <Withdrawn />,
  };

  const statusMapper = (currentStatus: ORDER_STATUS): ORDER_STATUS | ReactNode =>
    orderOption[`${currentStatus}`] || <OrderDetails />;

  return (
    <Fragment>
      {statusMapper(status)}
      <FarFromLocker />
    </Fragment>
  );
});
