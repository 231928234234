import { Fragment, memo } from 'react';
import { useTranslation } from 'react-i18next';

import { BlockButton } from '@ioupie/components/buttons';
import { useOrdersStore } from '@ioupie/hooks';
import { BUSINESS_AREA_OPTIONS } from '@ioupie/shared/constants';

import * as S from '../thank-step.styles';
import { RejectedMessageProps } from './rejected-message.models';

/**
 * @function RejectedMessageComponent
 */
export default memo((props: RejectedMessageProps) => {
  const [t] = useTranslation();
  const ordersStore = useOrdersStore();

  const { selectedOrderHistory } = ordersStore;
  const { type } = selectedOrderHistory ?? {};
  const { action } = props;

  const isLocker = type === BUSINESS_AREA_OPTIONS.LOCKER;
  const thankStepTranslationPath = 'containers.orders.track-order.finish-order-stepper.thank-step';

  return (
    <Fragment>
      <S.ThankMessage>
        <S.TitleMessage>{t(`${thankStepTranslationPath}.thank-message`)}</S.TitleMessage>
        <S.TextMessage>{t(`${thankStepTranslationPath}.rejected-message.come-back-soon`)}</S.TextMessage>
        {isLocker ? (
          <S.TextMessage>{t(`${thankStepTranslationPath}.rejected-message.locker-instruction`)}</S.TextMessage>
        ) : (
          <S.TextMessage>{t(`${thankStepTranslationPath}.rejected-message.delivery-instruction`)}</S.TextMessage>
        )}
        <S.TextMessage>{t(`${thankStepTranslationPath}.push-notification-disclaimer`)}</S.TextMessage>
      </S.ThankMessage>
      <BlockButton text={t(`${thankStepTranslationPath}.rejected-message.initial-page`)} onPress={action} />
    </Fragment>
  );
});
