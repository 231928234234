import { Switch, Text } from 'react-native-paper';
import styled from 'styled-components/native';

import { colors } from '@ioupie/shared/constants';
import { themefy } from '@ioupie/shared/utils';

export const Container = styled.View`
  flex-direction: row;
  justify-content: space-between;
  padding: 15px 0;
  width: 100%;
`;

export const Title = styled(Text)`
  color: ${themefy({
    light: colors.light.text,
    dark: colors.dark.text,
  })};
  font-size: 16px;
  justify-content: center;
  width: 75%;
`;

export const Switcher = styled(Switch)``;
