import { memo } from 'react';
import { useTranslation } from 'react-i18next';

import { Bold } from '@ioupie/components/typography';

import * as S from './dev-bar.styles';

/**
 * @function DevBarComponent
 */
export default memo(() => {
  const [t] = useTranslation();

  return (
    <S.RedBar>
      <Bold>{t('components.dev-bar.message')}</Bold>
    </S.RedBar>
  );
});
