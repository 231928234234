/* eslint-disable no-void */
import { observer } from 'mobx-react-lite';
import { Fragment, useCallback } from 'react';
import { useTranslation } from 'react-i18next';

import { BlockButton } from '@ioupie/components/buttons';
import { Dialog, ErrorSnackbar } from '@ioupie/components/custom';
import { ActionsBar, Portal } from '@ioupie/components/layout';
import { Text } from '@ioupie/components/typography';
import { usePaymentStore } from '@ioupie/hooks';

/**
 * @function DeleteCreditCardContainer
 */
export default observer(() => {
  const [t] = useTranslation();
  const paymentStore = usePaymentStore();

  const removePaymentMethod = useCallback(() => {
    const paymentMethod = paymentStore.selectedPaymentMethod;
    if (paymentMethod) {
      void paymentStore.removePaymentMethod(paymentMethod);
    }
    paymentStore.closeDeleteDialog();
  }, []);

  return (
    <Fragment>
      <Portal>
        <Dialog.View
          dismissable
          visible={paymentStore.isDeleteDialogOpen}
          onDismiss={() => paymentStore.closeDeleteDialog()}>
          <Dialog.Title>{t('containers.payment.delete-credit-card.title')}</Dialog.Title>
          <Dialog.Content>
            <Text>
              {paymentStore.selectedPaymentMethod
                ? t('containers.payment.delete-credit-card.alert-message', {
                    lastDigits: paymentStore.selectedPaymentMethod.lastDigits,
                  })
                : ''}
            </Text>
          </Dialog.Content>
          <Dialog.Actions>
            <ActionsBar>
              <BlockButton
                secondary
                reduced
                text={t('containers.payment.delete-credit-card.cancel')}
                onPress={() => paymentStore.closeDeleteDialog()}
              />
              <BlockButton
                reduced
                text={t('containers.payment.delete-credit-card.remove')}
                onPress={removePaymentMethod}
                disabled={!paymentStore.selectedPaymentMethod}
              />
            </ActionsBar>
          </Dialog.Actions>
        </Dialog.View>
      </Portal>
      <ErrorSnackbar errors={paymentStore.errors} onDismiss={() => paymentStore.clearErrors()} />
    </Fragment>
  );
});
