import { Text } from 'react-native-paper';
import styled from 'styled-components/native';

import { colors, fontsFamily, fontsWeight } from '@ioupie/shared/constants';
import { themefy } from '@ioupie/shared/utils';

export const LinkText = styled(Text)`
  color: ${themefy({
    light: colors.light.link,
    dark: colors.dark.link,
  })};
  font-family: ${fontsFamily.bold};
  font-weight: ${fontsWeight.bold};
  padding: 8px 0;
`;
