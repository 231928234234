import styled from 'styled-components/native';

import { Text } from '@ioupie/components/typography';
import { colors, fontsFamily } from '@ioupie/shared/constants';
import { themefy } from '@ioupie/shared/utils';

import { AmountTextProps } from './amount-item.models';

export const ItemInfoContainer = styled.View`
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  margin: 2px 16px;
`;

export const EntityTitle = styled(Text)<AmountTextProps>`
  color: ${({ gray }) =>
    themefy({
      light: gray ? colors.light.text3 : colors.light.title,
      dark: gray ? colors.dark.text3 : colors.dark.title,
    })};
  font-family: ${({ bold }) => (bold ? fontsFamily.bold : fontsFamily.regular)};
  font-size: 15px;
  padding: 2px 0;
`;

export const EntityDescription = styled(Text)<AmountTextProps>`
  color: ${({ gray }) =>
    themefy({
      light: gray ? colors.light.text3 : colors.light.title,
      dark: gray ? colors.dark.text3 : colors.dark.title,
    })};
  font-family: ${({ bold }) => (bold ? fontsFamily.bold : fontsFamily.regular)};
  font-size: 15px;
  padding: 2px 0;
`;
