import { MD3LightTheme } from 'react-native-paper';

import { colors } from '@ioupie/shared/constants';

import { fonts } from '../common';

const themeColors: typeof MD3LightTheme.colors = {
  ...MD3LightTheme.colors,
  primary: colors.light.primary,
  secondary: colors.light.secondary,
  background: colors.light.background,
  tertiary: colors.light.text,
};

const lightTheme: typeof MD3LightTheme = {
  ...MD3LightTheme,
  dark: false,
  colors: themeColors,
  roundness: 2,
  fonts,
};

export default lightTheme;
