import { observer } from 'mobx-react-lite';
import { Fragment, ReactElement, useEffect, useMemo } from 'react';

import { ScrollView } from '@ioupie/components/layout';
import { useHeaderStore, useOrdersStore, useNavigationStore } from '@ioupie/hooks';
import { ORDER_STEPS, routes } from '@ioupie/shared/constants';
import { Optional } from '@ioupie/shared/models';
import { safeObjectLookup } from '@ioupie/shared/utils';

import { FinishOrder } from './finish-order';
import { RejectModal } from './finish-order/reject-modal';
import { ScheduleStep } from './schedule-step';
import { ThankStep } from './thank-step';
import { LaundryDetails } from '../laundry-details';
import { OrderDetails } from '../order-details';
import { ProductsDetails } from '../products-details';
import { ScheduleDetails } from '../schedule-details';

/**
 * @function FinishOrderStepperContainer
 */
export default observer(() => {
  const headerStore = useHeaderStore();
  const ordersStore = useOrdersStore();
  const navigationStore = useNavigationStore();

  const { selectedOrderHistory } = ordersStore;
  const { orderNumber = '', inboundDetails, outboundDetails, payment } = selectedOrderHistory ?? {};
  const { requestedTime: inboundRequestedTime = '' } = inboundDetails ?? {};
  const { requestedTime: outboundRequestedTime = '' } = outboundDetails ?? {};
  const { involvedPayments = [] } = payment ?? {};

  const pendingPaymentOnAcceptedOrder =
    involvedPayments.length > 1 && ordersStore.hasInvolvedPaymentWithAuthorizationStatus;

  const headerBackActionsPerStep: Record<ORDER_STEPS, Optional<CallableFunction>> = useMemo(
    () => ({
      [ORDER_STEPS.FINISH_ORDER]: undefined,
      [ORDER_STEPS.SCHEDULE]: () => {
        ordersStore.changeStep(ORDER_STEPS.FINISH_ORDER);
      },
      [ORDER_STEPS.APPROVED]: () => {
        navigationStore.dispatchNavigation({
          stack: routes.stacks.orders,
          screen: routes.pages.orders.home,
        });
      },
      [ORDER_STEPS.REJECTED]: () => {
        navigationStore.dispatchNavigation({
          stack: routes.stacks.orders,
          screen: routes.pages.orders.home,
        });
      },
    }),
    [],
  );

  const actionsToTakePerStep: Record<ORDER_STEPS, CallableFunction> = useMemo(
    () => ({
      [ORDER_STEPS.FINISH_ORDER]: () => {
        headerStore.changeSubtitle('containers.orders.track-order.finish-order-stepper.header.subtitle', {
          orderNumber,
        });
      },
      [ORDER_STEPS.SCHEDULE]: () => {
        headerStore.changeSubtitle('containers.orders.track-order.finish-order-stepper.schedule-step.header.subtitle');
      },
      [ORDER_STEPS.APPROVED]: () => {
        headerStore.changeSubtitle(
          'containers.orders.track-order.finish-order-stepper.thank-step.approved-message.header.subtitle',
        );
      },
      [ORDER_STEPS.REJECTED]: () => {
        headerStore.changeSubtitle(
          'containers.orders.track-order.finish-order-stepper.thank-step.rejected-message.header.subtitle',
        );
      },
    }),
    [],
  );

  /**
   * @todo FIXME create a component for FINISH_ORDER
   */
  const containerPerStep: Record<ORDER_STEPS, ReactElement> = useMemo(
    () => ({
      [ORDER_STEPS.FINISH_ORDER]: pendingPaymentOnAcceptedOrder ? (
        <OrderDetails />
      ) : (
        <Fragment>
          <FinishOrder />
          <ScrollView>
            <LaundryDetails />
            <ProductsDetails />
            {inboundRequestedTime || outboundRequestedTime ? <ScheduleDetails /> : undefined}
          </ScrollView>
          <RejectModal />
        </Fragment>
      ),
      [ORDER_STEPS.SCHEDULE]: <ScheduleStep />,
      [ORDER_STEPS.APPROVED]: <ThankStep />,
      [ORDER_STEPS.REJECTED]: <ThankStep />,
    }),
    [],
  );

  useEffect(() => {
    // configure the header
    const headerBackAction = safeObjectLookup(headerBackActionsPerStep, ordersStore.orderStep);
    headerStore.onClickBack(headerBackAction);

    // side effects per state
    const actionsToTake = safeObjectLookup(actionsToTakePerStep, ordersStore.orderStep);
    if (actionsToTake) actionsToTake();
  }, [ordersStore.orderStep]);

  return safeObjectLookup(containerPerStep, ordersStore.orderStep) ?? <Fragment />;
});
