import styled from 'styled-components/native';

import { BlockButton } from '@ioupie/components/buttons';
import { Bold, Subheading, Text } from '@ioupie/components/typography';
import { colors, fontsFamily, fontsWeight } from '@ioupie/shared/constants';
import { themefy } from '@ioupie/shared/utils';

export const DeliveryScheduleContainer = styled.View`
  margin: 10px 10px 20px;
`;

export const LabelTitle = styled(Text)`
  font-family: ${fontsFamily.bold};
  font-weight: ${fontsWeight.bold};
  font-size: 18px;
  padding: 10px;
  text-align: left;
`;

export const DayTitle = styled(Subheading)`
  padding: 10px 15px;
`;

export const PeriodTitle = styled(Subheading)`
  margin-top: 15px;
  padding: 10px 15px;
`;

export const PeriodView = styled.View`
  background-color: ${themefy({
    light: colors.light.background,
    dark: colors.dark.background,
  })};
  flex-direction: column;
  margin-bottom: 10px;
`;

export const SharedLockerDisclaimer = styled(Bold)`
  background-color: ${themefy({
    light: colors.light.background,
    dark: colors.dark.background,
  })};
  margin-bottom: 15px;
  padding: 10px 15px;
  text-align: center;
`;

export const ContinueButton = styled(BlockButton)`
  margin: 16px 0 0;
`;

export const OtherDate = styled(Bold)`
  color: ${themefy({
    light: colors.light.text,
    dark: colors.dark.text,
  })};
  text-align: center;
  margin-top: 10px;
`;
