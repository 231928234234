import styled from 'styled-components/native';

import { TextInput } from '@ioupie/components/inputs';
import { ScrollView } from '@ioupie/components/layout';
import { Text } from '@ioupie/components/typography';

export const Container = styled(ScrollView)`
  flex: 1;
  padding: 20px;
`;

export const CenterTitle = styled.View`
  align-items: center;
  justify-content: center;
  margin: 0 0 10px;
  width: 100%;
`;

export const TermsOfUseDisclaimer = styled(Text)`
  margin-top: 20px;
`;

export const PhoneContainer = styled.View`
  flex-direction: row;
  justify-content: space-between;
  width: 100%;
`;

export const PhoneAreaInput = styled(TextInput)`
  width: 25%;
`;

export const PhoneNumberInput = styled(TextInput)`
  width: 70%;
`;

export const ErrorSnackbarContainer = styled.View`
  display: flex;
  flex: 1;
  align-items: center;
  height: 70px;
  width: 100%;
`;
