import { lighten, darken } from 'polished';
import styled from 'styled-components/native';

import { Divider } from '@ioupie/components/layout';
import { Text, Title } from '@ioupie/components/typography';
import { colors, fontsFamily, fontsWeight } from '@ioupie/shared/constants';
import { themefy } from '@ioupie/shared/utils';

export const SettingsContainer = styled.View`
  flex: 1;
  padding: 20px;
`;

export const LabelTitle = styled(Title)`
  color: ${themefy({
    light: colors.light.text,
    dark: colors.dark.text,
  })};
  font-family: ${fontsFamily.bold};
  font-size: 18px;
  padding: 0;
  text-align: left;
`;

export const SectionDivider = styled(Divider)`
  margin-bottom: 10px;
`;

export const VersionString = styled(Text)`
  color: ${themefy({
    light: lighten(0.3, colors.light.text),
    dark: darken(0.3, colors.dark.text),
  })};
  font-family: ${fontsFamily.regular};
  font-weight: ${fontsWeight.regular};
  font-size: 14px;
  padding: 0;
  text-align: center;
`;
