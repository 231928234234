/* eslint-disable no-void */
import { inject, injectable } from 'inversify';
import appsFlyer from 'react-native-appsflyer';

import { AnalyticsConstants, AnalyticsEvents, VALUE_TYPES, type ServiceFlags } from '@ioupie/shared/constants';
import { type UserProfile } from '@ioupie/shared/models';

import { type AnalyticsService } from './analytics.service';
import { BaseAnalyticsService } from './base-analytics.service';

@injectable()
export class AppsFlyerAnalyticsService extends BaseAnalyticsService implements AnalyticsService {
  private static readonly APPSFLYER_SHA256 = 1;

  public constructor(@inject(VALUE_TYPES.SERVICE_FLAGS) private readonly serviceFlags: ServiceFlags) {
    super();
  }

  public async setUser(userId: string) {
    if (!this.serviceFlags.analytics.appsFlyer.enable) {
      return Promise.resolve();
    }

    try {
      await new Promise((resolve, reject) => {
        try {
          appsFlyer.setCustomerUserId(userId, (result) => resolve(result));
        } catch (e) {
          reject(e);
        }
      });
    } catch (error) {
      console.error(`AppsFlyer user [${userId}] error`, error);
    }
  }

  public async setUserProperties(userProps: UserProfile) {
    if (!this.serviceFlags.analytics.appsFlyer.enable) {
      return Promise.resolve();
    }

    try {
      await new Promise((resolve, reject) =>
        appsFlyer.setUserEmails(
          { emails: [userProps.email], emailsCryptType: AppsFlyerAnalyticsService.APPSFLYER_SHA256 },
          (result) => resolve(result),
          (error) => reject(error),
        ),
      );
    } catch (error) {
      console.error(`AppsFlyer user [${userProps?.subject}] properties error`, error);
    }
  }

  public async setScreen(screen: string, metaData: Record<string, string> = {}) {
    if (!this.serviceFlags.analytics.appsFlyer.enable) {
      return Promise.resolve();
    }

    try {
      const screenView = this.prefixNativeEventName('screen_view');
      const eventPayload = this.buildScreenPayload(screen, metaData);

      await appsFlyer.logEvent(screenView, eventPayload);
    } catch (error) {
      console.error(`AppsFlyer screen [${screen}] error`, error);
    }
  }

  public async trackEvent(event: AnalyticsEvents, eventData: Record<string, any> = {}) {
    if (!this.serviceFlags.analytics.appsFlyer.enable) {
      return Promise.resolve();
    }

    try {
      const prefixedEventName = this.prefixEventName(AnalyticsConstants.AppsFlyer[event]);

      await appsFlyer.logEvent(prefixedEventName, eventData);
    } catch (error) {
      console.error(`AppsFlyer event [${event}] error`, error);
    }
  }

  public async trackUnprefixedEvent(event: AnalyticsEvents, eventData: Record<string, any> = {}) {
    if (!this.serviceFlags.analytics.appsFlyer.enable) {
      return Promise.resolve();
    }

    try {
      const prefixedEventName = this.prefixNativeEventName(AnalyticsConstants.AppsFlyer[event]);

      await appsFlyer.logEvent(prefixedEventName, eventData);
    } catch (error) {
      console.error(`AppsFlyer native event [${event}] error`, error);
    }
  }
}
