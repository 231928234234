import { createStackNavigator } from '@react-navigation/stack';
import { memo } from 'react';

import {
  Catalog,
  Home,
  Lockers,
  NpsEvaluation,
  Ordering,
  Providers,
  ShopDetails,
  Summary,
  Thank,
  TrackOrder,
} from '@ioupie/pages/orders';
import { routes } from '@ioupie/shared/constants';

const Stack = createStackNavigator();

/**
 * @function OrdersStack
 */
export default memo(() => {
  return (
    <Stack.Navigator initialRouteName={routes.pages.orders.home} screenOptions={{ headerShown: false }}>
      <Stack.Screen name={routes.pages.orders.home} component={Home} />
      <Stack.Screen name={routes.pages.orders.shop_details} component={ShopDetails} />
      <Stack.Screen name={routes.pages.orders.providers} component={Providers} />
      <Stack.Screen name={routes.pages.orders.lockers} component={Lockers} />
      <Stack.Screen name={routes.pages.orders.catalog} component={Catalog} />
      <Stack.Screen name={routes.pages.orders.track_order} component={TrackOrder} />
      <Stack.Screen name={routes.pages.orders.ordering} component={Ordering} />
      <Stack.Screen name={routes.pages.orders.summary} component={Summary} />
      <Stack.Screen name={routes.pages.orders.thank} component={Thank} />
      <Stack.Screen name={routes.pages.orders.nps_evaluation} component={NpsEvaluation} />
    </Stack.Navigator>
  );
});
