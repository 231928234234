import { observer } from 'mobx-react-lite';
import { Fragment } from 'react';
import { useTranslation } from 'react-i18next';
import { View } from 'react-native';

import { BlockButton } from '@ioupie/components/buttons';
import { ErrorSnackbar, UserStep } from '@ioupie/components/custom';
import { Text, Title } from '@ioupie/components/typography';
import { useAuthStore } from '@ioupie/hooks';

/**
 * @function SignOutStepComponent
 */
export default observer(() => {
  const [t] = useTranslation();
  const authStore = useAuthStore();

  const SignOutContent = (
    <View>
      <Title>{t('forms.sign-out.title', { name: authStore.userFirstName })}</Title>
      <Text>{t('forms.sign-out.instruction')}</Text>
    </View>
  );

  const SignOutActions = (
    <BlockButton
      blacken
      loading={authStore.loading}
      text={t('forms.sign-out.submit')}
      onPress={() => authStore.doSignOut()}
    />
  );

  return (
    <Fragment>
      <UserStep content={SignOutContent} actions={SignOutActions} />
      <ErrorSnackbar errors={authStore.errors} onDismiss={() => authStore.clearErrors()} />
    </Fragment>
  );
});
