import { lighten, darken } from 'polished';
import styled from 'styled-components/native';

import { Icon } from '@ioupie/components/gallery';
import { Text } from '@ioupie/components/typography';
import { colors, fontsFamily, fontsWeight } from '@ioupie/shared/constants';
import { themefy } from '@ioupie/shared/utils';

export const MessageBoxContainer = styled.View`
  align-items: center;
  border: 1px solid #dbdbdb;
  border-left-width: 5px;
  border-left-color: #7cb03c;
  background-color: ${themefy({
    light: lighten(0.2, colors.light.background),
    dark: darken(0.2, colors.dark.background),
  })};
  display: flex;
  flex-flow: row;
  justify-content: space-between;
  margin: 20px 10px 10px;
  padding: 20px;
`;

export const MessageBoxContent = styled.View`
  align-items: center;
  flex-flow: row;
  justify-content: flex-start;
`;

export const MessageBoxTitle = styled(Text)`
  font-family: ${fontsFamily.bold};
  font-size: 16px;
  font-weight: ${fontsWeight.bold};
`;

export const MessageBoxIcon = styled(Icon)`
  color: ${themefy({
    light: lighten(0.2, colors.light.text),
    dark: darken(0.2, colors.dark.text),
  })};
  margin-right: 10px;
`;

export const EvaluateButton = styled(Text)`
  color: ${themefy({
    light: lighten(0.2, colors.light.primary),
    dark: darken(0.2, colors.dark.primary),
  })};
  font-family: ${fontsFamily.semibold};
  font-size: 16px;
  font-weight: ${fontsWeight.semibold};
  border-bottom: 2px;
  border-bottom-style: solid;
  border-bottom-color: ${themefy({
    light: lighten(0.2, colors.light.primary),
    dark: darken(0.2, colors.dark.primary),
  })};
`;
