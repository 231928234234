import { observer } from 'mobx-react-lite';
import { Fragment, useCallback, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { FlatList, ListRenderItemInfo } from 'react-native';

import { ErrorSnackbar, List, Loader } from '@ioupie/components/custom';
import { Text, Title } from '@ioupie/components/typography';
import { useAddressStore, useLockersStore } from '@ioupie/hooks';
import { Locker } from '@ioupie/shared/models';

import { LockerEntry } from './locker-entry';
import * as S from './lockers-list.styles';

/**
 * @function LockersListContainer
 */
export default observer(() => {
  const [t] = useTranslation();
  const lockersStore = useLockersStore();
  const addressStore = useAddressStore();

  useEffect(() => {
    // eslint-disable-next-line no-void
    void lockersStore.fetchAllLockers();
  }, [addressStore.zipCodeInfo, addressStore.userAddressData.selectedAddress]);

  const providerKeyExtractor = useCallback((locker: Locker) => locker.id, []);

  const renderProviderListItem = useCallback((info: ListRenderItemInfo<Locker>) => {
    const { item: locker } = info;

    return <LockerEntry locker={locker} />;
  }, []);

  return (
    <Fragment>
      <S.IntroUsage>
        <S.IntroTitle>{t('containers.lockers.lockers-list.title')}</S.IntroTitle>
        <Text>{t('containers.lockers.lockers-list.description')}</Text>
      </S.IntroUsage>
      <List.Section>
        <FlatList<Locker>
          data={lockersStore.lockers}
          keyExtractor={providerKeyExtractor}
          renderItem={renderProviderListItem}
        />
      </List.Section>
      <Loader show={lockersStore.loading} message={t('containers.lockers.lockers-list.loading')} />
      <ErrorSnackbar errors={lockersStore.errors} onDismiss={() => lockersStore.clearErrors()} />
    </Fragment>
  );
});
