import styled from 'styled-components/native';

import { Divider } from '@ioupie/components/layout';
import { Text } from '@ioupie/components/typography';
import { colors } from '@ioupie/shared/constants';
import { themefy } from '@ioupie/shared/utils';

export const Container = styled.View`
  flex: 1;
  padding: 20px;
`;

export const MoreAboutPrivacyButton = styled(Text)`
  color: ${themefy({
    light: colors.light.link,
    dark: colors.dark.link,
  })};
  margin: 16px 0;
`;

export const Description = styled(Text)`
  margin: 4px 0;
  line-height: 22px;
`;

export const Separator = styled(Divider)`
  margin: 16px 0 8px;
`;
