import styled from 'styled-components/native';

import { colors, statusBarHeight } from '@ioupie/shared/constants';
import { themefy } from '@ioupie/shared/utils';

const heightPixels = `${statusBarHeight}px`;
const displayType = __DEV__ ? 'flex' : 'none'; // whether to show this bar or not

export const RedBar = styled.View`
  background-color: ${themefy({
    light: colors.light.error,
    dark: colors.dark.error,
  })};
  height: ${heightPixels};
  display: ${displayType};
  justify-content: center;
  align-items: center;
`;
