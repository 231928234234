import { memo } from 'react';

import { BodyView } from '@ioupie/components/layout';
import { AuthModal } from '@ioupie/containers/auth';
import { CreateOrder, NewOrderSummary } from '@ioupie/containers/orders';
import { useCurrentRouteFocus, useHeaderStore, useNavigationStore, useShopsStore } from '@ioupie/hooks';
import { routes } from '@ioupie/shared/constants';

/**
 * @function SummaryPage
 */
export default memo(() => {
  const shopsStore = useShopsStore();
  const headerStore = useHeaderStore();
  const navigationStore = useNavigationStore();

  useCurrentRouteFocus(routes.pages.orders.summary, () => {
    headerStore.allowNavigateBack(true);
    headerStore.removeHeaderShadow(false);
    headerStore.changeTitle('pages.summary.title');
    headerStore.changeSubtitle('pages.summary.subtitle');
    headerStore.onClickBack(() => {
      shopsStore.clearCoupon();
      navigationStore.dispatchGoBack();
    });
  });

  return (
    <BodyView>
      <NewOrderSummary />
      <CreateOrder />
      <AuthModal />
    </BodyView>
  );
});
