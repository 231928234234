import styled from 'styled-components/native';

import { Icon } from '@ioupie/components/gallery';
import { colors } from '@ioupie/shared/constants';
import { themefy } from '@ioupie/shared/utils';

export const IconWrapper = styled.View`
  align-items: center;
  flex: 1;
  justify-content: center;
`;

export const IconCentered = styled(Icon)`
  align-items: center;
  color: ${themefy({
    light: colors.light.text,
    dark: colors.dark.text,
  })};
  justify-content: center;
  text-align: center;
`;
