import { Optional } from '../models/common.models';

export const isEmpty = <T extends object>(value?: Optional<T>): boolean => {
  if (!value) {
    return true;
  } else {
    return Object.keys(value).length <= 0;
  }
};

export const isNotEmpty = <T extends object>(value?: Optional<T>): boolean => {
  if (!value) {
    return false;
  } else {
    return Object.keys(value).length > 0;
  }
};
