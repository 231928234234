import { observer } from 'mobx-react-lite';
import { Fragment, useCallback } from 'react';
import { useTranslation } from 'react-i18next';

import { useShopsStore } from '@ioupie/hooks';
import { SHOP_STEPS } from '@ioupie/shared/constants';

import * as S from './products-filter.styles';

/**
 * @function ProductsFilterContainer
 */
export default observer(() => {
  const [t] = useTranslation();
  const shopsStore = useShopsStore();

  const doNothing = useCallback((): void => undefined, []);

  // don't show the filter if in custom step
  if (shopsStore.shopStep === SHOP_STEPS.CUSTOM) {
    return <Fragment />;
  }

  return (
    <S.FilterContainer>
      <S.SearchTextInput
        autoCapitalize="none"
        returnKeyType="done"
        label={t('containers.catalog.shop-catalog.products-filter.label')}
        placeholder={t('containers.catalog.shop-catalog.products-filter.placeholder')}
        value={shopsStore.filterQuery}
        onChangeText={(query: string) => shopsStore.setFilterQuery(query)}
      />
      <S.SearchIconButton icon="magnify" size={30} disabled onPress={doNothing} />
    </S.FilterContainer>
  );
});
