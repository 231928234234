import { Picker } from '@react-native-picker/picker';
import styled from 'styled-components/native';

import { colors } from '@ioupie/shared/constants';
import { themefy } from '@ioupie/shared/utils';

/**
 * @todo FIXME
 */
export default styled(Picker)`
  color: ${themefy({ light: colors.light.text, dark: colors.dark.text })};
` as unknown as typeof Picker;
