import { Fragment, memo, useCallback, useState } from 'react';
import { useTranslation } from 'react-i18next';

import { BlockButton } from '@ioupie/components/buttons';
import { RadioButton } from '@ioupie/components/custom';
import { TextInput } from '@ioupie/components/inputs';
import { Divider } from '@ioupie/components/layout';
import { Bold } from '@ioupie/components/typography';
import {
  CARPET_SHAPES,
  CUSTOM_PRODUCT_SIZES,
  PRODUCT_METADATA_TYPES,
  ROUND_CARPET_DEFAULTS,
} from '@ioupie/shared/constants';
import { CarpetMetadata, ProductData } from '@ioupie/shared/models';
import { isEnumOf, normalizeAndParseFloat, safeObjectLookup } from '@ioupie/shared/utils';

import * as S from './round.styles';

export default memo(RoundCarpet);

type RoundCarpetProps = {
  readonly customizingCarpet: ProductData;
  readonly onSendCarpetSize: (metadata: CarpetMetadata) => void;
};

const INVALID_CUSTOM_SIZE_VALUE: Readonly<number> = 0;

function RoundCarpet({ customizingCarpet, onSendCarpetSize }: RoundCarpetProps) {
  const [t] = useTranslation();
  const [carpetSize, setCarpetSize] = useState(CUSTOM_PRODUCT_SIZES.SMALL);

  const [radius, setRadius] = useState('');

  const chosenSize =
    carpetSize === CUSTOM_PRODUCT_SIZES.CUSTOM
      ? { radius: normalizeAndParseFloat(radius) }
      : safeObjectLookup(ROUND_CARPET_DEFAULTS, carpetSize);

  const radiusIsInvalid = !chosenSize || Number.isNaN(chosenSize.radius) || chosenSize.radius <= 0;

  // π * r²
  const circleArea = useCallback((r: number): number => {
    const area = Math.PI * r ** 2;
    return area >= 1 ? area : 1;
  }, []);

  const calculatedValue: Readonly<number> = radiusIsInvalid
    ? INVALID_CUSTOM_SIZE_VALUE
    : circleArea(chosenSize.radius) * customizingCarpet.minPrice.amount;

  const onCarpetSizeSelection = useCallback((value: string): void => {
    const isCarpetSize = isEnumOf(CUSTOM_PRODUCT_SIZES);

    if (isCarpetSize(value)) {
      setCarpetSize(value);
    }
  }, []);

  const chooseRoundCarpetSize = (): void => {
    if (!chosenSize || radiusIsInvalid) {
      return;
    }

    onSendCarpetSize({
      type: PRODUCT_METADATA_TYPES.CARPET,
      shape: CARPET_SHAPES.ROUND,
      radius: chosenSize.radius,
      estimatedPrice: calculatedValue,
    });
  };

  return (
    <Fragment>
      <S.TitleBox>
        <Bold>{t('containers.catalog.shop-catalog.custom.round-carpet.size')}</Bold>
      </S.TitleBox>
      <RadioButton.Group onValueChange={onCarpetSizeSelection} value={carpetSize}>
        <RadioButton.Item
          mode="android"
          value={CUSTOM_PRODUCT_SIZES.SMALL}
          label={t('containers.catalog.shop-catalog.custom.round-carpet.small', {
            radius: ROUND_CARPET_DEFAULTS.small.radius.toFixed(2),
            unit: customizingCarpet.unitOfMeasurement,
          })}
        />
        <RadioButton.Item
          mode="android"
          value={CUSTOM_PRODUCT_SIZES.MEDIUM}
          label={t('containers.catalog.shop-catalog.custom.round-carpet.medium', {
            radius: ROUND_CARPET_DEFAULTS.medium.radius.toFixed(2),
            unit: customizingCarpet.unitOfMeasurement,
          })}
        />
        <RadioButton.Item
          mode="android"
          value={CUSTOM_PRODUCT_SIZES.BIG}
          label={t('containers.catalog.shop-catalog.custom.round-carpet.big', {
            radius: ROUND_CARPET_DEFAULTS.big.radius.toFixed(2),
            unit: customizingCarpet.unitOfMeasurement,
          })}
        />
        <RadioButton.Item
          mode="android"
          value={CUSTOM_PRODUCT_SIZES.CUSTOM}
          label={t('containers.catalog.shop-catalog.custom.round-carpet.custom')}
        />
      </RadioButton.Group>
      <Divider />
      {carpetSize === CUSTOM_PRODUCT_SIZES.CUSTOM && (
        <S.CarpetCustomBox>
          <S.CarpetInputBox>
            <S.CarpetInputTitle>
              {t('containers.catalog.shop-catalog.custom.round-carpet.radius-title')}
            </S.CarpetInputTitle>
            <S.CarpetInputText
              value={radius}
              keyboardType="numeric"
              onChangeText={setRadius}
              label={t('containers.catalog.shop-catalog.custom.round-carpet.radius-label')}
              placeholder={t('containers.catalog.shop-catalog.custom.round-carpet.radius-placeholder')}
              disabled={carpetSize !== CUSTOM_PRODUCT_SIZES.CUSTOM}
            />
          </S.CarpetInputBox>
        </S.CarpetCustomBox>
      )}
      <S.CarpetPriceBox>
        <Bold>{t('containers.catalog.shop-catalog.custom.round-carpet.price')}</Bold>
        <Bold>
          {t('containers.catalog.shop-catalog.custom.round-carpet.total', {
            price: calculatedValue.toFixed(2),
            currency: customizingCarpet.minPrice.currencyCode,
          })}
        </Bold>
      </S.CarpetPriceBox>
      <BlockButton
        disabled={radiusIsInvalid}
        onPress={chooseRoundCarpetSize}
        text={t('containers.catalog.shop-catalog.custom.round-carpet.add')}
      />
    </Fragment>
  );
}
