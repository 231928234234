import { memo } from 'react';

import { DeliveryInfoProps } from './delivery-info.models';
import * as S from './delivery-info.styles';

export default memo(({ data }: DeliveryInfoProps) => {
  const { iconName = '', iconColor = '', iconSize = 16, info = '', textColor = '' } = data ?? {};
  return (
    <S.InfoContent>
      <S.InfoIcon size={iconSize} name={iconName} iconColor={iconColor} />
      <S.InfoText textColor={textColor}>{info}</S.InfoText>
    </S.InfoContent>
  );
});
