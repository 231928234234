import { observer } from 'mobx-react-lite';
import { Fragment, useCallback, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';

import { Dialog, ErrorSnackbar } from '@ioupie/components/custom';
import { Portal, ScrollView } from '@ioupie/components/layout';
import { Text } from '@ioupie/components/typography';
import { useAddressStore, useAuthStore, useNavigationStore, useOrdersStore, useShopsStore } from '@ioupie/hooks';
import {
  AnalyticsEvents,
  BUSINESS_AREA_OPTIONS,
  BUSINESS_SEGMENTS_OPTIONS,
  ORDERING_STEPS,
  routes,
} from '@ioupie/shared/constants';
import { safeObjectLookup } from '@ioupie/shared/utils';

import { Address } from '../address';
import { Basket } from '../basket';
import { Dates } from '../dates';
import { Payment } from '../payment';
import { Summary } from '../summary';
import * as S from './summary-step.styles';

/**
 * @function SummaryStepContainer
 */
export default observer(() => {
  const [t] = useTranslation();

  const authStore = useAuthStore();
  const shopsStore = useShopsStore();
  const ordersStore = useOrdersStore();
  const addressStore = useAddressStore();
  const navigationStore = useNavigationStore();

  const [displayAddressConfirmationDialog, setDisplayAddressConfirmationDialog] = useState(false);

  const {
    streetAddress = '',
    houseNumber = '',
    district = '',
    neighborhood = '',
    state = '',
    addressAddon = '',
    zipCode = '',
  } = addressStore.selectedAddressInfo ?? {};
  const { nextPickupDatesAvailable, selectedPickupDate = '' } = ordersStore;
  const { selectionInfo, couponInformation } = shopsStore;
  const { totalPrice } = selectionInfo;
  const { totalDiscount } = couponInformation ?? {};
  const { amount: couponDiscount = 0 } = totalDiscount ?? {};

  const isDelivery = shopsStore.shopBusinessArea === BUSINESS_AREA_OPTIONS.DELIVERY;
  const pickupAmount = safeObjectLookup(nextPickupDatesAvailable, +selectedPickupDate)?.amount ?? 0;
  const pickupFee = isDelivery ? pickupAmount : 0;

  const finishOrderLabel = isDelivery
    ? t('containers.orders.ordering.summary-step.confirm-order-button')
    : t('containers.orders.ordering.summary-step.create-ticket-button');

  useEffect(() => {
    ordersStore.recordInAppEventForJourney(AnalyticsEvents.CHECKOUT_SUMMARY_STEP);
  }, []);

  const ticket = shopsStore.createOrderTicket();

  const confirmConfiguredAddress = useCallback(() => {
    if (!addressStore.selectedAddressInfo) {
      navigationStore.dispatchNavigation({
        stack: routes.stacks.address,
      });
      return;
    }

    setDisplayAddressConfirmationDialog(true);
  }, []);

  const navigateToAddressListPage = useCallback(() => {
    navigationStore.dispatchNavigation({
      stack: routes.stacks.address,
    });
    setDisplayAddressConfirmationDialog(false);
  }, []);

  const navigateToDeliveryDetails = useCallback(() => {
    ordersStore.setDetailsOfNewOrder(true);
    navigationStore.dispatchNavigation({
      stack: routes.stacks.orders,
      screen: routes.pages.orders.track_order,
    });
  }, []);

  const requestOrderCreation = useCallback(async (): Promise<void> => {
    setDisplayAddressConfirmationDialog(false);

    if (!authStore.haveAuthorizationData) {
      authStore.setShowAuthModal(true);
      return;
    }

    if (ticket) {
      const expectedAmount = parseFloat((totalPrice + pickupFee - couponDiscount).toFixed(2));
      await ordersStore.createServiceOrder(ticket, expectedAmount);

      if (ordersStore.errors.length === 0) {
        shopsStore.clearCoupon();
        shopsStore.clearSelectedProducts();
        ordersStore.clearInstallmentsData();
        ordersStore.clearScheduledDate();
        ordersStore.clearSelectedPickupDate();
        ordersStore.changeOrderingStep(ORDERING_STEPS.BASKET);
        navigateToDeliveryDetails();
      }
    }
  }, [ticket]);

  const typeBasedAction = async (): Promise<void> => {
    if (ticket) {
      const upcomingAction = {
        [BUSINESS_AREA_OPTIONS.DELIVERY]: confirmConfiguredAddress,
        [BUSINESS_AREA_OPTIONS.LOCKER]: requestOrderCreation,
      };
      await upcomingAction[ticket.type]();
    }
  };

  return (
    <Fragment>
      <ScrollView>
        <Summary />
        <Basket readOnly />
        <S.Separator />
        <Address />
        {shopsStore.shopProvider?.segment !== BUSINESS_SEGMENTS_OPTIONS.GOODS && <Dates />}
        {shopsStore.shopProvider?.segment !== BUSINESS_SEGMENTS_OPTIONS.GOODS && <S.Separator />}
        <Payment readOnly />
        <S.FinishButton loading={ordersStore.loading} text={finishOrderLabel} onPress={typeBasedAction} />
        <ErrorSnackbar errors={ordersStore.errors} onDismiss={() => ordersStore.clearErrors()} />
      </ScrollView>

      <Portal>
        <Dialog.View
          visible={displayAddressConfirmationDialog}
          onDismiss={() => setDisplayAddressConfirmationDialog(false)}>
          <Dialog.Title>{t('containers.orders.create-order.address-dialog.title')}</Dialog.Title>
          <Dialog.Content>
            <Text>
              {streetAddress}, {houseNumber}
            </Text>
            <Text>
              {neighborhood}, {district}/{state}
            </Text>
            <Text>{zipCode}</Text>
            {Boolean(addressAddon) && <S.DialogAddressAddon>{addressAddon}</S.DialogAddressAddon>}
          </Dialog.Content>
          <Dialog.Actions>
            <S.DialogButtonColumn>
              <S.DialogButton
                secondary
                text={t('containers.orders.create-order.address-dialog.change-address-label')}
                onPress={() => navigateToAddressListPage()}
              />
              <S.DialogButton
                text={t('containers.orders.create-order.address-dialog.next-label')}
                onPress={requestOrderCreation}
              />
            </S.DialogButtonColumn>
          </Dialog.Actions>
        </Dialog.View>
      </Portal>
    </Fragment>
  );
});
