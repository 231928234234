import { Subheading } from 'react-native-paper';
import styled from 'styled-components/native';

import { colors } from '@ioupie/shared/constants';
import { themefy } from '@ioupie/shared/utils';

export default styled(Subheading)`
  background-color: ${themefy({
    light: colors.light.background,
    dark: colors.dark.background,
  })};
`;
