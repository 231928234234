export declare type BankingInformation = BaseBankingInformation & SpecificBankInformation;

export declare type BaseBankingInformation = {
  readonly entityId: string;
  readonly bankInfoId: string;
  readonly corporateTaxCode: string;
  readonly type: string;
  readonly active: boolean;
  readonly createdAt: string;
  readonly lastModifiedAt: string;
  readonly lastModifiedBy: string;
};

export declare type SpecificBankInformation = Partial<BankAccount> & Partial<PixKey>;

export declare type BankAccount = {
  readonly bankId: string;
  readonly branchId: string;
  readonly accountId: string;
};

export declare type PixKey = {
  readonly pixKeyType: string;
  readonly key: string;
};

export const BankingInformationType = Object.freeze({
  BANK_ACCOUNT: 'BANK_ACCOUNT',
  PIX: 'PIX',
});

export const PixKeyType = Object.freeze({
  CELULAR: 'CELULAR',
  CPF_CNPJ: 'CPF_CNPJ',
  EMAIL: 'EMAIL',
  RANDOM: 'RANDOM',
});
