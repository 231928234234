import styled from 'styled-components/native';

import { Button } from '@ioupie/components/buttons';
import { TextInput } from '@ioupie/components/inputs';
import { Divider } from '@ioupie/components/layout';
import { Text } from '@ioupie/components/typography';
import { colors, fontsFamily, fontsWeight } from '@ioupie/shared/constants';
import { themefy } from '@ioupie/shared/utils';

export const CouponContainer = styled.View`
  justify-content: space-between;
  margin: 16px 16px 0;
`;

export const CouponTitle = styled(Text)`
  color: ${themefy({
    light: colors.light.title,
    dark: colors.dark.title,
  })};
  font-family: ${fontsFamily.bold};
  font-size: 16px;
  font-weight: ${fontsWeight.bold};
  margin-bottom: -5px;
`;

export const CouponText = styled(TextInput)`
  flex: 1;
  margin: 0;
`;

export const CouponButton = styled(Button)`
  padding: 0;
  margin-left: 10px;
`;

export const CouponField = styled.View`
  flex-direction: row;
  justify-content: space-between;
  width: 100%;
`;

export const Separator = styled(Divider)`
  margin: 16px 0 0;
`;
