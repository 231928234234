import { memo } from 'react';

import { MyAccount } from '@ioupie/containers/profile';
import { useCurrentRouteFocus, useHeaderStore, useNavigationStore } from '@ioupie/hooks';
import { routes } from '@ioupie/shared/constants';

import * as S from './my-account.styles';

/**
 * @function MyAccountPage
 */
export default memo(() => {
  const headerStore = useHeaderStore();
  const navigationStore = useNavigationStore();

  useCurrentRouteFocus(routes.pages.profile.my_account, () => {
    headerStore.allowNavigateBack(true);
    headerStore.removeHeaderShadow(false);
    headerStore.changeTitle('pages.profile.my-account.title');
    headerStore.changeSubtitle('');
    headerStore.onClickBack(() => navigationStore.dispatchGoBack());
  });

  return (
    <S.MyAccountContainer>
      <MyAccount />
    </S.MyAccountContainer>
  );
});
