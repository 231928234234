import styled from 'styled-components/native';

import { UniversalImage } from '@ioupie/components/gallery';
import { Text, Title } from '@ioupie/components/typography';
import { colors } from '@ioupie/shared/constants';
import { themefy } from '@ioupie/shared/utils';

export const PaymentEntryContainer = styled.View`
  align-items: center;
  display: flex;
  flex-flow: row wrap;
  justify-content: space-between;
`;

export const CreditCardDetails = styled.View`
  align-items: center;
  display: flex;
  flex-flow: row wrap;
  justify-content: space-between;
`;

export const PaymentMethodImage = styled(UniversalImage)`
  margin-right: 10px;
`;

export const PixHeader = styled.View`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;
  margin: 10px 30px;
`;

export const PixFooter = styled.View`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  margin: 10px 0px;
`;

export const PixCodeContainer = styled.View`
  display: flex;
  align-items: center;
`;

export const PixCode = styled(UniversalImage)`
  width: 200px;
  height: 200px;
`;

export const PixCopiaColaContainer = styled.View`
  align-items: center;
  display: flex;
  flex-flow: row;
`;

export const PixCopiaColaField = styled(Text)`
  background: ${themefy({
    light: colors.light.background,
    dark: colors.dark.background,
  })};
  height: 50px;
  overflow: hidden;
  padding: 15px;
  width: 60%;
`;

export const PixTitle = styled(Title)`
  width: 100%;
  padding: 0 5px;
`;

export const PaymentDate = styled(Text)`
  font-size: 15px;
  margin: 8px 0;
`;
