import { lighten, darken } from 'polished';
import styled from 'styled-components/native';

import { BlockButton, Button } from '@ioupie/components/buttons';
import { TextInput } from '@ioupie/components/inputs';
import { Text } from '@ioupie/components/typography';
import { colors } from '@ioupie/shared/constants';
import { themefy } from '@ioupie/shared/utils';

export const CouponText = styled(TextInput)`
  flex: 1;
`;

export const CouponButton = styled(Button)`
  margin: 0 -10px 0 0;
  padding: 0;
  right: -10px;
`;

export const CreateOrderContainer = styled.View`
  align-items: center;
  border-top-width: 1px;
  flex-direction: column;
  margin: 0;
`;

export const CouponField = styled.View`
  flex-direction: row;
  justify-content: space-between;
`;

export const CouponContainer = styled.View`
  justify-content: space-between;
  padding: 6px;
  width: 90%;
`;

export const OrderDetailLine = styled.View`
  flex-direction: row;
  justify-content: space-between;
  padding: 12px 6px;
  width: 90%;
`;

export const ProviderNotEligibleMessage = styled.View`
  align-items: baseline;
  background: ${themefy({
    light: lighten(0.7, colors.light.text),
    dark: darken(0.5, colors.dark.text),
  })};
  border-radius: 10px;
  flex-direction: column;
  justify-content: space-around;
  padding: 2% 0;
  width: 90%;
`;

export const InvalidMessage = styled.View`
  align-items: center;
  flex-direction: row;
  justify-content: space-between;
  margin: 0 10px;
  padding: 2%;
`;

export const InvalidMessageText = styled(Text)`
  margin: 0 4% 0 0;
  padding: 0 2%;
`;

export const DialogButtonColumn = styled.View`
  display: flex;
  flex: 1;
  flex-direction: row;
`;

export const DialogButton = styled(BlockButton)`
  flex: 1;
`;

export const DialogAddressAddon = styled(Text)`
  color: ${themefy({ light: lighten(0.4, colors.light.text), dark: darken(0.2, colors.dark.text) })};
  font-size: 14px;
  padding: 5px 0;
`;
