import styled from 'styled-components/native';

import { colors } from '@ioupie/shared/constants';
import { themefy } from '@ioupie/shared/utils';

declare type ExpandBarProps = {
  readonly expanded?: boolean;
};

export default styled.KeyboardAvoidingView<ExpandBarProps>`
  background-color: ${themefy({
    light: colors.light.primary,
    dark: colors.dark.primary,
  })};
  elevation: 4;
  min-height: ${({ expanded }) => {
    return expanded ? '30%' : '15%';
  }};
  padding: 10px;
  width: 100%;
`;
