import { yupResolver } from '@hookform/resolvers/yup';
import { DateTime } from 'luxon';
import { observer } from 'mobx-react-lite';
import { Fragment, useCallback, useEffect } from 'react';
import { useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { Keyboard } from 'react-native';
import { validateCPF } from 'validations-br';
import validator from 'validator';
import * as yup from 'yup';

import { BlockButton, SwitchButton } from '@ioupie/components/buttons';
import { ErrorSnackbar, Loader } from '@ioupie/components/custom';
import { DateInput, TextInput } from '@ioupie/components/inputs';
import { ScrollView } from '@ioupie/components/layout';
import { HelperText, LinkText, Small, Text, Title } from '@ioupie/components/typography';
import { useCurrentRouteFocus, useNavigationStore, useUserStore } from '@ioupie/hooks';
import { COUNTRY_CODES, routes } from '@ioupie/shared/constants';
import { getLatestLegalAgeDate } from '@ioupie/shared/utils';

import { UserDataForm } from './user-data.models';
import * as S from './user-data.styles';

const BRAZILIAN_PHONE_COUNTRY_CODE: Readonly<string> = '+55';

/**
 * @function UserDataContainer
 */
export default observer(() => {
  const [t] = useTranslation();
  const userStore = useUserStore();
  const navigationStore = useNavigationStore();

  const userDataSchema: yup.SchemaOf<UserDataForm> = yup.object().shape({
    name: yup
      .string()
      .required(t('forms.user-data.name.errors.required'))
      .min(1, ({ min }) => t('forms.user-data.name.errors.min', { min })),
    familyName: yup
      .string()
      .required(t('forms.user-data.family-name.errors.required'))
      .min(1, ({ min }) => t('forms.user-data.family-name.errors.min', { min })),
    cpf: yup
      .string()
      .notRequired()
      .test(
        'is-cpf',
        t('forms.user-data.cpf.errors.valid'),
        (cpf = '') => validateCPF(cpf) || COUNTRY_CODES.some((cc) => validator.isPassportNumber(cpf, cc)),
      ),
    phoneAreaCode: yup
      .string()
      .required(t('forms.user-data.phone-area-code.errors.required'))
      .length(2, ({ length }) => t('forms.user-data.phone-area-code.errors.length', { length })),
    phoneNumber: yup
      .string()
      .required(t('forms.user-data.phone-number.errors.required'))
      .length(9, ({ length }) => t('forms.user-data.phone-number.errors.length', { length })),
    birthDate: yup
      .date()
      .required(t('forms.user-data.birth-date.errors.required'))
      .max(getLatestLegalAgeDate(), t('forms.user-data.birth-date.errors.max')),
    perfume: yup.boolean().required(t('forms.user-data.perfume.errors.required')),
    pwd: yup.boolean().required(t('forms.user-data.pwd.errors.required')),
    allergen: yup.boolean().required(t('forms.user-data.allergen.errors.required')),
    allergenInfo: yup
      .string()
      .notRequired()
      .when('allergen', {
        is: Boolean,
        then: yup
          .string()
          .required(t('forms.user-data.allergen-info.errors.required'))
          .min(1, ({ min }) => t('forms.user-data.allergen-info.errors.min', { min })),
      }),
  });

  const {
    register,
    handleSubmit,
    setValue,
    watch,
    reset,
    formState: { errors, isValid },
  } = useForm<UserDataForm>({
    defaultValues: {
      allergen: false,
      allergenInfo: '',
      cpf: '',
      familyName: '',
      name: '',
      perfume: false,
      phoneAreaCode: '',
      phoneNumber: '',
      pwd: false,
    },
    resolver: yupResolver(userDataSchema),
  });

  useCurrentRouteFocus(routes.pages.profile.my_profile, () => {
    // eslint-disable-next-line no-void
    void userStore.fetchUserData();
  });

  useEffect(() => {
    register('name');
    register('familyName');
    register('cpf');
    register('phoneAreaCode');
    register('phoneNumber');
    register('birthDate');
    register('allergen');
    register('allergenInfo');
    register('perfume');
    register('pwd');
  }, [register]);

  const dateFromEditUserData = userStore.editUserData?.birthDate
    ? DateTime.fromFormat(userStore.editUserData?.birthDate, 'MM-dd-yyyy').toJSDate()
    : undefined;

  useEffect(() => {
    reset({
      ...userStore.editUserData,
      birthDate: dateFromEditUserData,
    });
  }, [userStore.editUserData]);

  const onSubmitValidForm = useCallback((form: UserDataForm) => {
    Keyboard.dismiss();

    return userStore.updateUserData({
      ...form,
      birthDate: DateTime.fromJSDate(form.birthDate).toFormat('MM-dd-yyyy'),
      phoneNumber: `${BRAZILIAN_PHONE_COUNTRY_CODE}${form.phoneAreaCode}${form.phoneNumber}`,
    });
  }, []);

  const navigateToMyAccount = () => {
    navigationStore.dispatchNavigation({
      stack: routes.stacks.profile,
      screen: routes.pages.profile.my_account,
    });
  };

  const NameSection = (
    <Fragment>
      <TextInput
        autoCapitalize="words"
        error={Boolean(errors.name)}
        label={t('forms.user-data.name.label')}
        placeholder={t('forms.user-data.name.placeholder')}
        value={watch('name')}
        onChangeText={(text) => {
          setValue('name', text, { shouldValidate: true });
        }}
      />
      {Boolean(errors.name) && (
        <HelperText type="error" visible={Boolean(errors.name)}>
          {errors.name?.message ?? ''}
        </HelperText>
      )}
    </Fragment>
  );

  const FamilyNameSection = (
    <Fragment>
      <TextInput
        autoCapitalize="words"
        error={Boolean(errors.familyName)}
        label={t('forms.user-data.family-name.label')}
        placeholder={t('forms.user-data.family-name.placeholder')}
        value={watch('familyName')}
        onChangeText={(text) => {
          setValue('familyName', text, { shouldValidate: true });
        }}
      />
      {Boolean(errors.familyName) && (
        <HelperText type="error" visible={Boolean(errors.familyName)}>
          {errors.familyName?.message ?? ''}
        </HelperText>
      )}
    </Fragment>
  );

  const CPFSection = (
    <Fragment>
      <HelperText type="info" visible>
        {t('forms.user-data.cpf.usage')}
      </HelperText>
      <TextInput
        keyboardType="numeric"
        maxLength={11}
        error={Boolean(errors.cpf)}
        label={t('forms.user-data.cpf.label')}
        placeholder={t('forms.user-data.cpf.placeholder')}
        value={watch('cpf')}
        onChangeText={(text) => {
          setValue('cpf', text, { shouldValidate: true });
        }}
      />
      {Boolean(errors.cpf) && (
        <HelperText type="error" visible={Boolean(errors.cpf)}>
          {errors.cpf?.message ?? ''}
        </HelperText>
      )}
    </Fragment>
  );

  const PhoneSection = (
    <Fragment>
      <S.PhoneContainer>
        <S.PhoneAreaInput
          keyboardType="numeric"
          maxLength={2}
          error={Boolean(errors.phoneAreaCode)}
          label={t('forms.user-data.phone-area-code.label')}
          placeholder={t('forms.user-data.phone-area-code.placeholder')}
          value={watch('phoneAreaCode')}
          onChangeText={(text) => {
            setValue('phoneAreaCode', text, { shouldValidate: true });
          }}
        />
        <S.PhoneNumberInput
          keyboardType="numeric"
          maxLength={9}
          error={Boolean(errors.phoneNumber)}
          label={t('forms.user-data.phone-number.label')}
          placeholder={t('forms.user-data.phone-number.placeholder')}
          value={watch('phoneNumber')}
          onChangeText={(text) => {
            setValue('phoneNumber', text, { shouldValidate: true });
          }}
        />
      </S.PhoneContainer>
      {Boolean(errors.phoneAreaCode) && (
        <HelperText type="error" visible={Boolean(errors.phoneAreaCode)}>
          {errors.phoneAreaCode?.message ?? ''}
        </HelperText>
      )}
      {Boolean(errors.phoneNumber) && (
        <HelperText type="error" visible={Boolean(errors.phoneNumber)}>
          {errors.phoneNumber?.message ?? ''}
        </HelperText>
      )}
    </Fragment>
  );

  const BirthDateSection = (
    <Fragment>
      <HelperText type="info" visible>
        {t('forms.user-data.birth-date.label')}
      </HelperText>
      <DateInput
        initialValue={dateFromEditUserData}
        onChangeDate={(date) => {
          if (date) {
            setValue('birthDate', date, { shouldValidate: true });
          }
        }}
      />
      {Boolean(errors.birthDate) && (
        <HelperText type="error" visible={Boolean(errors.birthDate)}>
          {errors.birthDate?.message ?? ''}
        </HelperText>
      )}
    </Fragment>
  );

  const PerfumeSection = (
    <Fragment>
      <SwitchButton
        text={t('forms.user-data.perfume.label')}
        value={watch('perfume')}
        onValueChange={(value) => setValue('perfume', value, { shouldValidate: true })}
      />
      {Boolean(errors.perfume) && (
        <HelperText type="error" visible={Boolean(errors.perfume)}>
          {errors.perfume?.message ?? ''}
        </HelperText>
      )}
    </Fragment>
  );

  const PWDSection = (
    <Fragment>
      <SwitchButton
        text={t('forms.user-data.pwd.label')}
        value={watch('pwd')}
        onValueChange={(value) => setValue('pwd', value, { shouldValidate: true })}
      />
      {Boolean(errors.pwd) && (
        <HelperText type="error" visible={Boolean(errors.pwd)}>
          {errors.pwd?.message ?? ''}
        </HelperText>
      )}
    </Fragment>
  );

  const AllergenSection = (
    <Fragment>
      <SwitchButton
        text={t('forms.user-data.allergen.label')}
        value={watch('allergen')}
        onValueChange={(value) => {
          setValue('allergen', value, { shouldValidate: true });
          setValue('allergenInfo', '');
        }}
      />
      {Boolean(errors.allergen) && (
        <HelperText type="error" visible={Boolean(errors.allergen)}>
          {errors.allergen?.message ?? ''}
        </HelperText>
      )}
    </Fragment>
  );

  const AllergenInfoSection = (
    <Fragment>
      <S.AllergenInfoBox>
        <Text>{t('forms.user-data.allergen-info.disclaimer')}</Text>
        <TextInput
          autoCapitalize="words"
          error={Boolean(errors.allergenInfo)}
          label={t('forms.user-data.allergen-info.label')}
          placeholder={t('forms.user-data.allergen-info.placeholder')}
          value={watch('allergenInfo')}
          onChangeText={(text) => {
            setValue('allergenInfo', text, { shouldValidate: true });
          }}
        />
        {Boolean(errors.allergenInfo) && (
          <HelperText type="error" visible={Boolean(errors.allergenInfo)}>
            {errors.allergenInfo?.message ?? ''}
          </HelperText>
        )}
      </S.AllergenInfoBox>
    </Fragment>
  );

  return (
    <S.Container>
      <ScrollView>
        <S.CenterTitle>
          <Title>{t('forms.user-data.title')}</Title>
          <Small>{userStore.profileData?.email ?? ''}</Small>
        </S.CenterTitle>
        {NameSection}
        {FamilyNameSection}
        {CPFSection}
        {PhoneSection}
        {BirthDateSection}
        {PerfumeSection}
        {PWDSection}
        {AllergenSection}
        {Boolean(watch('allergen')) && AllergenInfoSection}
        <BlockButton
          text={t('forms.user-data.submit')}
          disabled={!isValid}
          loading={userStore.loading}
          onPress={handleSubmit(onSubmitValidForm)}
        />
        <S.Separator />
        <S.AboutAccount>
          {t('containers.user-data.about-account')}
          <LinkText navigateTo={navigateToMyAccount} text={t('containers.user-data.more')} />
        </S.AboutAccount>
      </ScrollView>
      <Loader show={userStore.loading} message={t('containers.user-data.loading')} />
      <ErrorSnackbar errors={userStore.errors} onDismiss={() => userStore.clearErrors()} />
    </S.Container>
  );
});
