import styled from 'styled-components/native';

import { Text } from '@ioupie/components/typography';
import { colors, fontsFamily, fontsWeight } from '@ioupie/shared/constants';
import { themefy } from '@ioupie/shared/utils';

export const BasketTitle = styled(Text)`
  color: ${themefy({
    light: colors.light.title,
    dark: colors.dark.title,
  })};
  font-family: ${fontsFamily.bold};
  font-size: 16px;
  font-weight: ${fontsWeight.bold};
  margin: 16px 16px 0;
`;

export const ItemInfoContainer = styled.View`
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  margin: 8px 16px;
`;

export const ItemContainer = styled.View`
  flex-direction: column;
`;

export const ItemTitle = styled(Text)`
  color: ${themefy({
    light: colors.light.title,
    dark: colors.dark.title,
  })};
  font-size: 15px;
  padding: 2px 0;
`;

export const ItemDescription = styled(Text)`
  color: ${themefy({
    light: colors.light.text3,
    dark: colors.dark.text3,
  })};
  font-size: 13px;
  padding: 2px 0;
`;
