import { lighten } from 'polished';
import styled from 'styled-components/native';

import { Text } from '@ioupie/components/typography';
import { colors } from '@ioupie/shared/constants';
import { themefy } from '@ioupie/shared/utils';

export const CompartmentContainer = styled.View`
  border: 1px solid
    ${themefy({
      light: lighten(0.6, colors.light.secondary),
      dark: colors.dark.secondary,
    })};
  flex-direction: column;
  margin: 10px;
  padding: 0 0 10px;
  text-align: center;
`;

export const LockerTextView = styled.View`
  margin: 10px 20px;
`;

export const LockerText = styled(Text)`
  align-items: center;
  justify-content: center;
`;

export const LockerNumberStyle = styled.View`
  align-items: center;
  background-color: #e8e8e8;
  border-radius: 4px;
  height: 65px;
  justify-content: center;
  margin-bottom: 16px;
  padding: 8px;
  width: 90%;
`;

export const CompartmentNumber = styled(Text)`
  font-size: 32px;
  font-weight: 800;
`;

export const LockerUnavailable = styled(Text)`
  color: ${themefy({
    light: colors.light.error,
    dark: colors.dark.error,
  })};
  margin: 4px 16px 0 20px;
`;
