/* eslint-disable @typescript-eslint/no-unsafe-call */
/* eslint-disable @typescript-eslint/no-unsafe-member-access */
/* eslint-disable @typescript-eslint/no-unsafe-assignment */
import { yupResolver } from '@hookform/resolvers/yup';
import { ServiceOrderV2 } from '@ioupie/models-commons';
import { DateTime } from 'luxon';
import { observer } from 'mobx-react-lite';
import { Fragment, useState } from 'react';
import { FormProvider, useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { TouchableOpacity } from 'react-native';
import * as yup from 'yup';

import { BlockButton, IconButton } from '@ioupie/components/buttons';
import { Calendar, ErrorSnackbar } from '@ioupie/components/custom';
import { ScrollView } from '@ioupie/components/layout';
import { useNavigationStore, useOrdersStore, usePaymentStore } from '@ioupie/hooks';
import {
  BUSINESS_AREA_OPTIONS,
  CALENDAR_DATE_FORMAT,
  ORDER_PERIOD,
  ORDER_STEPS,
  PAYMENT_METHOD_TYPES,
  PERIOD_TIME_MAPPER,
  routes,
} from '@ioupie/shared/constants';
import { PaymentMethodForm } from '@ioupie/shared/models';
import { isEnumOf, safeObjectLookup } from '@ioupie/shared/utils';
import {
  dateAfterDays,
  getFollowingDays,
  getNextBusinessDay,
  getPreviousDays,
  isWeekend,
} from '@ioupie/shared/utils/date.utils';

import * as S from './schedule-step.styles';
import { Payment } from '../../payment';

/**
 * @function ScheduleStepComponent
 */
export default observer(() => {
  const [t] = useTranslation();
  const ordersStore = useOrdersStore();
  const paymentStore = usePaymentStore();
  const navigationStore = useNavigationStore();

  const isPeriodEnum = isEnumOf(ORDER_PERIOD);

  const { selectedOrderHistory, scheduledOrderDate = '' } = ordersStore;
  const { type, minimumDays = 0, grandTotal } = selectedOrderHistory ?? {};
  const { amount: currentAmount = 0 } = grandTotal ?? {};

  const hasAdditionalCharge = currentAmount - ordersStore.originalTotalAmount > 0;
  const firstPaymentByPix = !ordersStore.hasInvolvedPaymentWithAuthorizationStatus;

  const { hour: deliveryHour } = DateTime.fromISO(scheduledOrderDate);
  const defaultScheduledPeriod = type === BUSINESS_AREA_OPTIONS.LOCKER ? ORDER_PERIOD.EVENING : ORDER_PERIOD.AFTERNOON;

  const scheduledPeriod =
    Object.keys(PERIOD_TIME_MAPPER)
      .filter(isPeriodEnum)
      .find((key) => deliveryHour === safeObjectLookup(PERIOD_TIME_MAPPER, key)) ?? defaultScheduledPeriod;

  const [period, setPeriod] = useState(scheduledPeriod);
  const [chosenDate, setChosenDate] = useState(scheduledOrderDate);

  const paymentSchema: yup.SchemaOf<PaymentMethodForm> = yup.object().shape({
    method: yup
      .string()
      .required(t('containers.orders.track-order.finish-order-stepper.schedule-step.method-errors.required'))
      .min(1, ({ min }) =>
        t('containers.orders.track-order.finish-order-stepper.schedule-step.method-errors.min', { min }),
      ),
  });

  const formMethods = useForm<PaymentMethodForm>({
    defaultValues: {},
    resolver: yupResolver(paymentSchema),
  });

  // // Get todays value
  const currentDate = DateTime.now();
  // If the day which the service is being generated is an weekend,
  // do not account it as an working day.
  const append = isWeekend(currentDate.toJSDate()) ? 0 : 1;
  // The sum of today with the maximum working day, taking in account
  // if today is a weekend or not.
  const nextBusinessDay = getNextBusinessDay(currentDate);
  const dateAfter = nextBusinessDay.plus({ days: minimumDays + append }).toJSDate();
  // If the resulting date is on the next month
  const hasMonthChange = currentDate.toJSDate().getMonth() < dateAfter.getMonth();
  // Get the weekends between today and the target date
  const followingWeekends = getFollowingDays(currentDate.toJSDate())
    .filter(isWeekend)
    .filter((followingWeekend) => hasMonthChange || followingWeekend.getDate() <= dateAfter.getDate());
  // If the month has changed, get all weekends from next month
  const weekendsBefore = hasMonthChange ? getPreviousDays(dateAfter).filter(isWeekend) : [];
  // The total of weekends, the ones in the current month
  // with the ones into the next month
  const numberOfWeekends = followingWeekends.length + weekendsBefore.length;
  // Sum up the target date with the weekends
  const calculatedDate = dateAfterDays(dateAfter, numberOfWeekends);
  // Normalize the calculated date
  const futureDate = DateTime.fromJSDate(new Date(calculatedDate));
  // Convert it to string, as calendar only works with strings
  const blockingDate = DateTime.fromJSDate(dateAfter).toFormat(CALENDAR_DATE_FORMAT);
  // The date to be shown when the calendar bootstrap
  const bootstrapDate = currentDate.toJSDate().getMonth() !== futureDate.month ? blockingDate : undefined;

  const handleScheduleButton = async (): Promise<void> => {
    // Concatenate the date and the period
    const hour = safeObjectLookup(PERIOD_TIME_MAPPER, period ?? defaultScheduledPeriod) as number;
    const isoDateAndHour = DateTime.fromISO(chosenDate).set({ hour }).toString();

    ordersStore.setScheduleOrderDate(isoDateAndHour);

    if (hasAdditionalCharge && firstPaymentByPix) {
      const payload =
        paymentStore.selectedPaymentType === PAYMENT_METHOD_TYPES.CREDIT
          ? {
              _pid: formMethods.watch('method'),
              _cvv: '',
              method: PAYMENT_METHOD_TYPES.CREDIT,
            }
          : { method: PAYMENT_METHOD_TYPES.PIX };
      ordersStore.setOrderPaymentPayload(payload);
    }

    await ordersStore.activateTicket();

    if (ordersStore.errors.length > 0) {
      return;
    }

    if (paymentStore.selectedPaymentType === PAYMENT_METHOD_TYPES.PIX) {
      ordersStore.changeStep(ORDER_STEPS.FINISH_ORDER);
      navigationStore.dispatchNavigation({
        stack: routes.stacks.orders,
        screen: routes.pages.orders.track_order,
      });
    } else {
      ordersStore.changeStep(ORDER_STEPS.APPROVED);
    }
  };

  const handleHelpButton = (): void =>
    navigationStore.dispatchNavigation({
      stack: routes.stacks.help,
      screen: routes.pages.help.help,
    });

  const [showCalendar, setShowCalendar] = useState(false);
  const [originalDropOffDateTime, setOriginalDropOffDateTime] = useState('');

  const castedOrder = selectedOrderHistory as any as ServiceOrderV2;
  const { outboundDetails = {} } = castedOrder;
  const { requestedTime: dropOffDateTime = '' } = outboundDetails;
  if (originalDropOffDateTime === undefined || originalDropOffDateTime.trim().length === 0) {
    setOriginalDropOffDateTime(dropOffDateTime);
  }

  if (chosenDate === undefined || chosenDate.trim().length === 0) {
    setChosenDate(DateTime.fromISO(originalDropOffDateTime).toISO() ?? '');
  }

  const { payment: orderPaymentInformation = {} } = selectedOrderHistory as any as ServiceOrderV2;
  const { involvedPayments = [] } = orderPaymentInformation;
  const paidAmount = involvedPayments
    .filter((payment) => payment.status === 'SUCCESS')
    .reduce((accumulator, currentPayment) => {
      const { amount: paymentAmount = {} } = currentPayment;
      const { amount = 0 } = paymentAmount;
      return accumulator + amount;
    }, 0);

  const remainingAmount = currentAmount - paidAmount;

  const isAdditionalPayment = remainingAmount > 0.005;

  const scheduleTranslationPath = 'containers.orders.track-order.finish-order-stepper.schedule-step';

  const DeliveryDateLabelContainer = (
    <S.SectionContainer>
      <S.SectionTitle>{t(`${scheduleTranslationPath}.delivery-date.title`)}</S.SectionTitle>
      <S.SectionFlexContainer>
        <S.SectionText>
          {t(`${scheduleTranslationPath}.delivery-date.label`, {
            date: DateTime.fromISO(originalDropOffDateTime).toLocaleString(),
          })}
        </S.SectionText>
        <IconButton size={28} icon="pencil" onPress={() => setShowCalendar(true)} />
      </S.SectionFlexContainer>
    </S.SectionContainer>
  );

  const DeliveryDateCalendarContainer = (
    <S.SectionContainer>
      <S.LabelTitle>{t(`${scheduleTranslationPath}.label-title`)}</S.LabelTitle>
      <Calendar
        startDate={DateTime.fromISO(chosenDate).toISODate()}
        disableWeekends
        currentDate={bootstrapDate}
        disableDatesUntil={blockingDate}
        onDateChange={setChosenDate}
      />
      {type === BUSINESS_AREA_OPTIONS.LOCKER && (
        <S.SharedLockerDisclaimer>
          {t(`${scheduleTranslationPath}.locker.shared-locker-disclaimer`)}
        </S.SharedLockerDisclaimer>
      )}
      <TouchableOpacity onPress={handleHelpButton}>
        <S.OtherDate black>{t(`${scheduleTranslationPath}.help-button`)}</S.OtherDate>
      </TouchableOpacity>
    </S.SectionContainer>
  );

  const OrderSubtotalContainer = (
    <S.SectionContainer>
      <S.SectionTitle>{t(`${scheduleTranslationPath}.order-subtotals.title`)}</S.SectionTitle>
      {isAdditionalPayment && (
        <S.PreviousPaymentsContainer>
          <S.PaymentBreakdownEntry>
            <S.PaymentBreakdownText>
              {t(`${scheduleTranslationPath}.order-subtotals.grand-total.label`)}
            </S.PaymentBreakdownText>
            <S.PaymentBreakdownText>
              {t(`${scheduleTranslationPath}.order-subtotals.grand-total.amount`, { amount: currentAmount.toFixed(2) })}
            </S.PaymentBreakdownText>
          </S.PaymentBreakdownEntry>
          <S.PaymentBreakdownEntry>
            <S.PaymentBreakdownSecondaryText>
              {t(`${scheduleTranslationPath}.order-subtotals.previously-paid.label`)}
            </S.PaymentBreakdownSecondaryText>
            <S.PaymentBreakdownSecondaryText>
              {t(`${scheduleTranslationPath}.order-subtotals.previously-paid.amount`, {
                amount: paidAmount.toFixed(2),
              })}
            </S.PaymentBreakdownSecondaryText>
          </S.PaymentBreakdownEntry>
        </S.PreviousPaymentsContainer>
      )}
      <S.PaymentBreakdownEntry>
        <S.PaymentBreakdownBoldText>
          {remainingAmount >= 0
            ? t(`${scheduleTranslationPath}.order-subtotals.remaining-amount.label`)
            : t(`${scheduleTranslationPath}.order-subtotals.amount-to-reimburse.label`)}
        </S.PaymentBreakdownBoldText>
        <S.PaymentBreakdownBoldText>
          {t(`${scheduleTranslationPath}.order-subtotals.remaining-amount.amount`, {
            amount: Math.abs(remainingAmount).toFixed(2),
          })}
        </S.PaymentBreakdownBoldText>
      </S.PaymentBreakdownEntry>
    </S.SectionContainer>
  );

  return (
    <Fragment>
      <ScrollView>
        <S.DeliveryScheduleContainer>
          {showCalendar ? DeliveryDateCalendarContainer : DeliveryDateLabelContainer}

          {OrderSubtotalContainer}

          <S.NoPaddingSectionContainer>
            <FormProvider {...formMethods}>
              <Payment readOnly={!firstPaymentByPix || (firstPaymentByPix && !hasAdditionalCharge)} />
            </FormProvider>
          </S.NoPaddingSectionContainer>

          <BlockButton
            onPress={handleScheduleButton}
            text={t(`${scheduleTranslationPath}.next-button`)}
            disabled={chosenDate === ''}
            loading={ordersStore.loading}
          />
        </S.DeliveryScheduleContainer>
      </ScrollView>
      <ErrorSnackbar errors={ordersStore.errors} onDismiss={() => ordersStore.clearErrors()} />
    </Fragment>
  );
});
