import { memo } from 'react';
import { Linking } from 'react-native';

import { LinkTextProps } from './link-text.models';
import * as S from './link-text.styles';

/**
 * @function LinkTextComponent
 */
export default memo((props: LinkTextProps) => {
  const { link = '', text = '', navigateTo } = props;

  const fallbackLink = 'https://ioupie.com.br/faq/';
  const openLink = () => Linking.openURL(link ?? fallbackLink);

  const handleOnPress = navigateTo ?? openLink;

  return <S.LinkText onPress={handleOnPress}>{text}</S.LinkText>;
});
