import { observer } from 'mobx-react-lite';
import { Fragment, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { View } from 'react-native';

import { BlockButton, IconButton } from '@ioupie/components/buttons';
import { List } from '@ioupie/components/custom';
import { Divider } from '@ioupie/components/layout';
import { useAddressStore, useHeaderStore, useLockersStore, useOrdersStore } from '@ioupie/hooks';
import { LOCKER_STATUS, ORDER_TRACKING_SCREENS } from '@ioupie/shared/constants';

import * as S from './compartment.styles';

/**
 * @function CompartmentContainer
 */
export default observer(() => {
  const [t] = useTranslation();
  const headerStore = useHeaderStore();
  const ordersStore = useOrdersStore();
  const lockersStore = useLockersStore();
  const addressStore = useAddressStore();

  const { currentCompartment, selectedOrderHistory } = ordersStore;
  const { orderNumber = '', inboundDetails } = selectedOrderHistory ?? {};
  const { identifier: currentIdentifier } = currentCompartment ?? {};
  const { compartment, lockerName = '' } = inboundDetails ?? {};
  const { identifier: historyIdentifier } = compartment ?? {};
  const { lockerProvider } = lockersStore ?? {};

  const loading = ordersStore.loading || lockersStore.loading || addressStore.loading;

  useEffect(() => {
    headerStore.changeSubtitle(t('pages.track-order.subtitle', { orderNumber }));
  }, [orderNumber]);

  const handleOpenDoor = async (): Promise<void> => {
    ordersStore.logOrderTrackingNavigation(ORDER_TRACKING_SCREENS.ACTIVATE_TICKET);
    // Next step is to request a compartment number and/or open it
    await ordersStore.getFreeCompartmentNumber();
  };

  const handleReopenDoor = async (): Promise<void> => {
    if (inboundDetails) {
      await lockersStore.openLockerCompartment(inboundDetails);
    }
  };

  const body = (
    <View>
      <List.Message
        title={t('containers.orders.track-order.unlock-locker.header.title')}
        subtitle={t('containers.orders.track-order.unlock-locker.header.description', { lockerName })}
      />
    </View>
  );

  const compartmentNumber = historyIdentifier ?? currentIdentifier ?? 0;
  const isLockerAvailable = lockerProvider && lockerProvider.status === LOCKER_STATUS.AVAILABLE;

  return (
    <Fragment>
      {compartmentNumber !== 0 ? (
        <Fragment>
          <S.CompartmentContainer>
            <S.LockerTextView>
              <S.LockerText>{t('containers.orders.track-order.unlock-locker.insert-product')}</S.LockerText>
            </S.LockerTextView>

            <S.LockerNumberStyle>
              <S.CompartmentNumber>{compartmentNumber}</S.CompartmentNumber>
            </S.LockerNumberStyle>

            <BlockButton
              secondary
              text={t('containers.orders.track-order.unlock-locker.reopen-door')}
              loading={loading}
              onPress={handleReopenDoor}
            />
          </S.CompartmentContainer>
          <Divider />
        </Fragment>
      ) : (
        <S.CompartmentContainer>
          <List.Item
            left={<IconButton icon="ticket-confirmation-outline" size={40} />}
            body={body}
            showBorder={false}
          />
          <BlockButton
            text={t('containers.orders.track-order.unlock-locker.open-door')}
            loading={loading}
            onPress={handleOpenDoor}
            disabled={!isLockerAvailable}
          />
          {!isLockerAvailable && (
            <S.LockerUnavailable>
              {t('containers.orders.track-order.unlock-locker.lockerUnavailableDescription')}
            </S.LockerUnavailable>
          )}
        </S.CompartmentContainer>
      )}
    </Fragment>
  );
});
