import styled from 'styled-components/native';

import { List } from '@ioupie/components/custom';

export const CatalogBreak = styled(List.Message)`
  margin: 20px 0 0 20px;
`;

export const ProductImage = styled.View`
  margin: 10px 5px 0 10px;
`;
