import { observer } from 'mobx-react-lite';
import { Fragment } from 'react';
import { useTranslation } from 'react-i18next';

import { BlockButton } from '@ioupie/components/buttons';
import { useNavigationStore, useSettingsStore, useShopsStore } from '@ioupie/hooks';
import { BUSINESS_AREA_OPTIONS, routes } from '@ioupie/shared/constants';

import * as S from './ticket-activated.styles';

/**
 * @function TicketActivatedContainer
 */
export default observer(() => {
  const [t] = useTranslation();
  const settingsStore = useSettingsStore();
  const shopsStore = useShopsStore();
  const navigationStore = useNavigationStore();

  const { shopBusinessArea = BUSINESS_AREA_OPTIONS.LOCKER } = shopsStore;

  const handleGoHome = async (): Promise<void> => {
    navigationStore.dispatchNavigation({
      stack: routes.stacks.orders,
      screen: routes.pages.orders.home,
    });
    if (!settingsStore.pushNotificationPermissionAlreadyRequested) {
      await settingsStore.requestPushNotificationPermission();
    }
  };

  const isLocker = shopBusinessArea === BUSINESS_AREA_OPTIONS.LOCKER;
  const description = isLocker
    ? 'containers.orders.thank.ticket-activated.wait-pickup'
    : 'containers.orders.thank.ticket-activated.wait-contact';

  return (
    <Fragment>
      <S.ThankMessage>
        <S.TitleMessage>{t('containers.orders.thank.ticket-activated.success')}</S.TitleMessage>
        <S.TextMessage>{t(description)}</S.TextMessage>
        <S.TextMessage>{t('containers.orders.thank.ticket-activated.push-notification-disclaimer')}</S.TextMessage>
        <S.TextMessage>{t('containers.orders.thank.ticket-activated.stay-tuned')}</S.TextMessage>
      </S.ThankMessage>

      <BlockButton text={t('containers.orders.thank.ticket-activated.go-home')} onPress={handleGoHome} />
    </Fragment>
  );
});
