import { Fragment, memo } from 'react';

import { LoaderProps } from './loader.props';
import * as S from './loader.styles';

/**
 * @function LoaderComponent
 */
export default memo((props: LoaderProps) => {
  const { show = true, message } = props;

  return (
    <Fragment>
      {show && (
        <S.FullscreenView>
          <S.LoaderIndicator hidesWhenStopped animating={show} />
          {message && <S.Message>{message}</S.Message>}
        </S.FullscreenView>
      )}
    </Fragment>
  );
});
