import { Fragment, memo } from 'react';

import { BodyView } from '@ioupie/components/layout';
import { AuthModal } from '@ioupie/containers/auth';
import { DeliveryList } from '@ioupie/containers/delivery';
import { AddressBox } from '@ioupie/containers/location';
import {
  useAddressStore,
  useCurrentRouteFocus,
  useHeaderStore,
  useNavigationStore,
  useShopsStore,
} from '@ioupie/hooks';
import { routes } from '@ioupie/shared/constants';
import { PushNotificationModal } from '../modals';

/**
 * @function DeliveryTab
 */
export default memo(() => {
  const headerStore = useHeaderStore();
  const addressStore = useAddressStore();
  const shopsStore = useShopsStore();
  const navigationStore = useNavigationStore();

  useCurrentRouteFocus(routes.tabs.home.delivery, () => {
    headerStore.allowNavigateBack(false);
    headerStore.removeHeaderShadow(false);
    headerStore.changeTitle('pages.home.delivery-title');
    headerStore.changeSubtitle('');

    shopsStore.clearSelectedProducts();

    // eslint-disable-next-line no-void
    void addressStore.restoreSavedZipCodeInfo();

    navigationStore.sendNavigationAnalytics(`${routes.pages.orders.home}`, routes.tabs.home.delivery);
  });

  return (
    <Fragment>
      <BodyView>
        <AddressBox />
        <DeliveryList />
        <PushNotificationModal />
        <AuthModal dismissable={false} />
      </BodyView>
    </Fragment>
  );
});
