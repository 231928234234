import { yupResolver } from '@hookform/resolvers/yup';
import { DateTime } from 'luxon';
import { observer } from 'mobx-react-lite';
import { Fragment, useCallback, useEffect, useState } from 'react';
import { useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { Keyboard } from 'react-native';
import { validateCPF } from 'validations-br';
import validator from 'validator';
import * as yup from 'yup';

import { BlockButton, SwitchButton } from '@ioupie/components/buttons';
import { ErrorSnackbar } from '@ioupie/components/custom';
import { DateInput, PasswordInput, TextInput } from '@ioupie/components/inputs';
import { Divider } from '@ioupie/components/layout';
import { HelperText, LinkText, Small, Title } from '@ioupie/components/typography';
import { useAuthStore } from '@ioupie/hooks';
import { AnalyticsEvents, COUNTRY_CODES } from '@ioupie/shared/constants';
import { debounceOneSecond, getLatestLegalAgeDate } from '@ioupie/shared/utils';

import { MAX_CHARACTERS_PASSWORD, MIN_CHARACTERS_PASSWORD } from './sign-up.constants';
import { SignUpForm } from './sign-up.models';
import * as S from './sign-up.styles';

const BRAZILIAN_PHONE_COUNTRY_CODE: Readonly<string> = '+55';

/**
 * @function SignUpStepComponent
 */
export default observer(() => {
  const [t] = useTranslation();
  const authStore = useAuthStore();

  const [isValidCpf, setValidCpf] = useState(true);

  const signUpSchema: yup.SchemaOf<SignUpForm> = yup.object().shape({
    name: yup
      .string()
      .required(t('forms.sign-up.name.errors.required'))
      .min(1, ({ min }) => t('forms.sign-up.name.errors.min', { min })),
    cpf: yup
      .string()
      .notRequired()
      .test(
        'is-cpf',
        t('forms.sign-up.cpf.errors.valid'),
        (cpf = '') => validateCPF(cpf) || COUNTRY_CODES.some((cc) => validator.isPassportNumber(cpf, cc)),
      ),
    familyName: yup
      .string()
      .required(t('forms.sign-up.family-name.errors.required'))
      .min(1, ({ min }) => t('forms.sign-up.family-name.errors.min', { min })),
    phoneAreaCode: yup
      .string()
      .required(t('forms.sign-up.phone-area-code.errors.required'))
      .length(2, ({ length }) => t('forms.sign-up.phone-area-code.errors.length', { length })),
    phoneNumber: yup
      .string()
      .required(t('forms.sign-up.phone-number.errors.required'))
      .length(9, ({ length }) => t('forms.sign-up.phone-number.errors.length', { length })),
    password: yup
      .string()
      .required(t('forms.sign-up.password.errors.required'))
      .min(MIN_CHARACTERS_PASSWORD, ({ min }) => t('forms.sign-up.password.errors.min', { min })),
    confirmPassword: yup
      .string()
      .required(t('forms.sign-up.confirm-password.errors.required'))
      .oneOf([yup.ref('password')], t('forms.sign-up.confirm-password.errors.valid')),
    birthDate: yup
      .date()
      .required(t('forms.sign-up.birth-date.errors.required'))
      .max(getLatestLegalAgeDate(), t('forms.sign-up.birth-date.errors.max')),
    termsOfUse: yup
      .boolean()
      .required(t('forms.sign-up.terms-of-use.errors.required'))
      .oneOf([true], t('forms.sign-up.terms-of-use.errors.valid')),
  });

  const {
    register,
    handleSubmit,
    setValue,
    watch,
    formState: { errors, isValid },
  } = useForm<SignUpForm>({
    defaultValues: {
      name: '',
      cpf: '',
      familyName: '',
      phoneAreaCode: '',
      phoneNumber: '',
      password: '',
      confirmPassword: '',
      termsOfUse: false,
    },
    resolver: yupResolver(signUpSchema),
  });

  useEffect(() => {
    register('name');
    register('cpf');
    register('familyName');
    register('phoneAreaCode');
    register('phoneNumber');
    register('password');
    register('confirmPassword');
    register('birthDate');
    register('termsOfUse');
  }, [register]);

  const onSubmitValidForm = useCallback((form: SignUpForm) => {
    Keyboard.dismiss();

    authStore.dispatchAnalyticsEvent(AnalyticsEvents.AUTH_SIGNUP);

    return authStore.createNewAccount({
      ...form,
      email: authStore.username,
      birthDate: DateTime.fromJSDate(form.birthDate).toFormat('MM-dd-yyyy'),
      phoneNumber: `${BRAZILIAN_PHONE_COUNTRY_CODE}${form.phoneAreaCode}${form.phoneNumber}`,
    });
  }, []);

  const NameSection = (
    <Fragment>
      <TextInput
        autoCapitalize="words"
        error={Boolean(errors.name)}
        label={t('forms.sign-up.name.label')}
        placeholder={t('forms.sign-up.name.placeholder')}
        onChangeText={debounceOneSecond((text) => {
          setValue('name', text, { shouldValidate: true });
        })}
      />
      {Boolean(errors.name) && (
        <HelperText type="error" visible={Boolean(errors.name)}>
          {errors.name?.message ?? ''}
        </HelperText>
      )}
    </Fragment>
  );

  const FamilyNameSection = (
    <Fragment>
      <TextInput
        autoCapitalize="words"
        error={Boolean(errors.familyName)}
        label={t('forms.sign-up.family-name.label')}
        placeholder={t('forms.sign-up.family-name.placeholder')}
        onChangeText={debounceOneSecond((text) => {
          setValue('familyName', text, { shouldValidate: true });
        })}
      />
      {Boolean(errors.familyName) && (
        <HelperText type="error" visible={Boolean(errors.familyName)}>
          {errors.familyName?.message ?? ''}
        </HelperText>
      )}
    </Fragment>
  );

  const CPFSection = (
    <Fragment>
      <TextInput
        autoCapitalize="characters"
        maxLength={11}
        error={Boolean(errors.cpf)}
        label={t('forms.sign-up.cpf.label')}
        placeholder={t('forms.sign-up.cpf.placeholder')}
        onChangeText={debounceOneSecond((text) => {
          setValue('cpf', text, { shouldValidate: true });
          setValidCpf(validateCPF(text));
        })}
      />
      {Boolean(errors.cpf) && (
        <HelperText type="error" visible={Boolean(errors.cpf)}>
          {errors.cpf?.message ?? ''}
        </HelperText>
      )}
      {isValidCpf && (
        <HelperText type="info" visible>
          {t('forms.sign-up.cpf.usage')}
        </HelperText>
      )}
    </Fragment>
  );

  const PhoneSection = (
    <Fragment>
      <S.PhoneContainer>
        <S.PhoneAreaInput
          keyboardType="numeric"
          maxLength={2}
          error={Boolean(errors.phoneAreaCode)}
          label={t('forms.sign-up.phone-area-code.label')}
          placeholder={t('forms.sign-up.phone-area-code.placeholder')}
          onChangeText={debounceOneSecond((text) => {
            setValue('phoneAreaCode', text, { shouldValidate: true });
          })}
        />
        <S.PhoneNumberInput
          keyboardType="numeric"
          maxLength={9}
          error={Boolean(errors.phoneNumber)}
          label={t('forms.sign-up.phone-number.label')}
          placeholder={t('forms.sign-up.phone-number.placeholder')}
          onChangeText={debounceOneSecond((text) => {
            setValue('phoneNumber', text, { shouldValidate: true });
          })}
        />
      </S.PhoneContainer>
      {Boolean(errors.phoneAreaCode) && (
        <HelperText type="error" visible={Boolean(errors.phoneAreaCode)}>
          {errors.phoneAreaCode?.message ?? ''}
        </HelperText>
      )}
      {Boolean(errors.phoneNumber) && (
        <HelperText type="error" visible={Boolean(errors.phoneNumber)}>
          {errors.phoneNumber?.message ?? ''}
        </HelperText>
      )}
    </Fragment>
  );

  const PasswordSection = (
    <Fragment>
      <PasswordInput
        autoCompleteType="password"
        error={Boolean(errors.password)}
        label={t('forms.sign-up.password.label')}
        placeholder={t('forms.sign-up.password.placeholder')}
        onChangeText={debounceOneSecond((text) => {
          setValue('password', text, { shouldValidate: true });
        })}
      />
      {errors.password ? (
        <HelperText type="error" visible={Boolean(errors.password)}>
          {errors.password?.message ?? ''}
        </HelperText>
      ) : (
        <HelperText type="info" visible={!errors.password}>
          {t('forms.sign-up.password.tip', { min: MIN_CHARACTERS_PASSWORD, max: MAX_CHARACTERS_PASSWORD })}
        </HelperText>
      )}
    </Fragment>
  );

  const ConfirmPasswordSection = (
    <Fragment>
      <PasswordInput
        autoCompleteType="password"
        error={Boolean(errors.confirmPassword)}
        label={t('forms.sign-up.confirm-password.label')}
        placeholder={t('forms.sign-up.confirm-password.placeholder')}
        onChangeText={debounceOneSecond((text) => {
          setValue('confirmPassword', text, { shouldValidate: true });
        })}
      />
      {Boolean(errors.confirmPassword) && (
        <HelperText type="error" visible={Boolean(errors.confirmPassword)}>
          {errors.confirmPassword?.message ?? ''}
        </HelperText>
      )}
    </Fragment>
  );

  const BirthDateSection = (
    <Fragment>
      <HelperText type="info" visible>
        {t('forms.sign-up.birth-date.label')}
      </HelperText>
      <DateInput
        onChangeDate={(text) => {
          setValue('birthDate', text, { shouldValidate: true });
        }}
      />
      {Boolean(errors.birthDate) && (
        <HelperText type="error" visible={Boolean(errors.birthDate)}>
          {errors.birthDate?.message ?? ''}
        </HelperText>
      )}
    </Fragment>
  );

  const TermsOfUseSection = (
    <Fragment>
      <Divider />
      <S.TermsOfUseDisclaimer>
        {t('forms.sign-up.terms-of-use.description')}{' '}
        <LinkText
          link="https://ioupie.com.br/termos-de-uso/"
          text={t('forms.sign-up.terms-of-use.read-terms-of-use')}
        />
        {t('forms.sign-up.terms-of-use.description-between-terms')}{' '}
        <LinkText
          link="https://ioupie.com.br/politica-de-privacidade/"
          text={t('forms.sign-up.terms-of-use.read-privacy-policy')}
        />
      </S.TermsOfUseDisclaimer>
      <SwitchButton
        text={t('forms.sign-up.terms-of-use.label')}
        value={watch('termsOfUse')}
        onValueChange={(value) => setValue('termsOfUse', value, { shouldValidate: true })}
      />
      {Boolean(errors.termsOfUse) && (
        <HelperText type="error" visible={Boolean(errors.termsOfUse)}>
          {errors.termsOfUse?.message ?? ''}
        </HelperText>
      )}
    </Fragment>
  );

  return (
    <Fragment>
      <S.Container>
        <S.CenterTitle>
          <Title>{t('forms.sign-up.title')}</Title>
          <Small>{authStore.username}</Small>
        </S.CenterTitle>
        {NameSection}
        {FamilyNameSection}
        {CPFSection}
        {PhoneSection}
        {PasswordSection}
        {ConfirmPasswordSection}
        {BirthDateSection}
        {TermsOfUseSection}

        {authStore.errors && authStore.errors.length > 0 && (
          <S.ErrorSnackbarContainer>
            <ErrorSnackbar errors={authStore.errors} onDismiss={() => authStore.clearErrors()} />
          </S.ErrorSnackbarContainer>
        )}

        <BlockButton
          text={t('forms.sign-up.submit')}
          loading={authStore.loading}
          onPress={handleSubmit(onSubmitValidForm)}
        />
      </S.Container>
    </Fragment>
  );
});
