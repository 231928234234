import { memo } from 'react';

import { Icon } from '../icon';
import { TabIconProps } from './tab-icon.models';

/**
 * @function TabIconComponent
 */
export default memo((props: TabIconProps) => {
  return <Icon size={30} {...props} />;
});
