import { observer } from 'mobx-react-lite';
import { Fragment } from 'react';
import { useTranslation } from 'react-i18next';

import { IconButton } from '@ioupie/components/buttons';
import { useShopsStore } from '@ioupie/hooks';
import { ProductAmount } from '@ioupie/shared/models';

import { BasketProps } from './basket.models';
import * as S from './basket.styles';

/**
 * @function BasketContainer
 */
export default observer(({ readOnly }: BasketProps) => {
  const [t] = useTranslation();
  const shopsStore = useShopsStore();

  return (
    <Fragment>
      <S.BasketTitle>{t('containers.orders.ordering.basket.title')}</S.BasketTitle>
      {shopsStore.productsSummary.map((productAmount: ProductAmount) => {
        const { amount, product } = productAmount;
        const { name = '' } = product;

        const chosenPrice = product.metadata?.estimatedPrice ?? product.minPrice.amount;
        const price = amount * chosenPrice;

        return (
          <S.ItemInfoContainer key={productAmount.referenceId}>
            <S.ItemContainer>
              <S.ItemTitle>{name}</S.ItemTitle>
              <S.ItemDescription>
                {t('containers.orders.ordering.basket.item-description', { units: amount, price: price.toFixed(2) })}
              </S.ItemDescription>
            </S.ItemContainer>
            {!readOnly && (
              <IconButton
                icon="trash-can-outline"
                color="#f00"
                onPress={() => shopsStore.removeProduct(productAmount.referenceId)}
              />
            )}
          </S.ItemInfoContainer>
        );
      })}
    </Fragment>
  );
});
