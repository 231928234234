import { MD3DarkTheme, MD3LightTheme, configureFonts } from 'react-native-paper';
import { MD3Type } from 'react-native-paper/lib/typescript/types';

import { fontsFamily, fontsWeight } from '@ioupie/shared/constants';

const small: MD3Type = {
  fontFamily: fontsFamily.thin,
  fontWeight: fontsWeight.thin,
  fontSize: 15,
  lineHeight: 20,
  letterSpacing: 0,
} as const;

const medium: MD3Type = {
  fontFamily: fontsFamily.light,
  fontWeight: fontsWeight.light,
  fontSize: 15,
  lineHeight: 20,
  letterSpacing: 0,
} as const;

const large: MD3Type = {
  fontFamily: fontsFamily.regular,
  fontWeight: fontsWeight.regular,
  fontSize: 15,
  lineHeight: 20,
  letterSpacing: 0,
} as const;

const config: typeof MD3LightTheme.fonts & typeof MD3DarkTheme.fonts = {
  ...MD3LightTheme.fonts,
  default: medium,
  displaySmall: small,
  displayMedium: medium,
  displayLarge: large,
  bodySmall: small,
  bodyMedium: medium,
  bodyLarge: large,
  titleSmall: small,
  titleMedium: medium,
  titleLarge: large,
  labelSmall: small,
  labelMedium: medium,
  labelLarge: large,
  headlineSmall: small,
  headlineMedium: medium,
  headlineLarge: large,
};

export const fonts = configureFonts({ config });
