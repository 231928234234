import { checkForUpdateAsync, fetchUpdateAsync, reloadAsync } from 'expo-updates';
import { injectable } from 'inversify';

@injectable()
export class UpdateService {
  public async checkForUpdates() {
    return checkForUpdateAsync();
  }

  public async fetchRemoteUpdates() {
    return fetchUpdateAsync();
  }

  public async reloadApp() {
    return reloadAsync();
  }
}
