import { ActivityIndicator, Text } from 'react-native-paper';
import styled from 'styled-components/native';

import { colors } from '@ioupie/shared/constants';
import { themefy } from '@ioupie/shared/utils';

export const FullscreenView = styled.View`
  align-items: center;
  background-color: ${themefy({
    light: colors.light.background,
    dark: colors.dark.background,
  })};
  bottom: 0;
  elevation: 5;
  flex: 1;
  height: 100%;
  justify-content: center;
  left: 0;
  opacity: 0.9;
  position: absolute;
  right: 0;
  top: 0;
`;

export const Message = styled(Text)`
  align-items: center;
  font-size: 16px;
  margin-bottom: 5px;
  padding-top: 20px;
`;

export const LoaderIndicator = styled(ActivityIndicator)`
  align-items: center;
`;
