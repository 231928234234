import { memo } from 'react';

import ioupieIcon from '@ioupie/assets/icon.png';
import { TicketGenerated, TicketActivated, TicketFinished } from '@ioupie/containers/orders/thank';
import { useCurrentRouteFocus, useHeaderStore, useOrdersStore } from '@ioupie/hooks';
import { routes, THANK_TYPE } from '@ioupie/shared/constants';

import { TicketType } from './thank.model';
import * as S from './thank.styles';

/**
 * @function ThankPage
 */
export default memo(() => {
  const ordersStore = useOrdersStore();
  const headerStore = useHeaderStore();
  const { thankType } = ordersStore;

  const thankOption: Record<string, TicketType> = {
    [THANK_TYPE.TICKET_GENERATED]: {
      title: 'containers.orders.thank.ticket-generated.header.title',
      subTitle: '',
      component: <TicketGenerated />,
    },
    [THANK_TYPE.TICKET_ACTIVATED]: {
      title: 'containers.orders.thank.ticket-activated.header.title',
      subTitle: '',
      component: <TicketActivated />,
    },
    [THANK_TYPE.TICKET_FINISHED]: {
      title: 'containers.orders.thank.ticket-finished.header.title',
      subTitle: '',
      component: <TicketFinished />,
    },
  };

  useCurrentRouteFocus(routes.pages.orders.thank, () => {
    headerStore.allowNavigateBack(false);
    headerStore.removeHeaderShadow(false);
    headerStore.changeTitle(thankOption[`${thankType}`].title);
    headerStore.changeSubtitle(thankOption[`${thankType}`].subTitle);
  });

  return (
    <S.Background source={ioupieIcon}>
      <S.ThankContainer>{thankOption[`${thankType}`].component}</S.ThankContainer>
    </S.Background>
  );
});
