import { Fragment, memo } from 'react';

import { NoContentProps } from './no-content.props';
import * as S from './no-content.styles';

/**
 * @function NoContentComponent
 */
export default memo((props: NoContentProps) => {
  const { show = true, primaryText = false, iconSize = 64, iconName, title, subtitle } = props;

  return (
    <Fragment>
      {show && (
        <S.NoContentContainer>
          {iconName && <S.NoContentIcon size={iconSize} name={iconName} />}
          <S.NoContentTitle primaryText={primaryText}>{title}</S.NoContentTitle>
          {subtitle && <S.NoContentText primaryText={primaryText}>{subtitle}</S.NoContentText>}
        </S.NoContentContainer>
      )}
    </Fragment>
  );
});
