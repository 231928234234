import { memo } from 'react';

import { CHIP_COLOR } from './chip.constants';
import { ChipProps } from './chip.models';
import * as S from './chip.styles';

/**
 * @function ChipComponent
 */
export default memo((props: ChipProps) => {
  const { iconSize = 64, iconName, content = '', chipColor = CHIP_COLOR.GREY } = props;

  return (
    <S.ChipContainer chipColor={chipColor}>
      {Boolean(iconName) && <S.ChipIcon size={iconSize} name={iconName} />}
      <S.ChipText chipColor={chipColor}>{content}</S.ChipText>
    </S.ChipContainer>
  );
});
