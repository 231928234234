import { GenericMap } from '../helpers';
import { ServiceOrderV2 } from '../service-order';

export declare type DonnaNotificationRequest = {
  readonly recipients: readonly string[];
  readonly type: string;
  readonly order: ServiceOrderV2;
  readonly customerId?: string;
  readonly metadata?: GenericMap;
};

export const DonnaMessageType = Object.freeze({
  Customer_OrderCancelled: 'Customer_OrderCancelled',
  Customer_OrderDelivered: 'Customer_OrderDelivered',
  Customer_OrderDeliveryDateChange: 'Customer_OrderDeliveryDateChange',
  Customer_OrderPendingCustomerPickup: 'Customer_OrderPendingCustomerPickup',
  Customer_OrderPendingProviderIngestion: 'Customer_OrderPendingProviderIngestion',
  Customer_OrderPendingPayment: 'Customer_OrderPendingPayment',
  Customer_OrderRejected: 'Customer_OrderRejected',
  Customer_PaymentReceived: 'Customer_PaymentReceived',
  Customer_PaymentReceivedWithComments: 'Customer_PaymentReceivedWithComments',
  Customer_Provisioned: 'Customer_Provisioned',
  Customer_SignUp: 'Customer_SignUp',
  Customer_TicketGenerated: 'Customer_TicketGenerated',

  Ioupie_NewPixOrderReceived: 'Ioupie_NewPixOrderReceived',

  Provider_NewOrderReceived: 'Provider_NewOrderReceived',
  Provider_OrderCancelledPaymentExpired: 'Provider_OrderCancelledPaymentExpired',
  Provider_OrderInboundScheduled: 'Provider_OrderInboundScheduled',
  Provider_OrderDeliveryDelayed: 'Provider_OrderDeliveryDelayed',
  Provider_OrderDeliveryUpcoming: 'Provider_OrderDeliveryUpcoming',
  Provider_OrderPendingOS: 'Provider_OrderPendingOS',

  UserDataRemovalRequest: 'UserDataRemovalRequest',
});
