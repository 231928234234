import { lighten } from 'polished';
import styled from 'styled-components/native';

import { ScrollView } from '@ioupie/components/layout';
import { Text, Title } from '@ioupie/components/typography';
import { colors, fontsFamily, fontsWeight } from '@ioupie/shared/constants';
import { themefy } from '@ioupie/shared/utils';

export const CarouselContainer = styled.View`
  align-items: center;
  flex: 1;
  flex-grow: 1;
  flex-shrink: 1;
  height: auto;
  justify-content: center;
  margin: 0;
  padding: 15px;
  min-height: 220px;
`;

export const ViewLabel = styled.View`
  background-color: ${lighten(0.3, colors.light.background)};
  border-radius: 10px;
  border-bottom-color: ${lighten(0.1, colors.light.primary)};
  border-bottom-width: 2px;
  margin: 5% 0 1% 0;
  padding: 2% 1% 0;
  width: 100%;
`;

export const LabelTitle = styled(Title)`
  color: ${themefy({
    light: colors.light.text,
    dark: colors.dark.text,
  })};
  font-family: ${fontsFamily.bold};
  font-weight: ${fontsWeight.bold};
  font-size: 18px;
  padding: 0;
  text-align: center;
`;

export const NoDataTitle = styled(Title)`
  color: ${themefy({
    light: colors.light.text,
    dark: colors.dark.text,
  })};
  font-size: 24px;
  padding: 0;
  text-align: center;
  width: 100%;
`;

export const NoDataText = styled(Text)`
  color: ${themefy({
    light: colors.light.text,
    dark: colors.dark.text,
  })};
  font-size: 18px;
  margin: 0 20px;
  padding: 20px 0 0;
  text-align: center;
  width: 100%;
`;

export const SegmentsSlider = styled(ScrollView)`
  padding: 5px;
`;
