import AsyncStorage from '@react-native-async-storage/async-storage';
import { injectable } from 'inversify';

import { STORAGE_KEYS } from '@ioupie/shared/constants';
import { Optional } from '@ioupie/shared/models';

import { type StorageService } from './storage.service';

@injectable()
export class AsyncStorageService implements StorageService {
  public async persistData<T>(key: STORAGE_KEYS, item: Optional<T>): Promise<void> {
    const persistItem: Optional<string> = JSON.stringify(item);
    await AsyncStorage.setItem(key, persistItem);
  }

  public async retrieveData<T>(key: STORAGE_KEYS): Promise<Optional<T>> {
    const item: Optional<string> = await AsyncStorage.getItem(key);
    // eslint-disable-next-line @typescript-eslint/no-unsafe-return
    return item ? JSON.parse(item) : undefined;
  }

  public async deleteData(key: STORAGE_KEYS): Promise<void> {
    await AsyncStorage.removeItem(key);
  }
}
