import { observer } from 'mobx-react-lite';
import { Fragment, useCallback } from 'react';
import { useTranslation } from 'react-i18next';
import { FlatList, ListRenderItemInfo } from 'react-native';

import { ErrorSnackbar, List, Loader, NoContent } from '@ioupie/components/custom';
import { useShopsStore } from '@ioupie/hooks';
import { ProductData } from '@ioupie/shared/models';

import { ProductLegend } from './product-legend';
import * as S from './products-step.styles';

export default observer(() => {
  const [t] = useTranslation();
  const shopsStore = useShopsStore();

  const catalogKeyExtractor = useCallback((product: ProductData) => product.productId, []);

  const renderCatalogListItem = useCallback((info: ListRenderItemInfo<ProductData>) => {
    const { item: product } = info;
    return (
      <Fragment>
        {product.catalogBreak && <S.CatalogBreak title={product.catalogName} />}
        <List.Item
          alignTop={true}
          highlight={product.highlight ?? false}
          left={
            <S.ProductImage>
              <List.Image size="large" image={product.image} emptyImage />
            </S.ProductImage>
          }
          body={
            <Fragment>
              <List.Message title={product.name} subtitle={product.description} highlight={product.highlight} />
              <ProductLegend product={product} />
            </Fragment>
          }
        />
      </Fragment>
    );
  }, []);

  return (
    <Fragment>
      <NoContent
        show={shopsStore.filterQuery !== '' && shopsStore.productsFiltered.length === 0}
        iconName="cart-off"
        title={t('containers.catalog.shop-catalog.products-filter.no-results.title')}
        subtitle={t('containers.catalog.shop-catalog.products-filter.no-results.subtitle')}
      />
      <List.Section>
        <FlatList<ProductData>
          data={shopsStore.filterQuery !== '' ? shopsStore.productsFiltered : shopsStore.productsCategorized}
          keyExtractor={catalogKeyExtractor}
          renderItem={renderCatalogListItem}
        />
      </List.Section>
      <Loader show={shopsStore.loading} message={t('containers.catalog.shop-catalog.products.loading')} />
      <ErrorSnackbar errors={shopsStore.errors} onDismiss={() => shopsStore.clearErrors()} />
    </Fragment>
  );
});
