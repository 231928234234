import styled from 'styled-components/native';

import { BlockButton } from '@ioupie/components/buttons';
import { UniversalImage } from '@ioupie/components/gallery';
import { TextInput } from '@ioupie/components/inputs';
import { ScrollView } from '@ioupie/components/layout';

export const Container = styled(ScrollView)`
  padding: 20px;
`;

export const CardNumberContainer = styled.View`
  align-items: center;
  display: flex;
  flex-direction: row;
  justify-content: space-around;
  width: 100%;
`;

export const CardNumberInput = styled(TextInput)`
  flex-grow: 2;
`;

export const CardIssuerLogo = styled(UniversalImage)`
  margin-left: 20px;
`;

export const AddButton = styled(BlockButton)`
  width: 100%;
`;
