import { observer } from 'mobx-react-lite';
import { Fragment, useCallback, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { FlatList, ListRenderItemInfo } from 'react-native';

import { BlockButton, IconButton } from '@ioupie/components/buttons';
import { List, NoContent } from '@ioupie/components/custom';
import { useAddressStore, useNavigationStore } from '@ioupie/hooks';
import { routes } from '@ioupie/shared/constants';
import { PersistedZipCodeInfo } from '@ioupie/shared/models';

import * as S from './user-addresses.styles';

export default observer(() => {
  const [t] = useTranslation();
  const addressStore = useAddressStore();
  const navigationStore = useNavigationStore();

  useEffect(() => {
    // eslint-disable-next-line no-void
    void addressStore.restoreSavedZipCodeInfo();
  }, []);

  const keyExtractor = useCallback((zipCodeInfo: PersistedZipCodeInfo) => zipCodeInfo.id, []);

  const renderListItem = useCallback((info: ListRenderItemInfo<PersistedZipCodeInfo>) => {
    const { item: zipCodeInfo } = info;

    const addressAsText = `${zipCodeInfo.streetAddress}, ${zipCodeInfo.houseNumber}`;

    const onSelectAddress = () => {
      // eslint-disable-next-line no-void
      void addressStore.selectMainAddress(zipCodeInfo);
      navigationStore.dispatchGoBack();
    };

    const ListEntryBody = (
      <Fragment>
        <S.ListEntryTitle>{addressAsText}</S.ListEntryTitle>
        <S.ListEntryDescription>
          {zipCodeInfo.neighborhood}, {zipCodeInfo.district}/{zipCodeInfo.state}
        </S.ListEntryDescription>
        <S.ListEntryDescription>{zipCodeInfo.zipCode}</S.ListEntryDescription>
        {zipCodeInfo.addressAddon !== '' && <S.ListEntryAddon>{zipCodeInfo.addressAddon}</S.ListEntryAddon>}
      </Fragment>
    );

    return (
      <List.Item
        onPress={onSelectAddress}
        body={ListEntryBody}
        right={<IconButton icon="trash-can-outline" onPress={() => addressStore.openDeleteDialog(zipCodeInfo)} />}
      />
    );
  }, []);

  return (
    <Fragment>
      {addressStore.allAddresses.length === 0 ? (
        <NoContent
          iconName="home-group"
          title={t('containers.location.no-addresses.title')}
          subtitle={t('containers.location.no-addresses.label')}
        />
      ) : (
        <S.ListContainer>
          <S.ListTitle>{t('containers.location.user-addresses.title')}</S.ListTitle>
          <S.ListDescription>{t('containers.location.user-addresses.description')}</S.ListDescription>
          <FlatList<PersistedZipCodeInfo>
            data={addressStore.allAddresses}
            keyExtractor={keyExtractor}
            renderItem={renderListItem}
          />
        </S.ListContainer>
      )}
      <BlockButton
        text={t('containers.location.user-addresses.add-address')}
        onPress={() =>
          navigationStore.dispatchNavigation({
            stack: routes.stacks.address,
            screen: routes.pages.address.add_new_address,
          })
        }
      />
    </Fragment>
  );
});
