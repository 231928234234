import { memo } from 'react';

import { AreaInputProps } from './area-input.models';
import * as S from './area-input.styles';

/**
 * @function AreaInputComponent
 */
export default memo((props: AreaInputProps) => {
  return <S.MultilineInput multiline numberOfLines={10} {...props} />;
});
