import { Appbar } from 'react-native-paper';
import styled from 'styled-components/native';

export default styled(Appbar.Content)`
  align-items: center;
  flex: 1;
  justify-content: center;
  padding: 0 50px;
  position: absolute;
  width: 100%;
`;
