import styled from 'styled-components/native';

import { Text } from '@ioupie/components/typography';
import { colors, fontsFamily, fontsWeight } from '@ioupie/shared/constants';
import { themefy } from '@ioupie/shared/utils';

import { AmountTextProps } from './amount.models';

export const AmountTitle = styled(Text)`
  color: ${themefy({
    light: colors.light.title,
    dark: colors.dark.title,
  })};
  font-family: ${fontsFamily.bold};
  font-size: 16px;
  font-weight: ${fontsWeight.bold};
  margin: 16px 16px 8px;
`;

export const ItemInfoContainer = styled.View`
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  margin: 2px 16px;
`;

export const EntityTitle = styled(Text)<AmountTextProps>`
  color: ${({ gray }) =>
    themefy({
      light: gray ? colors.light.text : colors.light.title,
      dark: gray ? colors.dark.text : colors.dark.title,
    })};
  font-family: ${({ bold }) => (bold ? fontsFamily.bold : fontsFamily.regular)};
  font-size: 15px;
  padding: 2px 0;
`;

export const EntityDescription = styled(Text)<AmountTextProps>`
  color: ${({ gray }) =>
    themefy({
      light: gray ? colors.light.text : colors.light.title,
      dark: gray ? colors.dark.text : colors.dark.title,
    })};
  font-family: ${({ bold }) => (bold ? fontsFamily.bold : fontsFamily.regular)};
  font-size: 15px;
  padding: 2px 0;
`;
