import { lighten } from 'polished';
import styled from 'styled-components/native';

import { UniversalImage } from '@ioupie/components/gallery';
import { Title } from '@ioupie/components/typography';
import { colors } from '@ioupie/shared/constants';

export const Segment = styled.View`
  margin: 2px;
  padding: 2px;
`;

export const Name = styled(Title)`
  font-size: 16px;
  line-height: 20px;
  text-align: center;
  width: 120px;
`;

export const Visual = styled(UniversalImage)`
  border-color: ${lighten(0.55, colors.light.text)};
  border-radius: 55px;
  border-width: 3px;
  height: 110px;
  width: 110px;
`;
