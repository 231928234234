import { lighten } from 'polished';
import { StyleSheet } from 'react-native';

import { colors, fontsFamily } from '@ioupie/shared/constants';

export const styles = StyleSheet.create({
  tabsOptions: {
    backgroundColor: lighten(0.0, colors.light.primary),
    borderTopColor: lighten(0.2, colors.light.secondary),
    borderTopWidth: 0.8,
    fontFamily: fontsFamily.medium,
    padding: 5,
  },
});
