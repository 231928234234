import { memo } from 'react';

import { AmountItemProps } from './amount-item.models';
import * as S from './amount-item.styles';

/**
 * @function AmountItemComponent
 */
export default memo(({ title, value, gray, bold }: AmountItemProps) => (
  <S.ItemInfoContainer>
    <S.EntityTitle gray={gray} bold={bold}>
      {title}
    </S.EntityTitle>
    <S.EntityDescription gray={gray} bold={bold}>
      {value}
    </S.EntityDescription>
  </S.ItemInfoContainer>
));
