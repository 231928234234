import { memo, useCallback, useState } from 'react';
import { useTranslation } from 'react-i18next';

import { BlockButton } from '@ioupie/components/buttons';
import { RadioButton } from '@ioupie/components/custom';
import { Divider, ScrollView } from '@ioupie/components/layout';
import { Bold } from '@ioupie/components/typography';
import { CURTAIN_DEFAULTS, CUSTOM_PRODUCT_SIZES, PRODUCT_METADATA_TYPES } from '@ioupie/shared/constants';
import { CurtainMetadata, ProductData } from '@ioupie/shared/models';
import { isEnumOf, isNumber, normalizeAndParseFloat, safeObjectLookup } from '@ioupie/shared/utils';

import * as S from './curtain.styles';

export default memo(CurtainCustom);

type CurtainCustomProps = {
  readonly customizingCurtain: ProductData;
  readonly onSendCurtainSize: (metadata: CurtainMetadata) => void;
};

const INVALID_CUSTOM_SIZE_VALUE: Readonly<number> = 0;

function CurtainCustom({ customizingCurtain, onSendCurtainSize }: CurtainCustomProps) {
  const [t] = useTranslation();
  const [curtainSize, setCurtainSize] = useState(CUSTOM_PRODUCT_SIZES.SMALL);

  const [width, setWidth] = useState('');

  const chosenSize =
    curtainSize === CUSTOM_PRODUCT_SIZES.CUSTOM
      ? { width: normalizeAndParseFloat(width) }
      : safeObjectLookup(CURTAIN_DEFAULTS, curtainSize);

  const widthIsInvalid = !chosenSize || Number.isNaN(chosenSize.width) || chosenSize.width <= 0;

  const boundedCurtainWidth = useCallback((boundedWidth: number): number => {
    return boundedWidth >= 1 ? boundedWidth : 1;
  }, []);

  const calculatedValue: Readonly<number> = widthIsInvalid
    ? INVALID_CUSTOM_SIZE_VALUE
    : boundedCurtainWidth(chosenSize.width) * customizingCurtain.minPrice.amount;

  const onCurtainSizeSelection = useCallback((value: string): void => {
    const isCurtainSize = isEnumOf(CUSTOM_PRODUCT_SIZES);

    if (isCurtainSize(value)) {
      setCurtainSize(value);
    }
  }, []);

  const chooseCurtainSize = (): void => {
    if (!chosenSize || widthIsInvalid) {
      return;
    }

    onSendCurtainSize({
      type: PRODUCT_METADATA_TYPES.CURTAIN,
      width: chosenSize.width,
      estimatedPrice: calculatedValue,
    });
  };

  return (
    <ScrollView>
      <S.CurtainImageContainer>
        <S.CurtainImage image={customizingCurtain.image} />
      </S.CurtainImageContainer>
      <S.TitleBox>
        <Bold>{t('containers.catalog.shop-catalog.custom.curtain.size')}</Bold>
      </S.TitleBox>
      <RadioButton.Group onValueChange={onCurtainSizeSelection} value={curtainSize}>
        <RadioButton.Item
          mode="android"
          value={CUSTOM_PRODUCT_SIZES.SMALL}
          label={t('containers.catalog.shop-catalog.custom.curtain.small', {
            width: CURTAIN_DEFAULTS.small.width.toFixed(2),
            unit: customizingCurtain.unitOfMeasurement,
          })}
        />
        <RadioButton.Item
          mode="android"
          value={CUSTOM_PRODUCT_SIZES.MEDIUM}
          label={t('containers.catalog.shop-catalog.custom.curtain.medium', {
            width: CURTAIN_DEFAULTS.medium.width.toFixed(2),
            unit: customizingCurtain.unitOfMeasurement,
          })}
        />
        <RadioButton.Item
          mode="android"
          value={CUSTOM_PRODUCT_SIZES.BIG}
          label={t('containers.catalog.shop-catalog.custom.curtain.big', {
            width: CURTAIN_DEFAULTS.big.width.toFixed(2),
            unit: customizingCurtain.unitOfMeasurement,
          })}
        />
        <RadioButton.Item
          mode="android"
          value={CUSTOM_PRODUCT_SIZES.CUSTOM}
          label={t('containers.catalog.shop-catalog.custom.curtain.custom')}
        />
      </RadioButton.Group>
      <Divider />
      {curtainSize === CUSTOM_PRODUCT_SIZES.CUSTOM && (
        <S.CurtainCustomBox>
          <S.CurtainInputBox>
            <S.CurtainInputTitle>
              {t('containers.catalog.shop-catalog.custom.rectangle-carpet.width-title')}
            </S.CurtainInputTitle>
            <S.CurtainInputText
              value={width}
              keyboardType="numeric"
              onChangeText={setWidth}
              label={t('containers.catalog.shop-catalog.custom.rectangle-carpet.width-label')}
              placeholder={t('containers.catalog.shop-catalog.custom.rectangle-carpet.width-placeholder')}
              disabled={curtainSize !== CUSTOM_PRODUCT_SIZES.CUSTOM}
            />
          </S.CurtainInputBox>
        </S.CurtainCustomBox>
      )}
      <S.CurtainPriceBox>
        <Bold>{t('containers.catalog.shop-catalog.custom.curtain.price')}</Bold>
        <Bold>
          {t('containers.catalog.shop-catalog.custom.curtain.total', {
            price: calculatedValue.toFixed(2),
            currency: customizingCurtain.minPrice.currencyCode,
          })}
        </Bold>
      </S.CurtainPriceBox>
      <BlockButton
        disabled={widthIsInvalid}
        onPress={chooseCurtainSize}
        text={t('containers.catalog.shop-catalog.custom.round-carpet.add')}
      />
    </ScrollView>
  );
}
