import { memo } from 'react';
import { useTranslation } from 'react-i18next';

import { NpsEvaluation } from '@ioupie/containers/orders/nps-evaluation';
import { useCurrentRouteFocus, useHeaderStore } from '@ioupie/hooks';
import { routes } from '@ioupie/shared/constants';

/**
 * @function NpsEvaluationPage
 */
export default memo(() => {
  const [t] = useTranslation();
  const headerStore = useHeaderStore();

  useCurrentRouteFocus(routes.pages.orders.nps_evaluation, () => {
    headerStore.allowNavigateBack(false);
    headerStore.removeHeaderShadow(false);
    headerStore.changeTitle(t('pages.nps-evaluation.title'));
    headerStore.changeSubtitle('');
  });

  return <NpsEvaluation />;
});
