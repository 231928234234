import styled from 'styled-components/native';

import { UniversalImage } from '@ioupie/components/gallery';

export const TitleBox = styled.View`
  padding: 10px;
  margin: 10px;
`;

export const CarpetImageContainer = styled.View`
  align-items: center;
  justify-content: center;
`;

export const CarpetImage = styled(UniversalImage)`
  height: 200px;
  width: 200px;
`;
