import { darken, lighten } from 'polished';
import styled from 'styled-components/native';

import { TextInput } from '@ioupie/components/inputs';
import { Text } from '@ioupie/components/typography';
import { colors, fontsFamily, fontsWeight } from '@ioupie/shared/constants';
import { themefy } from '@ioupie/shared/utils';

export const ZipCodeContainer = styled.View`
  flex-direction: column;
  justify-content: space-between;
  width: 100%;
`;

export const ZipCodeContainerDescription = styled(Text)`
  margin: 0 0 20px 0;
`;

export const ZipCodeRetrievalOption = styled.View`
  align-items: center;
  background-color: ${themefy({
    light: lighten(0.7, colors.light.text),
    dark: darken(0.6, colors.dark.text),
  })};
  display: flex;
  flex-flow: row;
  justify-content: space-between;
  margin: 0 0 5%;
  padding: 5%;
  width: 100%;
`;

export const ZipCodeRetrievalOptionDivider = styled.View`
  border-style: solid;
  border-top-color: ${themefy({
    light: lighten(0.6, colors.light.text),
    dark: darken(0.4, colors.dark.text),
  })};
  border-top-width: 1px;
  margin: 20px 0 30px;
  padding: 0;
  text-align: center;
`;

export const ZipCodeRetrievalOptionDividerText = styled(Text)`
  background: ${themefy({
    light: colors.light.background,
    dark: colors.dark.background,
  })};
  font-size: 16px;
  margin: -10px 42% 0;
  text-align: center;
  width: 16%;
`;

export const ZipCodeRetrievalOptionText = styled.View`
  display: flex;
  flex: 1;
  flex-flow: column;
  justify-content: space-around;
`;

export const ZipCodeRetrievalOptionTitle = styled.Text`
  color: ${themefy({
    light: colors.light.text,
    dark: colors.dark.text,
  })};
  font-family: ${fontsFamily.bold};
  font-size: 16px;
  font-weight: ${fontsWeight.bold};
  padding: 10px 0;
`;

export const ZipCodeInput = styled(TextInput)`
  width: 100%;
`;

export const ZipCodeButtonBlock = styled.View`
  align-items: center;
  display: flex;
  flex-flow: row;
  justify-content: space-between;
  padding: 10px 20px;
  width: 100%;
`;

export const ZipCodeButtonBack = styled.View`
  elevation: 5;
  border-radius: 50px;
  background-color: ${themefy({
    light: colors.light.primary,
    dark: colors.dark.primary,
  })};
  margin: 0 0 0 20px;
  padding: 0;
`;

export const StreetContainer = styled.View`
  flex-direction: column;
  justify-content: space-between;
  width: 100%;
`;

export const ScreenContainer = styled.View`
  height: 100%;
  margin: 20px;
`;

export const EditableZipCodeTextInput = styled.TouchableOpacity`
  align-items: center;
  display: flex;
  flex-flow: row;
  justify-content: space-between;
  width: 100%;
`;

export const CityAndStateContainer = styled.View`
  display: flex;
  flex-flow: row;
  justify-content: space-between;
  width: 100%;
`;

export const CityAndStateItem = styled(TextInput)`
  min-width: 120px;
`;
