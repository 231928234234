import styled from 'styled-components/native';

import { TextInput } from '@ioupie/components/inputs';
import { Bold } from '@ioupie/components/typography';
import { colors, fontsFamily, fontsWeight } from '@ioupie/shared/constants';
import { themefy } from '@ioupie/shared/utils';

export const TitleBox = styled.View`
  padding: 10px;
  margin: 10px;
`;

export const CarpetCustomBox = styled.View`
  flex-direction: column;
  justify-content: space-between;
  padding: 10px 20px;
  margin: 10px 0px;
`;

export const CarpetInputBox = styled.View`
  padding: 0px;
  margin: 10px;
`;

export const CarpetInputTitle = styled(Bold)`
  color: ${themefy({
    light: colors.light.title,
    dark: colors.dark.title,
  })};
  font-size: 16px;
  margin-bottom: -5px;
  font-family: ${fontsFamily.bold};
  font-weight: ${fontsWeight.bold};
`;

export const CarpetInputText = styled(TextInput)`
  margin: 0;
`;

export const CarpetPriceBox = styled.View`
  flex-direction: row;
  padding: 10px;
  margin: 10px;
  justify-content: space-between;
`;
