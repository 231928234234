import styled from 'styled-components/native';

import { TextInput } from '@ioupie/components/inputs';
import { Title, Text, Small } from '@ioupie/components/typography';
import { colors } from '@ioupie/shared/constants';
import { themefy } from '@ioupie/shared/utils';

export const TitleContainer = styled.View`
  align-items: center;
  justify-content: center;
`;

export const ExpandTitle = styled(Title)`
  color: ${colors.light.text};
  margin: 10px 0;
`;

export const ExpandSmall = styled(Small)`
  color: ${colors.light.text};
`;

export const ExpandText = styled(Text)`
  color: ${colors.light.text};
`;

export const AddressField = styled(TextInput)`
  background-color: ${themefy({
    light: colors.light.primary,
    dark: colors.dark.primary,
  })};
  color: ${colors.light.text};
`;
