export declare type StoredSettings = {
  readonly language?: string;
  readonly darkMode?: boolean;
  readonly pushNotificationPermissionAlreadyRequested?: boolean;
};

export declare type PushNotificationToken = {
  readonly token: string;
  readonly status: string;
};

export const PushNotificationTokenStatus = Object.freeze({
  active: 'active',
  inactive: 'inactive',
});
