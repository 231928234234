import { darken, lighten } from 'polished';
import styled from 'styled-components/native';

import { Text } from '@ioupie/components/typography';
import { colors, fontsFamily } from '@ioupie/shared/constants';
import { themefy } from '@ioupie/shared/utils';

export const ProductLegend = styled.View`
  align-items: center;
  flex-direction: row;
  justify-content: space-between;
`;

export const ProductPriceLabel = styled(Text)`
  color: ${themefy({
    light: lighten(0.2, colors.light.text),
    dark: darken(0.2, colors.dark.text),
  })};
  font-family: ${fontsFamily.bold};
`;

export const UnitOfMeasurementLabel = styled(Text)`
  color: ${themefy({
    light: lighten(0.4, colors.light.text),
    dark: darken(0.3, colors.dark.text),
  })};
`;
