import styled from 'styled-components/native';

export const CenteredView = styled.View`
  padding: 0 25%;
  width: 100%;
`;

export const ContentView = styled.View`
  padding: 0 4%;
`;
