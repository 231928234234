import { KeyboardAvoidingView } from 'react-native';
import styled from 'styled-components/native';

import { Image } from '@ioupie/components/gallery';
import { TextInput } from '@ioupie/components/inputs';
import { Text } from '@ioupie/components/typography';

export const UnlockLockerContainer = styled.View`
  margin: 0;
`;

export const LockerTextView = styled.View`
  margin: 24px 20px;
`;

export const LockerText = styled(Text)`
  align-items: center;
  justify-content: center;
`;

export const CompartmentNumber = styled(Text)`
  font-size: 32px;
  font-weight: 800;
`;

export const LockerTooltipAlign = styled.View`
  align-items: center;
  flex-direction: column;
`;

export const ImageSize = styled(Image)`
  height: 24px;
  width: 24px;
`;

export const Tooltip = styled(Text)`
  font-size: 14px;
  font-weight: 800;
  margin: 10px;
`;

export const LockerNumberStyle = styled.View`
  align-items: center;
  background-color: #e8e8e8;
  border-radius: 4px;
  height: 65px;
  justify-content: center;
  padding: 8px;
  width: 95%;
`;

export const SummaryContent = styled.View`
  align-items: center;
  border-top-width: 1px;
  flex-direction: column;
  margin: 0 6px 12px;
`;

export const OrderDetailLine = styled.View`
  flex-direction: row;
  justify-content: space-between;
  padding: 12px 6px;
  width: 90%;
`;

export const AvoidView = styled(KeyboardAvoidingView)`
  flex: 1;
  justify-content: space-between;
`;

export const LaundryCaseNumberInfo = styled(Text)`
  margin: 16px 50px 40px;
  justify-content: center;
  text-align: center;
`;

export const LaundryCaseNumberInput = styled(TextInput)`
  font-size: 32px;
  height: 40px;
  justify-content: center;
  text-align: center;
  width: 100%;
`;

export const SubmitButton = styled.View`
  flex: 1;
`;
