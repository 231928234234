export const DF_LOCALE = 'constants.locale.date-format';

export const CUR_LOCATE = 'constants.locale.currency-format';

export enum SUPPORTED_LANGUAGES {
  PORTUGUESE = 'pt',
  ENGLISH = 'en',
}

export const FALLBACK_LANGUAGE: Readonly<string> = SUPPORTED_LANGUAGES.PORTUGUESE;

export const LANGUAGE_MAPPER: Record<SUPPORTED_LANGUAGES, string> = {
  [SUPPORTED_LANGUAGES.ENGLISH]: 'constants.locale.languages.english',
  [SUPPORTED_LANGUAGES.PORTUGUESE]: 'constants.locale.languages.portuguese',
};

export const COUNTRY_CODES = [
  'AM',
  'AR',
  'AT',
  'AU',
  'AZ',
  'BE',
  'BG',
  'BR',
  'BY',
  'CA',
  'CH',
  'CN',
  'CY',
  'CZ',
  'DE',
  'DK',
  'DZ',
  'EE',
  'ES',
  'FI',
  'FR',
  'GB',
  'GR',
  'HR',
  'HU',
  'IE',
  'IN',
  'ID',
  'IR',
  'IS',
  'IT',
  'JM',
  'JP',
  'KR',
  'KZ',
  'LI',
  'LT',
  'LU',
  'LV',
  'LY',
  'MT',
  'MZ',
  'MY',
  'NL',
  'NZ',
  'PH',
  'PK',
  'PL',
  'PT',
  'RO',
  'RU',
  'SE',
  'SL',
  'SK',
  'TH',
  'TR',
  'UA',
  'US',
];
