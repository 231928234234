import { TextInput } from 'react-native-paper';
import styled from 'styled-components/native';

export const MultilineInput = styled(TextInput)`
  align-items: flex-start;
  flex-shrink: 1;
  margin: 0;
  max-height: 100px;
  min-height: 100px;
  padding: 0;
`;
