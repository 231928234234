import { darken, lighten } from 'polished';
import styled from 'styled-components/native';

import { Icon, UniversalImage } from '@ioupie/components/gallery';
import { Text } from '@ioupie/components/typography';
import { colors, fontsFamily, fontsWeight } from '@ioupie/shared/constants';
import { themefy } from '@ioupie/shared/utils';

export const IntroUsage = styled.View`
  margin: 8px 20px 0;
`;

export const IntroTitle = styled(Text)`
  font-family: ${fontsFamily.bold};
  font-size: 18px;
  margin-bottom: 7px;
`;

export const ProviderImage = styled(UniversalImage)`
  border-radius: 45px;
  height: 75px;
  width: 75px;
`;

export const ProviderInformationContainer = styled.View`
  align-items: flex-start;
  display: flex;
  flex-flow: column;
  justify-content: space-around;
  padding: 0 0 0 10px;
`;

export const ProviderMedal = styled(Icon)`
  margin: 0 6px 0 0;
`;

export const ProviderName = styled.Text`
  color: ${themefy({
    light: colors.light.text,
    dark: colors.dark.text,
  })};
  font-family: ${fontsFamily.semibold};
  font-size: 15px;
  font-weight: ${fontsWeight.semibold};
  margin-bottom: 2px;
`;

export const ProviderNameContainer = styled.View`
  flex-direction: row;
`;

export const ProviderMessage = styled.View`
  align-items: flex-start;
  display: flex;
  flex-flow: row;
  justify-content: space-between;
  padding: 2% 0 0;
`;

export const ProviderMessageText = styled.Text`
  color: ${themefy({
    light: lighten(0.2, colors.light.text),
    dark: darken(0.2, colors.dark.text),
  })};
  flex-grow: 2;
  font-family: ${fontsFamily.regular};
  font-size: 13px;
  font-weight: ${fontsWeight.regular};
  padding: 0 0 0 2%;
`;

export const ProviderMessageIcon = styled(Icon)`
  color: ${themefy({
    light: lighten(0.2, colors.light.text),
    dark: darken(0.2, colors.dark.text),
  })};
`;

export const ProviderNotAvailableLabel = styled.Text`
  color: ${themefy({
    light: lighten(0.2, colors.light.text),
    dark: darken(0.2, colors.dark.text),
  })};
  font-family: ${fontsFamily.bold};
  font-size: 16px;
  font-weight: ${fontsWeight.bold};
  margin-top: 5px;
`;

export const DialogButtonColumn = styled.View`
  display: flex;
  flex: 1;
  flex-direction: column;
`;

export const ProviderInformationSticker = styled.View`
  align-items: flex-start;
  background-color: ${themefy({
    light: lighten(0.7, colors.light.text),
    dark: darken(0.5, colors.dark.text),
  })};
  display: flex;
  flex-flow: row;
  margin: 1% 0;
  padding: 2% 3%;
`;

export const ProviderInformationStickerText = styled(Text)`
  font-family: ${fontsFamily.regular};
  font-size: 12px;
  font-weight: ${fontsWeight.regular};
`;

export const ProviderInformationStickerIcon = styled(Icon)`
  color: ${themefy({
    light: colors.light.text,
    dark: colors.dark.text,
  })};
  margin: 0 1% 0 2.5%;
`;

export const RightItemWrapper = styled.TouchableOpacity`
  flex: 1;
`;
