import { observer } from 'mobx-react-lite';
import { Fragment } from 'react';
import { useTranslation } from 'react-i18next';

import { BlockButton } from '@ioupie/components/buttons';
import { Bold } from '@ioupie/components/typography';
import { useNavigationStore, useShopsStore } from '@ioupie/hooks';
import { routes, SHOP_STEPS } from '@ioupie/shared/constants';

import * as S from './selection.styles';

/**
 * @function SelectionContainer
 */
export default observer(() => {
  const [t] = useTranslation();
  const shopsStore = useShopsStore();
  const navigationStore = useNavigationStore();

  const { minimumDays, totalPrice, totalSelected } = shopsStore.selectionInfo;

  // don't show the filter if in custom step
  if (shopsStore.shopStep === SHOP_STEPS.CUSTOM) {
    return <Fragment />;
  }

  const itemCountLabel =
    totalSelected === 1
      ? 'containers.catalog.shop-catalog.selection.items-1'
      : 'containers.catalog.shop-catalog.selection.items';

  const minDaysCountLabel =
    minimumDays === 1
      ? 'containers.catalog.shop-catalog.selection.min-days-1'
      : 'containers.catalog.shop-catalog.selection.min-days';

  return (
    <S.SelectionContainer>
      <S.SummaryWrapper>
        <S.CartIcon name="shopping" size={16} />
        <Bold>{t(itemCountLabel, { totalSelected })}</Bold>
        <Bold>{t(minDaysCountLabel, { minDays: minimumDays })}</Bold>
        <Bold>{t('containers.catalog.shop-catalog.selection.total', { price: totalPrice.toFixed(2) })}</Bold>
      </S.SummaryWrapper>
      <BlockButton
        disabled={shopsStore.selectedProductCount === 0}
        text={t('containers.catalog.shop-catalog.selection.next')}
        onPress={() => {
          navigationStore.dispatchNavigation({
            stack: routes.stacks.orders,
            screen: routes.pages.orders.ordering,
          });
          shopsStore.changeStep(SHOP_STEPS.CATEGORIES, false);
        }}
      />
    </S.SelectionContainer>
  );
});
