import { Fragment, memo } from 'react';

import { Ordering } from '@ioupie/containers/orders/ordering';
import { useCurrentRouteFocus, useHeaderStore, useNavigationStore } from '@ioupie/hooks';
import { routes } from '@ioupie/shared/constants';

/**
 * @function OrderingPage
 */
export default memo(() => {
  const headerStore = useHeaderStore();
  const navigationStore = useNavigationStore();

  useCurrentRouteFocus(routes.pages.orders.ordering, () => {
    headerStore.allowNavigateBack(true);
    headerStore.removeHeaderShadow(false);
    headerStore.changeTitle('pages.ordering.title');
    headerStore.changeSubtitle('pages.ordering.subtitle');
    headerStore.onClickBack(() => {
      navigationStore.dispatchGoBack();
    });
  });

  return (
    <Fragment>
      <Ordering />
    </Fragment>
  );
});
