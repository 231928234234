import * as Location from 'expo-location';
import * as Notification from 'expo-notifications';
import { injectable } from 'inversify';
import { Platform } from 'react-native';

import { colors } from '@ioupie/shared/constants';

@injectable()
export class PushNotificationService {
  public async getCurrentPermission(): Promise<Location.PermissionStatus> {
    const { status } = await Notification.getPermissionsAsync();
    return status;
  }

  public async requestPermissions(): Promise<Location.PermissionStatus> {
    const { status } = await Notification.requestPermissionsAsync();
    if (status !== Location.PermissionStatus.GRANTED) {
      throw new Error('You must provide permission');
    }
    return status;
  }

  public async getExpoPushToken(): Promise<string> {
    try {
      const { data } = await Notification.getExpoPushTokenAsync({
        projectId: '55520001-b27a-477e-b77c-efe8df2ad341',
      });
      return data;
    } catch (error) {
      return 'Error retrieving Expo push token';
    }
  }

  public isValidExpoToken(token: string): boolean {
    return token.startsWith('ExponentPushToken[');
  }

  public async setNotificationChannel(): Promise<void> {
    if (Platform.OS === 'android') {
      await Notification.setNotificationChannelAsync('com.ioupie.client.native.push', {
        name: 'Notificações',
        importance: Notification.AndroidImportance.DEFAULT,
        vibrationPattern: [0, 250, 250, 250],
        lightColor: colors.light.primary,
      });
    }
  }
}
