import { getAnalytics, setAnalyticsCollectionEnabled } from 'firebase/analytics';
import { getApp, getApps, initializeApp, type FirebaseOptions } from 'firebase/app';

export default {
  app: (name?: string) => {
    return getApp(name);
  },
  get apps() {
    return getApps() ?? [];
  },
  initializeApp: async (options: FirebaseOptions) => {
    const app = initializeApp(options);
    const analytics = getAnalytics(app);
    setAnalyticsCollectionEnabled(analytics, true);

    return Promise.resolve(app);
  },
};
