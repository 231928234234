import { lighten, darken } from 'polished';
import styled from 'styled-components/native';

import { List } from '@ioupie/components/custom';
import { View } from 'react-native';
import { Text, Title } from '@ioupie/components/typography';
import { Icon } from '@ioupie/components/gallery';
import { colors } from '@ioupie/shared/constants';
import { themefy } from '@ioupie/shared/utils';
import { NoContentProps } from './no-content.props';

export const NoContentContainer = styled(View)`
  align-items: center;
  display: flex;
  flex-flow: column;
  justify-content: space-around;
  padding: 20px;
  text-align: center;
  width: 100%;
`;

export const NoContentIcon = styled(Icon)`
  color: ${themefy({ light: lighten(0.6, colors.light.text), dark: darken(0.4, colors.dark.text) })};
`;

export const NoContentTitle = styled(Title<NoContentProps>)`
  color: ${({ primaryText }) => {
    return primaryText ?
      themefy({ light: colors.light.text, dark: colors.dark.text }) :
      themefy({ light: lighten(0.3, colors.light.text), dark: darken(0.2, colors.dark.text) });
  }};
  margin-top: ${({ iconName }) => iconName ? '20px' : '0' };
  text-align: center;
`;

export const NoContentText = styled(Text<NoContentProps>)`
  color: ${({ primaryText }) => {
    return primaryText ?
      themefy({ light: colors.light.text, dark: colors.dark.text }) :
      themefy({ light: lighten(0.4, colors.light.text), dark: darken(0.2, colors.dark.text) });
  }};
  text-align: center;
`;
