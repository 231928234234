import { Platform, TouchableOpacity } from 'react-native';
import styled from 'styled-components/native';

import { BlockButton } from '@ioupie/components/buttons';
import { Image } from '@ioupie/components/gallery';
import { Divider, ScrollView } from '@ioupie/components/layout';
import { Bold, Text } from '@ioupie/components/typography';
import { fontsFamily, fontsWeight } from '@ioupie/shared/constants';

export const Container = styled(ScrollView)`
  padding: 20px;
`;

export const ContactOptions = styled.View`
  border-bottom-color: #ccc;
  border-bottom-width: 1px;
  flex: 1;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: space-between;
  margin-bottom: 20px;
  min-height: ${Platform.OS === 'web' ? '130px' : 'auto'};
  padding-bottom: 20px;
  padding-top: 10px;
`;

export const ContactOptionContainer = styled(TouchableOpacity)`
  width: 47%;
`;

export const ContactOptionItem = styled.View`
  align-items: center;
  background-color: #e6e6e6;
  padding: 20px;
`;

export const ContactOptionLabel = styled(Text)`
  padding-top: 15px;
`;

export const ContactOptionImage = styled(Image)`
  height: 40px;
  width: 40px;
  min-height: ${Platform.OS === 'web' ? '40px' : 'auto'};
  min-width: ${Platform.OS === 'web' ? '40px' : 'auto'};
`;

export const ScreenTitle = styled(Text)`
  font-family: ${fontsFamily.bold};
  font-size: 18px;
  font-weight: ${fontsWeight.bold};
  padding-bottom: 10px;
`;

export const Content = styled(Text)`
  font-size: 16px;
  padding: 0 0 5px;
`;

export const SectionDivider = styled(Divider)`
  margin: 20px 0;
`;

export const FaqPageButton = styled(BlockButton)`
  margin-top: 15px;
  width: 100%;
`;
