export enum ROUTE_STACKS {
  orders = 'pedidos',
  auth = 'autenticacao',
  payment = 'metodos_pagamento',
  help = 'ajuda',
  settings = 'preferencias',
  profile = 'perfil',
  address = 'enderecos',
}

export enum ORDER_ROUTES {
  home = 'home',
  shop_details = 'detalhes_lavanderia',
  providers = 'armario_lavanderias',
  lockers = 'armario',
  catalog = 'novo_catalogo',
  track_order = 'detalhes',
  ordering = 'novo_pedido',
  summary = 'novo_resumo',
  thank = 'typ',
  nps_evaluation = 'nps',
}

export enum PAYMENT_ROUTES {
  methods_list = 'lista',
  add_credit_card = 'novo',
}

export enum AUTH_ROUTES {
  sign_in = 'home_sign_in',
}

export enum HELP_ROUTES {
  help = 'home_help',
}

export enum SETTINGS_ROUTES {
  settings = 'home_settings',
}

export enum PROFILE_ROUTES {
  my_profile = 'meu_perfil',
  my_account = 'minha_conta',
}

export enum ADDRESS_ROUTES {
  add_new_address = 'novo_endereco',
  addresses_list = 'lista_enderecos',
}

export enum HOME_TABS {
  delivery = 'tabs_delivery',
  lockers = 'tabs_armarios',
  my_orders = 'tabs_meus_pedidos',
}

export const routes = Object.freeze({
  stacks: ROUTE_STACKS,
  tabs: {
    home: HOME_TABS,
  },
  pages: {
    orders: ORDER_ROUTES,
    auth: AUTH_ROUTES,
    payment: PAYMENT_ROUTES,
    help: HELP_ROUTES,
    settings: SETTINGS_ROUTES,
    profile: PROFILE_ROUTES,
    address: ADDRESS_ROUTES,
  },
} as const);
