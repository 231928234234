import { yupResolver } from '@hookform/resolvers/yup';
import ValidCard from 'card-validator';
import creditCardType from 'credit-card-type';
import { compose } from 'lodash/fp';
import { observer } from 'mobx-react-lite';
import { Fragment, useEffect, useState } from 'react';
import { useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { Keyboard } from 'react-native';
import * as yup from 'yup';

import { ErrorSnackbar } from '@ioupie/components/custom';
import { TextInput } from '@ioupie/components/inputs';
import { HelperText } from '@ioupie/components/typography';
import { useNavigationStore, usePaymentStore } from '@ioupie/hooks';
import { CREDIT_CARD_LOGOS } from '@ioupie/shared/constants';
import { safeObjectLookup } from '@ioupie/shared/utils';

import * as S from './credit-card-form.styles';
import { CreditCardForm } from './credit-card.models';

/**
 * @function CreditCardFormContainer
 */
export default observer(() => {
  const [t] = useTranslation();
  const paymentStore = usePaymentStore();
  const navigationStore = useNavigationStore();
  const [issuer, setIssuer] = useState('');

  const creditCardSchema: yup.SchemaOf<CreditCardForm> = yup.object().shape({
    cardNumber: yup
      .string()
      .required(t('forms.credit-card.card-number.errors.required'))
      .test(
        'is-valid-card-number',
        t('forms.credit-card.card-number.errors.valid'),
        (value) => Boolean(value) && ValidCard.number(value).isValid,
      ),
    cardHolderName: yup
      .string()
      .required(t('forms.credit-card.card-holder-name.errors.required'))
      .test(
        'is-valid-card-holder-name',
        t('forms.credit-card.card-holder-name.errors.valid'),
        (value) => Boolean(value) && ValidCard.cardholderName(value).isValid,
      ),
    expirationDate: yup
      .string()
      .required(t('forms.credit-card.valid-thru.errors.required'))
      .test(
        'is-valid-expiration-date',
        t('forms.credit-card.valid-thru.errors.valid'),
        (value) => Boolean(value) && ValidCard.expirationDate(value).isValid,
      ),
    cvv: yup.string().when('cardNumber', {
      is: (value: string) => {
        const info = creditCardType(value);
        const type = info.length > 0 ? info[0].type : '';
        return ['visa', 'mastercard'].includes(type);
      },
      then: yup
        .string()
        .required(t('forms.credit-card.cvv.errors.required'))
        .test(
          'is-valid-cvv',
          t('forms.credit-card.cvv.errors.valid'),
          (value) => Boolean(value) && [3, 4].includes(value?.length),
        ),
      otherwise: yup.string().notRequired(),
    }),
  });

  const {
    register,
    handleSubmit,
    setValue,
    watch,
    trigger,
    formState: { errors, isValid },
  } = useForm<CreditCardForm>({
    defaultValues: {
      cardNumber: '',
      cardHolderName: '',
      expirationDate: '',
      cvv: '',
    },
    resolver: yupResolver(creditCardSchema),
  });

  useEffect(() => {
    register('cardNumber');
    register('cardHolderName');
    register('expirationDate');
    register('cvv');
  }, [register]);

  const handleCardNumberFormat = compose(
    (text: string) => setValue('cardNumber', text, { shouldValidate: true }),
    (text: string) =>
      text
        .replace(/[^\dA-Z]/g, '')
        .replace(/(.{4})/g, '$1 ')
        .trim(),
    (text: string) => {
      const info = creditCardType(text);
      const type = info.length > 0 ? info[0].type : '';
      setIssuer(type);
      return text;
    },
  );

  const handleExpirationDateFormat = compose(
    (text: string) => setValue('expirationDate', text, { shouldValidate: true }),
    (text: string) => text.replace(/(\d{2})(\d{2})/g, '$1/$2').trim(),
  );

  const onSubmitForm = async (form: CreditCardForm) => {
    Keyboard.dismiss();
    const { expirationDate, cardNumber, ...creditCardData } = form;
    const [expirationMonth, expirationYear] = expirationDate.split('/');

    await paymentStore.saveCreditCard({
      ...creditCardData,
      cardNumber: cardNumber.replace(/\s/g, ''),
      expirationMonth,
      expirationYear: `20${expirationYear}`,
      preferred: false,
    });

    navigationStore.dispatchGoBack();
  };

  const CardNumberSection = (
    <Fragment>
      <S.CardNumberContainer>
        <S.CardNumberInput
          keyboardType="numeric"
          maxLength={19}
          label={t('forms.credit-card.card-number.label')}
          value={watch('cardNumber')}
          onChangeText={handleCardNumberFormat}
          onBlur={() => trigger('cardNumber')}
          error={Boolean(errors.cardNumber)}
        />
        <S.CardIssuerLogo image={safeObjectLookup(CREDIT_CARD_LOGOS, issuer) ?? ''} height={32} width={50} />
      </S.CardNumberContainer>
      <HelperText type="error" visible={Boolean(errors.cardNumber)}>
        {errors.cardNumber?.message ?? ''}
      </HelperText>
    </Fragment>
  );

  const CardHolderNameSection = (
    <Fragment>
      <TextInput
        autoCapitalize="words"
        label={t('forms.credit-card.card-holder-name.label')}
        value={watch('cardHolderName')}
        onChangeText={(text) => setValue('cardHolderName', text, { shouldValidate: true })}
        onBlur={() => trigger('cardHolderName')}
        error={Boolean(errors.cardHolderName)}
      />
      <HelperText type="error" visible={Boolean(errors.cardHolderName)}>
        {errors.cardHolderName?.message ?? ''}
      </HelperText>
    </Fragment>
  );

  const CardValidThruSection = (
    <Fragment>
      <TextInput
        keyboardType="numeric"
        maxLength={5}
        label={t('forms.credit-card.valid-thru.label')}
        placeholder={t('forms.credit-card.valid-thru.placeholder')}
        value={watch('expirationDate')}
        onChangeText={handleExpirationDateFormat}
        onBlur={() => trigger('expirationDate')}
        error={Boolean(errors.expirationDate)}
      />
      <HelperText type="error" visible={Boolean(errors.expirationDate)}>
        {errors.expirationDate?.message ?? ''}
      </HelperText>
    </Fragment>
  );

  const SecurityCodeSection = ['visa', 'mastercard'].includes(issuer) && (
    <Fragment>
      <TextInput
        keyboardType="numeric"
        maxLength={4}
        label={t('forms.credit-card.cvv.label')}
        placeholder={t('forms.credit-card.cvv.placeholder')}
        value={watch('cvv')}
        onBlur={() => trigger('cvv')}
        onChangeText={(text) => setValue('cvv', text, { shouldValidate: true })}
        error={Boolean(errors.cvv)}
      />
      <HelperText type="error" visible={Boolean(errors.cvv)}>
        {errors.cvv?.message ?? ''}
      </HelperText>
    </Fragment>
  );

  return (
    <Fragment>
      <S.Container>
        <HelperText type="info" visible>
          {t('forms.credit-card.instruction')}
        </HelperText>
        {CardNumberSection}
        {CardHolderNameSection}
        {CardValidThruSection}
        {SecurityCodeSection}
        <S.AddButton
          disabled={!isValid}
          loading={paymentStore.loading}
          text={t('forms.credit-card.submit')}
          onPress={handleSubmit(onSubmitForm)}
        />
      </S.Container>

      <ErrorSnackbar errors={paymentStore.errors} onDismiss={() => paymentStore.clearErrors()} />
    </Fragment>
  );
});
