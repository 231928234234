import { ProductMetadata } from '..';
import { PAYMENT_METHOD } from '../..';
import { CompartmentType } from '../entities';
import { Money } from '../promotions';
import { OrderStatusNames } from './service-order-status.enum';

export declare type ServiceOrderV2 = {
  readonly userId: string; // Partition Key
  readonly id: string; // Sort Key

  readonly serviceProviderId: string;
  readonly type: keyof typeof ServiceOrderType;
  readonly status: OrderStatusNames;

  readonly creationTimestamp: string;
  readonly lastModificationTimestamp: string;

  readonly orderNumber: string;
  readonly externalId?: string;
  readonly laundryCaseNumber?: string;

  readonly products: readonly OrderProductEntry[];
  readonly minimumDays?: number;
  readonly totalVolume?: number;

  readonly orderFees?: readonly OrderFeeEntry[];
  readonly orderLevelDiscounts?: readonly OrderDiscountEntry[];
  readonly grandTotal: Money;
  readonly payment: OrderPaymentInformation;

  readonly comments?: OrderComments;
  readonly tags?: readonly string[];

  readonly inboundDetails: CustomerInteractionDetails;
  readonly outboundDetails: CustomerInteractionDetails;

  readonly nps?: NpsEvaluation;
};

export declare type ServiceOrderPersistenceModel = ServiceOrderV2 & {
  readonly inputAddressId: string;
  readonly outputAddressId: string;
  readonly activeByProviderId?: string;
  readonly activeByTypeAndDevice?: string;
};

export const ServiceOrderType = Object.freeze({
  LOCKER: 'LOCKER',
  DELIVERY: 'DELIVERY',
});

export const ServiceOrderStandardTags = Object.freeze({
  B2B: 'B2B',
  CREATED_IN_MMS: 'CREATED_IN_MMS',
  DISABILITY: 'DISABILITY',
  PREPAID: 'PREPAID',
  RECURRENT_ORDER: 'RECURRENT_ORDER',
  REJECTED: 'REJECTED',
});

export declare type OrderProductEntry = {
  readonly productId: string;
  readonly quantity: number;
  readonly unitPrice: Money;
  readonly discounts: readonly OrderDiscountEntry[];
  readonly additionalCharges: Money;
  readonly notes?: string;
  readonly metadata?: ProductMetadata;
  readonly name?: string;
};

export declare type OrderDiscountEntry = {
  readonly type: string;
  readonly funding: readonly OrderProductEntryFunder[];
  readonly totalAmount: Money;
  readonly metadata?: Record<string, string>;
};

export declare type OrderFeeEntry = {
  readonly type: string;
  readonly amount: Money;
  readonly transferee?: string;
  readonly metadata?: Record<string, string>;
};

export const OrderFeeType = Object.freeze({
  /** Coleta */
  PICK_UP: 'Pickup',

  /** Entrega */
  DROP_OFF: 'DropOff',

  /** Taxa de Parcelamento */
  INSTALLMENTS_FEE: 'InstallmentsFee',
});

export declare type OrderProductEntryFunder = {
  readonly entity: string;
  readonly amount: Money;
};

export declare type OrderPaymentInformation = {
  readonly involvedPayments: readonly SinglePaymentInformation[];
};

export declare type SinglePaymentInformation = {
  readonly type?: PAYMENT_METHOD;
  readonly paymentMethodId: string;
  readonly amount: Money;
  readonly transactionNumber: string;
  readonly status?: string;
  readonly confirmedDate?: string;
  readonly processor?: string;

  // The following are not stored in database and will come empty in most of occasions
  readonly issuer?: string;
  readonly lastDigits?: string;
};

export declare type OrderComments = {
  readonly ioupieInternal?: string;
  readonly partnerInternal?: string;
  readonly partnerToCustomer?: string;
};

export declare type CustomerInteractionDetails = {
  readonly addressId: string;
  readonly lockerId?: string;
  readonly lockerName?: string;
  readonly compartment?: CustomerInteractionCompartment;
  readonly requestedTime?: string;
  readonly completedTime?: string;

  // The following is not stored in database and will come empty in most of occasions
  readonly address?: string;
};

export declare type CustomerInteractionCompartment = {
  readonly type: CompartmentType;
  readonly identifier: string;
};

export declare type NpsEvaluation = {
  readonly order: NpsEvaluationOrderData;
};

export declare type NpsEvaluationOrderData = {
  readonly score: number;
  readonly highlights: readonly string[];
  readonly comment?: string;
};
