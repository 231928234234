import styled from 'styled-components/native';

export const Background = styled.ImageBackground`
  flex: 1;
`;

export const ThankContainer = styled.View`
  background-color: rgba(255, 255, 255, 0.85);
  flex: 1;
`;
