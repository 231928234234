import { observer } from 'mobx-react-lite';

import { IconButton } from '@ioupie/components/buttons';
import { Title } from '@ioupie/components/typography';
import { useShopsStore } from '@ioupie/hooks';

import { SummaryLegendProps } from './summary-legend.models';
import * as S from './summary-legend.styles';

/**
 * @function SummaryLegendContainer
 */
export default observer(({ productAmount }: SummaryLegendProps) => {
  const shopsStore = useShopsStore();

  return (
    <S.SummaryLegend>
      <Title>{productAmount.amount.toString()}</Title>
      <IconButton
        icon="trash-can-outline"
        color="#f00"
        onPress={() => shopsStore.removeProduct(productAmount.referenceId)}
      />
    </S.SummaryLegend>
  );
});
