import { observer } from 'mobx-react-lite';
import { Fragment, useCallback } from 'react';
import { useTranslation } from 'react-i18next';
import { View } from 'react-native';

import { BlockButton, IconButton } from '@ioupie/components/buttons';
import { List } from '@ioupie/components/custom';
import { useOrdersStore } from '@ioupie/hooks';
import { ORDER_STEPS } from '@ioupie/shared/constants';

import * as S from './finish-order.styles';

/**
 * @function FinishOrderContainer
 */
export default observer(() => {
  const [t] = useTranslation();
  const ordersStore = useOrdersStore();

  const { loading, selectedOrderHistory } = ordersStore;
  const { comments = {} } = selectedOrderHistory ?? {};

  const body = (
    <View>
      <List.Message
        title={t('containers.orders.track-order.finish-order-stepper.finish-order.title')}
        subtitle={t('containers.orders.track-order.finish-order-stepper.finish-order.description')}
      />
    </View>
  );

  return (
    <Fragment>
      <S.FinishOrderContainer>
        <List.Item left={<IconButton icon="credit-card-check-outline" size={40} />} body={body} showBorder={false} />
        <S.OrderActions>
            <BlockButton
              reduced
              secondary
              text={t('containers.orders.track-order.finish-order-stepper.finish-order.reject-order')}
              onPress={() => ordersStore.setShowRejectionModal(true)}
              loading={loading}
            />

          <BlockButton
            reduced
            text={t('containers.orders.track-order.finish-order-stepper.finish-order.finish-order')}
            onPress={() => ordersStore.changeStep(ORDER_STEPS.SCHEDULE)}
          />
        </S.OrderActions>
      </S.FinishOrderContainer>
    </Fragment>
  );
});
