import 'expo-asset';
import 'intl';
import 'intl/locale-data/jsonp/en';
import 'intl/locale-data/jsonp/pt';
import 'react-native-gesture-handler';
import 'react-native-get-random-values';
import 'reflect-metadata';

import { observer } from 'mobx-react-lite';
import { Fragment, useEffect } from 'react';
import { Platform } from 'react-native';

import { useBootstrapStore } from '@ioupie/hooks';
import { AppRoutes } from '@ioupie/routes';

import * as development from './dev';
import './global';
import Main from './src';

if (__DEV__ && Platform.OS !== 'web') {
  development.disableTimerLog();
  development.disableIconsLog();
  development.enableNetworkDebug();
}

function App() {
  const bootstrapStore = useBootstrapStore();

  useEffect(() => {
    // eslint-disable-next-line no-void
    void bootstrapStore.loadApplication();
  }, []);

  return <Main>{bootstrapStore.appReady ? <AppRoutes /> : <Fragment />}</Main>;
}

export default observer(App);
