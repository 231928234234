import { memo } from 'react';

import { safeObjectLookup } from '@ioupie/shared/utils';

import { ListImageProps, ListImageSizes } from './image.models';
import * as S from './image.styles';

const sizesInPixels: Record<ListImageSizes, number> = {
  small: 40,
  medium: 60,
  large: 80,
};

/**
 * @function ListMessageComponent
 */
export default memo(({ image, size, emptyImage }: ListImageProps) => {
  const width = safeObjectLookup(sizesInPixels, size) ?? sizesInPixels.small;
  const height = safeObjectLookup(sizesInPixels, size) ?? sizesInPixels.small;

  return (
    <S.ImageWrapper pointerEvents="box-none">
      <S.ImageAdjusted image={image} size={size} width={width} height={height} emptyImage={emptyImage} />
    </S.ImageWrapper>
  );
});
