import styled from 'styled-components/native';

import { Text } from '@ioupie/components/typography';
import { colors, fontsFamily, fontsWeight } from '@ioupie/shared/constants';

export const OrderContainer = styled.View`
  border-color: #acacac;
  border-width: 1px;
  flex-direction: column;
  margin: 10px;
  padding: 0;
`;

export const ShadedContainer = styled.View`
  flex-direction: column;
  padding: 0;
`;

export const OrderInfo = styled.View`
  align-content: space-around;
  align-items: center;
  flex-direction: row;
  flex-wrap: wrap;
  font-size: 24px;
  padding: 0;
`;

export const OrderData = styled.View`
  align-items: center;
  flex-direction: row;
  font-size: 24px;
  justify-content: space-between;
  margin: 10px;
  padding: 0;
`;

export const OrderStatus = styled.View`
  align-content: space-around;
  align-items: center;
  flex-direction: row;
  font-size: 24px;
  justify-content: space-between;
  margin: 0 10px;
  padding: 0;
`;

export const OrderIdentifier = styled(Text)`
  font-family: ${fontsFamily.semibold};
  font-size: 16px;
  font-weight: ${fontsWeight.semibold};
`;

export const OrderPrice = styled(Text)`
  font-size: 14px;
  margin: 0 0 10px 34px;
`;
