import styled from 'styled-components/native';

import { Small } from '@ioupie/components/typography';
import { colors } from '@ioupie/shared/constants';

export const ForgotPasswordContainer = styled.View`
  height: 100%;
  margin: 20px;
`;

export const SnackbarContainer = styled.View`
  height: 50px;
`;

export const CenterTitle = styled.View`
  align-items: center;
  justify-content: center;
  margin: 0 0 15px;
  width: 100%;
`;

export const LinkText = styled(Small)`
  color: ${colors.light.link};
  font-weight: bold;
  font-size: 14px;
  padding: 12px;
  margin: 0 20px;
  text-align: center;
`;
