import axios, { AxiosError } from 'axios';
import { injectable } from 'inversify';
import { isPresent } from 'ts-is-present';

import {
  AsyncEpicError,
  BackendError,
  ErrorMessages,
  ErrorReason,
  ErrorReasons,
  ExpoApiError,
  Optional,
} from '@ioupie/shared/models';
import {
  isArrayOfErrors,
  isArrayOfStrings,
  isNotEmpty,
  isObject,
  isString,
  safeObjectLookup,
} from '@ioupie/shared/utils';

@injectable()
export class ErrorService {
  private readonly fallbackErrorMessage: string = 'utils.errors.no-fallback';

  public wrapApiErrorWithMessages(
    error: Optional<unknown>,
    statusCodeLookup: Optional<Record<string, string>>,
  ): ErrorMessages {
    if (error && this.checkAxiosError(error)) {
      return [safeObjectLookup(statusCodeLookup, error.code)].filter(isPresent);
    }

    return this.wrapApiError(error);
  }

  public wrapApiError(error: Optional<unknown>): ErrorMessages {
    if (error && this.checkAxiosError(error)) {
      // in case the backend haven't implemented the error for the API, avoid the html fallback
      return error.response && this.checkBackendError(error.response.data)
        ? this.parseErrorToArrayOfMessages(error.response.data)
        : this.parseErrorToArrayOfMessages(error.message);
    }

    return this.parseErrorToArrayOfMessages(error);
  }

  public parseErrorToArrayOfMessages(error: Optional<unknown>): ErrorMessages {
    // if the received object is empty
    if (!error) {
      return [];
    }

    if (this.checkComplexError(error)) {
      return error;
    }

    if (this.checkExpoError(error)) {
      return error.map((e) => e.message);
    }

    if (this.checkBackendError(error)) {
      return [error.message];
    }

    if (this.checkSimpleError(error)) {
      return [error];
    }

    return [this.fallbackErrorMessage];
  }

  private checkSimpleError(error: unknown): error is ErrorReason {
    return isString(error) && error.trim() !== '';
  }

  private checkComplexError(error?: unknown): error is ErrorReasons {
    return isArrayOfStrings(error);
  }

  private checkBackendError(error: unknown): error is BackendError {
    return isObject(error) && isNotEmpty(error);
  }

  private checkExpoError(error: unknown): error is ExpoApiError {
    return isArrayOfErrors(error);
  }

  private checkAxiosError(error: unknown): error is AxiosError<AsyncEpicError> {
    return axios.isAxiosError(error);
  }
}
