import { DateTime } from 'luxon';
import { observer } from 'mobx-react-lite';
import { Fragment } from 'react';
import { useTranslation } from 'react-i18next';

import { useOrdersStore, useShopsStore } from '@ioupie/hooks';
import { BUSINESS_AREA_OPTIONS } from '@ioupie/shared/constants';

import { Agenda } from './agenda';
import * as S from './dates.styles';

/**
 * @function DatesContainer
 */
export default observer(() => {
  const [t] = useTranslation();
  const shopsStore = useShopsStore();
  const ordersStore = useOrdersStore();

  const isLocker = shopsStore.shopBusinessArea === BUSINESS_AREA_OPTIONS.LOCKER;

  const formattedDate = (orderDate: string) => {
    const locale = t('constants.locale.locale');
    const dateFormat = t('containers.orders.ordering.dates.delivery-format');
    return DateTime.fromISO(orderDate).setLocale(locale).toFormat(dateFormat);
  };

  return (
    <Fragment>
      <S.DatesTitle>{t('containers.orders.ordering.dates.title')}</S.DatesTitle>
      <S.AgendaContainer>
        <Agenda
          subject={t('containers.orders.ordering.dates.pickup')}
          content={
            isLocker
              ? t('containers.orders.ordering.dates.next-working-day')
              : ordersStore.selectedPickupDate
              ? formattedDate(DateTime.fromMillis(+ordersStore.selectedPickupDate).toISO())
              : '-'
          }
        />
        <Agenda
          subject={t('containers.orders.ordering.dates.delivery')}
          content={ordersStore.scheduledOrderDate ? formattedDate(ordersStore.scheduledOrderDate) : '-'}
        />
      </S.AgendaContainer>
    </Fragment>
  );
});
