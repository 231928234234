import { Appbar } from 'react-native-paper';
import styled from 'styled-components/native';

import { colors } from '@ioupie/shared/constants';
import { themefy } from '@ioupie/shared/utils';

declare type HeaderBarProps = {
  readonly noShadows?: boolean;
};

export default styled(Appbar.Header)<HeaderBarProps>`
  background-color: ${themefy({
    light: colors.light.primary,
    dark: colors.dark.primary,
  })};
  elevation: ${({ noShadows }) => {
    return noShadows ? 0 : 4;
  }};
  position: relative;
`;
