import { Text } from 'react-native-paper';
import styled from 'styled-components/native';

import { colors } from '@ioupie/shared/constants';
import { themefy } from '@ioupie/shared/utils';

declare type SmallProps = {
  readonly color?: string;
};

export default styled(Text)<SmallProps>`
  color: ${(props) =>
    props.color
      ? props.color
      : themefy({
          light: colors.light.text,
          dark: colors.dark.text,
        })(props)};
  font-size: 12px;
`;
