import { MD3DarkTheme } from 'react-native-paper';

import { colors } from '@ioupie/shared/constants';

import { fonts } from '../common';

const themeColors: typeof MD3DarkTheme.colors = {
  ...MD3DarkTheme.colors,
  primary: colors.dark.primary,
  secondary: colors.dark.secondary,
  background: colors.dark.background,
  tertiary: colors.dark.text,
};

const darkTheme: typeof MD3DarkTheme = {
  ...MD3DarkTheme,
  dark: true,
  colors: themeColors,
  roundness: 2,
  fonts,
};

export default darkTheme;
