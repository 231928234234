import { darken, lighten } from 'polished';
import styled from 'styled-components/native';

import { BlockButton, Button } from '@ioupie/components/buttons';
import { Image } from '@ioupie/components/gallery';
import { Divider } from '@ioupie/components/layout';
import { Text } from '@ioupie/components/typography';
import { colors, fontsFamily, fontsWeight } from '@ioupie/shared/constants';
import { themefy } from '@ioupie/shared/utils';

export const NpsEvaluationContainer = styled.View`
  flex: 1;
  margin: 20px;
`;

export const NpsEvaluationTitle = styled.View`
  align-items: center;
  justify-content: center;
  margin: 0 0 16px;
`;

export const SubTitle = styled(Text)`
  font-family: ${fontsFamily.medium};
  font-size: 16px;
  font-weight: ${fontsWeight.medium};
  margin: 0 0 8px;
`;

export const ScoreContainer = styled.View`
  align-items: center;
  flex-direction: row;
  justify-content: space-between;
  margin: 8px 0 16px;
`;

export const CommentBox = styled.View`
  border: 1px solid
    ${themefy({
      light: lighten(0.4, colors.light.text3),
      dark: darken(0.4, colors.dark.text3),
    })};
  margin: 8px 0 0;
`;

export const EvaluationButton = styled(BlockButton)`
  margin: 0 0 8px;
`;

export const NoEvaluationButton = styled(Button)`
  margin: 0 0 16px;
`;

export const ThankContainer = styled.View`
  margin: 0 10px;
`;

export const ImageContainer = styled.View`
  align-items: center;
  flex-direction: row;
  justify-content: center;
`;

export const ImageView = styled.View`
  align-items: center;
  justify-content: center;
  margin: 8px 0;
  border: 1px solid ${colors.light.primary};
  border-radius: 50px;
  height: 90px;
  width: 90px;
`;

export const IoupieImage = styled(Image)`
  height: 50px;
  width: 50px;
`;

export const ThankText = styled(Text)`
  flex-wrap: wrap;
  flex: 1;
  margin: 0 0 0 16px;
`;

export const CloseButtonContainer = styled.View`
  flex: 1;
  justify-content: center;
`;

export const Separator = styled(Divider)`
  margin: 0 0 16px;
`;
