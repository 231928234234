import { observer } from 'mobx-react-lite';
import { Fragment, useCallback } from 'react';
import { useTranslation } from 'react-i18next';
import { FlatList, ListRenderItemInfo } from 'react-native';

import { List } from '@ioupie/components/custom';
import { Title, Text } from '@ioupie/components/typography';
import { useLockersStore, useNavigationStore, useShopsStore } from '@ioupie/hooks';
import { BUSINESS_AREA_OPTIONS, BUSINESS_SEGMENTS_OPTIONS, routes } from '@ioupie/shared/constants';
import { Provider } from '@ioupie/shared/models';

import * as S from './shops-list.styles';

/**
 * @function ShopsListContainer
 */
export default observer(() => {
  const [t] = useTranslation();
  const navigationStore = useNavigationStore();
  const lockersStore = useLockersStore();
  const shopsStore = useShopsStore();

  // the area type and segment should have been selected previously
  const selectShopAndNavigate = useCallback((provider: Provider) => {
    // still have to choose where to buy from
    shopsStore.selectShopProvider(provider.id);
    navigationStore.dispatchNavigation({
      stack: routes.stacks.orders,
      screen: routes.pages.orders.catalog,
      loggingMetaData: {
        segment: BUSINESS_SEGMENTS_OPTIONS.LAUNDRY,
        businessArea: BUSINESS_AREA_OPTIONS.LOCKER,
        provider: provider.id,
        locker: lockersStore.lockerProvider?.id ?? '',
      },
    });
  }, []);

  const providerKeyExtractor = useCallback((provider: Provider) => provider.id, []);

  const renderProviderListItem = useCallback((info: ListRenderItemInfo<Provider>) => {
    const { item: provider } = info;
    return (
      <List.Item
        onPress={() => selectShopAndNavigate(provider)}
        left={<List.Image size="large" image={provider.image} />}
        body={<List.Message title={provider.name} />}
        right={<List.Icon size="medium" icon="chevron-right" />}
      />
    );
  }, []);

  return (
    <Fragment>
      <S.IntroUsage>
        <Title>{t('containers.catalog.shops-list.title')}</Title>
        <Text>{t('containers.catalog.shops-list.description')}</Text>
      </S.IntroUsage>
      <List.Section>
        <FlatList<Provider>
          data={shopsStore.availableProviders}
          keyExtractor={providerKeyExtractor}
          renderItem={renderProviderListItem}
        />
      </List.Section>
    </Fragment>
  );
});
