import { memo } from 'react';
import { useTranslation } from 'react-i18next';

import { OutlinedButton } from '@ioupie/components/buttons';
import { Text } from '@ioupie/components/typography';

import { ServiceButtonsProps } from './service-buttons.models';
import * as S from './service-buttons.styles';

/**
 * @function ServiceButtonsComponent
 */
export default memo((props: ServiceButtonsProps) => {
  const [t] = useTranslation();
  const { title = '', highlights = [], highlightedList = [], onPress } = props;

  return (
    <S.ServicesView>
      <S.Title>{title}</S.Title>
      <S.ButtonsContainer>
        {highlights.map((service) => {
          const isHighlighted = highlightedList.find((highlight) => highlight === service) !== undefined;
          const body = (
            <S.BodyView>
              <Text>{t(`containers.orders.nps-evaluation.service-buttons.${service.toLowerCase()}`)}</Text>
              {isHighlighted ? <S.BodyIcon name="check" size={20} active /> : <S.BodyIcon name="plus" size={20} />}
            </S.BodyView>
          );
          return <OutlinedButton key={service} onPress={() => onPress(service)} active={isHighlighted} text={body} />;
        })}
      </S.ButtonsContainer>
    </S.ServicesView>
  );
});
