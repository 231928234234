import { isPresent } from 'ts-is-present';

import {
  ORDER_CLOSED_STATUSES,
  ORDER_PENDING_USER_ACTION_STATUSES,
  ORDER_REJECTED_STATUSES,
  ORDER_STATUS,
  ORDER_STATUS_TRANSLATIONS,
  ORDER_SUCCESSFUL_STATUSES,
} from '../constants';
import { Optional } from '../models';
import { safeObjectLookup } from './safe-accessor.utils';
import { isEnumOf } from './type-guard';

export const isOrderStatuses =
  (statuses: readonly ORDER_STATUS[]) =>
  (status?: Optional<ORDER_STATUS>): boolean => {
    return isPresent(status) && statuses.map((st) => st.toUpperCase()).includes(status.toUpperCase());
  };

export const isOrderSuccessfulStatus = isOrderStatuses(ORDER_SUCCESSFUL_STATUSES);
export const isOrderPendingUserActionStatus = isOrderStatuses(ORDER_PENDING_USER_ACTION_STATUSES);
export const isOrderClosedStatus = isOrderStatuses(ORDER_CLOSED_STATUSES);
export const isOrderRejectedStatus = isOrderStatuses(ORDER_REJECTED_STATUSES);

const UPPER_CASE_TRANSLATIONS: Record<string, string> = Object.keys(ORDER_STATUS_TRANSLATIONS)
  .filter(isEnumOf(ORDER_STATUS))
  .reduce(
    (result, key) => ({
      ...result,
      [key.toUpperCase()]: safeObjectLookup(ORDER_STATUS_TRANSLATIONS, key) ?? '',
    }),
    {},
  );

export const getOrderStatusTranslation = (status: ORDER_STATUS): string =>
  safeObjectLookup(UPPER_CASE_TRANSLATIONS, status.toUpperCase()) ?? '';
