import { Children, memo, useEffect, useState } from 'react';
import { Keyboard, KeyboardAvoidingView, Platform } from 'react-native';

import { IoupieLogo } from '@ioupie/components/gallery';
import { BrandingView, ScrollView } from '@ioupie/components/layout';

import { IoupieViewProps } from './ioupie-view.models';
import * as S from './ioupie-view.styles';

export default memo(({ children }: IoupieViewProps) => {
  const [keyboardIsVisible, setKeyboardIsVisible] = useState(false);

  useEffect(() => {
    Keyboard.addListener('keyboardDidShow', () => {
      setKeyboardIsVisible(true);
    });
    Keyboard.addListener('keyboardDidHide', () => {
      setKeyboardIsVisible(false);
    });
  }, []);

  return (
    <BrandingView>
      <ScrollView scrollEnabled={false}>
        <KeyboardAvoidingView>
          <ScrollView>
            {(Platform.OS === 'ios' || !keyboardIsVisible) && (
              <S.CenteredView>
                <IoupieLogo />
              </S.CenteredView>
            )}
            <S.ContentView>{Children.toArray(children)}</S.ContentView>
          </ScrollView>
        </KeyboardAvoidingView>
      </ScrollView>
    </BrandingView>
  );
});
