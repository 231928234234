const uid = 'appsFlyerUID';
const propsMap = new Map<string, string>();

const fetchAppsFlyerId = () => {
  if (!propsMap.has(uid)) {
    // @ts-ignore
    propsMap.set(uid, AF.id.pba.webAppId);
  }
};

try {
  fetchAppsFlyerId();
} catch (error) {
  console.error('Failed bootstrapping AppsFlyer web id', error);
}

export default {
  onDeepLink: async () => async () => Promise.resolve(),
  initSdk: async () => Promise.resolve(),
  setUserEmails: async () => Promise.resolve(),
  setCustomerUserId: (userId: string, successC?: CallableFunction) => {
    // @ts-ignore
    AF('pba', 'setCustomerUserId', userId);
    if (successC) successC();
  },
  getAppsFlyerUID: (callback: (error?: unknown, uid?: string) => void) => {
    try {
      fetchAppsFlyerId();
      const appsFlyerUID = propsMap.get(uid);
      callback(undefined, appsFlyerUID);
    } catch (error) {
      callback(error, undefined);
    }
  },
  logEvent: async (eventName: string, eventValue: object) => {
    // @ts-ignore
    AF('pba', 'event', {
      eventType: 'EVENT',
      eventName,
      eventValue,
    });
  },
};

export const AF_EMAIL_CRYPT_TYPE = {
  NONE: 0,
  SHA256: 1,
};
