import { observer } from 'mobx-react-lite';
import { Children, Fragment, useEffect } from 'react';
import { PaperProvider } from 'react-native-paper';
import { ThemeProvider as StyledProvider } from 'styled-components/native';

import { useSettingsStore } from '@ioupie/hooks';

import { Platform } from 'react-native';
import { PaperDarkTheme, StyledDarkTheme } from './dark';
import { PaperLightTheme, StyledLightTheme } from './light';
import { ThemeProps } from './theme.props';

/**
 * @function ThemeProvider
 */
export default observer(({ children }: ThemeProps) => {
  const settingsStore = useSettingsStore();

  useEffect(() => {
    if (!settingsStore.settingsLoaded) {
      // eslint-disable-next-line no-void
      void settingsStore.bootstrapSettings();
    }
  }, [settingsStore.settingsLoaded]);

  if (settingsStore.settingsLoaded) {
    const StyledTheme = settingsStore.darkMode ? StyledDarkTheme : StyledLightTheme;
    const PaperTheme = settingsStore.darkMode ? PaperDarkTheme : PaperLightTheme;

    return (
      <StyledProvider theme={StyledTheme}>
        <PaperProvider theme={PaperTheme}>
          <Fragment>
            {Platform.OS === 'web' ? (
              <style type="text/css">
                {`
                @font-face {
                  font-family: 'MaterialCommunityIcons';
                  src: url(${require('react-native-vector-icons/Fonts/MaterialCommunityIcons.ttf')}) format('truetype');
                }
              `}
              </style>
            ) : null}
            {Children.toArray(children)}
          </Fragment>
        </PaperProvider>
      </StyledProvider>
    );
  } else {
    return <Fragment />;
  }
});
