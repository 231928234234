import { Children, Fragment, memo } from 'react';

import { ActionsBar, FormView, KeyboardView, LightBox } from '@ioupie/components/layout';

import { UserStepProps } from './user-step.models';
import * as S from './user-step.styles';

/**
 * @function UserStepComponent
 */
export default memo(({ actions, content, tooltip }: UserStepProps) => {
  return (
    <Fragment>
      <FormView elevated>
        <S.UserStepView>
          <KeyboardView>
            <LightBox>{Children.toArray(content)}</LightBox>
            <ActionsBar>{Children.toArray(actions)}</ActionsBar>
          </KeyboardView>
        </S.UserStepView>
      </FormView>
      <Fragment>{Children.toArray(tooltip)}</Fragment>
    </Fragment>
  );
});
