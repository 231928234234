import { Platform } from 'react-native';
import { DefaultTheme, useTheme as useStyledComponentsTheme } from 'styled-components';

// wrap it, may return null/undefined for web
export const useTheme = (): DefaultTheme => {
  if (Platform.OS === 'web') {
    return { mode: 'light' };
  } else {
    const { mode = 'light' } = useStyledComponentsTheme() ?? {};

    return { mode };
  }
};
