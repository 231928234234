/**
 * Controls the user path while logging into the app
 *
 * CHECK_USERNAME       -> first screen, ask for username then redirect accordingly.
 * AUTO_LOGON           -> when the user just signed, do not ask for the password again.
 * DO_SIGN_IN           -> ask for user password, as it already has an account.
 * DO_SIGN_UP           -> user doesn't have an account, needs to fill the form.
 * DO_SIGN_OUT          -> user is already logged in, he wants to leave his current account.
 * TWO_FACTOR           -> user has signed up, but have not performed two factor verification.
 * FORGOT_PASSWORD      -> generates a recovery code of password reset, submit a new password.
 * NEED_HELP            -> contains an e-mail address for the user to reach out if they need any assistance.
 */
export enum USER_STEPS {
  CHECK_USERNAME = 'Email',
  AUTO_LOGON = 'AutoLogin',
  DO_SIGN_IN = 'Senha',
  DO_SIGN_UP = 'Cadastro',
  DO_SIGN_OUT = 'Logout',
  TWO_FACTOR = 'Verificacao',
  FORGOT_PASSWORD = 'EsqueciSenha',
  NEED_HELP = 'PrecisoAjuda',
}
