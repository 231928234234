import { memo } from 'react';
import { useTranslation } from 'react-i18next';

import { BlockButton } from '@ioupie/components/buttons';
import { useNavigationStore } from '@ioupie/hooks';
import { routes } from '@ioupie/shared/constants';

import { LoginRequiredProps } from './login-required.models';
import * as S from './login-required.styles';
import { NoContent } from '../no-content';

/**
 * @function LoginRequiredComponent
 */
export default memo(({ fillVertical = false }: LoginRequiredProps) => {
  const [t] = useTranslation();
  const navigationStore = useNavigationStore();

  return (
    <S.CenterView fillVertical={fillVertical}>
      <NoContent
        primaryText
        title={t('components.login-required.title')}
        subtitle={t('components.login-required.description')}
      />
      <BlockButton
        text={t('components.login-required.action')}
        onPress={() => navigationStore.dispatchNavigation({ stack: routes.stacks.auth })}
      />
    </S.CenterView>
  );
});
