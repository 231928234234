import { PermissionStatus } from 'expo-location';
import { observer } from 'mobx-react-lite';
import { useCallback, useEffect } from 'react';
import { useTranslation } from 'react-i18next';

import { SwitchButton } from '@ioupie/components/buttons';
import { useSettingsStore } from '@ioupie/hooks';

/**
 * @function PushNotificationSwitcherContainer
 */
export default observer(() => {
  const [t] = useTranslation();
  const settingsStore = useSettingsStore();

  useEffect(() => {
    // eslint-disable-next-line no-void
    void settingsStore.fetchPushNotificationPermission();
  }, []);

  const onChangePushNotification = useCallback(
    (value: boolean) =>
      value ? settingsStore.requestPushNotificationPermission() : settingsStore.disablePushNotificationPermission(),
    [],
  );

  return (
    <SwitchButton
      text={t('containers.settings.push-notification-switcher.toggle')}
      value={settingsStore.pushNotificationPermission === PermissionStatus.GRANTED}
      onValueChange={onChangePushNotification}
    />
  );
});
