import { memo } from 'react';

import { BodyView } from '@ioupie/components/layout';
import { LockersList } from '@ioupie/containers/lockers';
import { useAddressStore, useCurrentRouteFocus, useHeaderStore } from '@ioupie/hooks';
import { routes } from '@ioupie/shared/constants';

/**
 * @function LockersPage
 */
export default memo(() => {
  const headerStore = useHeaderStore();
  const addressStore = useAddressStore();

  useCurrentRouteFocus(routes.pages.orders.lockers, () => {
    headerStore.allowNavigateBack(true);
    headerStore.removeHeaderShadow(false);
    headerStore.changeTitle('pages.lockers.title');
    headerStore.changeSubtitle(addressStore.addressLocationAsText);
  });

  return (
    <BodyView>
      <LockersList />
    </BodyView>
  );
});
