import { memo } from 'react';

import { safeObjectLookup } from '@ioupie/shared/utils';

import { ListIconSizes, ListIconProps } from './icon.models';
import * as S from './icon.styles';

const sizesInPixels: Record<ListIconSizes, number> = {
  xsmall: 30,
  small: 40,
  medium: 60,
  large: 80,
};

/**
 * @function ListIconComponent
 */
export default memo(({ icon, size }: ListIconProps) => (
  <S.IconWrapper pointerEvents="box-none">
    <S.IconCentered name={icon} size={safeObjectLookup(sizesInPixels, size)} />
  </S.IconWrapper>
));
