import { memo } from 'react';

import { BodyView } from '@ioupie/components/layout';
import { ShopsList } from '@ioupie/containers/catalog';
import {
  useAddressStore,
  useCurrentRouteFocus,
  useHeaderStore,
  useLockersStore,
  useNavigationStore,
  useShopsStore,
} from '@ioupie/hooks';
import { routes } from '@ioupie/shared/constants';

/**
 * @function ProvidersPage
 */
export default memo(() => {
  const headerStore = useHeaderStore();
  const addressStore = useAddressStore();
  const shopsStore = useShopsStore();
  const lockersStore = useLockersStore();
  const navigationStore = useNavigationStore();

  useCurrentRouteFocus(routes.pages.orders.providers, () => {
    headerStore.allowNavigateBack(true);
    headerStore.removeHeaderShadow(false);

    headerStore.onClickBack(() => {
      navigationStore.dispatchGoBack();
      lockersStore.clearSelectedLockerProvider();
    });

    if (lockersStore.lockerProvider) {
      headerStore.changeTitle(lockersStore.lockerProvider.name);
      headerStore.changeSubtitle(lockersStore.lockerProviderAddressAsText);
    } else {
      headerStore.changeTitle(shopsStore.shopSegment);
      headerStore.changeSubtitle(addressStore.addressLocationAsText);
    }
  });

  return (
    <BodyView>
      <ShopsList />
    </BodyView>
  );
});
