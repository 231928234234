import { observer } from 'mobx-react-lite';
import { useTranslation } from 'react-i18next';

import { BlockButton } from '@ioupie/components/buttons';
import { ErrorSnackbar } from '@ioupie/components/custom';
import { ScrollView } from '@ioupie/components/layout';
import { LinkText, Text, Title } from '@ioupie/components/typography';
import { useNavigationStore, useUserStore } from '@ioupie/hooks';

import { DeleteAccount } from './delete-account';
import * as S from './my-account.styles';

/**
 * @function MyAccountContainer
 */
export default observer(() => {
  const [t] = useTranslation();
  const userStore = useUserStore();
  const navigationStore = useNavigationStore();

  const privacyPolicyLink = 'https://ioupie.com.br/politica-de-privacidade/';
  const termsOfUseLink = 'https://ioupie.com.br/termos-de-uso/';

  return (
    <ScrollView>
      <S.Container>
        <Title>{t('containers.profile.my-account.share-data.title')}</Title>
        <S.Description>{t('containers.profile.my-account.share-data.description.label1')}</S.Description>
        <S.Description>{t('containers.profile.my-account.share-data.description.label2')}</S.Description>
        <S.Description>{t('containers.profile.my-account.share-data.description.label3')}</S.Description>
        <S.Description>
          {t('containers.profile.my-account.share-data.description.label4')}
          {t('containers.profile.my-account.share-data.description.label5')}
          <LinkText link={privacyPolicyLink} text={t('containers.profile.my-account.share-data.description.label6')} />
          {t('containers.profile.my-account.share-data.description.label7')}
          <LinkText link={termsOfUseLink} text={t('containers.profile.my-account.share-data.description.label8')} />.
        </S.Description>
        <S.Separator />
        <Title>{t('containers.profile.my-account.delete-data.title')}</Title>
        <S.Description>{t('containers.profile.my-account.delete-data.description.label1')}</S.Description>
        <S.Description>{t('containers.profile.my-account.delete-data.description.label2')}</S.Description>
        <S.Description>{t('containers.profile.my-account.delete-data.description.label3')}</S.Description>
        <S.Description>{t('containers.profile.my-account.delete-data.description.label4')}</S.Description>
        <S.Separator />
        <DeleteAccount />
        <BlockButton
          text={t('containers.profile.my-account.back-button')}
          onPress={() => navigationStore.dispatchGoBack()}
        />
        <ErrorSnackbar errors={userStore.errors} onDismiss={() => userStore.clearErrors()} />
      </S.Container>
    </ScrollView>
  );
});
