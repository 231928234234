import { injectable } from 'inversify';
import { encode, decode } from 'js-base64';

@injectable()
export class EncodeService {
  public encodePayloadToBase64<T>(payload: T): string {
    return encode(JSON.stringify(payload), true);
  }

  public decodePayloadFromBase64<T>(encoded: string): T {
    // eslint-disable-next-line @typescript-eslint/no-unsafe-return
    return JSON.parse(decode(encoded));
  }
}
