import { QueryClientProvider } from '@tanstack/react-query';
import { StatusBar } from 'expo-status-bar';
import { ReactNode, memo } from 'react';
import { SafeAreaProvider } from 'react-native-safe-area-context';

import { NavigationProvider, ThemeProvider, TranslationProvider } from '@ioupie/providers';
import { queryClient } from './client';

type MainProps = {
  readonly children: ReactNode;
};

/**
 * @function Main
 */
export default memo(({ children }: MainProps) => {
  return (
    <QueryClientProvider client={queryClient}>
      <SafeAreaProvider>
        <ThemeProvider>
          <TranslationProvider>
            <NavigationProvider>{children}</NavigationProvider>
            <StatusBar />
          </TranslationProvider>
        </ThemeProvider>
      </SafeAreaProvider>
    </QueryClientProvider>
  );
});
