export enum ServiceOrderStatus {
  DRAFT = 'DRAFT',
  WAITING_FOR_LAUNDRY_PICKUP = 'WAITING_FOR_LAUNDRY_PICKUP',
  IN_TRANSIT_TO_LAUNDRY = 'IN_TRANSIT_TO_LAUNDRY',
  PENDING_LAUNDRY_VALIDATION = 'PENDING_LAUNDRY_VALIDATION',
  PENDING_USER_CONFIRMATION = 'PENDING_USER_CONFIRMATION',
  USER_PAYMENT_DECLINED = 'USER_PAYMENT_DECLINED',
  PENDING_PAYMENT_CONFIRMATION = 'PENDING_PAYMENT_CONFIRMATION',
  IN_LAUNDRY_PENDING_OS_NUMBER = 'IN_LAUNDRY_PENDING_OS_NUMBER',
  IN_LAUNDRY_WASHING = 'IN_LAUNDRY_WASHING',
  IN_LAUNDRY_REJECTED = 'IN_LAUNDRY_REJECTED',
  READY_FOR_DELIVERY = 'READY_FOR_DELIVERY',
  IN_TRANSIT_TO_LOCKER = 'IN_TRANSIT_TO_LOCKER',
  WAITING_FOR_CUSTOMER_PICKUP = 'WAITING_FOR_CUSTOMER_PICKUP',
  DELIVERED_TO_CUSTOMER = 'DELIVERED_TO_CUSTOMER',
  CANCELLED = 'CANCELLED',
}

export type GenericOrderStatus = {
  readonly name: string;
  readonly final: boolean;
  readonly next: readonly GenericOrderStatus[];
};

export enum OrderStatusNames {
  DRAFT = 'Draft',
  PENDING_PROVIDER_ACKNOWLEDGEMENT = 'PendingProviderAcknowledgement',
  PENDING_PROVIDER_INGESTION = 'PendingProviderIngestion',
  PROVIDER_ANALYZING_REQUEST = 'ProviderAnalyzingRequest',
  PENDING_PAYMENT = 'PendingPayment',
  PENDING_PROVIDER_OS = 'PendingProviderOS',
  IN_PROGRESS = 'InProgress',
  READY_FOR_DELIVERY = 'ReadyForDelivery',
  IN_TRANSIT = 'InTransit',
  PENDING_CUSTOMER_PICKUP = 'PendingCustomerPickup',
  SUCCESS = 'Success',
  CANCELLED = 'Cancelled',
  REJECTED = 'Rejected',
}

const SuccessStatus: GenericOrderStatus = { name: OrderStatusNames.SUCCESS, final: true, next: [] };
const CancelledStatus: GenericOrderStatus = { name: OrderStatusNames.CANCELLED, final: true, next: [] };
const RejectedStatus: GenericOrderStatus = { name: OrderStatusNames.REJECTED, final: true, next: [] };
const PendingCustomerPickupStatus: GenericOrderStatus = {
  name: OrderStatusNames.PENDING_CUSTOMER_PICKUP,
  final: false,
  next: [SuccessStatus],
};
const InTransitStatus: GenericOrderStatus = {
  name: OrderStatusNames.IN_TRANSIT,
  final: false,
  next: [PendingCustomerPickupStatus],
};
const ReadyForDeliveryStatus: GenericOrderStatus = {
  name: OrderStatusNames.READY_FOR_DELIVERY,
  final: false,
  next: [InTransitStatus],
};
const InProgressStatus: GenericOrderStatus = {
  name: OrderStatusNames.IN_PROGRESS,
  final: false,
  next: [ReadyForDeliveryStatus],
};
const PendingProviderOsStatus: GenericOrderStatus = {
  name: OrderStatusNames.PENDING_PROVIDER_OS,
  final: false,
  next: [InProgressStatus],
};
const PendingPaymentStatus: GenericOrderStatus = {
  name: OrderStatusNames.PENDING_PAYMENT,
  final: false,
  next: [PendingProviderOsStatus, RejectedStatus],
};
const ProviderAnalyzingRequestStatus: GenericOrderStatus = {
  name: OrderStatusNames.PROVIDER_ANALYZING_REQUEST,
  final: false,
  next: [PendingPaymentStatus],
};
const PendingProviderIngestionStatus: GenericOrderStatus = {
  name: OrderStatusNames.PENDING_PROVIDER_INGESTION,
  final: false,
  next: [ProviderAnalyzingRequestStatus],
};
const PendingProviderAcknowledgementStatus: GenericOrderStatus = {
  name: OrderStatusNames.PENDING_PROVIDER_ACKNOWLEDGEMENT,
  final: false,
  next: [PendingProviderIngestionStatus],
};
const DraftStatus: GenericOrderStatus = {
  name: OrderStatusNames.DRAFT,
  final: false,
  next: [PendingProviderIngestionStatus, PendingProviderAcknowledgementStatus, CancelledStatus],
};

export const ServiceOrderStatusV2: { readonly [key: string]: GenericOrderStatus } = Object.freeze({
  [OrderStatusNames.DRAFT]: DraftStatus,
  [OrderStatusNames.PENDING_PROVIDER_ACKNOWLEDGEMENT]: PendingProviderAcknowledgementStatus,
  [OrderStatusNames.PENDING_PROVIDER_INGESTION]: PendingProviderIngestionStatus,
  [OrderStatusNames.PROVIDER_ANALYZING_REQUEST]: ProviderAnalyzingRequestStatus,
  [OrderStatusNames.PENDING_PAYMENT]: PendingPaymentStatus,
  [OrderStatusNames.PENDING_PROVIDER_OS]: PendingProviderOsStatus,
  [OrderStatusNames.IN_PROGRESS]: InProgressStatus,
  [OrderStatusNames.READY_FOR_DELIVERY]: ReadyForDeliveryStatus,
  [OrderStatusNames.IN_TRANSIT]: InTransitStatus,
  [OrderStatusNames.PENDING_CUSTOMER_PICKUP]: PendingCustomerPickupStatus,
  [OrderStatusNames.SUCCESS]: SuccessStatus,
  [OrderStatusNames.CANCELLED]: CancelledStatus,
  [OrderStatusNames.REJECTED]: RejectedStatus,
});
