import { yupResolver } from '@hookform/resolvers/yup';
import { observer } from 'mobx-react-lite';
import { Fragment, useCallback, useEffect } from 'react';
import { useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { Keyboard, TouchableOpacity, View } from 'react-native';
import * as yup from 'yup';

import { BlockButton } from '@ioupie/components/buttons';
import { ErrorSnackbar, UserStep } from '@ioupie/components/custom';
import { TextInput } from '@ioupie/components/inputs';
import { TooltipBar } from '@ioupie/components/layout';
import { Bold, HelperText, Text, Title } from '@ioupie/components/typography';
import { useAuthStore, useNavigationStore } from '@ioupie/hooks';
import { routes } from '@ioupie/shared/constants';
import { debounceOneSecond } from '@ioupie/shared/utils';

import { CheckUserForm } from './check-user.models';

/**
 * @function CheckUserStepComponent
 */
export default observer(() => {
  const [t] = useTranslation();
  const authStore = useAuthStore();
  const navigationStore = useNavigationStore();

  const checkUserSchema: yup.SchemaOf<CheckUserForm> = yup.object().shape({
    userEmail: yup
      .string()
      .required(t('forms.check-user.email.errors.required'))
      .email(t('forms.check-user.email.errors.email')),
  });

  const {
    register,
    handleSubmit,
    setValue,
    formState: { errors, isValid },
  } = useForm<CheckUserForm>({
    defaultValues: {
      userEmail: '',
    },
    resolver: yupResolver(checkUserSchema),
  });

  useEffect(() => {
    register('userEmail');
  }, [register]);

  const onSubmitValidForm = useCallback((form: CheckUserForm) => {
    Keyboard.dismiss();
    return authStore.checkUsername(form.userEmail);
  }, []);

  const CheckUserContent = (
    <View>
      <Title>{t('forms.check-user.title')}</Title>
      <Text>{t('forms.check-user.instruction')}</Text>
      <TextInput
        autoCorrect={false}
        autoCapitalize="none"
        autoCompleteType="email"
        keyboardType="email-address"
        textContentType="emailAddress"
        error={Boolean(errors.userEmail)}
        label={t('forms.check-user.email.label')}
        placeholder={t('forms.check-user.email.placeholder')}
        onChangeText={debounceOneSecond((text: string) => {
          setValue('userEmail', text.trim().toLocaleLowerCase(), { shouldValidate: true });
        })}
      />
      <HelperText type="error" visible={Boolean(errors.userEmail)}>
        {errors.userEmail?.message ?? ''}
      </HelperText>
    </View>
  );

  const CheckUserActions = (
    <BlockButton
      blacken
      disabled={!isValid}
      loading={authStore.loading}
      text={t('forms.check-user.submit')}
      onPress={handleSubmit(onSubmitValidForm)}
    />
  );

  const CheckUserTooltip = (
    <TooltipBar center>
      <TouchableOpacity onPress={() => navigationStore.dispatchNavigation({ stack: routes.stacks.help })}>
        <Bold black>{t('containers.account.check-user.need-help')}</Bold>
      </TouchableOpacity>
    </TooltipBar>
  );

  return (
    <Fragment>
      <UserStep content={CheckUserContent} actions={CheckUserActions} tooltip={CheckUserTooltip} />
      <ErrorSnackbar errors={authStore.errors} onDismiss={() => authStore.clearErrors()} />
    </Fragment>
  );
});
