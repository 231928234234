import { memo, useCallback } from 'react';

import { SwitchButtonProps } from './switch-button.models';
import * as S from './switch-button.styles';

/**
 * @function SwitchButtonComponent
 */
export default memo((props: SwitchButtonProps) => {
  const { text, value = false, disabled = false, onValueChange } = props;

  const switchValue = useCallback(() => {
    onValueChange(!value);
  }, [value]);

  return (
    <S.Container>
      <S.Title>{text}</S.Title>
      <S.Switcher value={value} disabled={disabled} onValueChange={switchValue} />
    </S.Container>
  );
});
