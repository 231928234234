import { observer } from 'mobx-react-lite';
import { Fragment, useEffect, useState } from 'react';
import { useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';

import { BlockButton, IconButton } from '@ioupie/components/buttons';
import { Dialog, ErrorSnackbar } from '@ioupie/components/custom';
import { AreaInput } from '@ioupie/components/inputs';
import { Portal, ScrollView } from '@ioupie/components/layout';
import { Text, Title } from '@ioupie/components/typography';
import { useNavigationStore, useOrdersStore } from '@ioupie/hooks';
import { colors, routes } from '@ioupie/shared/constants';

import logoImage from './icon-android.png';
import { NpsEvaluationForm } from './nps-evaluation.models';
import * as S from './nps-evaluation.styles';
import { ServiceButtons } from './service-buttons';

/**
 * @function NpsEvaluationContainer
 */
export default observer(() => {
  const [t] = useTranslation();

  const ordersStore = useOrdersStore();
  const navigationStore = useNavigationStore();

  const [displayThankDialog, setDisplayThankDialog] = useState(false);

  const { register, handleSubmit, setValue, watch } = useForm<NpsEvaluationForm>({
    defaultValues: {
      score: undefined,
      highlights: [],
      comment: '',
    },
  });

  const MAX_SCORE = 5;
  const likedServices = ['app', 'prazo_de_lavagem', 'prazo_de_coleta_ou_entrega', 'atendimento'];
  const improveServices = ['app', 'prazo_de_lavagem', 'prazo_de_coleta_ou_entrega', 'atendimento', 'pagamento'];

  useEffect(() => {
    register('score' as const);
    register('highlights' as const);
    register('comment' as const);
  }, [register]);

  const handleGoHome = () => {
    setDisplayThankDialog(false);
    navigationStore.dispatchNavigation({
      stack: routes.stacks.orders,
      screen: routes.pages.orders.home,
    });
  };

  const onServicePressed = (service: string) => {
    const isServiceSelected = watch('highlights').find((highlight) => highlight === service) !== undefined;
    isServiceSelected
      ? setValue('highlights', [...watch('highlights').filter((highlight) => highlight !== service)])
      : setValue('highlights', [...watch('highlights'), service]);
  };

  const handleNpsEvaluationSubmit = async (form: NpsEvaluationForm) => {
    await ordersStore.sendNpsEvaluation(form);
    setDisplayThankDialog(true);
  };

  const likedSection = (
    <ServiceButtons
      title={t('containers.orders.nps-evaluation.liked-title')}
      highlights={likedServices}
      highlightedList={watch('highlights')}
      onPress={onServicePressed}
    />
  );

  const improveSection = (
    <ServiceButtons
      title={t('containers.orders.nps-evaluation.improve-title')}
      highlights={improveServices}
      highlightedList={watch('highlights')}
      onPress={onServicePressed}
    />
  );

  const commentSection = (
    <Fragment>
      <S.SubTitle>{t('containers.orders.nps-evaluation.comment-title')}</S.SubTitle>
      <S.CommentBox>
        <AreaInput
          placeholder={t('containers.orders.nps-evaluation.comment-placeholder')}
          value={watch('comment')}
          onChangeText={(text) => {
            setValue('comment', text);
          }}
        />
      </S.CommentBox>
    </Fragment>
  );

  return (
    <Fragment>
      <ScrollView>
        <S.NpsEvaluationContainer>
          <S.NpsEvaluationTitle>
            <Title>{t('containers.orders.nps-evaluation.title')}</Title>
          </S.NpsEvaluationTitle>
          <S.SubTitle>{t('containers.orders.nps-evaluation.score-title')}</S.SubTitle>
          <S.ScoreContainer>
            {Array.from({ length: MAX_SCORE }).map((_, index) => (
              <IconButton
                key={`star-rating-${index + 1}`}
                icon="star"
                onPress={() => {
                  setValue('score', index + 1);
                  setValue('highlights', []);
                }}
                iconColor={index < watch('score') ? colors.light.primary : colors.light.text}
                size={40}
              />
            ))}
          </S.ScoreContainer>
          <S.Separator />
          {watch('score') !== undefined ? (watch('score') === MAX_SCORE ? likedSection : improveSection) : undefined}
          <S.Separator />
          {commentSection}
        </S.NpsEvaluationContainer>
      </ScrollView>

      <S.EvaluationButton
        disabled={watch('score') === undefined}
        text={t('containers.orders.nps-evaluation.rate-button')}
        onPress={handleSubmit(handleNpsEvaluationSubmit)}
      />
      <S.NoEvaluationButton onPress={handleGoHome}>
        {t('containers.orders.nps-evaluation.rate-later-button')}
      </S.NoEvaluationButton>
      <ErrorSnackbar errors={ordersStore.errors} onDismiss={() => ordersStore.clearErrors()} />

      <Portal>
        <Dialog.View visible={displayThankDialog} onDismiss={handleGoHome}>
          <Dialog.Title>Ioupie!</Dialog.Title>
          <Dialog.Content>
            <S.ThankContainer>
              <S.ImageContainer>
                <S.ImageView>
                  <S.IoupieImage accessibilityIgnoresInvertColors source={logoImage} />
                </S.ImageView>
                <S.ThankText>{t('containers.orders.nps-evaluation.thank-modal.sentence1')}</S.ThankText>
              </S.ImageContainer>
              <Text>{t('containers.orders.nps-evaluation.thank-modal.sentence2')}</Text>
            </S.ThankContainer>
          </Dialog.Content>
          <Dialog.Actions>
            <S.CloseButtonContainer>
              <BlockButton
                text={t('containers.orders.nps-evaluation.thank-modal.close-button')}
                onPress={handleGoHome}
              />
            </S.CloseButtonContainer>
          </Dialog.Actions>
        </Dialog.View>
      </Portal>
    </Fragment>
  );
});
