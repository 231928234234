import { memo } from 'react';

import { EntityInfoProps } from './entity-info.models';
import * as S from './entity-info.styles';

/**
 * @function EntityInfoComponent
 */
export default memo(({ image, name, details }: EntityInfoProps) => (
  <S.EntityContainer>
    <S.EntityImage image={image} />
    <S.EntityInformation>
      <S.EntityName>{name}</S.EntityName>
      <S.EntityDetails>{details}</S.EntityDetails>
    </S.EntityInformation>
  </S.EntityContainer>
));
