import { DateTime } from 'luxon';
import { memo, useEffect, useState } from 'react';
import { Calendar, CalendarTheme, DateObject, DotMarking } from 'react-native-calendars';

import { useTheme } from '@ioupie/hooks';
import { dateAsISO, dateAsNormalizedString, getDaysInMonth, isWeekend, today } from '@ioupie/shared/utils';

import { CalendarProps, MonthYearPair } from './calendar.models';
import { styles } from './calendar.styles';
import {
  calendarDarkTheme,
  calendarLightTheme,
  currentDarkStyles,
  currentDayLightStyles,
  disabledDarkStyles,
  disabledLightStyles,
  selectedDarkStyles,
  selectedLightStyles,
} from './modes';

/**
 * @function CalendarComponent
 */
export default memo((props: CalendarProps) => {
  const {
    startDate = '',
    maxDate = '',
    currentDate,
    style,
    disableDatesUntil,
    disablePreviousMonth,
    disableNextMonth,
    disableWeekends,
    hideExtraDays,
    hideHeader,
    headerText,
    nonBusinessDays,
    unavailablePeriods,
    onDateChange = (): void => undefined,
  } = props;

  const theme = useTheme();

  const calendarTheme: CalendarTheme = theme.mode === 'light' ? calendarLightTheme : calendarDarkTheme;
  const currentDayProps: DotMarking = theme.mode === 'light' ? currentDayLightStyles : currentDarkStyles;
  const selectedProps: DotMarking = theme.mode === 'light' ? selectedLightStyles : selectedDarkStyles;
  const disabledProps: DotMarking = theme.mode === 'light' ? disabledLightStyles : disabledDarkStyles;

  const weekendsOfMonth = (pair: MonthYearPair): readonly string[] => {
    return getDaysInMonth(pair.month, pair.year).filter(isWeekend).map(dateAsNormalizedString);
  };

  const disableDates = (dates: readonly string[]): Record<string, DotMarking> => {
    return dates.reduce((record, dateToDisable) => {
      return { ...record, [dateToDisable]: disabledProps };
    }, {});
  };

  const handleDisableWeekends = (pair: MonthYearPair): Record<string, DotMarking> =>
    disableWeekends ? disableDates(weekendsOfMonth(pair)) : {};

  const [selectedDate, setSelectedDate] = useState<Record<string, DotMarking>>({});
  const [monthCounter, setMonthCounter] = useState(0);

  useEffect(() => {
    // initial dates bootstrap
    const now = DateTime.now();
    const presentDay = today();

    const parsedWeekends = handleDisableWeekends({
      month: now.get('month'),
      year: now.get('year'),
    });
    const parsedNonBusinessDays = nonBusinessDays ? disableDates(nonBusinessDays) : {};
    const parsedUnavailablePeriods = unavailablePeriods ? disableDates(unavailablePeriods) : {};

    // First values filled
    setSelectedDate({
      [presentDay]: currentDayProps,
      [startDate]: selectedProps,
      ...parsedWeekends,
      ...parsedNonBusinessDays,
      ...parsedUnavailablePeriods,
    });
  }, []);

  const disableArrowLeft = disablePreviousMonth && monthCounter === 0;
  const disableArrowRight = disableNextMonth && monthCounter === 0;
  const staticMonth = disableArrowLeft && disableArrowRight;

  const headerContent = hideHeader ? '' : headerText;
  const headerStyle = hideHeader ? styles.headless : undefined;

  return (
    <Calendar
      theme={calendarTheme}
      current={currentDate}
      style={style}
      minDate={disableDatesUntil}
      maxDate={maxDate}
      markedDates={selectedDate}
      disableArrowLeft={disableArrowLeft}
      disableArrowRight={disableArrowRight}
      disableMonthChange={staticMonth}
      enableSwipeMonths
      hideArrows={staticMonth}
      hideExtraDays={hideExtraDays}
      monthFormat={headerContent}
      headerStyle={headerStyle}
      onPressArrowLeft={(subtractMonth: Function): void => {
        setMonthCounter((prev) => prev - 1);
        subtractMonth();
      }}
      onPressArrowRight={(addMonth: Function): void => {
        setMonthCounter((prev) => prev + 1);
        addMonth();
      }}
      onMonthChange={(calendarDate: DateObject): void => {
        const parsedWeekends = handleDisableWeekends(calendarDate);
        setSelectedDate((prev) => ({
          ...prev,
          ...parsedWeekends,
        }));
      }}
      onDayPress={(calendarDate: DateObject): void => {
        const presentDay = today();
        const parsedWeekends = handleDisableWeekends(calendarDate);

        // remove props to ignore
        const { dateString, timestamp, ...dateObject } = calendarDate;
        const parsedDate = DateTime.fromObject(dateObject).toJSDate();

        // Avoid selecting weekends at all
        const shouldIgnore = disableWeekends && isWeekend(parsedDate);

        if (!shouldIgnore) {
          setSelectedDate({
            [presentDay]: currentDayProps,
            [dateString]: selectedProps,
            ...parsedWeekends,
          });

          onDateChange(dateAsISO(parsedDate));
        }
      }}
    />
  );
});
