import { useLocalObservable } from 'mobx-react-lite';

import { container } from '@ioupie/injection';
import { STORE_TYPES } from '@ioupie/shared/constants';
import { SegmentsStore } from '@ioupie/stores';

export const useSegmentsStore = (): SegmentsStore => {
  return useLocalObservable(() => {
    return container.get(STORE_TYPES.SEGMENTS);
  });
};
