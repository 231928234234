import styled from 'styled-components/native';

import { Icon } from '@ioupie/components/gallery';

export const FilterContainer = styled.View`
  margin: 6px 20px;
`;

export const RadioContainer = styled.View`
  margin: 10px 0px 0px 0px;
`;

export const FilterHeader = styled.TouchableOpacity`
  flex-direction: row;
  align-items: center;
`;

export const FilterIcon = styled(Icon)`
  margin-right: 20px;
`;
