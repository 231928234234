export enum ORDER_STATUS {
  DRAFT = 'Draft',
  PENDING_PROVIDER_ACKNOWLEDGEMENT = 'PendingProviderAcknowledgement',
  PENDING_PROVIDER_INGESTION = 'PendingProviderIngestion',
  PROVIDER_ANALYZING_REQUEST = 'ProviderAnalyzingRequest',
  PENDING_PAYMENT = 'PendingPayment',
  PENDING_PROVIDER_OS = 'PendingProviderOS',
  IN_PROGRESS = 'InProgress',
  READY_FOR_DELIVERY = 'ReadyForDelivery',
  IN_TRANSIT = 'InTransit',
  PENDING_CUSTOMER_PICKUP = 'PendingCustomerPickup',
  SUCCESS = 'Success',
  CANCELLED = 'Cancelled',
  REJECTED = 'Rejected',
}

export const ORDER_STATUS_TRANSLATIONS: Record<ORDER_STATUS, string> = {
  [ORDER_STATUS.DRAFT]: 'constants.orders.status.draft',
  [ORDER_STATUS.PENDING_PROVIDER_ACKNOWLEDGEMENT]:
    'constants.orders.status.pending-provider-acknowledge.{businessType}',
  [ORDER_STATUS.PENDING_PROVIDER_INGESTION]: 'constants.orders.status.pending-provider-ingestion',
  [ORDER_STATUS.PROVIDER_ANALYZING_REQUEST]: 'constants.orders.status.provider-analyzing-request',
  [ORDER_STATUS.PENDING_PAYMENT]: 'constants.orders.status.pending-payment',
  [ORDER_STATUS.PENDING_PROVIDER_OS]: 'constants.orders.status.pending-provider-os',
  [ORDER_STATUS.IN_PROGRESS]: 'constants.orders.status.in-progress',
  [ORDER_STATUS.READY_FOR_DELIVERY]: 'constants.orders.status.ready-for-delivery',
  [ORDER_STATUS.IN_TRANSIT]: 'constants.orders.status.in-transit',
  [ORDER_STATUS.PENDING_CUSTOMER_PICKUP]: 'constants.orders.status.pending-customer-pickup',
  [ORDER_STATUS.SUCCESS]: 'constants.orders.status.success',
  [ORDER_STATUS.CANCELLED]: 'constants.orders.status.cancelled',
  [ORDER_STATUS.REJECTED]: 'constants.orders.status.rejected',
} as const;

export const ORDER_SUCCESSFUL_STATUSES = [
  ORDER_STATUS.PENDING_PROVIDER_ACKNOWLEDGEMENT,
  ORDER_STATUS.PENDING_PROVIDER_INGESTION,
  ORDER_STATUS.PROVIDER_ANALYZING_REQUEST,
  ORDER_STATUS.PENDING_PROVIDER_OS,
  ORDER_STATUS.IN_PROGRESS,
  ORDER_STATUS.READY_FOR_DELIVERY,
  ORDER_STATUS.IN_TRANSIT,
] as const;

export const ORDER_CLOSED_STATUSES = [ORDER_STATUS.REJECTED, ORDER_STATUS.CANCELLED, ORDER_STATUS.SUCCESS] as const;

export const ORDER_PENDING_USER_ACTION_STATUSES = [
  ORDER_STATUS.DRAFT,
  ORDER_STATUS.PENDING_PAYMENT,
  ORDER_STATUS.PENDING_CUSTOMER_PICKUP,
] as const;

export const ORDER_REJECTED_STATUSES = [ORDER_STATUS.REJECTED] as const;

export enum ORDER_STATUS_CATEGORY {
  ALL = 'ALL',
  DONE = 'DONE',
  PENDING = 'PENDING',
}

export enum ORDER_STEPS {
  FINISH_ORDER = 'Aprovar',
  SCHEDULE = 'Agendamento',
  APPROVED = 'Aprovado',
  REJECTED = 'Rejeitado',
}

export enum ORDERING_STEPS {
  BASKET = 'Cesto',
  SCHEDULE = 'Agendamento',
  CALENDAR = 'Calendario',
  PAYMENT = 'Pagamento',
  SUMMARY = 'Resumo',
}

export enum ORDER_TRACKING_SCREENS {
  DETAILS = 'Home',
  PENDING_PAYMENT = 'PendentePagamento',
  ACTIVATE_TICKET = 'AtivarTicket',
  PICKUP = 'RetirarPedido',
}

export enum ORDER_PERIOD {
  MORNING = 'MORNING',
  AFTERNOON = 'AFTERNOON',
  EVENING = 'EVENING',
}

export const PERIOD_TIME_MAPPER: Record<ORDER_PERIOD, number> = {
  [ORDER_PERIOD.MORNING]: 4,
  [ORDER_PERIOD.AFTERNOON]: 12,
  [ORDER_PERIOD.EVENING]: 20,
} as const;

export enum THANK_TYPE {
  TICKET_GENERATED = 'TICKET_GENERATED',
  TICKET_ACTIVATED = 'TICKET_ACTIVATED',
  TICKET_FINISHED = 'TICKET_FINISHED',
}

export enum DISCOUNT_TYPE {
  COUPON = 'COUPON',
}

export enum FEE_TYPE {
  PICK_UP = 'Pickup',
}

export enum NPS_LIKED_SERVICE_TYPE {
  APP = 'APP',
  PRAZO_DE_LAVAGEM = 'PRAZO_DE_LAVAGEM',
  PRAZO_DE_COLETA_OU_ENTREGA = 'PRAZO_DE_COLETA_OU_ENTREGA',
  ATENDIMENTO = 'ATENDIMENTO',
}

export enum NPS_IMPROVE_SERVICE_TYPE {
  APP = 'APP',
  PRAZO_DE_LAVAGEM = 'PRAZO_DE_LAVAGEM',
  PRAZO_DE_COLETA_OU_ENTREGA = 'PRAZO_DE_COLETA_OU_ENTREGA',
  ATENDIMENTO = 'ATENDIMENTO',
  PAGAMENTO = 'PAGAMENTO',
}
