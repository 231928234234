import { memo } from 'react';

import { BodyView } from '@ioupie/components/layout';
import { ShopCatalog } from '@ioupie/containers/catalog';
import { useCurrentRouteFocus, useHeaderStore } from '@ioupie/hooks';
import { routes } from '@ioupie/shared/constants';

/**
 * @function CatalogPage
 */
export default memo(() => {
  const headerStore = useHeaderStore();

  useCurrentRouteFocus(routes.pages.orders.catalog, () => {
    headerStore.allowNavigateBack(true);
    headerStore.removeHeaderShadow(false);
    headerStore.changeTitle('pages.catalog.title');
  });

  return (
    <BodyView>
      <ShopCatalog />
    </BodyView>
  );
});
