import { ServiceProviderStatus, routes } from '@ioupie/models-commons';
import { DateTime } from 'luxon';
import { observer } from 'mobx-react-lite';
import { Fragment } from 'react';
import { useTranslation } from 'react-i18next';

import { Icon } from '@ioupie/components/gallery';
import { BodyView, ScrollView } from '@ioupie/components/layout';
import { Bold, Text, Title } from '@ioupie/components/typography';
import { useCurrentRouteFocus, useHeaderStore, useNavigationStore, useShopsStore } from '@ioupie/hooks';
import { ServiceProviderDropOffMetadata } from '@ioupie/shared/models';

import * as S from './shop-details.styles';

/**
 * @function ShopDetailsPage
 */
export default observer(() => {
  const [t] = useTranslation();
  const headerStore = useHeaderStore();
  const navigationStore = useNavigationStore();
  const shopsStore = useShopsStore();

  useCurrentRouteFocus(routes.pages.orders.shop_details, () => {
    headerStore.allowNavigateBack(true);
    headerStore.removeHeaderShadow(false);
    headerStore.changeTitle('pages.shop_details.title', { name: shopsStore.viewProviderDetails?.name });
    headerStore.changeSubtitle('pages.shop_details.subtitle', { address: shopsStore.viewProviderDetails?.address });
    headerStore.onClickBack(() => {
      navigationStore.dispatchGoBack();
    });
  });

  if (!shopsStore.viewProviderDetails) {
    return <Fragment />;
  }

  const providerDetails = shopsStore.viewProviderDetails;

  const sinceDate = new Date(providerDetails.creationTimestamp!).getTime();

  const { metadata = {} } = providerDetails;
  const emptyDropOffMetadata = {} as ServiceProviderDropOffMetadata;
  const { description, dropOff = emptyDropOffMetadata } = metadata ?? {};
  const { physicalAddress = {} } = dropOff;

  return (
    <BodyView>
      <ScrollView>
        <S.Container>
          <S.ProviderHeader>
            <S.ShopDetailsImage image={providerDetails.image} />
            <S.ProviderHeaderDetails>
              <S.ProviderTitle>{providerDetails.name}</S.ProviderTitle>
              <Text>
                {t('containers.delivery.provider-detail.header.taxpayerId', {
                  cnpj: providerDetails.taxpayerId ?? 'N/A',
                })}
              </Text>
            </S.ProviderHeaderDetails>
          </S.ProviderHeader>

          {providerDetails.status === ServiceProviderStatus.COMING_SOON && (
            <Fragment>
              <S.NotActiveBannerContainer>
                <S.NotActiveBannerHeader>
                  <S.PaddedIcon name="store-cog" size={18} />
                  <S.SubtitleWithoutDivider>
                    {t('containers.delivery.provider-detail.banners.coming-soon.title')}
                  </S.SubtitleWithoutDivider>
                </S.NotActiveBannerHeader>
                <S.DetailText>{t('containers.delivery.provider-detail.banners.coming-soon.description')}</S.DetailText>
              </S.NotActiveBannerContainer>
            </Fragment>
          )}

          {providerDetails.status === ServiceProviderStatus.TEMPORARILY_UNAVAILABLE && (
            <Fragment>
              <S.NotActiveBannerContainer>
                <S.NotActiveBannerHeader>
                  <S.PaddedIcon name="close-circle" color="#d61c1c" size={18} />
                  <S.SubtitleWithoutDivider>
                    {t('containers.delivery.provider-detail.banners.temporarily-unavailable.title')}
                  </S.SubtitleWithoutDivider>
                </S.NotActiveBannerHeader>
                <S.DetailText>
                  {t('containers.delivery.provider-detail.banners.temporarily-unavailable.description')}
                </S.DetailText>
              </S.NotActiveBannerContainer>
            </Fragment>
          )}

          <S.Subtitle>{t('containers.delivery.provider-detail.about.title')}</S.Subtitle>
          <S.DetailText>
            {t('containers.delivery.provider-detail.about.since', {
              date: `${
                DateTime.fromMillis(+sinceDate).monthLong ??
                DateTime.fromMillis(+sinceDate).monthShort ??
                `0${DateTime.fromMillis(+sinceDate).month}`.slice(-2)
              }/${DateTime.fromMillis(+sinceDate).year}`,
            })}
          </S.DetailText>
          {description && <Text>{description}</Text>}

          <S.Subtitle>{t('containers.delivery.provider-detail.address.title')}</S.Subtitle>
          {providerDetails.eligible ? (
            <S.ProviderEligibleLabel>
              <Icon name="check-bold" color="#29a11a" size={16} />
              <S.ProviderEligibleText>
                {t('containers.delivery.provider-detail.address.eligible-label')}
              </S.ProviderEligibleText>
            </S.ProviderEligibleLabel>
          ) : (
            <S.ProviderNotEligibleLabel>
              <Icon name="block-helper" color="#d61c1c" size={14} />
              <S.ProviderNotEligibleText>
                {t('containers.delivery.provider-detail.address.not-eligible-label')}
              </S.ProviderNotEligibleText>
            </S.ProviderNotEligibleLabel>
          )}
          {dropOff.dropOffPoint && (
            <Fragment>
              <S.DetailText>{t('containers.delivery.provider-detail.address.drop-off-point.disclaimer')}</S.DetailText>
              <S.DetailText>
                {t('containers.delivery.provider-detail.address.drop-off-point.parent-company-disclaimer')}
              </S.DetailText>
              <Bold>{dropOff.parentCompany ?? ''}</Bold>
            </Fragment>
          )}
          {physicalAddress.line1 && <Text>{physicalAddress.line1}</Text>}
          {physicalAddress.line2 && <Text>{physicalAddress.line2}</Text>}
          {physicalAddress.line3 && <Text>{physicalAddress.line3}</Text>}

          <S.ProviderResponsibilityDisclaimer>
            {t('containers.delivery.provider-detail.provider-responsibility.disclaimer')}
          </S.ProviderResponsibilityDisclaimer>
        </S.Container>
      </ScrollView>
    </BodyView>
  );
});
