import { observer } from 'mobx-react-lite';
import { Fragment, useCallback } from 'react';
import { useTranslation } from 'react-i18next';

import { Loader } from '@ioupie/components/custom';
import { Divider } from '@ioupie/components/layout/divider';
import { Text } from '@ioupie/components/typography';
import { useOrdersStore } from '@ioupie/hooks';
import { CUR_LOCATE, DISCOUNT_TYPE, FEE_TYPE } from '@ioupie/shared/constants';
import { OrderProductEntry } from '@ioupie/shared/models';

import * as S from './products-details.styles';

/**
 * @function ProductsDetailsContainer
 */
export default observer(() => {
  const [t] = useTranslation();
  const ordersStore = useOrdersStore();

  const {
    products = [],
    grandTotal,
    comments,
    orderLevelDiscounts = [],
    orderFees = [],
    orderNumber,
  } = ordersStore.selectedOrderHistory ?? {};
  const { amount = 0 } = grandTotal ?? {};
  const { partnerToCustomer = '' } = comments ?? {};

  const coupon = orderLevelDiscounts.find(({ type }) => type === DISCOUNT_TYPE.COUPON);
  const { totalAmount, metadata: couponMetadata } = coupon ?? {};
  const { amount: couponAmount = 0 } = totalAmount ?? {};
  const { redemptionKey = '' } = couponMetadata ?? {};

  const pickUpFee = orderFees.find(({ type }) => type === FEE_TYPE.PICK_UP);
  const { amount: pickUpAmount } = pickUpFee ?? {};
  const { amount: pickUpAmountValue = 0 } = pickUpAmount ?? {};

  const renderProductItem = useCallback((product: OrderProductEntry) => {
    const { name, unitPrice, additionalCharges, quantity = 0 } = product ?? {};
    const { amount: chargesAmount = 0 } = additionalCharges ?? {};
    const { amount: unitAmount = 0 } = unitPrice ?? {};

    const extraCharges = chargesAmount > 0 ? ` + ${chargesAmount.toFixed(2)}` : '';
    const subtitle = `${t(CUR_LOCATE)} ${(unitAmount * quantity).toFixed(2)} ${extraCharges}`;

    const quantityTranslationKey = quantity === 1 ? 'item-quantity-single' : 'item-quantity-multiple';

    return (
      <S.ProductEntry>
        <S.TotalPriceItemDetails>
          <S.TotalPriceItemName>{name}</S.TotalPriceItemName>
          <S.TotalPriceItemQuantity>
            {t(`containers.orders.track-order.products-details.${quantityTranslationKey}`, { quantity })}
          </S.TotalPriceItemQuantity>
        </S.TotalPriceItemDetails>
        <Text>{subtitle}</Text>
      </S.ProductEntry>
    );
  }, []);

  return (
    <Fragment>
      <S.ProductsDetailsContainer>
        <S.ProductListTitle>
          {t('containers.orders.track-order.products-details.title')} {orderNumber}
        </S.ProductListTitle>
        {Boolean(partnerToCustomer) && (
          <S.JustificationContainer>
            <S.JustificationTitle>{t('containers.orders.track-order.products-details.comment')}</S.JustificationTitle>
            <S.Justification>{partnerToCustomer}</S.Justification>
          </S.JustificationContainer>
        )}
        <S.ProductList>{products.map(renderProductItem)}</S.ProductList>
        <Divider />
        <S.TotalPriceContainer>
          {couponAmount > 0 ? (
            <S.AmountContainer>
              <S.Amount>
                {t('containers.orders.track-order.products-details.coupon-price')}
                {redemptionKey && `: ${redemptionKey}`}
              </S.Amount>
              <S.Amount>{`- ${t(CUR_LOCATE)} ${couponAmount.toFixed(2)}`}</S.Amount>
            </S.AmountContainer>
          ) : undefined}
          {pickUpAmountValue > 0 ? (
            <S.AmountContainer>
              <S.Amount>{t('containers.orders.track-order.products-details.pickup-price')}</S.Amount>
              <S.Amount>{`${t(CUR_LOCATE)} ${pickUpAmountValue.toFixed(2)}`}</S.Amount>
            </S.AmountContainer>
          ) : undefined}
          <S.AmountContainer>
            <S.TotalPriceBold>{t('containers.orders.track-order.products-details.total-price')}</S.TotalPriceBold>
            <S.TotalPriceBold>
              {t(CUR_LOCATE)} {amount.toFixed(2)}
            </S.TotalPriceBold>
          </S.AmountContainer>
        </S.TotalPriceContainer>
      </S.ProductsDetailsContainer>
      <Loader show={ordersStore.loading} />
    </Fragment>
  );
});
