import { observer } from 'mobx-react-lite';
import { Fragment } from 'react';
import { useTranslation } from 'react-i18next';

import { BlockButton } from '@ioupie/components/buttons';
import { useNavigationStore, useShopsStore } from '@ioupie/hooks';
import { routes } from '@ioupie/shared/constants';

import * as S from './ticket-finished.styles';

/**
 * @function TicketFinishedContainer
 */
export default observer(() => {
  const [t] = useTranslation();
  const navigationStore = useNavigationStore();

  const handleGoHome = (): void => {
    navigationStore.dispatchNavigation({
      stack: routes.stacks.orders,
      screen: routes.pages.orders.home,
    });
  };

  return (
    <Fragment>
      <S.ThankMessage>
        <S.TitleMessage>{t('containers.orders.thank.ticket-finished.sentence1')}</S.TitleMessage>
        <S.TextMessage>{t('containers.orders.thank.ticket-finished.sentence2')}</S.TextMessage>
        <S.TextMessage>{t('containers.orders.thank.ticket-finished.thanksMessage')}</S.TextMessage>
      </S.ThankMessage>

      <BlockButton text={t('containers.orders.thank.ticket-activated.go-home')} onPress={handleGoHome} />
    </Fragment>
  );
});
