export const USER_ROLES = Object.freeze({
  mms: {
    basic: 'MMS_Access_Basic',
    manager: 'MMS_Access_Manager',
  },
  tools: {
    basic: 'Tools_Basic',
    maintenance: 'Tools_Maintenance',
    service_provider: 'Tools_ServiceProvider',
  },
  ioupie: {
    admin: 'ioupie_admin',
  },
});
