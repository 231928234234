import styled from 'styled-components/native';

import { UniversalImage } from '@ioupie/components/gallery';
import { safeObjectLookup } from '@ioupie/shared/utils';

import { ListImageSizes } from './image.models';

const sizesInPixels: Record<ListImageSizes, string> = {
  small: '40px',
  medium: '60px',
  large: '80px',
};

export const ImageWrapper = styled.View`
  align-items: center;
  justify-content: center;
`;

export const ImageAdjusted = styled(UniversalImage)<{ readonly size: ListImageSizes }>`
  align-items: center;
  height: ${({ size }) => safeObjectLookup(sizesInPixels, size)};
  justify-content: center;
  margin: 0;
  padding: 0;
  width: ${({ size }) => safeObjectLookup(sizesInPixels, size)};
`;
