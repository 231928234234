import { observer } from 'mobx-react-lite';
import { useCallback } from 'react';
import { useTranslation } from 'react-i18next';

import { HelperText } from '@ioupie/components/typography';
import { useOrdersStore, useShopsStore } from '@ioupie/hooks';

import * as S from './coupon.styles';

/**
 * @function CouponFieldComponent
 */
export default observer(() => {
  const [t] = useTranslation();
  const shopsStore = useShopsStore();
  const ordersStore = useOrdersStore();
  const { coupon } = shopsStore;

  const couponLabel = t('containers.orders.ordering.amount.coupon-component.input-title');
  const couponPlaceholder = t('containers.orders.ordering.amount.coupon-component.placeholder');

  const ticket = shopsStore.createOrderTicket();

  const requestCouponValidation = useCallback(async (): Promise<void> => {
    if (ticket && coupon) {
      shopsStore.setCoupon(coupon);
      const inboundFee = ordersStore.getInboundFeeByPickupDate();
      await shopsStore.validateCoupon(ticket, coupon, inboundFee);
    }
  }, [coupon, ticket]);

  return (
    <S.CouponContainer>
      <S.CouponTitle>{t('containers.orders.ordering.amount.coupon-component.title')}</S.CouponTitle>
      <S.CouponField>
        <S.CouponText
          autoCapitalize="characters"
          label={couponLabel}
          placeholder={couponPlaceholder}
          value={coupon}
          onChangeText={(value: string) => shopsStore.setCoupon(value)}
        />
        <S.CouponButton
          loading={shopsStore.loadingCoupon}
          onPress={requestCouponValidation}
          disabled={shopsStore.loadingCoupon}>
          {t('containers.orders.ordering.amount.coupon-component.apply-button')}
        </S.CouponButton>
      </S.CouponField>
      {shopsStore.couponErrors && shopsStore.couponErrors.length > 0 && (
        <HelperText type="error" visible={Boolean(shopsStore.couponErrors)}>
          {shopsStore.couponErrors}
        </HelperText>
      )}
      <S.Separator />
    </S.CouponContainer>
  );
});
