import { FC, memo } from 'react';
import { useTranslation } from 'react-i18next';
import { Linking } from 'react-native';

import email from '@ioupie/assets/help/email.png';
import whatsapp from '@ioupie/assets/help/whatsapp.png';
import { LinkText } from '@ioupie/components/typography';

import * as S from './help.styles';

const HelpContainer: FC = () => {
  const [t] = useTranslation();

  const openWhatsApp = (): Promise<any> => {
    const phoneNumber = '5511969296677';
    return Linking.openURL(`whatsapp://send?phone=${phoneNumber}`).catch(() =>
      Linking.openURL(`https://wa.me/${phoneNumber}`),
    );
  };

  const openMail = (): Promise<any> => {
    return Linking.openURL(`mailto:contato@ioupie.com.br`);
  };

  const openFaqPage = (): Promise<any> => {
    return Linking.openURL(`https://www.ioupie.com.br/faq/`);
  };

  return (
    <S.Container>
      <S.ScreenTitle>{t('containers.help.getInTouch.title')}</S.ScreenTitle>
      <S.Content>{t('containers.help.getInTouch.text')}</S.Content>
      <S.ContactOptions>
        <S.ContactOptionContainer onPress={openWhatsApp}>
          <S.ContactOptionItem>
            <S.ContactOptionImage source={whatsapp} />
            <S.ContactOptionLabel>{t('containers.help.getInTouch.whatsapp')}</S.ContactOptionLabel>
          </S.ContactOptionItem>
        </S.ContactOptionContainer>
        <S.ContactOptionContainer onPress={openMail}>
          <S.ContactOptionItem>
            <S.ContactOptionImage source={email} />
            <S.ContactOptionLabel>{t('containers.help.getInTouch.email')}</S.ContactOptionLabel>
          </S.ContactOptionItem>
        </S.ContactOptionContainer>
      </S.ContactOptions>

      <S.ScreenTitle>{t('containers.help.faq.title')}</S.ScreenTitle>
      <S.Content>
        {t('containers.help.faq.text-1')}
        <LinkText text={t('containers.help.faq.text-2')} link="http://ioupie.com.br/faq/" />
        {t('containers.help.faq.text-3')}
      </S.Content>
      <S.FaqPageButton text={t('containers.help.faq.button-text')} onPress={openFaqPage} />
    </S.Container>
  );
};

export default memo(HelpContainer);
