import { createStackNavigator } from '@react-navigation/stack';
import { memo } from 'react';

import { MyAccount, MyProfile } from '@ioupie/pages/profile';
import { routes } from '@ioupie/shared/constants';

const Stack = createStackNavigator();

/**
 * @function ProfileStack
 */
export default memo(() => {
  return (
    <Stack.Navigator initialRouteName={routes.pages.profile.my_profile} screenOptions={{ headerShown: false }}>
      <Stack.Screen name={routes.pages.profile.my_profile} component={MyProfile} />
      <Stack.Screen name={routes.pages.profile.my_account} component={MyAccount} />
    </Stack.Navigator>
  );
});
