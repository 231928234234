import { getLocalizationAsync, Localization } from 'expo-localization';
import i18n from 'i18next';
import { injectable } from 'inversify';
import { Settings } from 'luxon';

@injectable()
export class LanguageService {
  public async getUserLocale(): Promise<Localization> {
    return getLocalizationAsync();
  }

  public async changeAppLanguage(locale: string): Promise<void> {
    if (i18n.isInitialized) {
      await i18n.changeLanguage(locale);
      // switch luxon date time defaults
      Settings.defaultLocale = locale;
    }
  }
}
