import { CustomParams, getAnalytics, logEvent, setUserId, setUserProperties } from 'firebase/analytics';
import { getApp } from 'firebase/app';

export default () => ({
  setUserId: async (userId: string) => {
    const analytics = getAnalytics(getApp());
    return Promise.resolve(setUserId(analytics, userId));
  },
  setUserProperties: async (params: CustomParams) => {
    const analytics = getAnalytics(getApp());
    return Promise.resolve(setUserProperties(analytics, params));
  },
  logEvent: async (event: string, params: CustomParams) => {
    const analytics = getAnalytics(getApp());
    return Promise.resolve(logEvent(analytics, event, params));
  },
});
