import { useFocusEffect } from '@react-navigation/native';
import { observer } from 'mobx-react-lite';
import { Fragment, ReactElement, useCallback, useEffect, useMemo } from 'react';
import { BackHandler } from 'react-native';

import { useHeaderStore, useNavigationStore, useShopsStore } from '@ioupie/hooks';
import { SHOP_STEPS } from '@ioupie/shared/constants';
import { Optional } from '@ioupie/shared/models';
import { safeObjectLookup } from '@ioupie/shared/utils';

import { Categories } from './categories';
import { Custom } from './custom';
import { Products } from './products';
import { ProductsFilter } from './products-filter';
import { Selection } from './selection';

/**
 * @function ShopCatalogContainer
 */
export default observer(() => {
  const headerStore = useHeaderStore();
  const shopsStore = useShopsStore();
  const navigationStore = useNavigationStore();

  const headerBackActionsPerStep: Record<SHOP_STEPS, Optional<CallableFunction>> = useMemo(
    () => ({
      [SHOP_STEPS.CATEGORIES]: () => {
        navigationStore.dispatchGoBack();
      },
      [SHOP_STEPS.PRODUCTS]: () => {
        // if only one category available, then navigate back before tweaking properties
        if (shopsStore.isShopSingleCategory) {
          navigationStore.dispatchGoBack();
        }

        shopsStore.changeStep(SHOP_STEPS.CATEGORIES);
        headerStore.changeSubtitle('');
        shopsStore.setFilterQuery('');
      },
      [SHOP_STEPS.CUSTOM]: () => {
        shopsStore.changeStep(SHOP_STEPS.PRODUCTS);
        shopsStore.clearCustomizingProduct();
      },
    }),
    [],
  );

  const actionsToTakePerStep: Record<SHOP_STEPS, CallableFunction> = useMemo(
    () => ({
      [SHOP_STEPS.CATEGORIES]: () => {
        const { name = '' } = shopsStore.shopProvider ?? {};
        headerStore.changeSubtitle(name);
      },
      [SHOP_STEPS.PRODUCTS]: () => {
        const { name = '' } = safeObjectLookup(shopsStore.productsCatalog, shopsStore.categorySelected) ?? {};
        headerStore.changeSubtitle(name);
      },
      [SHOP_STEPS.CUSTOM]: () => {
        const { name = '' } = shopsStore.customizingProduct ?? {};
        headerStore.changeSubtitle(name);
      },
    }),
    [],
  );

  const containerPerStep: Record<SHOP_STEPS, ReactElement> = useMemo(
    () => ({
      [SHOP_STEPS.CATEGORIES]: <Categories />,
      [SHOP_STEPS.PRODUCTS]: <Products />,
      [SHOP_STEPS.CUSTOM]: <Custom />,
    }),
    [],
  );

  // if only one category available, auto navigate
  useEffect(() => {
    shopsStore.checkAndAutoSelectOnlyCategory();
  }, [shopsStore.productsCatalog]);

  useEffect(() => {
    if (shopsStore.filterQuery !== '') {
      shopsStore.changeStep(SHOP_STEPS.PRODUCTS);
    } else {
      shopsStore.changeStep(SHOP_STEPS.CATEGORIES);
    }
  }, [shopsStore.filterQuery]);

  useEffect(() => {
    const shopId = shopsStore.selectedProviderId;
    // eslint-disable-next-line no-void
    void shopsStore.fetchShopCatalog(shopId);
  }, [shopsStore.shopProvider]);

  useEffect(() => {
    // configure the header
    const headerBackAction = safeObjectLookup(headerBackActionsPerStep, shopsStore.shopStep);
    headerStore.onClickBack(headerBackAction);

    // side effects per state
    const actionsToTake = safeObjectLookup(actionsToTakePerStep, shopsStore.shopStep);
    if (actionsToTake) actionsToTake();
  }, [shopsStore.shopStep]);

  useFocusEffect(
    // Android only
    useCallback(() => {
      const onBackPress = () => {
        switch (shopsStore.shopStep) {
          case SHOP_STEPS.CATEGORIES:
            navigationStore.dispatchGoBack();
            break;
          case SHOP_STEPS.PRODUCTS:
            shopsStore.changeStep(SHOP_STEPS.CATEGORIES);
            break;
          case SHOP_STEPS.CUSTOM:
            shopsStore.changeStep(SHOP_STEPS.PRODUCTS);
            break;
        }
        return true;
      };

      const subscription = BackHandler.addEventListener('hardwareBackPress', onBackPress);

      return () => subscription.remove();
    }, [shopsStore.shopStep]),
  );

  return (
    <Fragment>
      <ProductsFilter />
      {safeObjectLookup(containerPerStep, shopsStore.shopStep) ?? <Fragment />}
      <Selection />
    </Fragment>
  );
});
