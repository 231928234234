import { darken, lighten } from 'polished';
import { Button } from 'react-native-paper';
import styled from 'styled-components/native';

import { BlockButton } from '@ioupie/components/buttons';
import { Text } from '@ioupie/components/typography';
import { colors } from '@ioupie/shared/constants';
import { themefy } from '@ioupie/shared/utils';

export const ProviderNotEligibleMessage = styled.View`
  align-items: baseline;
  background: ${themefy({
    light: lighten(0.65, colors.light.text),
    dark: darken(0.5, colors.dark.text),
  })};
  border-radius: 10px;
  flex-direction: column;
  justify-content: space-around;
  margin: 2% 5% 0;
  padding: 2% 0;
  width: 90%;
`;

export const InvalidMessage = styled.View`
  align-items: center;
  flex-direction: row;
  justify-content: space-between;
  margin: 0 10px;
  padding: 2%;
`;

export const InvalidMessageTextAndButton = styled.View``;

export const InvalidMessageText = styled(Text)`
  margin: 0 4% 0 0;
  padding: 0 2%;
`;

export const ConfigureAddressButton = styled(Button)`
  background: ${themefy({
    light: colors.light.background,
    dark: colors.dark.background,
  })};
  margin: 1% 5% 2% 15%;
`;

export const NextButton = styled(BlockButton)`
  margin: 16px 0;
`;
