import { observer } from 'mobx-react-lite';
import { Fragment, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { TouchableOpacity } from 'react-native';

import { ExpandBar } from '@ioupie/components/custom';
import { useAddressStore, useNavigationStore, useSegmentsStore } from '@ioupie/hooks';
import { routes } from '@ioupie/shared/constants';

import * as S from './lockers-available.styles';

/**
 * @function DeliveryTargetContainer
 */
export default observer(() => {
  const [t] = useTranslation();
  const addressStore = useAddressStore();
  const segmentsStore = useSegmentsStore();
  const navigationStore = useNavigationStore();

  useEffect(() => {
    // eslint-disable-next-line no-void
    void addressStore.restoreSavedZipCodeInfo();
  }, []);

  return (
    <Fragment>
      <ExpandBar>
        {segmentsStore.hasLockers && (
          <TouchableOpacity
            onPress={() =>
              navigationStore.dispatchNavigation({
                stack: routes.stacks.orders,
                screen: routes.pages.orders.lockers,
              })
            }>
            <S.TitleContainer>
              <S.ExpandTitle>
                {t('containers.lockers.lockers-available.title', { num: segmentsStore.lockersData.length })}
              </S.ExpandTitle>
              <S.ExpandSmall>{t('containers.lockers.lockers-available.description')}</S.ExpandSmall>
            </S.TitleContainer>
          </TouchableOpacity>
        )}
      </ExpandBar>
      {/* <ErrorSnackbar errors={addressStore.errors} onDismiss={() => addressStore.clearErrors()} /> */}
    </Fragment>
  );
});
