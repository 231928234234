import { observer } from 'mobx-react-lite';
import { useCallback } from 'react';
import { useTranslation } from 'react-i18next';

import { SwitchButton } from '@ioupie/components/buttons';
import { useSettingsStore } from '@ioupie/hooks';

/**
 * @function DarkModeSwitcherContainer
 */
export default observer(() => {
  const [t] = useTranslation();
  const settingsStore = useSettingsStore();

  const onChangeDarkMode = useCallback(async (value: boolean) => {
    await settingsStore.setDarkMode(value);
    await settingsStore.storeUserSettings();
  }, []);

  return (
    <SwitchButton
      text={t('containers.settings.dark-mode-switcher.toggle')}
      value={settingsStore.darkMode ?? false}
      onValueChange={onChangeDarkMode}
    />
  );
});
