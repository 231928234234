import { observer } from 'mobx-react-lite';
import { useCallback } from 'react';
import { useTranslation } from 'react-i18next';

import { Dropdown } from '@ioupie/components/custom';
import { useSettingsStore, useTheme } from '@ioupie/hooks';
import { LANGUAGE_MAPPER, SUPPORTED_LANGUAGES, colors } from '@ioupie/shared/constants';
import { safeObjectLookup } from '@ioupie/shared/utils';

/**
 * @function LanguageSelectorContainer
 */
export default observer(() => {
  const [t] = useTranslation();
  const settingsStore = useSettingsStore();

  const theme = useTheme();
  const dropdownTextColorTheme = theme.mode === 'dark' ? colors.dark.text : colors.light.text;

  const onChangeLanguage = useCallback(async (value: string) => {
    await settingsStore.changeLanguage(value);
    await settingsStore.storeUserSettings();
  }, []);

  const renderItem = useCallback(
    (language: SUPPORTED_LANGUAGES) => (
      <Dropdown.Item
        color={dropdownTextColorTheme}
        key={language}
        value={language}
        label={t(safeObjectLookup(LANGUAGE_MAPPER, language) ?? '')}
      />
    ),
    [],
  );

  return (
    <Dropdown.View selectedValue={settingsStore.language} onValueChange={onChangeLanguage}>
      {Object.values(SUPPORTED_LANGUAGES).map(renderItem)}
    </Dropdown.View>
  );
});
