import { Fragment, memo, useCallback, useState } from 'react';
import { useTranslation } from 'react-i18next';

import { BlockButton } from '@ioupie/components/buttons';
import { RadioButton } from '@ioupie/components/custom';
import { TextInput } from '@ioupie/components/inputs';
import { Divider } from '@ioupie/components/layout';
import { Bold } from '@ioupie/components/typography';
import {
  CARPET_SHAPES,
  CUSTOM_PRODUCT_SIZES,
  PRODUCT_METADATA_TYPES,
  RECTANGLE_CARPET_DEFAULTS,
} from '@ioupie/shared/constants';
import { CarpetMetadata, ProductData } from '@ioupie/shared/models';
import { isEnumOf, normalizeAndParseFloat, safeObjectLookup } from '@ioupie/shared/utils';

import * as S from './rectangle.styles';

export default memo(RectangleCarpet);

type RectangleCarpetProps = {
  readonly customizingCarpet: ProductData;
  readonly onSendCarpetSize: (metadata: CarpetMetadata) => void;
};

const INVALID_CUSTOM_SIZE_VALUE: Readonly<number> = 0;

function RectangleCarpet({ customizingCarpet, onSendCarpetSize }: RectangleCarpetProps) {
  const [t] = useTranslation();
  const [carpetSize, setCarpetSize] = useState(CUSTOM_PRODUCT_SIZES.SMALL);

  const [height, setHeight] = useState('');
  const [width, setWidth] = useState('');

  const chosenSize =
    carpetSize === CUSTOM_PRODUCT_SIZES.CUSTOM
      ? { width: normalizeAndParseFloat(width), height: normalizeAndParseFloat(height) }
      : safeObjectLookup(RECTANGLE_CARPET_DEFAULTS, carpetSize);

  const widthIsInvalid = !chosenSize || Number.isNaN(chosenSize.width) || chosenSize.width <= 0;
  const heightIsInvalid = !chosenSize || Number.isNaN(chosenSize.height) || chosenSize.height <= 0;

  const carpetArea = useCallback((w: number, h: number): number => {
    const area = w * h;
    return area >= 1 ? area : 1;
  }, []);

  const calculatedValue: Readonly<number> =
    widthIsInvalid || heightIsInvalid
      ? INVALID_CUSTOM_SIZE_VALUE
      : carpetArea(chosenSize.width, chosenSize.height) * customizingCarpet.minPrice.amount;

  const onCarpetSizeSelection = useCallback((value: string): void => {
    const isCarpetSize = isEnumOf(CUSTOM_PRODUCT_SIZES);

    if (isCarpetSize(value)) {
      setCarpetSize(value);
    }
  }, []);

  const chooseRectangleCarpetSize = (): void => {
    if (!chosenSize || widthIsInvalid || heightIsInvalid) {
      return;
    }

    onSendCarpetSize({
      type: PRODUCT_METADATA_TYPES.CARPET,
      shape: CARPET_SHAPES.RECTANGLE,
      height: chosenSize.height,
      width: chosenSize.width,
      estimatedPrice: calculatedValue,
    });
  };

  return (
    <Fragment>
      <S.TitleBox>
        <Bold>{t('containers.catalog.shop-catalog.custom.rectangle-carpet.size')}</Bold>
      </S.TitleBox>
      <RadioButton.Group onValueChange={onCarpetSizeSelection} value={carpetSize}>
        <RadioButton.Item
          mode="android"
          value={CUSTOM_PRODUCT_SIZES.SMALL}
          label={t('containers.catalog.shop-catalog.custom.rectangle-carpet.small', {
            width: RECTANGLE_CARPET_DEFAULTS.small.width.toFixed(2),
            height: RECTANGLE_CARPET_DEFAULTS.small.height.toFixed(2),
            unit: customizingCarpet.unitOfMeasurement,
          })}
        />
        <RadioButton.Item
          mode="android"
          value={CUSTOM_PRODUCT_SIZES.MEDIUM}
          label={t('containers.catalog.shop-catalog.custom.rectangle-carpet.medium', {
            width: RECTANGLE_CARPET_DEFAULTS.medium.width.toFixed(2),
            height: RECTANGLE_CARPET_DEFAULTS.medium.height.toFixed(2),
            unit: customizingCarpet.unitOfMeasurement,
          })}
        />
        <RadioButton.Item
          mode="android"
          value={CUSTOM_PRODUCT_SIZES.BIG}
          label={t('containers.catalog.shop-catalog.custom.rectangle-carpet.big', {
            width: RECTANGLE_CARPET_DEFAULTS.big.width.toFixed(2),
            height: RECTANGLE_CARPET_DEFAULTS.big.height.toFixed(2),
            unit: customizingCarpet.unitOfMeasurement,
          })}
        />
        <RadioButton.Item
          mode="android"
          value={CUSTOM_PRODUCT_SIZES.CUSTOM}
          label={t('containers.catalog.shop-catalog.custom.rectangle-carpet.custom')}
        />
      </RadioButton.Group>
      <Divider />
      {carpetSize === CUSTOM_PRODUCT_SIZES.CUSTOM && (
        <S.CarpetCustomBox>
          <S.CarpetInputBox>
            <S.CarpetInputTitle>
              {t('containers.catalog.shop-catalog.custom.rectangle-carpet.height-title')}
            </S.CarpetInputTitle>
            <S.CarpetInputText
              value={height}
              keyboardType="numeric"
              onChangeText={setHeight}
              label={t('containers.catalog.shop-catalog.custom.rectangle-carpet.height-label')}
              placeholder={t('containers.catalog.shop-catalog.custom.rectangle-carpet.height-placeholder')}
              disabled={carpetSize !== CUSTOM_PRODUCT_SIZES.CUSTOM}
            />
          </S.CarpetInputBox>
          <S.CarpetInputBox>
            <S.CarpetInputTitle>
              {t('containers.catalog.shop-catalog.custom.rectangle-carpet.width-title')}
            </S.CarpetInputTitle>
            <S.CarpetInputText
              value={width}
              keyboardType="numeric"
              onChangeText={setWidth}
              label={t('containers.catalog.shop-catalog.custom.rectangle-carpet.width-label')}
              placeholder={t('containers.catalog.shop-catalog.custom.rectangle-carpet.width-placeholder')}
              disabled={carpetSize !== CUSTOM_PRODUCT_SIZES.CUSTOM}
            />
          </S.CarpetInputBox>
        </S.CarpetCustomBox>
      )}
      <S.CarpetPriceBox>
        <Bold>{t('containers.catalog.shop-catalog.custom.rectangle-carpet.price')}</Bold>
        <Bold>
          {t('containers.catalog.shop-catalog.custom.rectangle-carpet.total', {
            price: calculatedValue.toFixed(2),
            currency: customizingCarpet.minPrice.currencyCode,
          })}
        </Bold>
      </S.CarpetPriceBox>
      <BlockButton
        disabled={widthIsInvalid || heightIsInvalid}
        onPress={chooseRectangleCarpetSize}
        text={t('containers.catalog.shop-catalog.custom.round-carpet.add')}
      />
    </Fragment>
  );
}
