import { observer } from 'mobx-react-lite';
import { useEffect } from 'react';
import { useTranslation } from 'react-i18next';

import { useDeliveryStore, useLockersStore, useOrdersStore } from '@ioupie/hooks';
import { BUSINESS_AREA_OPTIONS } from '@ioupie/shared/constants';

import * as S from './laundry-details.styles';

/**
 * @function LaundryDetailsContainer
 */
export default observer(() => {
  const { t } = useTranslation();
  const lockersStore = useLockersStore();
  const ordersStore = useOrdersStore();
  const deliveryStore = useDeliveryStore();

  const { selectedOrderHistory } = ordersStore;
  const { outboundDetails, serviceProviderId = '', type = BUSINESS_AREA_OPTIONS.LOCKER } = selectedOrderHistory ?? {};
  const { lockerId = '' } = outboundDetails ?? {};
  const { lockerProvider } = lockersStore ?? {};
  const { providers } = lockerProvider ?? {};
  const { deliveryProviders = [] } = deliveryStore ?? {};

  const lockerLaundry = providers?.find((provider) => provider.id === serviceProviderId);
  const { image: laundryProviderImage = '', name: laundryProviderName = '' } = lockerLaundry ?? {};

  const deliveryProvider = deliveryProviders.find((provider) => provider.id === serviceProviderId);
  const { image: deliveryProviderImage = '', name: deliveryProviderName = '' } = deliveryProvider ?? {};

  const isLocker = type === BUSINESS_AREA_OPTIONS.LOCKER;
  const entityImage = isLocker ? laundryProviderImage : deliveryProviderImage;
  const entityName = isLocker ? laundryProviderName : deliveryProviderName;
  const details = t(`containers.orders.track-order.laundry-details.order-by-${isLocker ? 'locker' : 'delivery'}`);

  useEffect(() => {
    // eslint-disable-next-line no-void
    void lockersStore.selectLockerProvider(lockerId);
  }, []);

  return (
    <S.EntityContainer>
      <S.EntityImage image={entityImage} />
      <S.EntityInformation>
        <S.EntityName>{entityName}</S.EntityName>
        <S.EntityDetails>{details}</S.EntityDetails>
      </S.EntityInformation>
    </S.EntityContainer>
  );
});
