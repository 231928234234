import { darken, lighten } from 'polished';
import styled from 'styled-components/native';

import { BlockButton } from '@ioupie/components/buttons';
import { Divider } from '@ioupie/components/layout';
import { Text } from '@ioupie/components/typography';
import { colors } from '@ioupie/shared/constants';
import { themefy } from '@ioupie/shared/utils';

export const Separator = styled(Divider)`
  margin: 0 0 16px;
`;

export const DialogButtonColumn = styled.View`
  display: flex;
  flex: 1;
  flex-direction: row;
`;

export const DialogButton = styled(BlockButton)`
  flex: 1;
`;

export const DialogAddressAddon = styled(Text)`
  color: ${themefy({ light: lighten(0.4, colors.light.text), dark: darken(0.2, colors.dark.text) })};
  font-size: 14px;
  padding: 5px 0;
`;

export const FinishButton = styled(BlockButton)`
  margin: 16px 0;
`;
