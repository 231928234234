import { observer } from 'mobx-react-lite';
import { Fragment, useEffect } from 'react';
import { useFormContext } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { KeyboardAvoidingView, Platform, View } from 'react-native';

import { Button } from '@ioupie/components/buttons';
import { AttentionBox, Loader } from '@ioupie/components/custom';
import { UniversalImage } from '@ioupie/components/gallery';
import { TextInput } from '@ioupie/components/inputs';
import { HelperText } from '@ioupie/components/typography';
import { useNavigationStore, useOrdersStore, usePaymentStore } from '@ioupie/hooks';
import { CREDIT_CARD_LOGOS, PAYMENT_METHOD_TYPES, PIX_LOGO, routes } from '@ioupie/shared/constants';
import { debounceOneSecond, safeObjectLookup } from '@ioupie/shared/utils';

import { PaymentMethodForm, PaymentProps } from './payment.models';
import * as S from './payment.styles';

/**
 * @function PaymentContainer
 */
export default observer(({ readOnly }: PaymentProps) => {
  const [t] = useTranslation();

  const ordersStore = useOrdersStore();
  const paymentStore = usePaymentStore();
  const navigationStore = useNavigationStore();

  const { selectedOrderHistory } = ordersStore;
  const { grandTotal, payment } = selectedOrderHistory ?? {};
  const { involvedPayments = [] } = payment ?? {};
  const { amount: currentAmount = 0 } = grandTotal ?? {};

  const { selectedPaymentMethod, loading = false } = paymentStore;
  const { issuer = '', lastDigits = '' } = selectedPaymentMethod ?? {};

  const securityCodeLabel = t('containers.orders.track-order.payment.security-code');
  const logo = safeObjectLookup(CREDIT_CARD_LOGOS, selectedPaymentMethod?.issuer) ?? '';
  const noCreditCardAvailable = !issuer || issuer.length === 0;
  const isCreditMethod = paymentStore.selectedPaymentType === PAYMENT_METHOD_TYPES.CREDIT;
  const hasRefund = currentAmount - ordersStore.originalTotalAmount < 0;

  const formMethods = useFormContext<PaymentMethodForm>();

  const navigateToPaymentList = (): void => {
    navigationStore.dispatchNavigation({
      stack: routes.stacks.payment,
      screen: routes.pages.payment.methods_list,
    });
  };

  useEffect(() => {
    // eslint-disable-next-line no-void
    void paymentStore.fetchPaymentMethods();
  }, []);

  useEffect(() => {
    if (selectedPaymentMethod) {
      formMethods && formMethods.setValue('method', selectedPaymentMethod.id, { shouldValidate: true });
    }
  }, [selectedPaymentMethod]);

  const CardPaymentMethodDetails = (
    <S.MethodDetailsContainer
      disabled={readOnly}
      onPress={() => paymentStore.changePaymentType(PAYMENT_METHOD_TYPES.CREDIT)}
      selected={isCreditMethod}>
      <UniversalImage image={logo} width={48} height={48} />
      <S.MethodLabelsContainer>
        <S.MethodType>{t('containers.orders.track-order.payment.method-type.credit-card')}</S.MethodType>
        <S.MethodTypeInfo>{`${t('containers.orders.track-order.payment.card-final')} ${lastDigits}`}</S.MethodTypeInfo>
      </S.MethodLabelsContainer>
    </S.MethodDetailsContainer>
  );

  const PixPaymentMethodDetails = !readOnly ? (
    <S.MethodDetailsContainer
      disabled={readOnly}
      onPress={() => paymentStore.changePaymentType(PAYMENT_METHOD_TYPES.PIX)}
      selected={paymentStore.selectedPaymentType === PAYMENT_METHOD_TYPES.PIX}>
      <UniversalImage image={PIX_LOGO} width={96} height={48} />
    </S.MethodDetailsContainer>
  ) : (
    <S.PixReadOnlyContainer>
      <UniversalImage image={PIX_LOGO} width={96} height={48} />
      {hasRefund ? (
        <AttentionBox
          title={t('containers.orders.track-order.payment.refund-title')}
          text={t('containers.orders.track-order.payment.refund-body')}
        />
      ) : undefined}
    </S.PixReadOnlyContainer>
  );

  const SecurityCodeSection =
    !readOnly && isCreditMethod ? (
      <S.SecurityCodeInput>
        <S.SecurityCodeLabel>{t('containers.orders.track-order.payment.security-code-label')}</S.SecurityCodeLabel>
        <S.SecurityCodeBlock>
          <TextInput
            id="securityCode"
            error={Boolean(formMethods && formMethods.formState.errors.securityCode)}
            keyboardType="number-pad"
            returnKeyType="done"
            maxLength={4}
            label={securityCodeLabel}
            placeholder={securityCodeLabel}
            onChangeText={debounceOneSecond((text: string) => {
              formMethods && formMethods.setValue('securityCode', text, { shouldValidate: true });
            })}
          />
          {Boolean(formMethods && formMethods.formState.errors.securityCode) && (
            <HelperText type="error" visible={Boolean(formMethods && formMethods.formState.errors.securityCode)}>
              {(formMethods && formMethods.formState.errors.securityCode?.message) ?? ''}
            </HelperText>
          )}
        </S.SecurityCodeBlock>
      </S.SecurityCodeInput>
    ) : (
      <Fragment />
    );

  return (
    <Fragment>
      <View>
        <KeyboardAvoidingView behavior={Platform.OS === 'ios' ? 'padding' : 'height'}>
          {loading ? (
            <Loader show={loading} />
          ) : (
            <Fragment>
              <S.PaymentHeader>
                <S.PaymentTitle>{t('containers.orders.track-order.payment.title')}</S.PaymentTitle>
                {noCreditCardAvailable && isCreditMethod && (
                  <Button mode="text" onPress={navigateToPaymentList}>
                    {t('containers.orders.track-order.payment.add-card')}
                  </Button>
                )}
                {!noCreditCardAvailable && isCreditMethod && (
                  <Button mode="text" onPress={navigateToPaymentList}>
                    {t('containers.orders.track-order.payment.change-button')}
                  </Button>
                )}
              </S.PaymentHeader>
              <View>
                {readOnly ? (
                  involvedPayments.map((involvedPayment) => {
                    const { lastDigits: involvedPaymentLastDigits = '', issuer: involvedPaymentIssuer } =
                      involvedPayment ?? {};
                    const involvedPaymentLogo = safeObjectLookup(CREDIT_CARD_LOGOS, involvedPaymentIssuer) ?? '';
                    return involvedPayment.type === PAYMENT_METHOD_TYPES.PIX ? (
                      PixPaymentMethodDetails
                    ) : (
                      <S.CreditReadOnlyContainer>
                        <UniversalImage image={involvedPaymentLogo} width={48} height={48} />
                        <S.MethodLabelsContainer>
                          <S.MethodType>
                            {t('containers.orders.track-order.payment.method-type.credit-card')}
                          </S.MethodType>
                          <S.MethodTypeInfo>{`${t(
                            'containers.orders.track-order.payment.card-final',
                          )} ${involvedPaymentLastDigits}`}</S.MethodTypeInfo>
                        </S.MethodLabelsContainer>
                      </S.CreditReadOnlyContainer>
                    );
                  })
                ) : (
                  <Fragment>
                    {PixPaymentMethodDetails}
                    {CardPaymentMethodDetails}
                  </Fragment>
                )}
              </View>
              {!readOnly && isCreditMethod && SecurityCodeSection}
            </Fragment>
          )}
        </KeyboardAvoidingView>
      </View>
    </Fragment>
  );
});
