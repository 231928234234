import { Image, Platform } from 'react-native';
import * as SVG from 'react-native-svg';
import styled from 'styled-components/native';

// workaround for web support
const { SvgXml: LocalSvg = Image, SvgUri: RemoteSvg = Image } = SVG ?? {};

type WithUri = {
  readonly uri?: string;
  readonly source?: {
    readonly uri?: string;
  };
};

export const Svg = styled(LocalSvg)<WithUri>`
  align-items: center;
  justify-content: center;
  margin: 0;
  padding: 0;
  flex: ${Platform.OS === 'web' ? '1' : 'none'};
`;

export const SvgUri = styled(RemoteSvg)<WithUri>`
  align-items: center;
  justify-content: center;
  margin: 0;
  padding: 0;
  flex: ${Platform.OS === 'web' ? '1' : 'none'};
`;
