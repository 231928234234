export enum AnalyticsEvents {
  ADDRESS_FETCH_GPS = 'ADDRESS_FETCH_GPS',
  ADDRESS_FETCH_ZIPCODE = 'ADDRESS_FETCH_ZIPCODE',
  ADDRESS_SELECT_MAIN_ADDRESS = 'ADDRESS_SELECT_MAIN_ADDRESS',
  // Authentication flow
  AUTH_REFRESHTOKEN = 'AUTH_REFRESHTOKEN',
  AUTH_SIGNIN = 'AUTH_SIGNIN',
  AUTH_SIGNOUT = 'AUTH_SIGNOUT',
  AUTH_SIGNUP = 'AUTH_SIGNUP',
  AUTH_VERIFY = 'AUTH_VERIFY',
  // Navigation
  NAVIGATION = 'NAVIGATION',
  // Fetching
  PROVIDERS_FETCH_ALL = 'PROVIDERS_FETCH_ALL',
  LOCKERS_FETCH_ALL = 'LOCKERS_FETCH_ALL',
  // Catalog
  SHOPS_FETCH_CATALOG = 'SHOPS_FETCH_CATALOG',
  SHOPS_VALIDATE_COUPON = 'SHOPS_VALIDATE_COUPON',
  // Search
  SEARCH = 'SEARCH',
  // Ordering flow
  LIST_VIEW = 'LIST_VIEW',
  ADD_TO_CART = 'ADD_TO_CART',
  PURCHASE = 'PURCHASE',
  SELECT_CONTENT = 'SELECT_CONTENT',
  REMOVE_FROM_CART = 'REMOVE_FROM_CART',
  INITIATE_CHECKOUT = 'INITIATE_CHECKOUT',
  CHECKOUT_INITIATED_PAYMENT_SELECTION = 'CHECKOUT_INITIATED_PAYMENT_SELECTION',
  CHECKOUT_SUMMARY_STEP = 'CHECKOUT_SUMMARY_STEP',
  // Payment
  ADD_PAYMENT_INFO = 'ADD_PAYMENT_INFO',
  // Device-specific
  DEVICE_FETCH_LANGUAGE = 'DEVICE_FETCH_LANGUAGE',
  DEVICE_CHANGE_LANGUAGE = 'DEVICE_CHANGE_LANGUAGE',
  DEVICE_SET_APPEARANCE = 'DEVICE_SET_APPEARANCE',
  // Deep Linking
  DEEP_LINKING = 'DEEP_LINKING',
  FAILURE_LINKING = 'FAILURE_LINKING',
}

type AnalyticsTokens<T extends string = string> = Record<AnalyticsEvents, T>;

export const RudderStackTokens = {
  [AnalyticsEvents.ADDRESS_FETCH_GPS]: 'fetch_address_gps',
  [AnalyticsEvents.ADDRESS_FETCH_ZIPCODE]: 'fetch_address_zip',
  [AnalyticsEvents.ADDRESS_SELECT_MAIN_ADDRESS]: 'select_main_address',
  [AnalyticsEvents.AUTH_REFRESHTOKEN]: 'auth_refresh_token',
  [AnalyticsEvents.AUTH_SIGNIN]: 'auth_sign_in',
  [AnalyticsEvents.AUTH_SIGNOUT]: 'auth_sign_out',
  [AnalyticsEvents.AUTH_SIGNUP]: 'auth_sign_up',
  [AnalyticsEvents.AUTH_VERIFY]: 'auth_verify',
  [AnalyticsEvents.NAVIGATION]: 'navigation',
  [AnalyticsEvents.SHOPS_FETCH_CATALOG]: 'fetch_partner_catalog',
  [AnalyticsEvents.SHOPS_VALIDATE_COUPON]: 'coupon_validate',
  [AnalyticsEvents.SEARCH]: 'search',
  [AnalyticsEvents.ADD_TO_CART]: 'add_to_cart',
  [AnalyticsEvents.REMOVE_FROM_CART]: 'remove_from_cart',
  [AnalyticsEvents.INITIATE_CHECKOUT]: 'init_checkout',
  [AnalyticsEvents.CHECKOUT_INITIATED_PAYMENT_SELECTION]: 'select_payment_checkout',
  [AnalyticsEvents.CHECKOUT_SUMMARY_STEP]: 'summary_checkout',
  [AnalyticsEvents.PURCHASE]: 'purchase',
  [AnalyticsEvents.SELECT_CONTENT]: 'select_content',
  [AnalyticsEvents.ADD_PAYMENT_INFO]: 'add_payment_method',
  [AnalyticsEvents.DEVICE_CHANGE_LANGUAGE]: 'change_app_language',
  [AnalyticsEvents.DEVICE_FETCH_LANGUAGE]: 'fetch_app_language',
  [AnalyticsEvents.DEVICE_SET_APPEARANCE]: 'set_app_appearance',
  [AnalyticsEvents.PROVIDERS_FETCH_ALL]: 'fetch_all_delivery_partners',
  [AnalyticsEvents.LOCKERS_FETCH_ALL]: 'fetch_all_locker_partners',
  [AnalyticsEvents.DEEP_LINKING]: 'deep_linking_success',
  [AnalyticsEvents.FAILURE_LINKING]: 'deep_linking_failure',
  [AnalyticsEvents.LIST_VIEW]: 'list_view',
} as const satisfies AnalyticsTokens;

export const AdjustTokens = {
  [AnalyticsEvents.ADDRESS_FETCH_GPS]: '4fw9ib',
  [AnalyticsEvents.ADDRESS_FETCH_ZIPCODE]: 'dt845t',
  [AnalyticsEvents.ADDRESS_SELECT_MAIN_ADDRESS]: 'b4bsu1',
  [AnalyticsEvents.AUTH_REFRESHTOKEN]: 'xf5ezr',
  [AnalyticsEvents.AUTH_SIGNIN]: 'dg5vmy',
  [AnalyticsEvents.AUTH_SIGNOUT]: 'tz2zzc',
  [AnalyticsEvents.AUTH_SIGNUP]: 'uevud7',
  [AnalyticsEvents.AUTH_VERIFY]: '2wkuu7',
  [AnalyticsEvents.NAVIGATION]: 'bdyb83',
  [AnalyticsEvents.SHOPS_FETCH_CATALOG]: 'dxglj4',
  [AnalyticsEvents.SHOPS_VALIDATE_COUPON]: 'eb5w73',
  [AnalyticsEvents.SEARCH]: 'chkcjf',
  [AnalyticsEvents.ADD_TO_CART]: 'x5lg2a',
  [AnalyticsEvents.REMOVE_FROM_CART]: 'iptw4t',
  [AnalyticsEvents.INITIATE_CHECKOUT]: 'd9ygk4',
  [AnalyticsEvents.CHECKOUT_INITIATED_PAYMENT_SELECTION]: '3cfoyd',
  [AnalyticsEvents.CHECKOUT_SUMMARY_STEP]: 'mabz54',
  [AnalyticsEvents.PURCHASE]: 'lnu7rk',
  [AnalyticsEvents.SELECT_CONTENT]: 'fbto7u',
  [AnalyticsEvents.ADD_PAYMENT_INFO]: 'tcx3ed',
  [AnalyticsEvents.DEVICE_CHANGE_LANGUAGE]: 'd5vchu',
  [AnalyticsEvents.DEVICE_FETCH_LANGUAGE]: 'lk13r1',
  [AnalyticsEvents.DEVICE_SET_APPEARANCE]: 'sz1h9n',
  [AnalyticsEvents.PROVIDERS_FETCH_ALL]: 'u38606',
  [AnalyticsEvents.LOCKERS_FETCH_ALL]: 'ybdoo1',
  [AnalyticsEvents.DEEP_LINKING]: 'h7vq7v',
  [AnalyticsEvents.FAILURE_LINKING]: '6voaii',
  [AnalyticsEvents.LIST_VIEW]: 'bxm6lm',
} as const satisfies AnalyticsTokens;

export const AmplitudeTokens = {
  [AnalyticsEvents.ADDRESS_FETCH_GPS]: 'Obter endereço via GPS',
  [AnalyticsEvents.ADDRESS_FETCH_ZIPCODE]: 'Obter endereço via CEP',
  [AnalyticsEvents.ADDRESS_SELECT_MAIN_ADDRESS]: 'Selecionar endereço',
  [AnalyticsEvents.AUTH_REFRESHTOKEN]: 'Auth Refresh Token',
  [AnalyticsEvents.AUTH_SIGNIN]: 'login',
  [AnalyticsEvents.AUTH_SIGNOUT]: 'logout',
  [AnalyticsEvents.AUTH_SIGNUP]: 'iniciar cadastro',
  [AnalyticsEvents.AUTH_VERIFY]: 'concluir cadastro',
  [AnalyticsEvents.NAVIGATION]: 'navegação',
  [AnalyticsEvents.SHOPS_FETCH_CATALOG]: 'carregar catálogo de parceiro',
  [AnalyticsEvents.SHOPS_VALIDATE_COUPON]: 'validar cupom',
  [AnalyticsEvents.SEARCH]: 'busca',
  [AnalyticsEvents.ADD_TO_CART]: 'adicionar ao carrinho',
  [AnalyticsEvents.REMOVE_FROM_CART]: 'remover do carrinho',
  [AnalyticsEvents.INITIATE_CHECKOUT]: 'iniciar o checkout',
  [AnalyticsEvents.CHECKOUT_INITIATED_PAYMENT_SELECTION]: 'seleção de pagamento no checkout',
  [AnalyticsEvents.CHECKOUT_SUMMARY_STEP]: 'tela de resumo do checkout',
  [AnalyticsEvents.PURCHASE]: 'purchase',
  [AnalyticsEvents.SELECT_CONTENT]: 'selecionar opção',
  [AnalyticsEvents.ADD_PAYMENT_INFO]: 'adicionar método de pagamento',
  [AnalyticsEvents.DEVICE_CHANGE_LANGUAGE]: 'alterar idioma do app',
  [AnalyticsEvents.DEVICE_FETCH_LANGUAGE]: 'obter idioma do app',
  [AnalyticsEvents.DEVICE_SET_APPEARANCE]: 'alterar tema do app',
  [AnalyticsEvents.PROVIDERS_FETCH_ALL]: 'Delivery_FetchAllProviders',
  [AnalyticsEvents.LOCKERS_FETCH_ALL]: 'Delivery_FetchAllLockers',
  [AnalyticsEvents.DEEP_LINKING]: 'Deep Linking',
  [AnalyticsEvents.FAILURE_LINKING]: 'Deep Linking Failure',
  [AnalyticsEvents.LIST_VIEW]: 'visualizar lista',
} as const satisfies AnalyticsTokens;

export const AppsFlyerTokens = {
  [AnalyticsEvents.ADDRESS_FETCH_GPS]: 'Address_Fetch_Gps',
  [AnalyticsEvents.ADDRESS_FETCH_ZIPCODE]: 'Address_Fetch_ZipCode',
  [AnalyticsEvents.ADDRESS_SELECT_MAIN_ADDRESS]: 'Address_SelectMainAddress',
  [AnalyticsEvents.AUTH_REFRESHTOKEN]: 'Auth_RefreshToken',
  [AnalyticsEvents.AUTH_SIGNIN]: 'af_login',
  [AnalyticsEvents.AUTH_SIGNOUT]: 'logout',
  [AnalyticsEvents.AUTH_SIGNUP]: 'start_sign_up',
  [AnalyticsEvents.AUTH_VERIFY]: 'af_complete_registration',
  [AnalyticsEvents.NAVIGATION]: 'navegação',
  [AnalyticsEvents.SHOPS_FETCH_CATALOG]: 'carregar catálogo de parceiro',
  [AnalyticsEvents.SHOPS_VALIDATE_COUPON]: 'validar cupom',
  [AnalyticsEvents.SEARCH]: 'af_search',
  [AnalyticsEvents.ADD_TO_CART]: 'af_add_to_cart',
  [AnalyticsEvents.REMOVE_FROM_CART]: 'af_remove_from_cart',
  [AnalyticsEvents.INITIATE_CHECKOUT]: 'af_initiated_checkout',
  [AnalyticsEvents.CHECKOUT_INITIATED_PAYMENT_SELECTION]: 'checkout_initiated_payment_selection',
  [AnalyticsEvents.CHECKOUT_SUMMARY_STEP]: 'checkout_summary_step',
  [AnalyticsEvents.PURCHASE]: 'af_purchase',
  [AnalyticsEvents.SELECT_CONTENT]: 'select_content',
  [AnalyticsEvents.ADD_PAYMENT_INFO]: 'af_add_payment_info',
  [AnalyticsEvents.DEVICE_CHANGE_LANGUAGE]: 'Device_ChangeLanguage',
  [AnalyticsEvents.DEVICE_FETCH_LANGUAGE]: 'Device_FetchLanguage',
  [AnalyticsEvents.DEVICE_SET_APPEARANCE]: 'Device_SetAppearance',
  [AnalyticsEvents.PROVIDERS_FETCH_ALL]: 'Delivery_FetchAllProviders',
  [AnalyticsEvents.LOCKERS_FETCH_ALL]: 'Delivery_FetchAllLockers',
  [AnalyticsEvents.DEEP_LINKING]: 'deep_linking',
  [AnalyticsEvents.FAILURE_LINKING]: 'failure_linking',
  [AnalyticsEvents.LIST_VIEW]: 'list_view',
} as const satisfies AnalyticsTokens;

// https://developers.facebook.com/docs/marketing-api/app-event-api/
export const FacebookTokens = {
  [AnalyticsEvents.ADDRESS_FETCH_GPS]: 'Address_Fetch_Gps',
  [AnalyticsEvents.ADDRESS_FETCH_ZIPCODE]: 'Address_Fetch_ZipCode',
  [AnalyticsEvents.ADDRESS_SELECT_MAIN_ADDRESS]: 'fb_mobile_achievement_unlocked',
  [AnalyticsEvents.AUTH_REFRESHTOKEN]: 'Auth_RefreshToken',
  [AnalyticsEvents.AUTH_SIGNIN]: 'login',
  [AnalyticsEvents.AUTH_SIGNOUT]: 'logout',
  [AnalyticsEvents.AUTH_SIGNUP]: 'start_sign_up',
  [AnalyticsEvents.AUTH_VERIFY]: 'fb_mobile_complete_registration',
  [AnalyticsEvents.NAVIGATION]: 'fb_page_view',
  [AnalyticsEvents.SHOPS_FETCH_CATALOG]: 'fb_mobile_tutorial_completion',
  [AnalyticsEvents.SHOPS_VALIDATE_COUPON]: 'fb_mobile_spent_credits',
  [AnalyticsEvents.SEARCH]: 'fb_mobile_search',
  [AnalyticsEvents.ADD_TO_CART]: 'fb_mobile_add_to_cart',
  [AnalyticsEvents.REMOVE_FROM_CART]: 'remove_from_cart',
  [AnalyticsEvents.INITIATE_CHECKOUT]: 'fb_mobile_initiated_checkout',
  [AnalyticsEvents.CHECKOUT_INITIATED_PAYMENT_SELECTION]: 'fb_mobile_tutorial_completion',
  [AnalyticsEvents.CHECKOUT_SUMMARY_STEP]: 'fb_mobile_add_to_wishlist',
  [AnalyticsEvents.PURCHASE]: 'fb_mobile_level_achieved',
  [AnalyticsEvents.SELECT_CONTENT]: 'fb_mobile_content_view',
  [AnalyticsEvents.ADD_PAYMENT_INFO]: 'fb_mobile_add_payment_info',
  [AnalyticsEvents.DEVICE_CHANGE_LANGUAGE]: 'Device_ChangeLanguage',
  [AnalyticsEvents.DEVICE_FETCH_LANGUAGE]: 'Device_FetchLanguage',
  [AnalyticsEvents.DEVICE_SET_APPEARANCE]: 'Device_SetAppearance',
  [AnalyticsEvents.PROVIDERS_FETCH_ALL]: 'Delivery_FetchAllProviders',
  [AnalyticsEvents.LOCKERS_FETCH_ALL]: 'Delivery_FetchAllLockers',
  [AnalyticsEvents.DEEP_LINKING]: 'deep_linking',
  [AnalyticsEvents.FAILURE_LINKING]: 'failure_linking',
  [AnalyticsEvents.LIST_VIEW]: 'fb_mobile_rate',
} as const satisfies AnalyticsTokens;

export const FirebaseTokens = {
  [AnalyticsEvents.ADDRESS_FETCH_GPS]: 'Address_Fetch_Gps',
  [AnalyticsEvents.ADDRESS_FETCH_ZIPCODE]: 'Address_Fetch_ZipCode',
  [AnalyticsEvents.ADDRESS_SELECT_MAIN_ADDRESS]: 'Address_SelectMainAddress',
  [AnalyticsEvents.AUTH_REFRESHTOKEN]: 'Auth_RefreshToken',
  [AnalyticsEvents.AUTH_SIGNIN]: 'login',
  [AnalyticsEvents.AUTH_SIGNOUT]: 'logout',
  [AnalyticsEvents.AUTH_SIGNUP]: 'start_sign_up',
  [AnalyticsEvents.AUTH_VERIFY]: 'sign_up',
  [AnalyticsEvents.NAVIGATION]: 'Navigation',
  [AnalyticsEvents.SHOPS_FETCH_CATALOG]: 'Delivery_Provider_Fetch',
  [AnalyticsEvents.SHOPS_VALIDATE_COUPON]: 'Coupon_Validate',
  [AnalyticsEvents.SEARCH]: 'search',
  [AnalyticsEvents.ADD_TO_CART]: 'add_to_cart',
  [AnalyticsEvents.REMOVE_FROM_CART]: 'remove_from_cart',
  [AnalyticsEvents.INITIATE_CHECKOUT]: 'initiated_checkout',
  [AnalyticsEvents.CHECKOUT_INITIATED_PAYMENT_SELECTION]: 'checkout_initiated_payment_selection',
  [AnalyticsEvents.CHECKOUT_SUMMARY_STEP]: 'checkout_summary_step',
  [AnalyticsEvents.PURCHASE]: 'purchase',
  [AnalyticsEvents.SELECT_CONTENT]: 'select_content',
  [AnalyticsEvents.ADD_PAYMENT_INFO]: 'add_payment_info',
  [AnalyticsEvents.DEVICE_CHANGE_LANGUAGE]: 'Device_ChangeLanguage',
  [AnalyticsEvents.DEVICE_FETCH_LANGUAGE]: 'Device_FetchLanguage',
  [AnalyticsEvents.DEVICE_SET_APPEARANCE]: 'Device_SetAppearance',
  [AnalyticsEvents.PROVIDERS_FETCH_ALL]: 'Delivery_FetchAllProviders',
  [AnalyticsEvents.LOCKERS_FETCH_ALL]: 'Delivery_FetchAllLockers',
  [AnalyticsEvents.DEEP_LINKING]: 'deep_linking',
  [AnalyticsEvents.FAILURE_LINKING]: 'failure_linking',
  [AnalyticsEvents.LIST_VIEW]: 'list_view',
} as const satisfies AnalyticsTokens;

export const AnalyticsConstants = {
  RudderStack: RudderStackTokens,
  Adjust: AdjustTokens,
  Amplitude: AmplitudeTokens,
  AppsFlyer: AppsFlyerTokens,
  Facebook: FacebookTokens,
  Firebase: FirebaseTokens,
} as const;
