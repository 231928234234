import { observer } from 'mobx-react-lite';
import { useCallback, useEffect } from 'react';

import { LoginRequired, Modal } from '@ioupie/components/custom';
import { useAuthStore } from '@ioupie/hooks';

import { AuthModalProps } from './auth-modal.models';

/**
 * @function AuthModalContainer
 */
export default observer(({ dismissable = true }: AuthModalProps) => {
  const authStore = useAuthStore();

  const dismissAuthModal = useCallback(() => {
    authStore.setShowAuthModal(false);
  }, []);

  useEffect(() => {
    // it's not initializing the tokens anymore and doesn't have authorization data
    if (!authStore.haveAuthorizationData && !authStore.bootstrappingUserTokens) {
      authStore.setShowAuthModal(true);
    }
  }, [authStore.haveAuthorizationData, authStore.bootstrappingUserTokens]);

  return (
    <Modal dismissable={dismissable} visible={authStore.showAuthModal} onDismiss={dismissAuthModal}>
      <LoginRequired />
    </Modal>
  );
});
