/* eslint-disable no-void */
import * as amplitude from '@amplitude/analytics-react-native';
import { inject, injectable } from 'inversify';

import { AnalyticsConstants, AnalyticsEvents, VALUE_TYPES, type ServiceFlags } from '@ioupie/shared/constants';
import { type UserProfile } from '@ioupie/shared/models';

import { type AnalyticsService } from './analytics.service';
import { BaseAnalyticsService } from './base-analytics.service';

@injectable()
export class AmplitudeAnalyticsService extends BaseAnalyticsService implements AnalyticsService {
  public constructor(@inject(VALUE_TYPES.SERVICE_FLAGS) private readonly serviceFlags: ServiceFlags) {
    super();
  }

  public async setUser(userId: string) {
    if (!this.serviceFlags.analytics.amplitude.enable) {
      return Promise.resolve();
    }

    try {
      await new Promise((resolve, reject) => {
        try {
          resolve(amplitude.setUserId(userId));
        } catch (e) {
          reject(e);
        }
      });
    } catch (error) {
      console.error(`Amplitude user [${userId}] error`, error);
    }
  }

  public async setUserProperties(userProps: UserProfile) {
    if (!this.serviceFlags.analytics.amplitude.enable) {
      return Promise.resolve();
    }

    try {
      const stringUserProps = this.convert(userProps);

      const id = new amplitude.Identify();
      Object.entries(stringUserProps).forEach(([key, value]) => id.set(key, value));

      await amplitude.identify(id).promise;
    } catch (error) {
      console.error(`Amplitude user [${userProps?.subject}] properties error`, error);
    }
  }

  public async setScreen(screen: string, metaData: Record<string, string> = {}) {
    if (!this.serviceFlags.analytics.amplitude.enable) {
      return Promise.resolve();
    }

    try {
      const eventPayload = this.buildScreenPayload(screen, this.convert(metaData));

      await amplitude.logEvent(AnalyticsConstants.Amplitude.NAVIGATION, eventPayload).promise;
    } catch (error) {
      console.error(`Amplitude screen [${screen}] error`, error);
    }
  }

  public async trackEvent(event: AnalyticsEvents, eventData: Record<string, any> = {}) {
    if (!this.serviceFlags.analytics.amplitude.enable) {
      return Promise.resolve();
    }

    try {
      const prefixedEventName = this.prefixEventName(AnalyticsConstants.Amplitude[event]);

      await amplitude.logEvent(prefixedEventName, eventData).promise;
    } catch (error) {
      console.error(`Amplitude event [${event}] error`, error);
    }
  }

  public async trackUnprefixedEvent(event: AnalyticsEvents, eventData: Record<string, any> = {}) {
    if (!this.serviceFlags.analytics.amplitude.enable) {
      return Promise.resolve();
    }

    try {
      const prefixedEventName = this.prefixNativeEventName(AnalyticsConstants.Amplitude[event]);

      await amplitude.logEvent(prefixedEventName, eventData).promise;
    } catch (error) {
      console.error(`Amplitude native event [${event}] error`, error);
    }
  }
}
