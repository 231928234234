import styled from 'styled-components/native';

import { colors } from '@ioupie/shared/constants';
import { themefy } from '@ioupie/shared/utils';

import { LoginRequiredProps } from './login-required.models';

declare type CenterProps = Pick<LoginRequiredProps, 'fillVertical'>;

export const CenterView = styled.View<CenterProps>`
  align-items: center;
  background-color: ${themefy({
    light: colors.light.background,
    dark: colors.dark.background,
  })};
  height: ${({ fillVertical }) => {
    return fillVertical ? '100%' : 'auto';
  }};
  justify-content: center;
  padding: 20px;
  width: 100%;
`;
