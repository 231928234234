import styled from 'styled-components/native';
import { Text } from '@ioupie/components/typography';
import { fontsFamily } from '@ioupie/shared/constants';

export const IntroUsage = styled.View`
  margin: 16px 20px 0;
`;

export const IntroTitle = styled(Text)`
  font-family: ${fontsFamily.bold};
  font-size: 18px;
`;
