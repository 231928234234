import { darken, lighten } from 'polished';
import styled from 'styled-components/native';

import { Button } from '@ioupie/components/buttons';
import { Text } from '@ioupie/components/typography';
import { colors, fontsFamily, fontsWeight } from '@ioupie/shared/constants';
import { themefy } from '@ioupie/shared/utils';

export const AddressTitle = styled(Text)`
  color: ${themefy({
    light: colors.light.title,
    dark: colors.dark.title,
  })};
  font-family: ${fontsFamily.bold};
  font-size: 16px;
  font-weight: ${fontsWeight.bold};
  margin: 0 0 8px 0;
`;

export const AddressContainer = styled.View`
  margin: 16px;
`;

export const AddressStreet = styled(Text)`
  color: ${themefy({
    light: colors.light.title,
    dark: colors.dark.title,
  })};
  margin: 0 0 5px 0;
`;

export const AddressNeighborhood = styled(Text)`
  margin: 0 0 5px 0;
`;

export const AddressAddon = styled(Text)`
  color: ${themefy({ light: lighten(0.4, colors.light.text), dark: darken(0.2, colors.dark.text) })};
  font-size: 14px;
`;

export const AddAddressButton = styled(Button)`
  align-items: flex-end;
`;
