import { createStackNavigator } from '@react-navigation/stack';
import { memo } from 'react';

import { AddNewAddress, AddressesList } from '@ioupie/pages/address';
import { routes } from '@ioupie/shared/constants';

const Stack = createStackNavigator();

/**
 * @function AddressStack
 */
export default memo(() => {
  return (
    <Stack.Navigator initialRouteName={routes.pages.address.addresses_list} screenOptions={{ headerShown: false }}>
      <Stack.Screen name={routes.pages.address.addresses_list} component={AddressesList} />
      <Stack.Screen name={routes.pages.address.add_new_address} component={AddNewAddress} />
    </Stack.Navigator>
  );
});
