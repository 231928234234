import { useNavigation, useNavigationState } from '@react-navigation/native';
import { useCallback, useEffect } from 'react';

import { NavigationRoute } from '@ioupie/shared/models';
import { safeArrayLookup } from '@ioupie/shared/utils';

export const useCurrentRouteFocus = (route: string, onRouteFocusAction: () => void): NavigationRoute => {
  const currentRoute = useNavigationState((state) => safeArrayLookup(state.routes, state.index));
  const navigation = useNavigation();

  const tweakHeader = useCallback((): void => {
    if (currentRoute?.name === route) onRouteFocusAction();
  }, [currentRoute]);

  useEffect(() => {
    const unsubscribe = navigation.addListener('focus', tweakHeader);
    return unsubscribe;
  }, []);

  useEffect(() => {
    tweakHeader();
  }, [currentRoute]);

  return currentRoute;
};
