import * as Clipboard from 'expo-clipboard';
import { injectable } from 'inversify';

@injectable()
export class ClipboardService {
  public async copyToClipboard(text: string = ''): Promise<void> {
    await Clipboard.setStringAsync(text);
  }

  public async pasteFromClipboard(): Promise<string> {
    return Clipboard.getStringAsync();
  }
}
