import styled from 'styled-components/native';

import { Text } from '@ioupie/components/typography';
import { colors, fontsFamily, fontsWeight } from '@ioupie/shared/constants';
import { themefy } from '@ioupie/shared/utils';

export const PaymentHeader = styled.View`
  align-items: center;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  margin: 0 0 8px 16px;
`;

export const PaymentTitle = styled(Text)`
  color: ${themefy({
    light: colors.light.title,
    dark: colors.dark.title,
  })};
  font-family: ${fontsFamily.bold};
  font-size: 16px;
  font-weight: ${fontsWeight.bold};
  margin: 16px 0 10px;
`;

export const PaymentMethodContainer = styled.View`
  align-items: center;
  flex-direction: row;
  justify-content: space-between;
`;

export const MethodTypeContainer = styled.View`
  display: flex;
`;

type Selectable = {
  readonly selected?: boolean;
};

export const MethodDetailsContainer = styled.TouchableOpacity<Selectable>`
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
  border-radius: 10px;
  padding: 2px 10px;
  margin: 0px 15px;
  opacity: ${(props) => {
    if (props.selected) {
      return '1';
    }

    return '0.3';
  }};
`;

export const CreditReadOnlyContainer = styled.View`
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
  padding: 0 15px 15px;
`;

export const PixReadOnlyContainer = styled.View`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin: 4px 0 16px;
`;

export const MethodLabelsContainer = styled.View`
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  margin-left: 16px;
`;

export const MethodType = styled(Text)`
  font-size: 15px;
  margin-bottom: 5px;
`;

export const MethodTypeInfo = styled(Text)`
  margin: 0;
`;

export const SecurityCodeBlock = styled.View`
  flex: 1;
  margin-left: 10px;
`;

export const SecurityCodeInput = styled.View`
  align-items: center;
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  padding: 0 15px;
`;

export const SecurityCodeLabel = styled(Text)`
  font-family: ${fontsFamily.bold};
  font-size: 15px;
  font-weight: ${fontsWeight.bold};
`;
