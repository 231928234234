import { DateTime } from 'luxon';
import { observer } from 'mobx-react-lite';
import { Fragment, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { View } from 'react-native';
import { isPresent } from 'ts-is-present';

import { Loader, RadioButton } from '@ioupie/components/custom';
import { AttentionBox } from '@ioupie/components/custom/attention-box';
import { Divider } from '@ioupie/components/layout';
import { Text } from '@ioupie/components/typography';
import { useLockersStore, useOrdersStore, useShopsStore } from '@ioupie/hooks';
import { BUSINESS_AREA_OPTIONS, IANA_ZONE } from '@ioupie/shared/constants';
import { dateAfterBusinessDays, getPreviewDates } from '@ioupie/shared/utils';

import * as S from './schedule.styles';

/**
 * @function ScheduleContainer
 */
export default observer(() => {
  const [t] = useTranslation();
  const shopsStore = useShopsStore();
  const ordersStore = useOrdersStore();
  const lockersStore = useLockersStore();

  const { lockerProvider } = lockersStore ?? {};
  const { loading, blockedDates } = ordersStore ?? {};
  const { selectionInfo } = shopsStore ?? {};
  const { minimumDays } = selectionInfo ?? {};

  const isLocker = shopsStore.shopBusinessArea === BUSINESS_AREA_OPTIONS.LOCKER;

  const formattedDate = (orderDate?: string | null) => {
    if (!orderDate) {
      return '';
    }

    const locale = t('constants.locale.locale');
    const dateFormat = t('containers.orders.ordering.schedule.delivery-format');
    return DateTime.fromISO(orderDate).setZone(IANA_ZONE).setLocale(locale).toFormat(dateFormat);
  };

  const handlePickupDateChange = (value: string) => {
    ordersStore.setPickUpOrderDate(value);
    ordersStore.clearScheduledDate();
  };

  const renderAvailablePickupDates = (
    <Fragment>
      <S.PickupDescription>
        <S.PickupDescriptionText>{t('containers.orders.ordering.schedule.pickup-delivery')}</S.PickupDescriptionText>
      </S.PickupDescription>
      {loading ? (
        <Loader />
      ) : (
        <RadioButton.Group onValueChange={handlePickupDateChange} value={ordersStore.selectedPickupDate ?? ''}>
          {ordersStore.nextPickupDatesAvailable &&
            Object.entries(ordersStore.nextPickupDatesAvailable).map(([date, fee]) => (
              <View key={date}>
                <RadioButton.Item
                  mode="android"
                  label={formattedDate(DateTime.fromMillis(+date).toISO())}
                  value={date}
                />
                <S.DateContainer>
                  {/* <S.DateAvailable>{formattedDate(DateTime.fromMillis(+date).toISO())}</S.DateAvailable> */}
                  <S.DeliveryFee>
                    {fee.amount === 0
                      ? t('containers.orders.ordering.schedule.free-fee')
                      : t('containers.orders.ordering.schedule.delivery-fee-price', {
                          deliveryFeePrice: fee.amount.toFixed(2),
                        })}
                  </S.DeliveryFee>
                </S.DateContainer>
              </View>
            ))}
        </RadioButton.Group>
      )}
    </Fragment>
  );

  const firstPickupDateAvailable =
    ordersStore.nextPickupDatesAvailable && Object.keys(ordersStore.nextPickupDatesAvailable).length > 0
      ? Object.keys(ordersStore.nextPickupDatesAvailable)[0]
      : Date.now();

  const nextStartDayMillis: number = ordersStore.selectedPickupDate
    ? +ordersStore.selectedPickupDate
    : +firstPickupDateAvailable;

  const nextStartDay = dateAfterBusinessDays(
    DateTime.fromMillis(nextStartDayMillis).startOf('day').toJSDate(),
    minimumDays + 1, // 1 day just for pickup,
    blockedDates,
  );

  const numberOfDaysToShow = 5;
  const nextDatesList = getPreviewDates(DateTime.fromISO(nextStartDay).toJSDate(), numberOfDaysToShow, blockedDates)
    .slice(0, numberOfDaysToShow)
    .map((date) => date.toISO());

  const renderAvailableDeliveryDates = (
    <Fragment>
      {loading ? (
        <Loader />
      ) : (
        <RadioButton.Group
          onValueChange={(value) => ordersStore.setScheduleOrderDate(value)}
          value={ordersStore.scheduledOrderDate ?? ''}>
          {nextDatesList.filter(isPresent).map((dateAvailable: string) => (
            <RadioButton.Item
              mode="android"
              key={dateAvailable}
              label={formattedDate(dateAvailable)}
              value={dateAvailable}
            />
          ))}
        </RadioButton.Group>
      )}
    </Fragment>
  );

  useEffect(() => {
    const type = isLocker ? BUSINESS_AREA_OPTIONS.LOCKER : BUSINESS_AREA_OPTIONS.DELIVERY;
    // eslint-disable-next-line no-void
    void ordersStore.fetchScheduleDates(type, isLocker ? lockerProvider?.id : undefined);
  }, []);

  return (
    <Fragment>
      <S.ScheduleContainer>
        <S.ScheduleTitle>{t('containers.orders.ordering.schedule.pickup-title')}</S.ScheduleTitle>
        {isLocker ? (
          <Text>{t('containers.orders.ordering.schedule.pickup-description')}</Text>
        ) : (
          renderAvailablePickupDates
        )}
      </S.ScheduleContainer>
      <Divider />
      <S.ScheduleContainer>
        <S.ScheduleTitle>{t('containers.orders.ordering.schedule.delivery-title')}</S.ScheduleTitle>
        <Text>{t('containers.orders.ordering.schedule.delivery-description')}</Text>
        {renderAvailableDeliveryDates}
      </S.ScheduleContainer>
      {isLocker && <AttentionBox text={t('containers.orders.ordering.schedule.attention-box')} />}
    </Fragment>
  );
});
