import { darken, lighten } from 'polished';
import { Title } from 'react-native-paper';
import styled from 'styled-components/native';

import { Text } from '@ioupie/components/typography';
import { colors, fontsFamily, fontsWeight } from '@ioupie/shared/constants';
import { themefy } from '@ioupie/shared/utils';

export const ScheduleDetailsContainer = styled.View`
  border-top-color: ${themefy({
    light: lighten(0.6, colors.light.text),
    dark: darken(0.5, colors.dark.text),
  })};
  border-top-width: 3px;
  margin: 10px;
  padding: 10px;
`;

export const ScheduleDetailsTitle = styled(Title)`
  color: ${themefy({
    light: colors.light.text,
    dark: colors.dark.text,
  })};
  font-family: ${fontsFamily.bold};
  font-size: 16px;
  font-weight: ${fontsWeight.bold};
`;

export const ScheduleBlock = styled.View`
  margin: 10px 0;
`;

export const ScheduleBlockName = styled.View`
  align-items: center;
  flex: 1;
  flex-flow: row wrap;
  justify-content: left;
  margin: 0;
  padding: 0;
`;

export const ScheduleBlockContent = styled.View`
  padding: 0 0 0 40px;
`;

export const ScheduleBlockText = styled(Text)`
  margin: 4px 0;
`;
