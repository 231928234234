import { inject, injectable, postConstruct } from 'inversify';
import { action, computed, makeObservable, observable, runInAction } from 'mobx';

import { ErrorService, RestService, type AnalyticsService, type StorageService } from '@ioupie/services';
import {
  AnalyticsEvents,
  PATH_PARAMS,
  PAYMENT_METHOD_TYPES,
  SERVICE_TYPES,
  STORAGE_KEYS,
  STORE_TYPES,
  endpoints,
} from '@ioupie/shared/constants';
import type { CreditCard, ErrorMessages, PaymentMethod, UrlEndpoint } from '@ioupie/shared/models';
import { PaymentMethodsResponsePayload, SaveCreditCardPayload } from '@ioupie/shared/payloads';

import { AuthStore } from './auth.store';

@injectable()
export class PaymentStore {
  @inject(SERVICE_TYPES.STORAGE.ASYNC_STORAGE)
  private readonly storageService: StorageService;
  @inject(SERVICE_TYPES.REST)
  private readonly restService: RestService;
  @inject(SERVICE_TYPES.ERROR)
  private readonly errorService: ErrorService;
  @inject(SERVICE_TYPES.ANALYTICS.COMPOSITE)
  private readonly analyticsService: AnalyticsService;

  @inject(STORE_TYPES.AUTH)
  private readonly authStore: AuthStore;

  @observable public paymentMethods: readonly PaymentMethod[] = [];
  @observable public isDeleteDialogOpen: boolean = false;
  @observable public isSelectMethodDialogOpen: boolean = false;
  @observable public selectedPaymentMethod?: PaymentMethod;
  @observable public selectedPaymentType: PAYMENT_METHOD_TYPES = PAYMENT_METHOD_TYPES.PIX;

  @observable public loading: boolean = false;
  @observable public errors: ErrorMessages = [];

  @postConstruct()
  public init(): void {
    makeObservable(this);
  }

  @action
  public clearErrors(): void {
    this.errors = [];
  }

  @action
  public changePaymentType(paymentType: PAYMENT_METHOD_TYPES): void {
    this.selectedPaymentType = paymentType;
  }

  @action
  public showDeleteDialog(paymentMethod: PaymentMethod): void {
    this.selectedPaymentMethod = paymentMethod;
    this.isDeleteDialogOpen = true;
  }

  @action
  public closeDeleteDialog(): void {
    this.selectedPaymentMethod = undefined;
    this.isDeleteDialogOpen = false;
  }

  @action
  public showSelectMethodDialog(showDialog: boolean): void {
    this.isSelectMethodDialogOpen = showDialog;
  }

  @action
  public async saveSelectedMethod(paymentMethod: PaymentMethod): Promise<void> {
    this.loading = true;
    this.selectedPaymentMethod = paymentMethod;
    this.isSelectMethodDialogOpen = false;

    try {
      await this.storageService.persistData<PaymentMethod>(STORAGE_KEYS.SELECTED_PAYMENT_METHOD, paymentMethod);
      runInAction(() => {
        this.loading = false;
      });
    } catch (error) {
      runInAction(() => {
        this.errors = this.errorService.wrapApiError(error);
        this.loading = false;
      });
    }
  }

  @action
  public async saveCreditCard(creditCard: CreditCard): Promise<void> {
    this.loading = true;

    const urlEndpoint: UrlEndpoint = {
      url: endpoints.portaria.payment.create,
    };

    const payload: SaveCreditCardPayload = {
      methodType: PAYMENT_METHOD_TYPES.CREDIT,
      ...creditCard,
    };

    try {
      // maybe type later
      await this.restService.post<SaveCreditCardPayload, unknown>(
        urlEndpoint,
        payload,
        this.authStore.buildAuthHeaders(),
      );

      await this.fetchPaymentMethods();

      const sortByCreationDate = (method1: PaymentMethod, method2: PaymentMethod) =>
        (method2.createdAt ?? 0) - (method1.createdAt ?? 0);

      const sortedByCreation = this.paymentMethods.slice().sort(sortByCreationDate);
      if (sortedByCreation.length > 0) {
        await this.saveSelectedMethod(sortedByCreation[0]);
      }

      runInAction(() => {
        this.loading = false;
      });

      this.analyticsService.trackUnprefixedEvent(AnalyticsEvents.ADD_PAYMENT_INFO, {
        af_success: true, // AppsFlyer
        payment_method_type: 'credit card', // AppsFlyer
        payment_type: 'credit_card', // Google
        tick: '1', // Google
      });
    } catch (error) {
      runInAction(() => {
        this.errors = this.errorService.wrapApiError(error);
        this.loading = false;
      });
    }
  }

  @action
  public async fetchPaymentMethods(): Promise<void> {
    this.loading = true;

    const urlEndpoint: UrlEndpoint = {
      url: endpoints.portaria.payment.fetch_list,
    };

    try {
      const { paymentMethods } = await this.restService.get<PaymentMethodsResponsePayload>(
        urlEndpoint,
        this.authStore.buildAuthHeaders(),
      );

      const defaultMethod = await this.storageService.retrieveData<PaymentMethod>(STORAGE_KEYS.SELECTED_PAYMENT_METHOD);

      // if no previous selected method, pick the first one as the default
      if (!defaultMethod) {
        const selectedPaymentMethod = paymentMethods[0] ?? {};
        await this.storageService.persistData<PaymentMethod>(
          STORAGE_KEYS.SELECTED_PAYMENT_METHOD,
          selectedPaymentMethod,
        );
      }

      runInAction(() => {
        this.paymentMethods = paymentMethods;
        // cascade fallback
        this.selectedPaymentMethod = defaultMethod ?? paymentMethods[0] ?? {};
        this.loading = false;
      });
    } catch (error) {
      runInAction(() => {
        this.errors = this.errorService.wrapApiError(error);
        this.loading = false;
      });
    }
  }

  @action
  public async removePaymentMethod(paymentMethod: PaymentMethod): Promise<void> {
    this.loading = true;

    const urlEndpoint: UrlEndpoint = {
      url: endpoints.portaria.payment.delete,
      pathParams: { [PATH_PARAMS.METHOD_ID]: paymentMethod.id },
    };

    try {
      await this.restService.delete<void>(urlEndpoint, this.authStore.buildAuthHeaders());

      runInAction(() => {
        this.paymentMethods = this.paymentMethods.filter((method) => method.id !== paymentMethod.id);
        this.loading = false;
      });
    } catch (error) {
      runInAction(() => {
        this.errors = this.errorService.wrapApiError(error);
        this.loading = false;
      });
    }
  }

  @computed
  public get hasPaymentMethods(): boolean {
    return !this.loading && this.paymentMethods?.length > 0;
  }
}
