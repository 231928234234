import styled from 'styled-components/native';

declare type CenterTooltip = {
  readonly center?: boolean;
};

export default styled.View<CenterTooltip>`
  flex: 1;
  flex-direction: row;
  justify-content: ${({ center }) => {
    return center ? 'center' : 'space-between';
  }};
  padding-top: 10px;
  width: 100%;
`;
