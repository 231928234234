export const isEnumOf = <T extends object>(enumerator: T) => {
  return (data?: unknown): data is T[keyof T] => {
    // eslint-disable-next-line no-null/no-null
    if (data === undefined || data === null) {
      return false;
    } else {
      return Object.values(enumerator).includes(data as T[keyof T]);
    }
  };
};
