import { observer } from 'mobx-react-lite';
import { Fragment } from 'react';
import { useTranslation } from 'react-i18next';

import { BlockButton } from '@ioupie/components/buttons';
import { useOrdersStore, useNavigationStore, useSettingsStore } from '@ioupie/hooks';
import { routes } from '@ioupie/shared/constants';

import * as S from './ticket-generated.styles';

/**
 * @function TicketGeneratedContainer
 */
export default observer(() => {
  const [t] = useTranslation();
  const ordersStore = useOrdersStore();
  const settingsStore = useSettingsStore();
  const navigationStore = useNavigationStore();

  const handleGoHome = async (): Promise<void> => {
    navigationStore.dispatchNavigation({
      stack: routes.stacks.orders,
      screen: routes.pages.orders.home,
    });
    if (!settingsStore.pushNotificationPermissionAlreadyRequested) {
      await settingsStore.requestPushNotificationPermission();
    }
  };

  const handleUnlockLocker = async (): Promise<void> => {
    await ordersStore.fetchUserOrdersHistory();
    // @todo redirect to tab my-orders
    navigationStore.dispatchNavigation({
      stack: routes.stacks.orders,
      screen: routes.pages.orders.track_order,
    });
  };

  return (
    <Fragment>
      <S.ThankMessage>
        <S.TitleMessage>{t('containers.orders.thank.ticket-generated.success')}</S.TitleMessage>
        <S.TextMessage>{t('containers.orders.thank.ticket-generated.finish-order')}</S.TextMessage>
      </S.ThankMessage>

      <S.ButtonActions>
        <BlockButton text={t('containers.orders.thank.ticket-generated.finish-now')} onPress={handleUnlockLocker} />
        <BlockButton
          secondary
          text={t('containers.orders.thank.ticket-generated.finish-later')}
          onPress={handleGoHome}
        />
      </S.ButtonActions>
    </Fragment>
  );
});
