import { memo } from 'react';

import { AgendaProps } from './agenda.models';
import * as S from './agenda.styles';

/**
 * @function AgendaComponent
 */
export default memo(({ subject, content }: AgendaProps) => (
  <S.Agenda>
    <S.AgendaText>{subject}</S.AgendaText>
    <S.AgendaText>{content}</S.AgendaText>
  </S.Agenda>
));
