import { DrawerContentScrollView } from '@react-navigation/drawer';
import { StyleSheet } from 'react-native';
import styled from 'styled-components/native';

import { IoupieLogo } from '@ioupie/components/gallery';
import { colors, statusBarHeight } from '@ioupie/shared/constants';
import { themefy } from '@ioupie/shared/utils';

export const styles = StyleSheet.create({
  scrollContent: { paddingTop: '5%' },
});

export const LogoContainer = styled.View`
  background-color: ${themefy({
    light: colors.light.primary,
    dark: colors.dark.primary,
  })};
  height: 15%;
  justify-content: center;
  padding-top: ${statusBarHeight}px;
`;

export const LogoBanner = styled(IoupieLogo)`
  margin: 5%;
  width: 35%;
`;

export const UnderLogoView = styled(DrawerContentScrollView)`
  margin: 0;
  padding: 0;
  top: 0;
`;
