import { inject, injectable, postConstruct } from 'inversify';
import { DateTime } from 'luxon';
import { action, computed, makeObservable, observable, runInAction } from 'mobx';
import { isPresent } from 'ts-is-present';
import { v4 as uuidV4 } from 'uuid';

import { ClipboardService, EncodeService, ErrorService, RestService, type AnalyticsService } from '@ioupie/services';
import {
  AnalyticsEvents,
  BUSINESS_AREA_OPTIONS,
  ORDERING_STEPS,
  ORDER_STATUS,
  ORDER_STATUS_CATEGORY,
  ORDER_STEPS,
  PATH_PARAMS,
  PAYMENT_METHOD_TYPES,
  PAYMENT_STATUS,
  QUERY_PARAMS,
  SERVICE_TYPES,
  STORE_TYPES,
  THANK_TYPE,
  endpoints,
  routes,
} from '@ioupie/shared/constants';
import type {
  ErrorMessages,
  GetOrderInstallmentsPriceOutput,
  GetOrderInstallmentsPriceOutputEntry,
  LockerCompartment,
  Money,
  MoneyWrap,
  NpsEvaluationOrder,
  NpsEvaluationPayload,
  OrderTicket,
  PayInfo,
  ScheduleDates,
  ServiceOrder,
  UrlEndpoint,
} from '@ioupie/shared/models';
import {
  ActivateTicketResponsePayload,
  CreateOrderRequestPayload,
  HistoryFromUserResponsePayload,
  OrderHistoryResponsePayload,
  RequestOrderHistoryPayload,
  RequestServicePayload,
} from '@ioupie/shared/payloads';
import {
  getPreviewDates,
  isEnumOf,
  isOrderClosedStatus,
  isOrderPendingUserActionStatus,
  safeObjectLookup,
} from '@ioupie/shared/utils';

import { AddressStore } from './address.store';
import { AuthStore } from './auth.store';
import { LockersStore } from './lockers.store';
import { PaymentStore } from './payment.store';
import { ShopsStore } from './shops.store';

@injectable()
export class OrdersStore {
  @inject(SERVICE_TYPES.REST)
  private readonly restService: RestService;
  @inject(SERVICE_TYPES.CLIPBOARD)
  private readonly clipboardService: ClipboardService;
  @inject(SERVICE_TYPES.ERROR)
  private readonly errorService: ErrorService;
  @inject(SERVICE_TYPES.ENCODE)
  private readonly encodeService: EncodeService;
  @inject(SERVICE_TYPES.ANALYTICS.COMPOSITE)
  private readonly analyticsService: AnalyticsService;

  @inject(STORE_TYPES.ADDRESS)
  private readonly addressStore: AddressStore;
  @inject(STORE_TYPES.SHOPS)
  private readonly shopsStore: ShopsStore;
  @inject(STORE_TYPES.LOCKERS)
  private readonly lockersStore: LockersStore;
  @inject(STORE_TYPES.PAYMENT)
  private readonly paymentStore: PaymentStore;
  @inject(STORE_TYPES.AUTH)
  private readonly authStore: AuthStore;

  @observable public ordersHistory: readonly ServiceOrder[] = [];
  @observable public orderStep: ORDER_STEPS = ORDER_STEPS.FINISH_ORDER;
  @observable public orderingStep: ORDERING_STEPS = ORDERING_STEPS.BASKET;
  @observable public thankType: THANK_TYPE = THANK_TYPE.TICKET_GENERATED;

  @observable public loading: boolean = false;
  @observable public errors: ErrorMessages = [];

  @observable public isFarFromLocker: boolean = false;
  @observable public orderPaymentPayload: string;
  @observable public scheduleDates: ScheduleDates;

  @observable public blockedDates: DateTime[] = [];
  @observable public selectedOrderHistory?: ServiceOrder;
  @observable public selectedPickupDate?: string;
  @observable public scheduledOrderDate?: string;
  @observable public currentCompartment?: LockerCompartment;
  @observable public showRejectionModal?: boolean = false;

  @observable public nextPickupDatesAvailable?: Record<number, Money>;
  @observable public nextDeliveryDatesAvailable: string[] = [];

  @observable public detailsOfNewOrder: boolean = false;

  @observable public categoriesFilterValue: ORDER_STATUS_CATEGORY = ORDER_STATUS_CATEGORY.ALL;
  @observable public showCategoriesFilter: boolean = false;

  @observable public filterQuery: string = '';
  @observable public currentServiceOrderId: string = '';
  @observable public npsEvaluationOrder: NpsEvaluationOrder;

  @observable public installmentOfferings: Record<string, GetOrderInstallmentsPriceOutputEntry> = {};
  @observable public installments = '1';
  @observable public showInstallmentOptions: boolean = false;

  @postConstruct()
  public init(): void {
    makeObservable(this);
  }

  @action
  public clearErrors(): void {
    this.errors = [];
  }

  @action
  public setError(error: unknown): void {
    this.errors = this.errorService.wrapApiError(error);
  }

  @action
  public toggleShowCategoryFilter() {
    this.showCategoriesFilter = !this.showCategoriesFilter;
  }

  @action
  public toggleShowInstallmentOptions() {
    this.showInstallmentOptions = !this.showInstallmentOptions;
  }

  @action
  public setDetailsOfNewOrder(value: boolean) {
    this.detailsOfNewOrder = value;
  }

  @action
  public setInstallments(value: string) {
    this.installments = value;
  }

  @action
  public onStatusCategorySelection(value: string) {
    const isStatusCategory = isEnumOf(ORDER_STATUS_CATEGORY);

    if (isStatusCategory(value)) {
      this.categoriesFilterValue = value;
    }
  }

  @action
  public setScheduleOrderDate(scheduleOrderDate: string): void {
    this.scheduledOrderDate = scheduleOrderDate;

    this.analyticsService.trackEvent(AnalyticsEvents.SELECT_CONTENT, {
      content_type: 'DropOffOrderDate',
      item_id: scheduleOrderDate,
    }); // Google
  }

  @action
  public setPickUpOrderDate(pickUpOrderDate: string): void {
    this.selectedPickupDate = pickUpOrderDate;

    this.analyticsService.trackEvent(AnalyticsEvents.SELECT_CONTENT, {
      content_type: 'PickupOrderDate',
      item_id: pickUpOrderDate,
    }); // Google
  }

  @action
  public setFilterQuery(query: string): void {
    this.filterQuery = query;
    if (query && query.length > 0) {
      this.analyticsService.trackEvent(AnalyticsEvents.SEARCH, { history_search: query });
    }
  }

  @action
  public setNpsEvaluationOrder(value: NpsEvaluationOrder) {
    this.npsEvaluationOrder = value;
  }

  @action
  public showFarFromLocker(): void {
    this.isFarFromLocker = true;
  }

  @action
  public clearFarFromLocker(): void {
    this.isFarFromLocker = false;
  }

  @action
  public clearScheduledDate(): void {
    this.scheduledOrderDate = '';
  }

  @action
  public clearSelectedPickupDate(): void {
    this.selectedPickupDate = '';
  }

  @action
  public clearCurrentCompartment(): void {
    this.currentCompartment = undefined;
  }

  @action
  public clearInstallmentsData(): void {
    this.installmentOfferings = {};
    this.installments = '1';
  }

  @action
  public changeStep(step: ORDER_STEPS): void {
    this.orderStep = step;
    this.analyticsService.setScreen(`${routes.stacks.orders}/${routes.pages.orders.track_order}/${step.toString()}`);
  }

  @action
  public copyPixCodeToClipboard(pixCode: string): void {
    // eslint-disable-next-line no-void
    void this.clipboardService.copyToClipboard(pixCode);
  }

  @action
  public changeOrderingStep(step: ORDERING_STEPS): void {
    this.orderingStep = step;
    this.analyticsService.setScreen(`${routes.stacks.orders}/${routes.pages.orders.ordering}/${step.toString()}`);
  }

  @action
  public recordInAppEventForJourney(event: AnalyticsEvents, eventData?: Record<string, any>): void {
    // eslint-disable-next-line no-void
    void this.analyticsService.trackUnprefixedEvent(event, eventData);
  }

  @action
  public changeThankType(type: THANK_TYPE): void {
    this.thankType = type;
  }

  @action
  public setShowRejectionModal(show: boolean): void {
    this.showRejectionModal = show;
  }

  @action
  public setOrderPaymentPayload(payInfo: PayInfo): void {
    this.orderPaymentPayload = this.encodeService.encodePayloadToBase64(payInfo);
  }

  @action
  public getInboundFeeByPickupDate(): number {
    return (
      (Number(this.selectedPickupDate) &&
        safeObjectLookup(this.nextPickupDatesAvailable, Number(this.selectedPickupDate))?.amount) ??
      0
    );
  }

  @action
  public async trackOrderHistory(orderHistory: ServiceOrder): Promise<void> {
    const { serviceProviderId, orderNumber } = orderHistory;
    this.loading = true;
    try {
      const urlEndpoint: UrlEndpoint = {
        url: endpoints.harvey.orders.search,
        pathParams: { [PATH_PARAMS.USER_ID]: this.authStore.username },
      };

      const payload: RequestOrderHistoryPayload = {
        serviceProviderId,
        orderNumber,
      };

      const { serviceOrderInformation } = await this.restService.post<
        RequestOrderHistoryPayload,
        OrderHistoryResponsePayload
      >(urlEndpoint, payload, this.authStore.buildAuthHeaders());

      runInAction(() => {
        this.selectedOrderHistory = serviceOrderInformation;
        this.loading = false;
      });
    } catch (error) {
      runInAction(() => {
        this.errors = this.errorService.wrapApiError(error);
        this.loading = false;
      });
    }
  }

  @action
  public async createServiceOrder(ticket: OrderTicket, expectedAmount: number = 0): Promise<void> {
    this.loading = true;

    try {
      if (ticket.type === BUSINESS_AREA_OPTIONS.DELIVERY && !this.addressStore.selectedAddressInfo) {
        throw new Error('Erro: Nenhum endereço informado.');
      }

      const urlEndpoint: UrlEndpoint = {
        url: endpoints.harvey.orders.manage_order,
      };

      const pickupAmount = this.getInboundFeeByPickupDate();
      this.currentServiceOrderId = uuidV4();

      const payload: CreateOrderRequestPayload = {
        orderId: this.currentServiceOrderId,
        status: ORDER_STATUS.DRAFT,
        address: this.addressStore.selectedAddressInfo,
        lockerId: ticket.type === BUSINESS_AREA_OPTIONS.LOCKER ? this.lockersStore.lockerProvider?.id : undefined,
        _payload: this.orderPaymentPayload ?? '',
        coupon: this.shopsStore.coupon ?? '',
        paymentMethodType: PAYMENT_METHOD_TYPES.CREDIT,
        requestedInboundDate: this.selectedPickupDate
          ? DateTime.fromMillis(+this.selectedPickupDate).toISO()
          : undefined,
        requestedOutboundDate: this.scheduledOrderDate,
        inboundFee: pickupAmount,
        installments: parseInt(this.installments, 10),
        ...ticket,
      };

      const response = await this.restService.post<CreateOrderRequestPayload, ServiceOrder>(
        urlEndpoint,
        payload,
        this.authStore.buildAuthHeaders(),
      );

      runInAction(() => {
        this.loading = false;
        this.selectedOrderHistory = response;
      });

      // Analytics
      const productMap = Object.keys(ticket.products).map((key) => ({
        item_id: key,
        item_name: safeObjectLookup(ticket.products, key)?.product?.name,
        quantity: safeObjectLookup(ticket.products, key)?.amount,
      }));

      const registerUnprefixedEventData = {
        currency: 'BRL',
        transaction_id: this.currentServiceOrderId,
        value: expectedAmount,
        shipping: pickupAmount,
        coupon: this.shopsStore.coupon ?? '',
        items: productMap,
        serviceProviderId: ticket.serviceProviderId,
        orderNumber: response.orderNumber,
        type: ticket.type,
      };
      this.analyticsService.trackUnprefixedEvent(AnalyticsEvents.PURCHASE, registerUnprefixedEventData); // Google
      this.analyticsService.trackEvent(AnalyticsEvents.PURCHASE, registerUnprefixedEventData); // Adjust

      // AppsFlyer
      this.analyticsService.trackUnprefixedEvent(AnalyticsEvents.PURCHASE, {
        af_revenue: expectedAmount,
        af_price: expectedAmount,
        af_content_id: productMap.map((product) => product.item_id).join(','),
        af_currency: 'BRL',
        af_quantity: productMap.map((product) => product.quantity).join(','),
        af_order_id: response.orderNumber,
        af_receipt_id: response.orderNumber,
        orderNumber: response.orderNumber,
      });
    } catch (error) {
      runInAction(() => {
        this.errors = this.errorService.wrapApiError(error);
        this.loading = false;
      });
    }
  }

  @action
  public async fetchUserOrdersHistory(): Promise<void> {
    // if no auth data, just bypass
    if (!this.authStore.haveAuthorizationData) {
      return Promise.resolve();
    }

    this.loading = true;
    try {
      const urlEndpoint: UrlEndpoint = {
        url: endpoints.harvey.orders.history_from_user,
        pathParams: { [PATH_PARAMS.USER_ID]: this.authStore.username },
      };

      const { orders = [] } = await this.restService.get<HistoryFromUserResponsePayload>(
        urlEndpoint,
        this.authStore.buildAuthHeaders(),
      );

      runInAction(() => {
        this.ordersHistory = orders;
        this.loading = false;
      });
    } catch (error) {
      runInAction(() => {
        this.errors = this.errorService.wrapApiError(error);
        this.loading = false;
      });
    }
  }

  @action
  public logOrderTrackingNavigation(screen: string): void {
    this.analyticsService.setScreen(`${routes.stacks.orders}/${routes.pages.orders.track_order}/${screen}`);
  }

  @action
  public async rejectServiceOrder(serviceOrder?: ServiceOrder): Promise<void> {
    this.loading = true;
    try {
      if (!serviceOrder) {
        throw new Error('No provided service order!');
      }

      const urlEndpoint: UrlEndpoint = {
        url: endpoints.harvey.service_orders.service_order,
        pathParams: { [PATH_PARAMS.ORDER_ID]: serviceOrder.id },
      };

      const payload: ServiceOrder = {
        ...serviceOrder,
        status: ORDER_STATUS.REJECTED,
      };

      await this.restService.put<ServiceOrder, unknown>(urlEndpoint, payload, this.authStore.buildAuthHeaders());

      runInAction(() => {
        this.loading = false;
        this.orderStep = ORDER_STEPS.REJECTED;
      });
    } catch (error) {
      runInAction(() => {
        this.errors = this.errorService.wrapApiError(error);
        this.loading = false;
      });
    }
  }

  @action
  public async getFreeCompartmentNumber(): Promise<void> {
    this.loading = true;
    try {
      if (!this.lockersStore.lockerProvider) {
        throw new Error('No locker provider selected!');
      }

      // First, verify if user is next to the locker
      const lockerInRadius = await this.addressStore.isNextToLocker(this.lockersStore.lockerProvider);

      if (!lockerInRadius) {
        runInAction(() => {
          this.loading = false;
          this.isFarFromLocker = true;
        });

        return;
      }

      const urlEndpoint: UrlEndpoint = {
        url: endpoints.harvey.orders.service,
      };

      const payload: RequestServicePayload = {
        serviceOrderId: this.selectedOrderHistory?.id ?? '',
        userId: this.authStore.username,
        status: ORDER_STATUS.PENDING_PROVIDER_INGESTION,
      };

      const response = await this.restService.post<RequestServicePayload, ActivateTicketResponsePayload>(
        urlEndpoint,
        payload,
        this.authStore.buildAuthHeaders(),
      );

      runInAction(() => {
        this.loading = false;
        this.currentCompartment = response.compartment;
      });
    } catch (error) {
      runInAction(() => {
        this.errors = this.errorService.wrapApiError(error);
        this.loading = false;
      });
    }
  }

  @action
  public async activateTicket(laundryCaseNumber?: string): Promise<void> {
    this.loading = true;
    try {
      const urlEndpoint: UrlEndpoint = {
        url: endpoints.harvey.orders.service,
      };

      const payload: RequestServicePayload = {
        laundryCaseNumber,
        userId: this.authStore.username,
        _payload: this.orderPaymentPayload ?? '',
        requestedOutboundDate: this.scheduledOrderDate,
        serviceOrderId: this.selectedOrderHistory?.id ?? '',
        status: laundryCaseNumber ? ORDER_STATUS.PENDING_PROVIDER_INGESTION : ORDER_STATUS.PENDING_PROVIDER_OS,
      };

      const updatedOrder = await this.restService.post<RequestServicePayload, ServiceOrder>(
        urlEndpoint,
        payload,
        this.authStore.buildAuthHeaders(),
      );

      runInAction(() => {
        this.loading = false;
        this.currentCompartment = undefined;
        this.thankType = THANK_TYPE.TICKET_ACTIVATED;
        this.selectedOrderHistory = updatedOrder;
      });
    } catch (error) {
      runInAction(() => {
        this.errors = this.errorService.wrapApiError(error);
        this.loading = false;
      });
    }
  }

  @action
  public async removeTicket(serviceOrderId = ''): Promise<void> {
    this.loading = true;
    try {
      const urlEndpoint: UrlEndpoint = {
        url: endpoints.harvey.orders.service,
      };

      const payload: RequestServicePayload = {
        serviceOrderId,
        userId: this.authStore.username,
        status: ORDER_STATUS.CANCELLED,
      };

      const response = await this.restService.post<RequestServicePayload, ActivateTicketResponsePayload>(
        urlEndpoint,
        payload,
        this.authStore.buildAuthHeaders(),
      );

      runInAction(() => {
        this.loading = false;
      });
    } catch (error) {
      runInAction(() => {
        this.errors = this.errorService.wrapApiError(error);
        this.loading = false;
      });
    }
  }

  @action
  public async finishServiceOrder(serviceOrder?: ServiceOrder): Promise<void> {
    this.loading = true;
    try {
      if (!serviceOrder) {
        throw new Error('No provided service order!');
      }

      const urlEndpoint: UrlEndpoint = {
        url: endpoints.harvey.orders.manage_order,
      };

      const payload: RequestServicePayload = {
        serviceOrderId: serviceOrder.id,
        userId: serviceOrder.userId,
        status: ORDER_STATUS.SUCCESS,
      };

      await this.restService.post<RequestServicePayload, unknown>(
        urlEndpoint,
        payload,
        this.authStore.buildAuthHeaders(),
      );

      runInAction(() => {
        this.loading = false;
        this.currentServiceOrderId = serviceOrder.id;
      });
    } catch (error) {
      runInAction(() => {
        this.errors = this.errorService.wrapApiError(error);
        this.loading = false;
      });
    }
  }

  @action
  public async fetchInstallmentOptions(): Promise<void> {
    this.loading = true;

    try {
      const urlEndpoint: UrlEndpoint = {
        url: endpoints.precificador.price.installments,
      };

      const response = await this.restService.post<MoneyWrap, GetOrderInstallmentsPriceOutput>(
        urlEndpoint,
        {
          grandTotal: {
            amount: this.shopsStore.totalPrice,
            currencyCode: 'BRL',
          },
        },
        this.authStore.buildAuthHeaders(),
      );

      const parsedOfferings = response.offerings.reduce((options, offer) => {
        return {
          ...options,
          [offer.installments.toString()]: {
            ...offer,
            installments: offer.installments.toString(),
          },
        };
      }, {});

      runInAction(() => {
        this.installmentOfferings = parsedOfferings;
        this.loading = false;
      });
    } catch (error) {
      runInAction(() => {
        this.errors = this.errorService.wrapApiError(error);
        this.loading = false;
      });
    }
  }

  @action
  public async fetchScheduleDates(type: BUSINESS_AREA_OPTIONS, lockerId?: string, grandTotal?: number): Promise<void> {
    this.loading = true;
    try {
      const urlEndpoint: UrlEndpoint = {
        url: endpoints.harvey.orders.dates,
        pathParams: { [PATH_PARAMS.ORDER_TYPE]: type },
        queryParams: {
          [QUERY_PARAMS.ORDER_DATES]: {
            deviceId: type === BUSINESS_AREA_OPTIONS.LOCKER ? lockerId : undefined,
            serviceProviderId: this.shopsStore.shopProvider?.id,
            grandTotal: this.orderPrice ?? 0,
            products: this.encodeService.encodePayloadToBase64(this.shopsStore.selectedProducts),
          },
        },
      };

      const response = await this.restService.get<ScheduleDates>(urlEndpoint, this.authStore.buildAuthHeaders());
      const { nonBusinessDays, unavailablePeriods, pickupDates } = response;
      const blockedDatesList =
        [...nonBusinessDays, ...unavailablePeriods].map((date) => DateTime.fromMillis(date).startOf('day')) ?? [];
      const minDate = DateTime.now()
        .startOf('day')
        .plus({ days: this.shopsStore.selectionInfo.minimumDays + 1 ?? 0 })
        .toJSDate();
      const nextDeliveryDates = getPreviewDates(minDate, 5, blockedDatesList)
        .map((date) => date.toISO())
        .filter(isPresent);

      runInAction(() => {
        this.scheduleDates = response;
        this.blockedDates = blockedDatesList;
        this.nextPickupDatesAvailable = pickupDates;
        this.nextDeliveryDatesAvailable = nextDeliveryDates;
        this.loading = false;
      });
    } catch (error) {
      runInAction(() => {
        this.errors = this.errorService.wrapApiError(error);
        this.loading = false;
      });
    }
  }

  @action
  public async sendNpsEvaluation(data: NpsEvaluationOrder): Promise<void> {
    this.loading = true;
    try {
      const urlEndpoint: UrlEndpoint = {
        url: endpoints.harvey.orders.nps,
        pathParams: { [PATH_PARAMS.ORDER_ID]: this.currentServiceOrderId || this.selectedOrderHistory?.id },
      };

      const payload = {
        order: data,
      };

      await this.restService.post<NpsEvaluationPayload, unknown>(
        urlEndpoint,
        payload,
        this.authStore.buildAuthHeaders(),
      );

      runInAction(() => {
        this.npsEvaluationOrder = {} as NpsEvaluationOrder;
        this.loading = false;
      });
    } catch (error) {
      runInAction(() => {
        this.errors = this.errorService.wrapApiError(error);
        this.loading = false;
      });
    }
  }

  @computed
  public get filteredOrdersHistory(): readonly ServiceOrder[] {
    const queryFiltered =
      this.filterQuery !== ''
        ? this.ordersHistory.filter(
            (order) => order.id.includes(this.filterQuery) || order.orderNumber.includes(this.filterQuery),
          )
        : this.ordersHistory;

    // filter by state
    return queryFiltered.filter((order) => {
      const { status } = order;

      if (this.categoriesFilterValue === ORDER_STATUS_CATEGORY.PENDING && isOrderPendingUserActionStatus(status)) {
        return true;
      }

      if (this.categoriesFilterValue === ORDER_STATUS_CATEGORY.DONE && isOrderClosedStatus(status)) {
        return true;
      }

      return this.categoriesFilterValue === ORDER_STATUS_CATEGORY.ALL;
    });
  }

  @computed
  public get hasPaymentInformation(): boolean {
    const methodsLength = this.selectedOrderHistory?.payment?.involvedPayments?.length ?? 0;
    return !this.loading && methodsLength > 0;
  }

  @computed
  public get totalQuantity(): number {
    const { products = [] } = this.selectedOrderHistory ?? {};
    return products.reduce((totalQuantity, next) => totalQuantity + next.quantity, 0);
  }

  @computed
  public get originalTotalAmount(): number {
    const { payment } = this.selectedOrderHistory ?? {};
    const { involvedPayments = [] } = payment ?? {};
    return involvedPayments.reduce((totalAmount, next) => {
      const { amount: paymentAmount } = next.amount ?? {};
      return totalAmount + paymentAmount;
    }, 0);
  }

  @computed
  public get hasInvolvedPaymentWithAuthorizationStatus(): boolean {
    const { payment } = this.selectedOrderHistory ?? {};
    const { involvedPayments = [] } = payment ?? {};

    return involvedPayments.some((involvedPayment) => involvedPayment.status === PAYMENT_STATUS.AUTHORIZATION);
  }

  @computed
  public get nextDatesAvailable(): string[] {
    return this.scheduledOrderDate
      ? [...new Set([...this.nextDeliveryDatesAvailable, this.scheduledOrderDate])]
      : this.nextDeliveryDatesAvailable;
  }

  @computed
  public get pickupFee(): number {
    const pickupAmount = safeObjectLookup(this.nextPickupDatesAvailable, Number(this.selectedPickupDate))?.amount ?? 0;
    return this.shopsStore.isDelivery ? pickupAmount : 0;
  }

  @computed
  public get orderPrice() {
    return this.shopsStore.estimatedPrice + this.installmentCharge + this.pickupFee;
  }

  @computed
  public get installmentsAvailable() {
    return (
      this.paymentStore.selectedPaymentType === PAYMENT_METHOD_TYPES.CREDIT &&
      Object.values(this.installmentOfferings).length > 0
    );
  }

  @computed
  public get installmentCharge() {
    return this.installmentsAvailable
      ? (safeObjectLookup(this.installmentOfferings, this.installments)?.additionalAmount.amount ?? 0)
      : 0;
  }
}
