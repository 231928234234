import { lighten } from 'polished';
import styled from 'styled-components/native';

import { colors } from '@ioupie/shared/constants';
import { themefy } from '@ioupie/shared/utils';

export const FinishOrderContainer = styled.View`
  background-color: ${themefy({
    light: colors.light.background,
    dark: colors.dark.background,
  })};
  border: 1px solid
    ${themefy({
      light: lighten(0.6, colors.light.secondary),
      dark: colors.dark.secondary,
    })};
  margin: 10px;
`;

export const OrderActions = styled.View`
  flex-direction: row;
  justify-content: center;
  margin: 0 8px 8px;
`;
