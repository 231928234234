import { yupResolver } from '@hookform/resolvers/yup';
import { observer } from 'mobx-react-lite';
import { Fragment, useCallback, useEffect } from 'react';
import { useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { Keyboard, TouchableOpacity, View } from 'react-native';
import * as yup from 'yup';

import { BlockButton } from '@ioupie/components/buttons';
import { ErrorSnackbar, UserStep } from '@ioupie/components/custom';
import { PasswordInput } from '@ioupie/components/inputs';
import { TooltipBar } from '@ioupie/components/layout';
import { Bold, HelperText, Text, Title } from '@ioupie/components/typography';
import { useAuthStore } from '@ioupie/hooks';
import { USER_STEPS } from '@ioupie/shared/constants';
import { debounceOneSecond } from '@ioupie/shared/utils';

import { SignInForm } from './sign-in.models';

/**
 * @function SignInStepComponent
 */
export default observer(() => {
  const [t] = useTranslation();
  const authStore = useAuthStore();

  const signInSchema: yup.SchemaOf<SignInForm> = yup.object().shape({
    userPassword: yup
      .string()
      .required(t('forms.sign-in.password.errors.required'))
      .min(8, ({ min }) => t('forms.sign-in.password.errors.min', { min })),
  });

  const {
    register,
    handleSubmit,
    setValue,
    formState: { errors, isValid },
  } = useForm<SignInForm>({
    defaultValues: {
      userPassword: '',
    },
    resolver: yupResolver(signInSchema),
  });

  useEffect(() => {
    register('userPassword');
  }, [register]);

  const onSubmitValidForm = useCallback((form: SignInForm) => {
    Keyboard.dismiss();
    return authStore.doSignIn(form.userPassword);
  }, []);

  const SignInContent = (
    <View>
      <Title>{t('forms.sign-in.title', { name: authStore.userFirstName })}</Title>
      <Text>{t('forms.sign-in.instruction')}</Text>
      <PasswordInput
        autoCompleteType="password"
        error={Boolean(errors.userPassword)}
        label={t('forms.sign-in.password.label')}
        placeholder={t('forms.sign-in.password.placeholder')}
        onChangeText={debounceOneSecond((text) => {
          setValue('userPassword', text, { shouldValidate: true });
        })}
      />
      <HelperText type="error" visible={Boolean(errors.userPassword)}>
        {errors.userPassword?.message ?? ''}
      </HelperText>
    </View>
  );

  const SignInActions = (
    <BlockButton
      blacken
      disabled={!isValid}
      loading={authStore.loading}
      text={t('forms.sign-in.submit')}
      onPress={handleSubmit(onSubmitValidForm)}
    />
  );

  const SignInTooltip = (
    <TooltipBar center>
      <TouchableOpacity onPress={() => authStore.changeStep(USER_STEPS.FORGOT_PASSWORD)}>
        <Bold black>{t('containers.account.sign-in.forgot-password')}</Bold>
      </TouchableOpacity>
    </TooltipBar>
  );

  return (
    <Fragment>
      <UserStep content={SignInContent} actions={SignInActions} tooltip={SignInTooltip} />
      <ErrorSnackbar errors={authStore.errors} onDismiss={() => authStore.clearErrors()} />
    </Fragment>
  );
});
