import { BaseEntity, Money } from '..';

export declare type ServiceProvider = BaseEntity & {
  readonly segment: string;
  readonly status: string;
  readonly corporateName: string;
  readonly taxpayerId: string;
  readonly addressStreet?: string;
  readonly addressNumber?: string;
  readonly addressComplement?: string;
  readonly addressNeighborhood?: string;
  readonly addressCity?: string;
  readonly addressState?: string;
  readonly addressZipCode?: string;
  readonly maxAcceptableDistance?: number;
  readonly phoneNumber: string;
  readonly responsibleName: string;
  readonly responsibleEmail: string;
  readonly responsibleRole: string;
  readonly lockers: readonly ServiceProviderLockerEntry[];
  readonly logo?: string;
  readonly metadata?: ServiceProviderMetadata;
};

export declare type ServiceProviderMetadata = {
  readonly minProducts?: number;
  readonly minValue?: Money;
  readonly message?: string;
  readonly deliveryFees?: ServiceProviderDeliveryFees;
  readonly description?: string;
  readonly dropOff?: ServiceProviderDropOffMetadata;
  readonly financialMetadata?: ServiceProviderFinancialMetadata;
};

export declare type ServiceProviderDropOffMetadata = {
  readonly dropOffPoint: boolean;
  readonly parentCompany?: string;
  readonly physicalAddressId: number;
  readonly physicalAddress?: Record<string, string>;
};

export declare type ServiceProviderFinancialMetadata = {
  readonly takeRate: TakeRateProductTypeMetadata;
  readonly perSegment: Record<string, BusinessModelMetadata>;
};

export declare type BusinessModelMetadata = Record<string, SegmentMetadata>;

export declare type SegmentMetadata = {
  readonly takeRates: readonly SegmentTakeRate[];
  readonly paymentFees: SegmentPaymentFees;
};

export declare type SegmentTakeRate = {
  readonly amountFrom: string | number;
  readonly amountTo: string | number;
  readonly rate: string | number;
};

export declare type SegmentPaymentFees = {
  readonly includedInTakeRate: boolean;
  readonly overrides: readonly SegmentPaymentFeeOverride[];
};

export declare type SegmentPaymentFeeOverride = {
  readonly processor: string;
  readonly type: string;
  readonly gatewayFee: PaymentFeeDefinition;
  readonly ioupieFee: PaymentFeeDefinition;
};

export declare type PaymentFeeDefinition = {
  readonly percentage: number;
  readonly fixed: Money;
};

export declare type TakeRateProductTypeMetadata = {
  readonly goods: TakeRateMetadata;
  readonly service: TakeRateMetadata;
};

export declare type TakeRateMetadata = {
  readonly b2b: TakeRateGranularDefinition;
  readonly b2c: TakeRateGranularDefinition;
};

export declare type TakeRateGranularDefinition = {
  readonly rate: number;
  readonly includesPaymentFees: boolean;
};

export declare type ServiceProviderDeliveryFees = {
  readonly from: number;
  readonly to: number;
  readonly feePerBusinessDay: Record<string, number>;
  readonly transferee?: string;
};

export declare type ServiceProviderLockerEntry = {
  readonly id: string;
  readonly name: string;
};

export const ServiceProviderStatus = Object.freeze({
  ACTIVE: 'ACTIVE',
  COMING_SOON: 'COMING_SOON',
  INACTIVE: 'INACTIVE',
  PENDING_VALIDATION: 'PENDING_VALIDATION',
  TEMPORARILY_UNAVAILABLE: 'TEMPORARILY_UNAVAILABLE',
});
