import { inject, injectable, postConstruct } from 'inversify';
import { action, makeObservable, observable, runInAction } from 'mobx';

import { EncodeService, ErrorService, LocationService, RestService, type AnalyticsService } from '@ioupie/services';
import { AnalyticsEvents, QUERY_PARAMS, SERVICE_TYPES, STORE_TYPES, endpoints } from '@ioupie/shared/constants';
import type { ErrorMessages } from '@ioupie/shared/models';
import { LocationSearchQuery, Provider } from '@ioupie/shared/models';
import { DeliveryProvidersResponsePayload } from '@ioupie/shared/payloads';

import { AddressStore } from './address.store';
import { AuthStore } from './auth.store';

@injectable()
export class DeliveryStore {
  @inject(SERVICE_TYPES.REST)
  private readonly restService: RestService;
  @inject(SERVICE_TYPES.ENCODE)
  private readonly encodeService: EncodeService;
  @inject(SERVICE_TYPES.ERROR)
  private readonly errorService: ErrorService;
  @inject(SERVICE_TYPES.ANALYTICS.COMPOSITE)
  private readonly analyticsService: AnalyticsService;
  @inject(SERVICE_TYPES.LOCATION)
  private readonly locationService: LocationService;

  @inject(STORE_TYPES.ADDRESS)
  private readonly addressStore: AddressStore;
  @inject(STORE_TYPES.AUTH)
  private readonly authStore: AuthStore;

  @observable public deliveryProviders: readonly Provider[] = [];

  @observable public loading: boolean = false;
  @observable public errors: ErrorMessages = [];

  @postConstruct()
  public init(): void {
    makeObservable(this);
  }

  @action
  public clearErrors(): void {
    this.errors = [];
  }

  @action
  public async fetchAllDeliveryProviders(): Promise<void> {
    this.loading = true;
    try {
      const startTime1 = Date.now();
      const coords = await this.locationService.getUserCurrentPosition();
      const endTime1 = Date.now();
      const { latitude, longitude } = coords ?? {};

      const searchQuery: LocationSearchQuery = {
        lat: latitude,
        lng: longitude,
        address: this.addressStore.selectedAddressInfo,
      };

      const startTime2 = Date.now();
      const response = await this.restService.get<DeliveryProvidersResponsePayload>({
        url: endpoints.portaria.providers.fetch_all,
        queryParams: {
          [QUERY_PARAMS.ADDRESS_POSITION]: {
            search: this.encodeService.encodePayloadToBase64(searchQuery),
            decrypt: false,
            username: this.authStore.username,
          },
        },
      });
      const endTime2 = Date.now();

      runInAction(() => {
        this.deliveryProviders = response.providers;
        this.loading = false;
      });

      this.analyticsService.trackEvent(AnalyticsEvents.PROVIDERS_FETCH_ALL, {
        address: JSON.stringify(searchQuery),
        count: response.providers.length,
        eligible: response.providers.filter((provider) => provider.eligible).length,
        coordsDurationMs: endTime1 - startTime1,
        providerDurationMs: endTime2 - startTime2,
      });
    } catch (error) {
      runInAction(() => {
        this.errors = this.errorService.wrapApiError(error);
        this.loading = false;
      });
    }
  }
}
