import { memo } from 'react';

import { BodyView } from '@ioupie/components/layout';
import { DeleteAddress, UserAddresses } from '@ioupie/containers/location';
import { useCurrentRouteFocus, useHeaderStore, useNavigationStore } from '@ioupie/hooks';
import { routes } from '@ioupie/shared/constants';

/**
 * @function AddressesListPage
 */
export default memo(() => {
  const headerStore = useHeaderStore();
  const navigationStore = useNavigationStore();

  useCurrentRouteFocus(routes.pages.address.addresses_list, () => {
    headerStore.allowNavigateBack(true);
    headerStore.removeHeaderShadow(false);
    headerStore.changeTitle('pages.address.addresses-list.title');
    headerStore.changeSubtitle('pages.address.addresses-list.subtitle');
    headerStore.onClickBack(() => navigationStore.dispatchGoBack());
  });

  return (
    <BodyView>
      <UserAddresses />
      <DeleteAddress />
    </BodyView>
  );
});
