import styled from 'styled-components/native';

import { Title, Text } from '@ioupie/components/typography';
import { colors } from '@ioupie/shared/constants';
import { themefy } from '@ioupie/shared/utils';

export const UnlockLockerContainer = styled.View`
  flex: 1;
  margin: 0 16px;
`;

export const CompartmentTitle = styled(Title)`
  margin: 20px;
`;

export const LockerNumberStyle = styled.View`
  align-self: center;
  background-color: #e8e8e8;
  border-radius: 4px;
  height: 65px;
  justify-content: center;
  padding: 8px;
  width: 90%;
  margin-bottom: 60px;
`;

export const CompartmentNumber = styled(Text)`
  font-size: 32px;
  font-weight: 800;
  text-align: center;
`;

export const Description = styled(Text)`
  margin-bottom: 20px;
`;

export const ActionButtons = styled.View`
  flex-direction: row;
  justify-content: center;
`;

export const LockerUnavailable = styled(Text)`
  color: ${themefy({
    light: colors.light.error,
    dark: colors.dark.error,
  })};
  margin: 4px 0 0 16px;
`;
