import { View } from 'react-native';
import { IconButton } from 'react-native-paper';
import styled from 'styled-components/native';

export const PasswordContainer = styled(View)`
  position: relative;
`;

export const EyeIconButton = styled(IconButton)`
  bottom: 5px;
  position: absolute;
  right: 0;
`;
