import { DrawerActions } from '@react-navigation/native';
import { observer } from 'mobx-react-lite';
import { Fragment, useCallback } from 'react';
import { useTranslation } from 'react-i18next';

import { AppBar, DevBar } from '@ioupie/components/custom';
import { Text, Title } from '@ioupie/components/typography';
import { useHeaderStore, useNavigationStore, useSettingsStore } from '@ioupie/hooks';
import { colors, statusBarHeight } from '@ioupie/shared/constants';
import { DrawerHeaderProps } from '@ioupie/shared/models';

const BUTTON_SIZES: Readonly<number> = 30;

/**
 * @function HeaderContainer
 */
export default observer(({ navigation }: DrawerHeaderProps) => {
  const [t] = useTranslation();
  const headerStore = useHeaderStore();
  const settingsStore = useSettingsStore();
  const navigationStore = useNavigationStore();

  const navigateBack = useCallback((): void => {
    if (headerStore.goBackAction) {
      headerStore.goBackAction();
    } else {
      navigationStore.dispatchGoBack();
    }
  }, []);

  const openDrawer = useCallback((): void => {
    navigation.dispatch(DrawerActions.toggleDrawer());
  }, []);

  const title = <Title>{t(headerStore.title, headerStore.titleMap)}</Title>;
  const subtitle = <Text>{t(headerStore.subtitle, headerStore.subtitleMap)}</Text>;

  const itemColor = colors.light.text;

  return (
    <Fragment>
      <AppBar.Header dark={settingsStore.darkMode} noShadows={headerStore.noShadow} statusBarHeight={statusBarHeight}>
        {headerStore.back && <AppBar.BackButton color={itemColor} size={BUTTON_SIZES} onPress={navigateBack} />}
        <AppBar.Content title={title} subtitle={subtitle} color={itemColor} />
        {headerStore.menu && <AppBar.MenuButton color={itemColor} size={BUTTON_SIZES} onPress={openDrawer} />}
      </AppBar.Header>
      <DevBar />
    </Fragment>
  );
});
