import { observer } from 'mobx-react-lite';
import { Fragment } from 'react';
import { useTranslation } from 'react-i18next';

import { useOrdersStore, useShopsStore } from '@ioupie/hooks';

import { AmountItem } from './amount-item';
import { AmountProps } from './amount.models';
import * as S from './amount.styles';
import { CouponField } from './coupon';
import { Installments } from './installments';

/**
 * @function AmountContainer
 */
export default observer(({ showCouponField }: AmountProps) => {
  const [t] = useTranslation();
  const shopsStore = useShopsStore();
  const ordersStore = useOrdersStore();

  const { coupon = '' } = shopsStore;

  const couponCode = t('containers.orders.ordering.amount.coupon-code', { coupon });
  const couponTitle = `${t('containers.orders.ordering.amount.coupon')}${coupon ? couponCode : ''}`;
  const couponValue = shopsStore.couponDiscount
    ? t('containers.orders.ordering.amount.coupon-value', { discount: shopsStore.couponDiscount.toFixed(2) })
    : t('containers.orders.ordering.amount.no-coupon');

  return (
    <Fragment>
      {showCouponField && <CouponField />}
      <S.AmountTitle>{t('containers.orders.ordering.amount.title')}</S.AmountTitle>
      <AmountItem
        title={t('containers.orders.ordering.amount.subtotal')}
        value={t('containers.orders.ordering.amount.subtotal-value', {
          price: shopsStore.totalPrice.toFixed(2),
        })}
      />
      <Installments />
      {ordersStore.installmentCharge > 0 && (
        <AmountItem
          title={t('containers.orders.ordering.amount.installment-fee')}
          value={t('containers.orders.ordering.amount.delivery-fees-value', {
            price: ordersStore.installmentCharge.toFixed(2),
          })}
          gray
        />
      )}
      {shopsStore.isDelivery && (
        <AmountItem
          title={t('containers.orders.ordering.amount.delivery-fees')}
          value={t('containers.orders.ordering.amount.delivery-fees-value', {
            price: ordersStore.pickupFee.toFixed(2),
          })}
          gray
        />
      )}
      {shopsStore.couponDiscount > 0 && <AmountItem title={couponTitle} value={couponValue} gray />}
      <AmountItem
        title={t('containers.orders.ordering.amount.total-estimated')}
        value={t('containers.orders.ordering.amount.total-estimated-value', {
          price: ordersStore.orderPrice.toFixed(2),
        })}
        bold
      />
    </Fragment>
  );
});
