import styled from 'styled-components/native';

import { Icon } from '@ioupie/components/gallery';
import { Text } from '@ioupie/components/typography';
import { colors, fontsFamily, fontsWeight } from '@ioupie/shared/constants';
import { themefy } from '@ioupie/shared/utils';

declare type ButtonIconProps = {
  readonly active: boolean;
};

export const ServicesView = styled.View`
  margin: 8px 0 16px;
`;

export const Title = styled(Text)`
  font-family: ${fontsFamily.medium};
  font-size: 16px;
  font-weight: ${fontsWeight.medium};
  margin: 0 0 8px;
`;

export const ButtonsContainer = styled.View`
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: space-between;
`;

export const BodyView = styled.View`
  align-items: center;
  flex-direction: row;
  justify-content: center;
  padding: 3px 0 0;
`;

export const BodyIcon = styled(Icon)<ButtonIconProps>`
  color: ${(props) => {
    if (props.active) {
      return themefy({
        light: colors.light.primary,
        dark: colors.dark.primary,
      })(props);
    } else {
      return themefy({
        light: colors.light.text2,
        dark: colors.dark.text2,
      })(props);
    }
  }};
  margin: 0 0 0 6px;
`;
