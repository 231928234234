export enum COGNITO_ATTRIBUTES {
  NAME = 'name',
  FAMILY_NAME = 'family_name',
  BIRTH_DATE = 'birthdate',
  EMAIL = 'email',
  EMAIL_VERIFIED = 'email_verified',
  PHONE_NUMBER = 'phone_number',
  CPF = 'custom:cpf',
  ALLERGEN = 'custom:allergen',
  ALLERGEN_INFO = 'custom:allergenInfo',
  PERFUME = 'custom:perfume',
  PWD = 'custom:pwd',
  USERNAME = 'cognito:username',
  EXPIRES = 'exp',
  SUBJECT = 'sub',
}
