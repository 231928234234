import { DateTime } from 'luxon';
import { memo, useMemo, Fragment, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { TouchableOpacity } from 'react-native';

import { IconButton } from '@ioupie/components/buttons';
import { Divider } from '@ioupie/components/layout';
import { Text } from '@ioupie/components/typography';
import { BUSINESS_AREA_OPTIONS, CUR_LOCATE, DF_LOCALE, IANA_ZONE, ORDER_STATUS } from '@ioupie/shared/constants';
import { getOrderStatusTranslation, isOrderPendingUserActionStatus, isOrderRejectedStatus } from '@ioupie/shared/utils';

import { OrderHistoryItemProps } from './order-history-item.models';
import * as S from './order-history-item.styles';
import { RemoveConfirmationModal } from './remove-confirmation-modal';

/**
 * @function OrderHistoryItemComponent
 */
export default memo(({ orderHistory, onOrderHistoryPressed, onRemoveTicketPressed }: OrderHistoryItemProps) => {
  const [t] = useTranslation();

  const [openModal, setOpenModal] = useState(false);

  const {
    creationTimestamp,
    orderNumber = '',
    grandTotal,
    inboundDetails = {},
    status = ORDER_STATUS.DRAFT,
    type = BUSINESS_AREA_OPTIONS.LOCKER,
  } = orderHistory;
  const { amount = 0 } = grandTotal ?? {};

  const price = `${t(CUR_LOCATE)} ${amount.toFixed(2)}`;
  const wrappedDate = DateTime.fromISO(creationTimestamp);
  wrappedDate.setZone(IANA_ZONE);
  const creationDate = wrappedDate.toFormat(t(DF_LOCALE));

  const isPendingProviderAcknowledgeStatus = status === ORDER_STATUS.PENDING_PROVIDER_ACKNOWLEDGEMENT;

  const getOrderType = getOrderStatusTranslation(status).replace('{businessType}', type.toLowerCase());
  const humanReadableStatus = t(getOrderType);

  const requestedLocationName =
    type === BUSINESS_AREA_OPTIONS.DELIVERY
      ? t('containers.orders.orders-list.order-history-item.order-placeholder-delivery')
      : inboundDetails.lockerName || t('containers.orders.orders-list.order-history-item.order-placeholder-locker');

  const handleRemoveTicket = () => {
    setOpenModal(false);
    onRemoveTicketPressed();
  };

  const statusButton = useMemo(() => {
    if (isOrderPendingUserActionStatus(status)) {
      return { icon: 'exclamation-thick', color: '#ff4100' };
    }

    if (isOrderRejectedStatus(status)) {
      return { icon: 'close', color: '#ff4100' };
    }

    return { icon: 'check-bold', color: '#0cb600' };
  }, [status]);

  return (
    <Fragment>
      <TouchableOpacity onPress={onOrderHistoryPressed}>
        <S.OrderContainer>
          <S.ShadedContainer>
            <S.OrderInfo>
              <IconButton icon={statusButton.icon} color={statusButton.color} size={22} />
              <S.OrderIdentifier>
                {t('containers.orders.orders-list.order-history-item.order-number', { orderNumber })}
              </S.OrderIdentifier>
            </S.OrderInfo>
            <S.OrderInfo>
              <S.OrderPrice>{price}</S.OrderPrice>
            </S.OrderInfo>
            <Divider />
            <S.OrderData>
              <Text numberOfLines={1} ellipsizeMode="tail">
                {t('containers.orders.orders-list.order-history-item.order-description', {
                  date: creationDate,
                  addressName: requestedLocationName,
                })}
              </Text>
              {isPendingProviderAcknowledgeStatus && type !== BUSINESS_AREA_OPTIONS.DELIVERY && (
                <IconButton icon="trash-can-outline" color="#f00" size={25} onPress={() => setOpenModal(true)} />
              )}
            </S.OrderData>
          </S.ShadedContainer>
          <S.OrderStatus>
            <Text>{humanReadableStatus}</Text>
            <IconButton icon="chevron-right" size={25} />
          </S.OrderStatus>
        </S.OrderContainer>
      </TouchableOpacity>
      <RemoveConfirmationModal
        showModal={openModal}
        onShowModal={setOpenModal}
        onRemoveTicketPressed={handleRemoveTicket}
        orderNumber={orderNumber}
      />
    </Fragment>
  );
});
