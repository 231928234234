import { Fragment, memo } from 'react';

import { Account } from '@ioupie/containers/account';
import { useAuthStore, useCurrentRouteFocus, useHeaderStore, useNavigationStore } from '@ioupie/hooks';
import { routes } from '@ioupie/shared/constants';

/**
 * @function AuthPage
 */
export default memo(() => {
  const headerStore = useHeaderStore();
  const authStore = useAuthStore();
  const navigationStore = useNavigationStore();

  useCurrentRouteFocus(routes.pages.auth.sign_in, () => {
    headerStore.allowNavigateBack(true);
    headerStore.removeHeaderShadow(true);
    headerStore.changeTitle('pages.auth.title');
    headerStore.onClickBack(() => {
      navigationStore.dispatchGoBack();
      authStore.setShowAuthModal(true);
    });
    authStore.setShowAuthModal(false);
  });

  return (
    <Fragment>
      <Account />
    </Fragment>
  );
});
