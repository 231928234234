import { PermissionStatus } from 'expo-location';
import { observer } from 'mobx-react-lite';
import { useCallback, useEffect } from 'react';
import { useTranslation } from 'react-i18next';

import { SwitchButton } from '@ioupie/components/buttons';
import { useSettingsStore } from '@ioupie/hooks';

/**
 * @function LocationSwitcherContainer
 */
export default observer(() => {
  const [t] = useTranslation();
  const settingsStore = useSettingsStore();

  useEffect(() => {
    // eslint-disable-next-line no-void
    void settingsStore.fetchDeviceLocationPermission();
  }, []);

  const onChangeLocation = useCallback((value: boolean) => settingsStore.requestDeviceLocationPermission(), []);

  return (
    <SwitchButton
      text={t('containers.settings.location-switcher.toggle')}
      value={settingsStore.locationPermission === PermissionStatus.GRANTED}
      onValueChange={onChangeLocation}
    />
  );
});
