import { observer } from 'mobx-react-lite';
import { memo } from 'react';
import { useTranslation } from 'react-i18next';

import { Dialog } from '@ioupie/components/custom';
import { Portal } from '@ioupie/components/layout';
import { Text } from '@ioupie/components/typography';
import { useLockersStore, useOrdersStore } from '@ioupie/hooks';

import { FarFromLockerProps } from './far-from-locker.model';
import * as S from './far-from-locker.styles';

/**
 * @function FarFromLockerContainer
 */
export default observer(({}: FarFromLockerProps) => {
  const [t] = useTranslation();
  const ordersStore = useOrdersStore();
  const lockersStore = useLockersStore();

  const onClose = () => ordersStore.clearFarFromLocker();

  return (
    <Portal>
      <Dialog.View visible={ordersStore.isFarFromLocker} onDismiss={onClose}>
        <Dialog.Title>{t('containers.orders.orders-list.farFromLocker.title')}</Dialog.Title>
        <Dialog.Content>
          <Text>{t('containers.orders.orders-list.farFromLocker.tooFar')}</Text>
          <Text>{t('containers.orders.orders-list.farFromLocker.tryAgain')}</Text>
          {lockersStore.lockerProvider?.restricted && (
            <Text>{t('containers.orders.orders-list.farFromLocker.restrictedLocker')}</Text>
          )}
        </Dialog.Content>
        <Dialog.Actions>
          <S.OkButton text={t('containers.orders.orders-list.farFromLocker.okButton')} onPress={onClose} />
        </Dialog.Actions>
      </Dialog.View>
    </Portal>
  );
});
