export declare type SensitiveAddressData = {
  readonly street: string;
  readonly number: string;
  readonly complement: string;
  readonly neighborhood: string;
  readonly city: string;
  readonly state: string;
  readonly postalCode: string;
  readonly lat: number | string;
  readonly lng: number | string;
  readonly name?: string;
  readonly reference?: string;
};

export declare type PublicAddressData = {
  readonly addressId: string;
  readonly ownerType: string;
  readonly ownerId: string;
  readonly active?: boolean;
};

export declare type Address = PublicAddressData & SensitiveAddressData;

export declare type AddressPersistenceModel = PublicAddressData & {
  readonly searchString?: string; // private
  readonly createdAt: number; // private
  readonly lastModifiedAt: number; // private
  readonly lastModifiedBy: string; // private
  readonly addressData: string; // encrypted SensitiveAddressData
};

export const AddressOwnerTypes = Object.freeze({
  Customer: 'C',
  Locker: 'L',
  ServiceProvider: 'S',
});
