import styled from 'styled-components/native';

import { Text } from '@ioupie/components/typography';
import { colors } from '@ioupie/shared/constants';
import { themefy } from '@ioupie/shared/utils';

export const Agenda = styled.View`
  flex-direction: row;
  justify-content: space-between;
  margin: 0 0 8px;
`;

export const AgendaText = styled(Text)`
  font-size: 15px;
  color: ${themefy({
    light: colors.light.title,
    dark: colors.dark.title,
  })};
`;
