import { DateTime } from 'luxon';
import { observer } from 'mobx-react-lite';
import { useTranslation } from 'react-i18next';
import { Divider } from 'react-native-paper';

import { IconButton } from '@ioupie/components/buttons/icon-button';
import { Loader } from '@ioupie/components/custom';
import { Bold } from '@ioupie/components/typography';
import { useOrdersStore, useSettingsStore } from '@ioupie/hooks';
import { normalizeToDateTime } from '@ioupie/shared/utils';

import * as S from './schedule-details.styles';

/**
 * @function ScheduleDetailsContainer
 */
export default observer(() => {
  const [t] = useTranslation();
  const settingsStore = useSettingsStore();
  const ordersStore = useOrdersStore();

  const { language = 'pt' } = settingsStore;
  const { selectedOrderHistory } = ordersStore;
  const { inboundDetails, outboundDetails } = selectedOrderHistory ?? {};
  const { requestedTime: inboundRequestedTime } = inboundDetails ?? {};
  const { requestedTime: outboundRequestedTime } = outboundDetails ?? {};

  const inboundDateTime = inboundRequestedTime ? normalizeToDateTime(inboundRequestedTime) : undefined;
  const inboundScheduledDate =
    inboundDateTime && inboundDateTime.setLocale(language).toLocaleString(DateTime.DATE_HUGE);
  const outboundDateTime = outboundRequestedTime ? normalizeToDateTime(outboundRequestedTime) : undefined;
  const outboundScheduledDate =
    outboundDateTime && outboundDateTime.setLocale(language).toLocaleString(DateTime.DATE_HUGE);

  const { lockerName: outboundLockerName, address: outboundAddress } = outboundDetails ?? {};
  const { lockerName: inboundLockerName, address: inboundAddress } = inboundDetails ?? {};

  return (
    <S.ScheduleDetailsContainer>
      <S.ScheduleDetailsTitle>{t('containers.orders.track-order.schedule-details.title')}</S.ScheduleDetailsTitle>
      {inboundRequestedTime ? (
        <S.ScheduleBlock>
          <S.ScheduleBlockName>
            <IconButton icon="truck-fast-outline" size={24} flip={true} disabled={true} />
            <Bold>{t('containers.orders.track-order.schedule-details.pick-up')}</Bold>
          </S.ScheduleBlockName>
          <S.ScheduleBlockContent>
            <S.ScheduleBlockText>{inboundScheduledDate}</S.ScheduleBlockText>
            <S.ScheduleBlockText>{inboundLockerName ?? inboundAddress}</S.ScheduleBlockText>
          </S.ScheduleBlockContent>
        </S.ScheduleBlock>
      ) : undefined}
      {inboundRequestedTime && outboundRequestedTime ? <Divider /> : undefined}
      {outboundRequestedTime ? (
        <S.ScheduleBlock>
          <S.ScheduleBlockName>
            <IconButton icon="truck-fast-outline" size={24} disabled={true} />
            <Bold>{t('containers.orders.track-order.schedule-details.drop-off')}</Bold>
          </S.ScheduleBlockName>
          <S.ScheduleBlockContent>
            <S.ScheduleBlockText>{outboundScheduledDate}</S.ScheduleBlockText>
            <S.ScheduleBlockText>{outboundLockerName ?? outboundAddress}</S.ScheduleBlockText>
          </S.ScheduleBlockContent>
        </S.ScheduleBlock>
      ) : undefined}
      <Loader show={ordersStore.loading} />
    </S.ScheduleDetailsContainer>
  );
});
