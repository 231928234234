import { useLocalObservable } from 'mobx-react-lite';

import { container } from '@ioupie/injection';
import { STORE_TYPES } from '@ioupie/shared/constants';
import { PaymentStore } from '@ioupie/stores';

export const usePaymentStore = (): PaymentStore => {
  return useLocalObservable(() => {
    return container.get(STORE_TYPES.PAYMENT);
  });
};
