import { observer } from 'mobx-react-lite';
import { Fragment, useCallback, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { FlatList, ListRenderItemInfo } from 'react-native';

import { BlockButton } from '@ioupie/components/buttons';
import { Chip, CHIP_COLOR, Dialog, ErrorSnackbar, List, Loader } from '@ioupie/components/custom';
import { Portal } from '@ioupie/components/layout';
import { Text } from '@ioupie/components/typography';
import { useAddressStore, useDeliveryStore, useNavigationStore, useShopsStore } from '@ioupie/hooks';
import {
  BUSINESS_AREA_OPTIONS,
  BUSINESS_PARTNER_STATUS,
  BUSINESS_SEGMENTS_OPTIONS,
  routes,
} from '@ioupie/shared/constants';
import { PLAN, Provider } from '@ioupie/shared/models';

import { DeliveryData } from './delivery-data';
import * as S from './delivery-list.styles';

/**
 * @function LockersListContainer
 */
export default observer(() => {
  const [t] = useTranslation();
  const navigationStore = useNavigationStore();

  const deliveryStore = useDeliveryStore();
  const shopsStore = useShopsStore();
  const addressStore = useAddressStore();

  const [displayNoAddressDialog, setDisplayNoAddressDialog] = useState(false);
  const [selectedProvider, setSelectedProvider] = useState<Provider>();

  useEffect(() => {
    // user address must not be loading and/or bootstraping
    if (!addressStore.loadingUserAddress) {
      // eslint-disable-next-line no-void
      void deliveryStore.fetchAllDeliveryProviders();
    }
  }, [addressStore.zipCodeInfo, addressStore.userAddressData.selectedAddress, addressStore.loadingUserAddress]);

  const selectAndNavigateToProviders = (provider: Provider) => {
    if (provider.status !== BUSINESS_PARTNER_STATUS.ACTIVE) {
      return;
    }

    setSelectedProvider(provider);

    if (!addressStore.selectedAddressInfo) {
      setDisplayNoAddressDialog(true);
      return;
    }

    navigateToProviderCatalog(provider);
  };

  const navigateToAddressPage = () => {
    const screen =
      addressStore.allAddresses.length === 0
        ? routes.pages.address.add_new_address
        : routes.pages.address.addresses_list;

    navigationStore.dispatchNavigation({
      stack: routes.stacks.address,
      screen,
      loggingMetaData: {
        segment: BUSINESS_SEGMENTS_OPTIONS.LAUNDRY,
        businessArea: BUSINESS_AREA_OPTIONS.DELIVERY,
      },
    });
    setDisplayNoAddressDialog(false);
  };

  const navigateToProviderCatalog = (receivedProvider?: Provider) => {
    const provider = receivedProvider ?? selectedProvider;
    if (provider) {
      shopsStore.setShopSegment(BUSINESS_SEGMENTS_OPTIONS.LAUNDRY); // laundry only currently
      shopsStore.setShopBusinessArea(BUSINESS_AREA_OPTIONS.DELIVERY);
      shopsStore.selectShopProvider(provider.id);
      navigationStore.dispatchNavigation({
        stack: routes.stacks.orders,
        screen: routes.pages.orders.catalog,
        loggingMetaData: {
          segment: BUSINESS_SEGMENTS_OPTIONS.LAUNDRY,
          businessArea: BUSINESS_AREA_OPTIONS.DELIVERY,
          provider: provider.id,
        },
      });
      setDisplayNoAddressDialog(false);
    }
  };

  const viewShopDetailsPage = useCallback((providerId: string) => {
    shopsStore.selectProviderToViewDetails(providerId);
    navigationStore.dispatchNavigation({
      stack: routes.stacks.orders,
      screen: routes.pages.orders.shop_details,
    });
  }, []);

  const providerKeyExtractor = useCallback((provider: Provider) => provider.id, []);

  const renderProviderListItem = useCallback((info: ListRenderItemInfo<Provider>) => {
    const { item: provider } = info;

    const shouldDisplayNotWithinRadiusLabel =
      provider.status === BUSINESS_PARTNER_STATUS.ACTIVE &&
      !provider.eligible &&
      Boolean(addressStore.selectedAddressInfo);

    // const shouldDisplayChevronRightIcon =
    //   provider.status === BUSINESS_PARTNER_STATUS.ACTIVE &&
    //   provider.eligible &&
    //   Boolean(addressStore.selectedAddressInfo);

    const { metadata, dist = -1, status } = provider ?? {};
    const {
      message = '',
      minProducts = 0,
      minimumDays = -1,
      plan = PLAN.BASIC,
      minValue,
      rating,
      deliveryFees = { from: 0, to: 0 },
    } = metadata ?? {};
    const { currencyCode = 'BRL', amount: minAmount = 0 } = minValue ?? {};
    const { value: ratingValue = 0 } = rating ?? {};
    const { from: deliveryStartAmount = 0, to: deliveryEndAmount = 0 } = deliveryFees;

    const shouldDisplayShopRequirements = minProducts > 0 || minAmount > 0;
    const shouldDisplayMedalIcon = plan === PLAN.PREMIUM;
    const isDeliveryFree = deliveryStartAmount === 0 && deliveryEndAmount === 0;
    const deliveryAmount = isDeliveryFree
      ? t('containers.delivery.delivery-list.free')
      : deliveryStartAmount === deliveryEndAmount
      ? t('containers.delivery.delivery-list.delivery-price-fixed', { deliveryPrice: deliveryStartAmount.toFixed(2) })
      : t('containers.delivery.delivery-list.delivery-price-range', {
          from: deliveryStartAmount.toFixed(0),
          to: deliveryEndAmount.toFixed(0),
        });

    const deliveryInfo = {
      iconName: 'truck-fast-outline',
      iconColor: isDeliveryFree ? '#5fb205' : undefined,
      info: deliveryAmount,
      textColor: isDeliveryFree ? '#5fb205' : undefined,
    };
    const ratingInfo =
      ratingValue > 0
        ? {
            iconName: 'star',
            iconColor: '#ffd201',
            info: ratingValue.toFixed(1),
          }
        : undefined;
    const distanceInfo =
      dist >= 0
        ? {
            iconName: 'map-marker-right',
            info: t('containers.delivery.delivery-list.distance', { distance: (dist / 1000).toFixed(1) }),
          }
        : undefined;
    const minimumDaysInfo =
      minimumDays >= 0
        ? {
            iconName: 'calendar',
            info: t('containers.delivery.delivery-list.minimum-days', { minimumDays }),
          }
        : undefined;

    const renderShopRequirements = shouldDisplayShopRequirements ? (
      <S.ProviderInformationSticker>
        <S.ProviderInformationStickerText>
          {t('containers.delivery.delivery-list.shop-requirements.minimum-requirements-title')}
        </S.ProviderInformationStickerText>
        {minAmount > 0 && (
          <Fragment>
            <S.ProviderInformationStickerIcon name="currency-usd" size={16} />
            <S.ProviderInformationStickerText>
              {t(`constants.currency.${currencyCode}`)} {minAmount.toFixed(2)}
            </S.ProviderInformationStickerText>
          </Fragment>
        )}
        {minProducts > 0 && (
          <Fragment>
            <S.ProviderInformationStickerIcon name="tshirt-crew" size={16} />
            <S.ProviderInformationStickerText>
              {minProducts} {t('containers.delivery.delivery-list.shop-requirements.pieces')}
            </S.ProviderInformationStickerText>
          </Fragment>
        )}
      </S.ProviderInformationSticker>
    ) : (
      <Fragment />
    );

    return (
      <List.Item
        // showBorder={false}
        onPress={() => selectAndNavigateToProviders(provider)}
        left={<S.ProviderImage image={provider.image} height={75} width={75} />}
        body={
          <S.ProviderInformationContainer>
            <S.ProviderNameContainer>
              {shouldDisplayMedalIcon && <S.ProviderMedal size={18} name="medal" />}
              <S.ProviderName>{provider.name ?? ''}</S.ProviderName>
            </S.ProviderNameContainer>
            <DeliveryData leftInfo={deliveryInfo} rightInfo={ratingInfo} />
            <DeliveryData leftInfo={distanceInfo} rightInfo={minimumDaysInfo} />
            {Boolean(shouldDisplayShopRequirements) && renderShopRequirements}
            {Boolean(status) &&
              ![
                BUSINESS_PARTNER_STATUS.ACTIVE,
                BUSINESS_PARTNER_STATUS.COMING_SOON,
                BUSINESS_PARTNER_STATUS.TEMPORARILY_UNAVAILABLE,
              ].includes(status) && (
                <S.ProviderNotAvailableLabel>
                  {t(`containers.delivery.delivery-list.status.${status}`)}
                </S.ProviderNotAvailableLabel>
              )}
            {status === BUSINESS_PARTNER_STATUS.COMING_SOON && (
              <Chip
                content={t(`containers.delivery.delivery-list.status.${status}`)}
                iconName="tools"
                iconSize={16}
                chipColor={CHIP_COLOR.YELLOW}
              />
            )}
            {shouldDisplayNotWithinRadiusLabel && (
              <Chip
                content={t('containers.delivery.delivery-list.not-within-radius')}
                iconName="map-marker-off"
                iconSize={16}
                chipColor={CHIP_COLOR.RED}
              />
            )}
            {status === BUSINESS_PARTNER_STATUS.INACTIVE && (
              <Chip
                content={t(`containers.delivery.delivery-list.status.${status}`)}
                iconName="cart-off"
                iconSize={16}
                chipColor={CHIP_COLOR.RED}
              />
            )}
            {status === BUSINESS_PARTNER_STATUS.TEMPORARILY_UNAVAILABLE && (
              <Chip
                content={t(`containers.delivery.delivery-list.status.${status}`)}
                iconName="clock-outline"
                iconSize={16}
                chipColor={CHIP_COLOR.GREY}
              />
            )}
            {Boolean(message) && (
              <S.ProviderMessage>
                <S.ProviderMessageIcon name="truck-outline" size={16} />
                <S.ProviderMessageText>{message}</S.ProviderMessageText>
              </S.ProviderMessage>
            )}
          </S.ProviderInformationContainer>
        }
        // right={shouldDisplayChevronRightIcon && <List.Icon size="medium" icon="chevron-right" />}
        right={
          <S.RightItemWrapper onPress={() => viewShopDetailsPage(provider.id)}>
            <List.Icon size="xsmall" icon="information-outline" />
          </S.RightItemWrapper>
        }
      />
    );
  }, []);

  return (
    <Fragment>
      <List.Section>
        <FlatList<Provider>
          data={deliveryStore.deliveryProviders}
          keyExtractor={providerKeyExtractor}
          renderItem={renderProviderListItem}
        />
      </List.Section>
      <Loader show={deliveryStore.loading} message={t('containers.delivery.delivery-list.loading')} />
      <ErrorSnackbar errors={deliveryStore.errors} onDismiss={() => deliveryStore.clearErrors()} />

      <Portal>
        <Dialog.View visible={displayNoAddressDialog} onDismiss={() => setDisplayNoAddressDialog(false)}>
          <Dialog.Title>{t('containers.delivery.delivery-list.no-address-dialog.title')}</Dialog.Title>
          <Dialog.Content>
            <Text>{t('containers.delivery.delivery-list.no-address-dialog.description')}</Text>
          </Dialog.Content>
          <Dialog.Actions>
            <S.DialogButtonColumn>
              <BlockButton
                text={t('containers.delivery.delivery-list.no-address-dialog.configure-address-label')}
                onPress={() => navigateToAddressPage()}
              />
              <BlockButton
                secondary
                text={t('containers.delivery.delivery-list.no-address-dialog.view-catalog-label')}
                onPress={() => navigateToProviderCatalog()}
              />
            </S.DialogButtonColumn>
          </Dialog.Actions>
        </Dialog.View>
      </Portal>
    </Fragment>
  );
});
