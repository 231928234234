export enum PRODUCT_METADATA_TYPES {
  CURTAIN = 'curtain',
  CARPET = 'carpet',
}

export enum CARPET_SHAPES {
  RECTANGLE = 'rectangle',
  ROUND = 'round',
}

export enum CUSTOM_PRODUCT_SIZES {
  SMALL = 'small',
  MEDIUM = 'medium',
  BIG = 'big',
  CUSTOM = 'custom',
}

export type RectangleCarpetMetrics = {
  readonly width: number;
  readonly height: number;
};

export type RoundCarpetMetrics = {
  readonly radius: number;
};

export type CurtainMetrics = {
  readonly width: number;
};

export const RECTANGLE_CARPET_DEFAULTS: Record<CUSTOM_PRODUCT_SIZES, RectangleCarpetMetrics> = {
  [CUSTOM_PRODUCT_SIZES.SMALL]: { width: 1, height: 1.5 },
  [CUSTOM_PRODUCT_SIZES.MEDIUM]: { width: 1.5, height: 2 },
  [CUSTOM_PRODUCT_SIZES.BIG]: { width: 2, height: 3 },
  [CUSTOM_PRODUCT_SIZES.CUSTOM]: { width: 0, height: 0 },
};

export const ROUND_CARPET_DEFAULTS: Record<CUSTOM_PRODUCT_SIZES, RoundCarpetMetrics> = {
  [CUSTOM_PRODUCT_SIZES.SMALL]: { radius: 1 },
  [CUSTOM_PRODUCT_SIZES.MEDIUM]: { radius: 1.5 },
  [CUSTOM_PRODUCT_SIZES.BIG]: { radius: 2 },
  [CUSTOM_PRODUCT_SIZES.CUSTOM]: { radius: 0 },
};

export const CURTAIN_DEFAULTS: Record<CUSTOM_PRODUCT_SIZES, CurtainMetrics> = {
  [CUSTOM_PRODUCT_SIZES.SMALL]: { width: 4 },
  [CUSTOM_PRODUCT_SIZES.MEDIUM]: { width: 8 },
  [CUSTOM_PRODUCT_SIZES.BIG]: { width: 12 },
  [CUSTOM_PRODUCT_SIZES.CUSTOM]: { width: 0 },
};
