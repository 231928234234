import { memo } from 'react';

import { SelectionInfoProps } from './selection-info.models';
import * as S from './selection-info.styles';

/**
 * @function SelectionInfoComponent
 */
export default memo(({ subject, content }: SelectionInfoProps) => (
  <S.SelectionInfo>
    <S.SelectionInfoText>{subject}</S.SelectionInfoText>
    <S.SelectionInfoText>{content}</S.SelectionInfoText>
  </S.SelectionInfo>
));
