import { darken, lighten } from 'polished';
import styled from 'styled-components/native';

import { Icon } from '@ioupie/components/gallery';
import { Text } from '@ioupie/components/typography';
import { colors } from '@ioupie/shared/constants';
import { themefy } from '@ioupie/shared/utils';

declare type DeliveryInfoStyleProps = {
  readonly iconColor: string;
  readonly textColor: string;
};

export const InfoContent = styled.View`
  align-items: flex-start;
  flex-flow: row;
  margin: 4px 0;
  width: 50%;
`;

export const InfoText = styled(Text)<DeliveryInfoStyleProps>`
  color: ${({ textColor }) =>
    textColor ||
    themefy({
      light: lighten(0.2, colors.light.text),
      dark: darken(0.2, colors.dark.text),
    })};
  font-size: 13px;
`;

export const InfoIcon = styled(Icon)<DeliveryInfoStyleProps>`
  color: ${({ iconColor }) =>
    iconColor ||
    themefy({
      light: lighten(0.2, colors.light.text),
      dark: darken(0.2, colors.dark.text),
    })};
  margin: 0 4% 0 0;
`;
