import { memo } from 'react';

import { FormView } from '@ioupie/components/layout';
import { CreditCardForm } from '@ioupie/containers/payment';
import { useCurrentRouteFocus, useHeaderStore, useNavigationStore } from '@ioupie/hooks';
import { routes } from '@ioupie/shared/constants';

/**
 * @function AddCreditCardPage
 */
export default memo(() => {
  const headerStore = useHeaderStore();
  const navigationStore = useNavigationStore();

  useCurrentRouteFocus(routes.pages.payment.add_credit_card, () => {
    headerStore.allowNavigateBack(true);
    headerStore.removeHeaderShadow(false);
    headerStore.changeTitle('pages.add-credit-card.title');
    headerStore.changeSubtitle('pages.add-credit-card.subtitle');
    headerStore.onClickBack(() => navigationStore.dispatchGoBack());
  });

  return (
    <FormView>
      <CreditCardForm />
    </FormView>
  );
});
