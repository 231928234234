import { inject, injectable, postConstruct } from 'inversify';
import { action, computed, makeObservable, observable, runInAction } from 'mobx';

import { EncodeService, ErrorService, LocationService, RestService, type AnalyticsService } from '@ioupie/services';
import {
  AnalyticsEvents,
  COMPARTMENT_TYPE,
  LOCKER_STATUS,
  PATH_PARAMS,
  QUERY_PARAMS,
  SERVICE_TYPES,
  STORE_TYPES,
  endpoints,
} from '@ioupie/shared/constants';
import type {
  CustomerInteractionDetails,
  ErrorMessages,
  LocationSearchQuery,
  Locker,
  LockersPayload,
  UrlEndpoint,
} from '@ioupie/shared/models';
import { safeObjectLookup } from '@ioupie/shared/utils';

import { AddressStore } from './address.store';
import { AuthStore } from './auth.store';

@injectable()
export class LockersStore {
  @inject(SERVICE_TYPES.REST)
  private readonly restService: RestService;
  @inject(SERVICE_TYPES.ENCODE)
  private readonly encodeService: EncodeService;
  @inject(SERVICE_TYPES.LOCATION)
  private readonly locationService: LocationService;
  @inject(SERVICE_TYPES.ERROR)
  private readonly errorService: ErrorService;
  @inject(SERVICE_TYPES.ANALYTICS.COMPOSITE)
  private readonly analyticsService: AnalyticsService;

  @inject(STORE_TYPES.AUTH)
  private readonly authStore: AuthStore;
  @inject(STORE_TYPES.ADDRESS)
  private readonly addressStore: AddressStore;

  @observable public loading: boolean = false;
  @observable public errors: ErrorMessages = [];

  @observable public lockers: readonly Locker[] = [];

  @observable public lockerProvider?: Locker;

  @postConstruct()
  public init(): void {
    makeObservable(this);
  }

  @action
  public clearErrors(): void {
    this.errors = [];
  }

  @action
  public selectLockerProvider(id: string): void {
    this.lockerProvider = this.lockers.find((locker) => locker.id === id);
  }

  @action
  public clearSelectedLockerProvider(): void {
    this.lockerProvider = undefined;
  }

  @action
  public async fetchAllLockers(): Promise<void> {
    this.loading = true;

    try {
      const startTime1 = Date.now();
      const coords = await this.locationService.getUserCurrentPosition();
      const endTime1 = Date.now();
      const { latitude, longitude } = coords ?? {};

      const searchQuery: LocationSearchQuery = {
        lat: latitude,
        lng: longitude,
        address: this.addressStore.selectedAddressInfo,
      };

      const urlEndpoint: UrlEndpoint = {
        url: endpoints.harvey.lockers.fetch,
        queryParams: {
          [QUERY_PARAMS.ADDRESS_POSITION]: {
            search: this.encodeService.encodePayloadToBase64(searchQuery),
            decrypt: false,
            username: this.authStore.username,
          },
        },
      };

      const startTime2 = Date.now();
      const response = await this.restService.get<LockersPayload>(urlEndpoint, this.authStore.buildAuthHeaders());
      const endTime2 = Date.now();

      const startTime3 = Date.now();
      this.analyticsService.trackEvent(AnalyticsEvents.LOCKERS_FETCH_ALL, {
        address: JSON.stringify(searchQuery),
        count: response.lockers.length,
        available: response.lockers.filter((locker) => locker.status === LOCKER_STATUS.AVAILABLE).length,
        coordsDurationMs: endTime1 - startTime1,
        providerDurationMs: endTime2 - startTime2,
      });
      const endTime3 = Date.now();

      runInAction(() => {
        this.loading = false;
        this.lockers = response.lockers;
      });
    } catch (error) {
      runInAction(() => {
        this.errors = this.errorService.wrapApiError(error);
        this.loading = false;
      });
    }
  }

  @action
  public async openLockerCompartment(compartmentDetails: CustomerInteractionDetails): Promise<void> {
    this.loading = true;

    const { lockerId = '', compartment } = compartmentDetails ?? {};
    const { identifier = 0, type = COMPARTMENT_TYPE.SHOWCASE } = compartment ?? {};

    /**
     * @todo remove this mapper as soon as the new open compartment API is ready
     */
    const compartmentMapper: Record<string, string> = {
      [COMPARTMENT_TYPE.SHOWCASE]: 'door',
      [COMPARTMENT_TYPE.DOOR]: 'drawer',
    };

    try {
      const urlEndpoint: UrlEndpoint = {
        url: endpoints.harvey.lockers.switch_lock,
        pathParams: {
          [PATH_PARAMS.LOCKER_ID]: lockerId,
          [PATH_PARAMS.COMPARTMENT_TYPE]: safeObjectLookup(compartmentMapper, type) ?? '',
          [PATH_PARAMS.COMPARTMENT_NUMBER]: identifier.toString(),
        },
      };

      await this.restService.post<void, unknown>(urlEndpoint, undefined, this.authStore.buildAuthHeaders());

      runInAction(() => {
        this.loading = false;
      });
    } catch (error) {
      runInAction(() => {
        this.errors = this.errorService.wrapApiError(error);
        this.loading = false;
      });
    }
  }

  @computed
  public get lockerProviderAddressAsText(): string {
    // const { address: { number = '', street = '' } = {} } = this.lockerProvider ?? {};
    // return `${street}, ${number}`;

    const { address = '' } = this.lockerProvider ?? {};
    return address;
  }
}
