import { DarkTheme, DefaultTheme } from '@react-navigation/native';

export const NavigationDarkTheme: typeof DarkTheme = {
  ...DarkTheme,
  dark: true,
};

export const NavigationLightTheme: typeof DefaultTheme = {
  ...DefaultTheme,
  dark: false,
};
