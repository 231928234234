import { Fragment, memo } from 'react';
import { useTranslation } from 'react-i18next';

import { BlockButton } from '@ioupie/components/buttons';

import * as S from '../thank-step.styles';
import { ApprovedMessageProps } from './approved-message.models';

/**
 * @function ApprovedMessageComponent
 */
export default memo((props: ApprovedMessageProps) => {
  const [t] = useTranslation();

  const { totalPrice, scheduledDate, action } = props;

  const thankStepTranslationPath = 'containers.orders.track-order.finish-order-stepper.thank-step';

  return (
    <Fragment>
      <S.ThankMessage>
        <S.TitleMessage>{t(`${thankStepTranslationPath}.thank-message`)}</S.TitleMessage>
        <S.TextMessage>
          {t(`${thankStepTranslationPath}.approved-message.payment-approved`, { totalPrice })}
        </S.TextMessage>
        <S.TextMessage>
          {t(`${thankStepTranslationPath}.approved-message.schedule-date`, { scheduledDate })}
        </S.TextMessage>
        <S.TextMessage>{t(`${thankStepTranslationPath}.push-notification-disclaimer`)}</S.TextMessage>
      </S.ThankMessage>
      <BlockButton text={t(`${thankStepTranslationPath}.approved-message.view-order-status`)} onPress={action} />
    </Fragment>
  );
});
