import { Money } from '../promotions';
import { UnitOfMeasurement } from './unit-of-measurement.enum';

export declare type Product = {
  readonly productId: string;
  readonly offeringId: string;
  readonly groupId: string;
  readonly name: string;
  readonly description: string;
  readonly image: string;
  readonly lockerId: string;
  readonly serviceProviderId: string;
  readonly minDays: number;
  readonly minPrice: Money;
  readonly maxPrice: Money;
  readonly volume: number;
  readonly unitOfMeasurement?: UnitOfMeasurement;
  readonly displayPrecedence?: number;
  readonly creationTimestamp: string;
  readonly lastModificationTimestamp: string;
  readonly lastModifiedBy: string;
  readonly active: boolean;
  readonly customizable?: CustomizableProductType | ProductType;
  readonly highlight?: boolean;
  readonly metadata?: ProductMetadata;
  readonly images?: string[];
  readonly longDescription?: string;
  readonly type?: string;
  readonly minDaysPost?: number;
};

export enum ProductType {
  GOOD = 'good',
  SERVICE = 'service',
}

export declare type ProductMetadata = {
  readonly dimension?: DimensionMetadata;
  readonly promotion?: PromotionMetadata;
  readonly good?: GoodMetadata;
};

type PromotionMetadata = {
  readonly listPrice: Money;
  readonly percentageOff: number;
};

export enum CustomizableProductType {
  CARPET = 'carpet',
  CURTAIN = 'curtain',
}

export enum CarpetShape {
  RECTANGLE = 'rectangle',
  ROUND = 'round',
}

type CarpetMetadata = {
  readonly type: CustomizableProductType.CARPET;
  readonly shape: string;
};

type RectangleCarpetMetadata = CarpetMetadata & {
  readonly shape: CarpetShape.RECTANGLE;
  readonly width: number;
  readonly height: number;
};

type RoundCarpetMetadata = CarpetMetadata & {
  readonly shape: CarpetShape.ROUND;
  readonly radius: number;
};

type CurtainMetadata = {
  readonly type: CustomizableProductType.CURTAIN;
  readonly width: number;
};

type GoodMetadata = {
  readonly type: ProductType.GOOD;
  readonly height?: number;
  readonly width?: number;
  readonly depth?: number;
  readonly weight?: number;
  readonly unitCount?: number;
  readonly brand?: string;
  readonly ean?: string;
};

export type DimensionMetadata = RectangleCarpetMetadata | RoundCarpetMetadata | CurtainMetadata;

export const isCarpet = (meta: DimensionMetadata): meta is RectangleCarpetMetadata | RoundCarpetMetadata =>
  meta.type === CustomizableProductType.CARPET;

export const isCurtain = (meta: DimensionMetadata): meta is CurtainMetadata =>
  meta.type === CustomizableProductType.CURTAIN;
