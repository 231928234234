import { memo } from 'react';
import { TouchableOpacity } from 'react-native';

import { SegmentBoxProps } from './segment-box.models';
import * as S from './segment-box.styles';

/**
 * @function SegmentBoxComponent
 */
export default memo(({ name, image, onPress }: SegmentBoxProps) => {
  return (
    <TouchableOpacity onPress={onPress}>
      <S.Segment>
        <S.Visual image={image} height={120} width={120} />
        <S.Name numberOfLines={2}>{name}</S.Name>
      </S.Segment>
    </TouchableOpacity>
  );
});
