import { darken, lighten } from 'polished';
import { css } from 'styled-components';
import styled from 'styled-components/native';

import { colors } from '@ioupie/shared/constants';
import { themefy } from '@ioupie/shared/utils';

import { ListItemProps } from './item.models';

declare type ItemContainerProps = Pick<ListItemProps, 'alignTop' | 'showBorder' | 'highlight'>;

export const ItemContainer = styled.SafeAreaView<ItemContainerProps>`
  background-color: ${({ highlight }) => (highlight ? '#ffe800' : 'auto')};
  flex-direction: column;
  ${({ showBorder }) => {
    const showBorderStyle = showBorder
      ? css`
          border-color: ${themefy({
            light: lighten(0.5, colors.light.text),
            dark: darken(0.5, colors.dark.text),
          })};
          border-bottom-width: 0.5px;
        `
      : '';

    return showBorderStyle;
  }}
  margin: 6px 20px;
`;

export const ItemTouchableArea = styled.TouchableOpacity``;

export const ItemContent = styled.View<ItemContainerProps>`
  flex-direction: row;
  padding: 0 0 10px;
  ${({ showBorder }) => {
    const showBorderStyle = showBorder
      ? css`
          border-color: ${themefy({
            light: colors.light.text,
            dark: colors.dark.text,
          })};
          border-bottom-width: 0.5px;
        `
      : '';

    return showBorderStyle;
  }}
`;

export const TopContent = styled.View`
  margin-top: 0;
`;

export const BottomContent = styled.View`
  margin-bottom: 20px;
`;

export const LeftContent = styled.View<ItemContainerProps>`
  ${({ alignTop }) => {
    const justifyContentStyle = alignTop ? 'flex-start' : 'center';
    return css`
      justify-content: ${justifyContentStyle};
    `;
  }}
  margin-left: 0;
`;

export const RightContent = styled.View`
  justify-content: center;
  margin-right: 0;
`;

export const BodyContent = styled.View`
  flex: 1;
  justify-content: center;
  margin: 4px 6px;
`;
