import * as Location from 'expo-location';
import { injectable, postConstruct } from 'inversify';

import environment from '@ioupie/env';
import { LocationAddress, LocationCoords } from '@ioupie/shared/models';

@injectable()
export class LocationService {
  @postConstruct()
  public init(): void {
    Location.setGoogleApiKey(environment.GOOGLE_API_KEY);
  }

  public async getCurrentPermission(): Promise<Location.LocationPermissionResponse> {
    return Location.getForegroundPermissionsAsync();
  }

  public async requestForegroundPermission(): Promise<Location.LocationPermissionResponse> {
    return Location.requestForegroundPermissionsAsync();
  }

  public async getUserCurrentPosition(): Promise<LocationCoords> {
    const status = await this.getLocationBasicPermissions();

    if (status === Location.PermissionStatus.GRANTED) {
      const currentPosition = await Location.getLastKnownPositionAsync();

      const { coords = {} } = currentPosition ?? {};

      return coords;
    } else {
      return {};
    }
  }

  public async getAddressFromPosition(coords: LocationCoords): Promise<LocationAddress> {
    const status = await this.getLocationBasicPermissions();

    if (status === Location.PermissionStatus.GRANTED) {
      // usually comes with one position only
      const [address] = await Location.reverseGeocodeAsync({
        latitude: coords.latitude ?? 0,
        longitude: coords.longitude ?? 0,
      });

      return { coords, address };
    } else {
      return { coords: {}, address: {} };
    }
  }

  private async getLocationBasicPermissions(): Promise<Location.PermissionStatus> {
    try {
      const { status } = await Location.requestForegroundPermissionsAsync();

      if (status !== Location.PermissionStatus.GRANTED) {
        throw new Error('You must provide permission');
      }

      await Location.enableNetworkProviderAsync();

      return status;
    } catch (error) {
      return Location.PermissionStatus.UNDETERMINED;
    }
  }
}
