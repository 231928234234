import { yupResolver } from '@hookform/resolvers/yup';
import { DateTime } from 'luxon';
import { Fragment, memo, useCallback, useEffect } from 'react';
import { useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import * as yup from 'yup';

import { Bold, HelperText } from '@ioupie/components/typography';
import { debounceOneSecond } from '@ioupie/shared/utils';

import { DateForm, DateInputProps } from './date-input.models';
import * as S from './date-input.styles';

const DATE_SEPARATOR: Readonly<string> = '-';

/**
 * @function DateInputComponent
 */
export default memo(({ initialValue, onChangeDate }: DateInputProps) => {
  const [t] = useTranslation();

  const dateSchema: yup.SchemaOf<DateForm> = yup.object().shape({
    day: yup
      .string()
      .required(t('components.date-input.day.errors.required'))
      .length(2, ({ length }) => t('components.date-input.day.errors.length', { length })),
    month: yup
      .string()
      .required(t('components.date-input.month.errors.required'))
      .length(2, ({ length }) => t('components.date-input.month.errors.length', { length })),
    year: yup
      .string()
      .required(t('components.date-input.year.errors.required'))
      .length(4, ({ length }) => t('components.date-input.year.errors.length', { length })),
  });

  const {
    register,
    setValue,
    watch,
    reset,
    trigger,
    formState: { errors, isValid },
  } = useForm<DateForm>({
    defaultValues: {
      day: '',
      month: '',
      year: '',
    },
    resolver: yupResolver(dateSchema),
  });

  const day = watch('day');
  const month = watch('month');
  const year = watch('year');

  const debouncedValidation = useCallback(
    debounceOneSecond(() => trigger(['day', 'month', 'year'])),
    [trigger],
  );

  useEffect(() => {
    if (day !== '' && month !== '' && year !== '') {
      debouncedValidation();
    }
  }, [day, month, year]);

  useEffect(() => {
    if (initialValue) {
      const dateFromInitialValue = DateTime.fromJSDate(initialValue);

      const initialDay = dateFromInitialValue.get('day');
      const initialMonth = dateFromInitialValue.get('month');
      const initialYear = dateFromInitialValue.get('year');

      // normalize to two digits when single digit (lesser than 10)
      reset({
        day: initialDay < 10 ? `0${initialDay}` : initialDay.toString(),
        month: initialMonth < 10 ? `0${initialMonth}` : initialMonth.toString(),
        year: initialYear.toString(),
      });

      onChangeDate(initialValue);
    }
  }, [initialValue?.toString()]);

  useEffect(() => {
    if (!day || !month || !year) {
      return;
    }

    if (!isValid) {
      onChangeDate(new Date());
      return;
    }

    const dateFromFields = DateTime.fromFormat(`${month}-${day}-${year}`, 'MM-dd-yyyy');
    if (dateFromFields.isValid) {
      onChangeDate(dateFromFields.toJSDate());
    } else {
      onChangeDate(new Date());
    }
  }, [day, month, year, isValid]);

  useEffect(() => {
    register('day');
    register('month');
    register('year');
  }, [register]);

  return (
    <Fragment>
      <S.DateContainer>
        <S.DayInput
          keyboardType="numeric"
          textAlign="center"
          textAlignVertical="center"
          maxLength={2}
          value={day}
          error={Boolean(errors.day)}
          label={t('components.date-input.day.label')}
          placeholder={t('components.date-input.day.placeholder')}
          onChangeText={(text) => setValue('day', text)}
        />
        <Bold>{DATE_SEPARATOR}</Bold>
        <S.MonthInput
          keyboardType="numeric"
          textAlign="center"
          textAlignVertical="center"
          maxLength={2}
          value={month}
          error={Boolean(errors.month)}
          label={t('components.date-input.month.label')}
          placeholder={t('components.date-input.month.placeholder')}
          onChangeText={(text) => setValue('month', text)}
        />
        <Bold>{DATE_SEPARATOR}</Bold>
        <S.YearInput
          keyboardType="numeric"
          textAlign="center"
          textAlignVertical="center"
          maxLength={4}
          value={year}
          error={Boolean(errors.year)}
          label={t('components.date-input.year.label')}
          placeholder={t('components.date-input.year.placeholder')}
          onChangeText={(text) => setValue('year', text)}
        />
      </S.DateContainer>
      <S.ErrorsContainer>
        {Boolean(errors.day) && (
          <HelperText type="error" visible={Boolean(errors.day)}>
            {errors.day?.message ?? ''}
          </HelperText>
        )}
        {Boolean(errors.month) && (
          <HelperText type="error" visible={Boolean(errors.month)}>
            {errors.month?.message ?? ''}
          </HelperText>
        )}
        {Boolean(errors.year) && (
          <HelperText type="error" visible={Boolean(errors.year)}>
            {errors.year?.message ?? ''}
          </HelperText>
        )}
      </S.ErrorsContainer>
    </Fragment>
  );
});
