import { useLocalObservable } from 'mobx-react-lite';

import { container } from '@ioupie/injection';
import { STORE_TYPES } from '@ioupie/shared/constants';
import { AddressStore } from '@ioupie/stores';

export const useAddressStore = (): AddressStore => {
  return useLocalObservable(() => {
    return container.get(STORE_TYPES.ADDRESS);
  });
};
