import { memo } from 'react';

import { IconButtonProps } from './icon-button.models';
import * as S from './icon-button.styles';

/**
 * @function IconButtonComponent
 */
export default memo((props: IconButtonProps) => {
  const { icon = '', disabled = false, size, iconColor, flip = false, onPress } = props;

  return (
    <S.IconButtonComponent
      icon={icon}
      onPress={onPress}
      disabled={disabled}
      iconColor={iconColor}
      size={size}
      flip={flip}
    />
  );
});
