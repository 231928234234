import { TextInput } from 'react-native-paper';
import styled from 'styled-components/native';

export default styled(TextInput)`
  background-color: transparent;
  height: 55px;
  margin-bottom: 10px;
  margin-left: 0;
  margin-right: 0;
  margin-top: 0;
  padding: 0;
`;
