import { Children, memo } from 'react';

import { BrandingViewProps } from './branding-view.models';
import * as S from './branding-view.styles';

/**
 * @function BrandingViewComponent
 */
export default memo(({ children }: BrandingViewProps) => {
  return <S.FranchisingView>{Children.toArray(children)}</S.FranchisingView>;
});
