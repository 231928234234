export enum PAYMENT_METHOD_TYPES {
  CREDIT = 'CC',
  PIX = 'PIX',
}

export enum PAYMENT_STATUS {
  SUCCESS = 'SUCCESS',
  AUTHORIZATION = 'AUTHORIZATION',
  CANCELLED = 'CANCELLED',
}
