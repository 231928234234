import { observer } from 'mobx-react-lite';
import { Fragment, useCallback } from 'react';
import { useTranslation } from 'react-i18next';
import { FlatList, ListRenderItemInfo } from 'react-native';

import { List } from '@ioupie/components/custom';
import { AttentionBox } from '@ioupie/components/custom/attention-box';
import { useShopsStore } from '@ioupie/hooks';
import { ProductAmount } from '@ioupie/shared/models';

import { SummaryLegend } from './summary-legend';

/**
 * @function NewOrderSummaryContainer
 */
export default observer(() => {
  const [t] = useTranslation();
  const shopsStore = useShopsStore();

  const summaryKeyExtractor = useCallback((productAmount: ProductAmount) => productAmount.product.productId, []);

  const renderSummaryListItem = useCallback((info: ListRenderItemInfo<ProductAmount>) => {
    const { item: productAmount } = info;
    const price = productAmount.amount * productAmount.product.minPrice.amount;
    return (
      <List.Item
        body={
          <List.Message
            title={productAmount.product.name}
            subtitle={t('containers.orders.new-order-summary.description', { price: price.toFixed(2) })}
          />
        }
        right={<SummaryLegend productAmount={productAmount} />}
      />
    );
  }, []);

  return (
    <Fragment>
      <AttentionBox text={t('containers.orders.new-order-summary.attention-box')} />
      <List.Section>
        <FlatList<ProductAmount>
          data={shopsStore.productsSummary}
          keyExtractor={summaryKeyExtractor}
          renderItem={renderSummaryListItem}
        />
      </List.Section>
    </Fragment>
  );
});
