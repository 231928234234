export enum PAYMENT_METHOD {
  CASH = 'CASH',
  CC = 'CC',
  PIX = 'PIX',
}

export enum PAYMENT_METHOD_STATUS {
  ACTIVE = 'ACTIVE',
  INACTIVE = 'INACTIVE',
}
