import { darken, lighten } from 'polished';
import styled from 'styled-components/native';

import { Icon } from '@ioupie/components/gallery';
import { colors } from '@ioupie/shared/constants';
import { themefy } from '@ioupie/shared/utils';

export const SelectionContainer = styled.View`
  align-items: center;
  border-top-color: ${colors.light.primary};
  border-top-width: 3px;
  margin: 0 6px 12px;
`;

export const SummaryWrapper = styled.View`
  flex-direction: row;
  justify-content: space-between;
  padding: 12px 6px;
  width: 90%;
`;

export const CartIcon = styled(Icon)`
  color: ${themefy({
    light: lighten(0.2, colors.light.text),
    dark: darken(0.2, colors.dark.text),
  })};
  margin: 0;
`;
