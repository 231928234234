import { observer } from 'mobx-react-lite';
import { Fragment } from 'react';
import { useTranslation } from 'react-i18next';

import { BlockButton } from '@ioupie/components/buttons';
import { Dialog, ErrorSnackbar } from '@ioupie/components/custom';
import { ActionsBar, Portal } from '@ioupie/components/layout';
import { Text } from '@ioupie/components/typography';
import { useAddressStore } from '@ioupie/hooks';

/**
 * @function DeleteAddressContainer
 */
export default observer(() => {
  const [t] = useTranslation();
  const addressStore = useAddressStore();

  const removeAddress = async () => {
    const currentAddress = addressStore.addressCherryPick;
    if (currentAddress) {
      await addressStore.removeZipCodeInfo(currentAddress.id);
      addressStore.closeDeleteDialog();
    }
  };

  const { zipCode = '' } = addressStore.addressCherryPick ?? {};

  return (
    <Fragment>
      <Portal>
        <Dialog.View
          dismissable
          visible={addressStore.isDeleteDialogOpen}
          onDismiss={() => addressStore.closeDeleteDialog()}>
          <Dialog.Title>{t('containers.location.delete-address.title')}</Dialog.Title>
          <Dialog.Content>
            <Text>{t('containers.location.delete-address.alert-message', { zipCode })}</Text>
          </Dialog.Content>
          <Dialog.Actions>
            <ActionsBar>
              <BlockButton
                secondary
                reduced
                text={t('containers.location.delete-address.cancel')}
                onPress={() => addressStore.closeDeleteDialog()}
              />
              <BlockButton reduced text={t('containers.location.delete-address.remove')} onPress={removeAddress} />
            </ActionsBar>
          </Dialog.Actions>
        </Dialog.View>
      </Portal>
      <ErrorSnackbar errors={addressStore.errors} onDismiss={() => addressStore.clearErrors()} />
    </Fragment>
  );
});
