import { observer } from 'mobx-react-lite';
import { Fragment } from 'react';
import { useTranslation } from 'react-i18next';
import { TouchableOpacity } from 'react-native';

import { ErrorSnackbar } from '@ioupie/components/custom';
import { KeyboardView } from '@ioupie/components/layout';
import { useAddressStore, useNavigationStore } from '@ioupie/hooks';
import { routes } from '@ioupie/shared/constants';

import * as S from './address-box.styles';

/**
 * @function AddressBoxContainer
 */
export default observer(() => {
  const [t] = useTranslation();
  const addressStore = useAddressStore();
  const navigationStore = useNavigationStore();

  return (
    <Fragment>
      <KeyboardView>
        {addressStore.addressLocationAsText ? (
          <S.AddressContainer>
            <TouchableOpacity
              onPress={() =>
                navigationStore.dispatchNavigation({
                  stack: routes.stacks.address,
                  screen: routes.pages.address.addresses_list,
                })
              }>
              <S.AddressDisplayWrapper>
                <S.AddressSecondaryIcon name="home-city-outline" size={18} />
                <S.AddressDisplayText>{addressStore.addressStreetAndNumberAsText}</S.AddressDisplayText>
                <S.AddressEditIcon name="pencil" size={18} />
              </S.AddressDisplayWrapper>
            </TouchableOpacity>
          </S.AddressContainer>
        ) : (
          <S.AddressContainer>
            <TouchableOpacity
              onPress={() => {
                const screen =
                  addressStore.allAddresses.length === 0
                    ? routes.pages.address.add_new_address
                    : routes.pages.address.addresses_list;

                navigationStore.dispatchNavigation({
                  stack: routes.stacks.address,
                  screen,
                });
              }}>
              <S.AddressDisplayWrapper>
                <S.AddressDisplayWrapperTitleAndSubtitle>
                  <S.AddressDisplayTitle>{t('containers.location.address-box.title')}</S.AddressDisplayTitle>
                </S.AddressDisplayWrapperTitleAndSubtitle>
                <S.AddressPrimaryIcon name="pencil" size={24} />
              </S.AddressDisplayWrapper>
            </TouchableOpacity>
          </S.AddressContainer>
        )}
      </KeyboardView>
      <ErrorSnackbar errors={addressStore.errors} onDismiss={() => addressStore.clearErrors()} />
    </Fragment>
  );
});
