import { DateTime } from 'luxon';
import { observer } from 'mobx-react-lite';
import { Fragment } from 'react';
import { useTranslation } from 'react-i18next';
import { TouchableOpacity } from 'react-native';

import { BlockButton } from '@ioupie/components/buttons';
import { UniversalImage } from '@ioupie/components/gallery';
import { Small, Text } from '@ioupie/components/typography';
import { useOrdersStore } from '@ioupie/hooks';
import { CREDIT_CARD_LOGOS, DF_LOCALE, PAYMENT_METHOD_TYPES, PAYMENT_STATUS, PIX_LOGO } from '@ioupie/shared/constants';
import { safeObjectLookup } from '@ioupie/shared/utils';

import type { InvolvedPaymentProps } from './involved-payment.models';
import * as S from './involved-payment.styles';

/**
 * @function InvolvedPaymentContainer
 */
export default observer((props: InvolvedPaymentProps) => {
  const [t] = useTranslation();
  const ordersStore = useOrdersStore();

  const { singlePaymentInformation } = props;
  const {
    amount,
    issuer,
    lastDigits = '',
    confirmedDate = '',
    metadata,
    type,
    status,
  } = singlePaymentInformation ?? {};

  const { pixCopiaCola = '', qrCodeBase64 = '', expirationDate = '' } = metadata ?? {};

  const logo = safeObjectLookup(CREDIT_CARD_LOGOS, issuer) ?? '';

  const obfuscatedNo = `${t('containers.orders.track-order.payment-details.ends-with')} ${lastDigits}`;

  const paidAmount = amount?.amount ? `${t('constants.currency.BRL')} ${amount.amount.toFixed(2)}` : '';
  const paidOn = confirmedDate ? DateTime.fromISO(confirmedDate).toFormat(t(DF_LOCALE)) : '';

  const CreditCardPaymentInfo = (
    <S.PaymentEntryContainer>
      <S.CreditCardDetails>
        {logo && <S.PaymentMethodImage image={logo} width={28} height={28} />}
        <Text>{lastDigits ? obfuscatedNo : t('containers.orders.track-order.payment-details.method-cc')}</Text>
      </S.CreditCardDetails>
      <Text>{paidOn}</Text>
      <Text>{paidAmount}</Text>
    </S.PaymentEntryContainer>
  );

  const copyPixCodeToClipboard = () => {
    ordersStore.copyPixCodeToClipboard(pixCopiaCola);
    ordersStore.setError(t('containers.orders.track-order.payment-details.pix-copied'));
  };

  const PixPaymentInfo =
    status === PAYMENT_STATUS.AUTHORIZATION ? (
      <Fragment>
        <TouchableOpacity onPress={copyPixCodeToClipboard}>
          <S.PixHeader>
            <UniversalImage image={PIX_LOGO} width={100} height={50} />
            <S.PixCopiaColaContainer>
              <S.PixCopiaColaField>{pixCopiaCola}</S.PixCopiaColaField>
              <BlockButton
                autoWidth={true}
                text={t('containers.orders.track-order.payment-details.pix-2-clipboard')}
                onPress={copyPixCodeToClipboard}
              />
            </S.PixCopiaColaContainer>
          </S.PixHeader>
          <S.PixCodeContainer>
            <S.PixCode image={`data:image/png;base64,${qrCodeBase64}`} />
          </S.PixCodeContainer>
          <S.PixFooter>
            <Small>
              {t('containers.orders.track-order.payment-details.pix-expire', {
                date: DateTime.fromISO(expirationDate).toFormat(t(DF_LOCALE)),
              })}
            </Small>
          </S.PixFooter>
        </TouchableOpacity>
      </Fragment>
    ) : (
      <S.PaymentEntryContainer>
        <S.CreditCardDetails>
          <S.PaymentMethodImage image={PIX_LOGO} width={28} height={28} />
          <Text>{t('containers.orders.track-order.payment-details.method-pix')}</Text>
        </S.CreditCardDetails>
        <Text>{paidOn}</Text>
        <Text>{paidAmount}</Text>
      </S.PaymentEntryContainer>
    );

  return type === PAYMENT_METHOD_TYPES.PIX ? PixPaymentInfo : CreditCardPaymentInfo;
});
