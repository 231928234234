import { getTypeInfo } from 'credit-card-type';
import { observer } from 'mobx-react-lite';
import { Fragment, useCallback, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { FlatList, ListRenderItemInfo } from 'react-native';

import { BlockButton, IconButton } from '@ioupie/components/buttons';
import { ErrorSnackbar, List, Loader, NoContent } from '@ioupie/components/custom';
import { Title, Small } from '@ioupie/components/typography';
import { useNavigationStore, usePaymentStore } from '@ioupie/hooks';
import { CREDIT_CARD_LOGOS, routes } from '@ioupie/shared/constants';
import { PaymentMethod } from '@ioupie/shared/models';
import { safeObjectLookup } from '@ioupie/shared/utils';

import * as S from './payment-list.styles';

/**
 * @function PaymentListContainer
 */
export default observer(() => {
  const [t] = useTranslation();
  const paymentStore = usePaymentStore();
  const navigationStore = useNavigationStore();

  useEffect(() => {
    // eslint-disable-next-line no-void
    void paymentStore.fetchPaymentMethods();
  }, []);

  const handleMethodSelection = (paymentMethod: PaymentMethod): void => {
    paymentStore.saveSelectedMethod(paymentMethod);
    navigationStore.dispatchGoBack();
  };

  const paymentKeyExtractor = useCallback((paymentMethod: PaymentMethod) => paymentMethod.id, []);

  const renderPaymentListItem = useCallback((info: ListRenderItemInfo<PaymentMethod>) => {
    const { item: paymentMethod } = info;
    const logo = safeObjectLookup(CREDIT_CARD_LOGOS, paymentMethod?.issuer) ?? '';
    const name = getTypeInfo(paymentMethod?.issuer)?.niceType ?? '';

    return (
      <List.Item
        onPress={() => handleMethodSelection(paymentMethod)}
        left={<List.Image image={logo} size="medium" />}
        body={
          <S.CardDetails
            title={name ?? ''}
            subtitle={t('containers.payment.payment-list.last-digits-label', {
              lastDigits: paymentMethod?.lastDigits ?? '****',
            })}
          />
        }
        right={<IconButton icon="trash-can-outline" onPress={() => paymentStore.showDeleteDialog(paymentMethod)} />}
      />
    );
  }, []);

  return (
    <Fragment>
      <S.CenterTitle>
        <Title>{t('containers.payment.payment-list.title')}</Title>
        <Small>{t('containers.payment.payment-list.description')}</Small>
      </S.CenterTitle>
      <S.PaymentListContainer>
        {paymentStore.hasPaymentMethods ? (
          <FlatList<PaymentMethod>
            data={paymentStore.paymentMethods}
            keyExtractor={paymentKeyExtractor}
            renderItem={renderPaymentListItem}
          />
        ) : (
          <NoContent
            iconName="credit-card-off-outline"
            title={t('containers.payment.payment-list.no-card')}
            subtitle={t('containers.payment.payment-list.no-card-subtitle')}
          />
        )}
      </S.PaymentListContainer>
      <BlockButton
        text={t('containers.payment.payment-list.add-card')}
        onPress={() =>
          navigationStore.dispatchNavigation({
            stack: routes.stacks.payment,
            screen: routes.pages.payment.add_credit_card,
          })
        }
      />
      <Loader show={paymentStore.loading} message={t('containers.payment.payment-list.loading')} />
      <ErrorSnackbar errors={paymentStore.errors} onDismiss={() => paymentStore.clearErrors()} />
    </Fragment>
  );
});
