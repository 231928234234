import styled from 'styled-components/native';

import { Title, Text } from '@ioupie/components/typography';
import { colors } from '@ioupie/shared/constants';
import { themefy } from '@ioupie/shared/utils';

export const ModalActions = styled.View`
  flex-direction: row;
`;

export const ModalContent = styled.View`
  background-color: ${themefy({
    light: colors.light.background,
    dark: colors.dark.background,
  })};
  padding: 16px;
`;

export const ModalTitle = styled(Title)`
  padding: 0 5px;
`;

export const ModalWarning = styled(Text)`
  padding: 16px 5px;
`;
