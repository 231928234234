import { View } from 'react-native';
import styled from 'styled-components/native';

import { Button } from '@ioupie/components/buttons';
import { Text } from '@ioupie/components/typography';
import { colors, fontsFamily, fontsWeight } from '@ioupie/shared/constants';
import { themefy } from '@ioupie/shared/utils';

export const ScheduleContainer = styled(View)`
  margin: 16px 16px 0;
`;

export const ScheduleTitle = styled(Text)`
  color: ${themefy({
    light: colors.light.title,
    dark: colors.dark.title,
  })};
  font-family: ${fontsFamily.bold};
  font-size: 16px;
  font-weight: ${fontsWeight.bold};
  margin: 0 0 8px 0;
`;

export const MoreDates = styled(Button)`
  align-items: flex-end;
`;

export const DateContainer = styled(View)`
  padding: 0 20px 0;
  margin: 0;
`;

export const PickupDescription = styled(View)`
  background-color: ${themefy({
    light: colors.light.background,
    dark: colors.dark.background,
  })};
  border-color: ${themefy({
    light: colors.light.primary,
    dark: colors.dark.primary,
  })};
  border-left-width: 7px;
  border-width: 2px;
  font-family: ${fontsFamily.bold};
  font-weight: ${fontsWeight.bold};
  margin: 0 0 8px 0;
  padding: 10px;
`;

export const PickupDescriptionText = styled(Text)`
  font-family: ${fontsFamily.bold};
  font-weight: ${fontsWeight.bold};
`;

export const DateAvailable = styled(Text)`
  color: ${() =>
    themefy({
      light: colors.light.title,
      dark: colors.dark.title,
    })};
  font-family: ${({ bold }) => (bold ? fontsFamily.bold : fontsFamily.regular)};
  font-size: 15px;
  padding: 5px 0 0;
`;

export const DeliveryFee = styled(Text)`
  color: ${() =>
    themefy({
      light: colors.light.text3,
      dark: colors.dark.text3,
    })};
  font-family: ${fontsFamily.regular};
  font-size: 15px;
  padding: 2px 0;
`;
