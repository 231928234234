export enum PATH_PARAMS {
  USER_ID = '{USER-ID}',
  LOCKER_ID = '{LOCKER-ID}',
  ORDER_ID = '{ORDER-ID}',
  ORDER_TYPE = '{ORDER-TYPE}',
  ORDER_STATUS = '{ORDER-STATUS}',
  PROVIDER_ID = '{PROVIDER-ID}',
  METHOD_ID = '{METHOD-ID}',
  COMPARTMENT_TYPE = '{COMPARTMENT_TYPE}',
  COMPARTMENT_NUMBER = '{COMPARTMENT_NUMBER}',
  ZIP_NUMBER = '{ZIP_NUMBER}',
}

export enum QUERY_PARAMS {
  LAUNDRY_ID = '{LAUNDRY-ID}',
  ADDRESS_BASE64 = '{ADDRESS-BASE64}',
  ADDRESS_POSITION = '{ADDRESS-POSITION}',
  LANGUAGE = '{LANGUAGE}',
  ORDER_DATES = '{ORDER-DATES}',
  USER_ID = '{USER-ID}',
}
