import { IconButton } from 'react-native-paper';
import { css } from 'styled-components';
import styled from 'styled-components/native';

import { IconButtonProps } from './icon-button.models';

declare type IconProps = Pick<IconButtonProps, 'flip'>;

export const IconButtonComponent = styled(IconButton)<IconProps>`
  align-items: center;
  justify-content: center;
  margin: 0;
  padding: 0;
  text-align: center;
  ${({ flip }) => {
    return css`
      transform: ${flip ? 'scaleX(-1)' : undefined};
    `;
  }}
`;
