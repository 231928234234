import { memo } from 'react';
import { useTranslation } from 'react-i18next';
import { isPresent } from 'ts-is-present';

import { useTheme } from '@ioupie/hooks';
import { colors } from '@ioupie/shared/constants';

import { ErrorSnackbarProps } from './error-snackbar.models';
import * as S from './error-snackbar.styles';

/**
 * @function ErrorSnackbarComponent
 */
export default memo((props: ErrorSnackbarProps) => {
  const [t] = useTranslation();
  const theme = useTheme();

  const { errors = [], onDismiss } = props;

  // remove blank values
  const filteredErrors = errors.filter(isPresent).filter((message) => message !== '');
  // join into a single message
  const errorMessage = filteredErrors.map(t).join('\n');

  const closeColor = theme.mode === 'dark' ? colors.dark.text2 : colors.light.text2;

  return (
    <S.ErrorBar
      visible={filteredErrors.length > 0}
      onDismiss={onDismiss}
      action={{ label: '', onPress: onDismiss, icon: 'close', color: closeColor }}>
      {errorMessage}
    </S.ErrorBar>
  );
});
