import { CalendarTheme, DotMarking } from 'react-native-calendars';

import { colors, fontsFamily } from '@ioupie/shared/constants';

const backgroundColor = colors.dark.background;
const textColor = colors.dark.text;
const disabledColor = colors.dark.text2;
const primaryColor = colors.dark.primary;
const secondaryColor = colors.dark.secondary;

export const calendarDarkTheme: CalendarTheme = {
  textMonthFontWeight: 'bold',
  textDayFontFamily: fontsFamily.regular,
  textMonthFontFamily: fontsFamily.medium,
  textDayHeaderFontFamily: fontsFamily.regular,
  arrowColor: primaryColor,
  selectedDayBackgroundColor: primaryColor,
  dotColor: primaryColor,
  selectedDotColor: primaryColor,
  calendarBackground: backgroundColor,
  textDisabledColor: disabledColor,
  monthTextColor: textColor,
  dayTextColor: textColor,
};

export const currentDarkStyles: DotMarking = {
  selected: true,
  selectedColor: secondaryColor,
};

export const selectedDarkStyles: DotMarking = {
  selected: true,
  selectedColor: primaryColor,
};

export const disabledDarkStyles: DotMarking = {
  disabled: true,
  disableTouchEvent: true,
};
