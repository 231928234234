import { useFocusEffect } from '@react-navigation/native';
import { observer } from 'mobx-react-lite';
import { Fragment, ReactElement, useCallback, useEffect, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { BackHandler } from 'react-native';

import { ScrollView } from '@ioupie/components/layout';
import { useHeaderStore, useNavigationStore, useOrdersStore, useShopsStore } from '@ioupie/hooks';
import { BUSINESS_SEGMENTS_OPTIONS, ORDERING_STEPS } from '@ioupie/shared/constants';
import { Optional } from '@ioupie/shared/models';
import { safeObjectLookup } from '@ioupie/shared/utils';

import { BasketStep } from './basket-step';
import { Calendar } from './calendar';
import { PaymentStep } from './payment-step';
import { ScheduleStep } from './schedule-step';
import { SummaryStep } from './summary-step';

/**
 * @function AccountContainer
 */
export default observer(() => {
  const [t] = useTranslation();
  const headerStore = useHeaderStore();
  const ordersStore = useOrdersStore();
  const navigationStore = useNavigationStore();
  const shopsStore = useShopsStore();

  const headerBackActionsPerStep: Record<ORDERING_STEPS, Optional<CallableFunction>> = useMemo(
    () => ({
      [ORDERING_STEPS.BASKET]: () => navigationStore.dispatchGoBack(),
      [ORDERING_STEPS.SCHEDULE]: () => {
        ordersStore.changeOrderingStep(ORDERING_STEPS.BASKET);
        headerStore.changeTitle(t('containers.orders.ordering.pickup-delivery-step'));
        headerStore.changeSubtitle('');
      },
      [ORDERING_STEPS.CALENDAR]: () => {
        ordersStore.changeOrderingStep(ORDERING_STEPS.SCHEDULE);
        headerStore.changeTitle(t('containers.orders.ordering.schedule-step.title'));
        headerStore.changeSubtitle('');
      },
      [ORDERING_STEPS.PAYMENT]: () => {
        if (shopsStore.shopProvider?.segment === BUSINESS_SEGMENTS_OPTIONS.GOODS) {
          ordersStore.changeOrderingStep(ORDERING_STEPS.BASKET);
          headerStore.changeTitle(t('containers.orders.ordering.pickup-delivery-step'));
        } else {
          ordersStore.changeOrderingStep(ORDERING_STEPS.SCHEDULE);
          headerStore.changeTitle(t('containers.orders.ordering.payment.title'));
        }
        ordersStore.clearInstallmentsData();
        headerStore.changeSubtitle('');
      },
      [ORDERING_STEPS.SUMMARY]: () => {
        ordersStore.changeOrderingStep(ORDERING_STEPS.PAYMENT);
        headerStore.changeTitle(t('containers.orders.ordering.summary-step.title'));
        headerStore.changeSubtitle('');
      },
    }),
    [],
  );

  const actionsToTakePerStep: Record<ORDERING_STEPS, CallableFunction> = useMemo(
    () => ({
      [ORDERING_STEPS.BASKET]: () => {
        headerStore.changeTitle(t('containers.orders.ordering.my-basket-step'));
        headerStore.changeSubtitle(t('containers.orders.ordering.my-basket-step-subtitle'));
      },
      [ORDERING_STEPS.SCHEDULE]: () => {
        headerStore.changeTitle(t('containers.orders.ordering.pickup-delivery-step'));
        headerStore.changeSubtitle('');
      },
      [ORDERING_STEPS.CALENDAR]: () => {
        headerStore.changeTitle(t('containers.orders.ordering.schedule-step.title'));
        headerStore.changeSubtitle('');
      },
      [ORDERING_STEPS.PAYMENT]: () => {
        headerStore.changeTitle(t('containers.orders.ordering.payment.title'));
        headerStore.changeSubtitle('');
      },
      [ORDERING_STEPS.SUMMARY]: () => {
        headerStore.changeTitle(t('containers.orders.ordering.summary-step.title'));
        headerStore.changeSubtitle('');
      },
    }),
    [],
  );

  const containerPerStep: Record<ORDERING_STEPS, ReactElement> = useMemo(
    () => ({
      [ORDERING_STEPS.BASKET]: <BasketStep />,
      [ORDERING_STEPS.SCHEDULE]: <ScheduleStep />,
      [ORDERING_STEPS.CALENDAR]: (
        <ScrollView>
          <Calendar />
        </ScrollView>
      ),
      [ORDERING_STEPS.PAYMENT]: <PaymentStep />,
      [ORDERING_STEPS.SUMMARY]: <SummaryStep />,
    }),
    [],
  );

  useEffect(() => {
    // configure the header
    const headerBackAction = safeObjectLookup(headerBackActionsPerStep, ordersStore.orderingStep);
    headerStore.onClickBack(headerBackAction);

    // side effects per state
    const actionsToTake = safeObjectLookup(actionsToTakePerStep, ordersStore.orderingStep);
    if (actionsToTake) actionsToTake();
  }, [ordersStore.orderingStep]);

  useFocusEffect(
    useCallback(() => {
      const onBackPress = () => {
        /**
         * @todo FIXME Improve code. Need to find a way to access the lookup table
         * inside this useCallback.
         */
        if (ordersStore.orderingStep === ORDERING_STEPS.BASKET) {
          navigationStore.dispatchGoBack();
        }
        if (ordersStore.orderingStep === ORDERING_STEPS.SCHEDULE) {
          ordersStore.changeOrderingStep(ORDERING_STEPS.BASKET);
          headerStore.changeTitle(t('containers.orders.ordering.pickup-delivery-step'));
          headerStore.changeSubtitle('');
        }
        if (ordersStore.orderingStep === ORDERING_STEPS.CALENDAR) {
          ordersStore.changeOrderingStep(ORDERING_STEPS.SCHEDULE);
          headerStore.changeTitle(t('containers.orders.ordering.schedule-step.title'));
          headerStore.changeSubtitle('');
        }
        if (ordersStore.orderingStep === ORDERING_STEPS.PAYMENT) {
          if (shopsStore.shopProvider?.segment === BUSINESS_SEGMENTS_OPTIONS.GOODS) {
            ordersStore.changeOrderingStep(ORDERING_STEPS.BASKET);
            headerStore.changeTitle(t('containers.orders.ordering.pickup-delivery-step'));
          } else {
            ordersStore.changeOrderingStep(ORDERING_STEPS.SCHEDULE);
            headerStore.changeTitle(t('containers.orders.ordering.payment.title'));
          }
          headerStore.changeSubtitle('');
          ordersStore.clearInstallmentsData();
        }
        if (ordersStore.orderingStep === ORDERING_STEPS.SUMMARY) {
          ordersStore.changeOrderingStep(ORDERING_STEPS.PAYMENT);
          headerStore.changeTitle(t('containers.orders.ordering.summary-step.title'));
          headerStore.changeSubtitle('');
        }

        return true;
      };

      const subscription = BackHandler.addEventListener('hardwareBackPress', onBackPress);

      return () => subscription.remove();
    }, [ordersStore.orderingStep]),
  );

  return safeObjectLookup(containerPerStep, ordersStore.orderingStep) ?? <Fragment />;
});
