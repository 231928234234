import { yupResolver } from '@hookform/resolvers/yup';
import { observer } from 'mobx-react-lite';
import { Fragment, useCallback, useEffect } from 'react';
import { useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { Keyboard, TouchableOpacity } from 'react-native';
import * as yup from 'yup';

import { BlockButton } from '@ioupie/components/buttons';
import { ErrorSnackbar } from '@ioupie/components/custom';
import { PasswordInput, TextInput } from '@ioupie/components/inputs';
import { ScrollView } from '@ioupie/components/layout';
import { HelperText, Small, Text, Title } from '@ioupie/components/typography';
import { useAuthStore } from '@ioupie/hooks';
import { USER_STEPS } from '@ioupie/shared/constants';
import { debounceOneSecond } from '@ioupie/shared/utils';

import { ForgotPasswordForm } from './forgot-password.models';
import * as S from './forgot-password.styles';

/**
 * @function ForgotPasswordStepComponent
 */
export default observer(() => {
  const [t] = useTranslation();
  const authStore = useAuthStore();

  const forgotPasswordSchema: yup.SchemaOf<ForgotPasswordForm> = yup.object().shape({
    token: yup
      .string()
      .required(t('forms.forgot-password.token.errors.required'))
      .length(6, ({ length }) => t('forms.forgot-password.token.errors.length', { length })),
    password: yup
      .string()
      .required(t('forms.forgot-password.password.errors.required'))
      .min(8, ({ min }) => t('forms.forgot-password.password.errors.min', { min })),
    confirmPassword: yup
      .string()
      .required(t('forms.forgot-password.confirm-password.errors.required'))
      .oneOf([yup.ref('password')], t('forms.forgot-password.confirm-password.errors.valid')),
  });

  const {
    register,
    handleSubmit,
    setValue,
    formState: { errors, isValid },
  } = useForm<ForgotPasswordForm>({
    defaultValues: {
      token: '',
      password: '',
      confirmPassword: '',
    },
    resolver: yupResolver(forgotPasswordSchema),
  });

  useEffect(() => {
    register('token');
    register('password');
    register('confirmPassword');
  }, [register]);

  useEffect(() => {
    if (authStore.userStep === USER_STEPS.FORGOT_PASSWORD) {
      void authStore.requestForgotPasswordCode();
    }
  }, [authStore.userStep]);

  const onSubmitValidForm = useCallback((form: ForgotPasswordForm) => {
    Keyboard.dismiss();
    return authStore.recoverLostAccount(form);
  }, []);

  const TokenSection = (
    <Fragment>
      <S.CenterTitle>
        <Text>{t('forms.forgot-password.token.usage', { email: authStore.username })}</Text>
      </S.CenterTitle>
      <TextInput
        keyboardType="numeric"
        maxLength={6}
        error={Boolean(errors.token)}
        label={t('forms.forgot-password.token.label')}
        placeholder={t('forms.forgot-password.token.placeholder')}
        onChangeText={debounceOneSecond((text) => {
          setValue('token', text, { shouldValidate: true });
        })}
      />
      <HelperText type="error" visible={Boolean(errors.token)}>
        {errors.token?.message ?? ''}
      </HelperText>
    </Fragment>
  );

  const PasswordSection = (
    <Fragment>
      <PasswordInput
        autoCompleteType="password"
        error={Boolean(errors.password)}
        label={t('forms.forgot-password.password.label')}
        placeholder={t('forms.forgot-password.password.placeholder')}
        onChangeText={debounceOneSecond((text) => {
          setValue('password', text, { shouldValidate: true });
        })}
      />
      <HelperText type="error" visible={Boolean(errors.password)}>
        {errors.password?.message ?? ''}
      </HelperText>
    </Fragment>
  );

  const ConfirmPasswordSection = (
    <Fragment>
      <PasswordInput
        autoCompleteType="password"
        error={Boolean(errors.confirmPassword)}
        label={t('forms.forgot-password.confirm-password.label')}
        placeholder={t('forms.forgot-password.confirm-password.placeholder')}
        onChangeText={debounceOneSecond((text) => {
          setValue('confirmPassword', text, { shouldValidate: true });
        })}
      />
      <HelperText type="error" visible={Boolean(errors.confirmPassword)}>
        {errors.confirmPassword?.message ?? ''}
      </HelperText>
    </Fragment>
  );

  return (
    <S.ForgotPasswordContainer>
      <ScrollView>
        <S.CenterTitle>
          <Title>{t('forms.forgot-password.title')}</Title>
          <Text>{t('forms.forgot-password.instruction', { name: authStore.userFirstName })}</Text>
        </S.CenterTitle>
        {TokenSection}
        {PasswordSection}
        {ConfirmPasswordSection}
        <S.SnackbarContainer>
          <ErrorSnackbar errors={authStore.errors} onDismiss={() => authStore.clearErrors()} />
        </S.SnackbarContainer>
      </ScrollView>
      <BlockButton
        text={t('forms.forgot-password.submit')}
        disabled={!isValid}
        loading={authStore.loading}
        onPress={handleSubmit(onSubmitValidForm)}
      />
      <TouchableOpacity onPress={() => authStore.requestForgotPasswordCode()}>
        <S.LinkText>{t('forms.forgot-password.token.send-token')}</S.LinkText>
      </TouchableOpacity>
    </S.ForgotPasswordContainer>
  );
});
