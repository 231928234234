import environment from '@ioupie/env';

import { PATH_PARAMS, QUERY_PARAMS } from './url-params.constants';

export const hosts = Object.freeze({
  portaria: `${environment.API_HOST}/portaria/v1`,
  catalog: `${environment.API_HOST}/catalog/v1`,
  harvey: `${environment.API_HOST}/harvey/v1`,
  harveyV2: `${environment.API_HOST}/harvey/v2`,
  precificador: `${environment.API_HOST}/precificador/v1`,
} as const);

export const endpoints = Object.freeze({
  external: {
    fetch_zip_code_info: `https://viacep.com.br/ws/${PATH_PARAMS.ZIP_NUMBER}/json`,
  },
  portaria: {
    auth: {
      verify_user: `${hosts.portaria}/auth/signIn/email/${PATH_PARAMS.USER_ID}`,
      sign_in: `${hosts.portaria}/auth/signIn/email`,
      forgot_password: `${hosts.portaria}/auth/forgotPassword/email`,
      forgot_password_verify: `${hosts.portaria}/auth/forgotPassword/verify`,
      refresh: `${hosts.portaria}/auth/refresh/token`,
    },
    user: {
      sign_up: `${hosts.portaria}/users/signUp`,
      sign_up_verify: `${hosts.portaria}/users/signUp/verify`,
      resend_verification: `${hosts.portaria}/users/signUp/verify/resendVerification`,
      info: `${hosts.portaria}/resources/users/userInfo/${PATH_PARAMS.USER_ID}`,
      sign_out: `${hosts.portaria}/resources/users/signOut/${PATH_PARAMS.USER_ID}`,
      update: `${hosts.portaria}/users`,
      persist_phone_token: `${hosts.portaria}/resources/users/notificationToken`,
      list_phone_tokens: `${hosts.portaria}/resources/users/notificationTokens/${PATH_PARAMS.USER_ID}`,
      removal: `${hosts.portaria}/users/data/removal`,
    },
    providers: {
      fetch_all: `${hosts.portaria}/serviceProviders/all?${QUERY_PARAMS.ADDRESS_POSITION}`,
      find: `${hosts.portaria}/serviceProviders/findByRadius?${QUERY_PARAMS.ADDRESS_BASE64}`,
    },
    payment: {
      create: `${hosts.portaria}/paymentMethod`,
      create_or_update: `${hosts.portaria}/resources/paymentMethod/createOrUpdate`,
      fetch: `${hosts.portaria}/paymentMethod/${PATH_PARAMS.METHOD_ID}?${QUERY_PARAMS.USER_ID}`,
      fetch_list: `${hosts.portaria}/paymentMethod/user`,
      delete: `${hosts.portaria}/paymentMethod/${PATH_PARAMS.METHOD_ID}`,
    },
    lockers: {
      fetch: `${hosts.portaria}/lockers/all`,
    },
    address: {
      geocode: `${hosts.portaria}/address/geocode?${QUERY_PARAMS.ADDRESS_POSITION}`,
    },
  },
  catalog: {
    products: {
      find: `${hosts.catalog}/products/findByServiceProvider`,
      groups: `${hosts.catalog}/productGroups?${QUERY_PARAMS.LANGUAGE}`,
    },
  },
  harvey: {
    orders: {
      order_details: `${hosts.harveyV2}/orders/service/${PATH_PARAMS.ORDER_ID}`,
      manage_order: `${hosts.harveyV2}/orders/service`,
      history_from_user: `${hosts.harveyV2}/orders/list/${PATH_PARAMS.USER_ID}`,
      search: `${hosts.harveyV2}/orders/search`,
      service: `${hosts.harveyV2}/orders/service`,
      dates: `${hosts.harveyV2}/orders/dates/${PATH_PARAMS.ORDER_TYPE}?${QUERY_PARAMS.ORDER_DATES}`,
      nps: `${hosts.harveyV2}/orders/service/${PATH_PARAMS.ORDER_ID}/nps`,
    },
    service_orders: {
      service_order: `${hosts.harvey}/serviceOrders/${PATH_PARAMS.ORDER_ID}`,
    },
    lockers: {
      fetch: `${hosts.harveyV2}/lockers/all?${QUERY_PARAMS.ADDRESS_POSITION}`,
      switch_lock: `${hosts.harvey}/lockers/${PATH_PARAMS.LOCKER_ID}/switchLock/${PATH_PARAMS.COMPARTMENT_TYPE}/${PATH_PARAMS.COMPARTMENT_NUMBER}`,
    },
  },
  precificador: {
    coupon: {
      validate: `${hosts.precificador}/coupon/validate`,
    },
    price: {
      installments: `${hosts.precificador}/price/order/installments`,
    },
  },
} as const);
