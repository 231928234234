import { memo } from 'react';

import { BodyView } from '@ioupie/components/layout';
import { Help } from '@ioupie/containers/help';
import { useCurrentRouteFocus, useHeaderStore, useNavigationStore } from '@ioupie/hooks';
import { routes } from '@ioupie/shared/constants';

/**
 * @function HelpPage
 */
export default memo(() => {
  const headerStore = useHeaderStore();
  const navigationStore = useNavigationStore();

  useCurrentRouteFocus(routes.pages.help.help, () => {
    headerStore.allowNavigateBack(true);
    headerStore.removeHeaderShadow(false);
    headerStore.changeTitle('containers.help.title');
    headerStore.changeSubtitle('');
    headerStore.onClickBack(() => navigationStore.dispatchGoBack());
  });

  return (
    <BodyView>
      <Help />
    </BodyView>
  );
});
