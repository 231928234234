import { useLocalObservable } from 'mobx-react-lite';

import { container } from '@ioupie/injection';
import { STORE_TYPES } from '@ioupie/shared/constants';
import { HeaderStore } from '@ioupie/stores';

export const useHeaderStore = (): HeaderStore => {
  return useLocalObservable(() => {
    return container.get(STORE_TYPES.HEADER);
  });
};
