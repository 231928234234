import { lighten } from 'polished';
import { Title } from 'react-native-paper';
import styled from 'styled-components/native';

import { List } from '@ioupie/components/custom';
import { colors, fontsFamily, fontsWeight } from '@ioupie/shared/constants';
import { themefy } from '@ioupie/shared/utils';

export const ProductsDetailsContainer = styled.View`
  border-top-color: ${themefy({
    light: lighten(0.6, colors.light.secondary),
    dark: colors.dark.secondary,
  })};
  border-top-width: 3px;
  margin: 10px;
  padding: 10px;
  flex: 1;
  flex-grow: 1;
`;

export const ProductListTitle = styled(Title)`
  color: ${themefy({
    light: colors.light.text,
    dark: colors.dark.text,
  })};
  font-family: ${fontsFamily.bold};
  font-size: 16px;
  font-weight: ${fontsWeight.bold};
`;

export const ProductsSection = styled(List.Section)`
  flex: 1;
`;

export const ProductList = styled.View`
  display: flex;
  flex-flow: column;
  justify-content: space-between;
  margin: 0 0 10px;
`;

export const ProductEntry = styled.View`
  flex: 1;
  flex-flow: row wrap;
  justify-content: space-between;
  margin: 10px 0;
`;

export const JustificationContainer = styled.View`
  background-color: ${themefy({
    light: colors.light.background,
    dark: colors.dark.background,
  })};
  border-left-width: 5px;
  border-left-color: ${themefy({
    light: colors.light.tertiary,
    dark: colors.dark.tertiary,
  })};
  margin: 15px 0;
  padding: 15px;
`;

export const JustificationTitle = styled.Text`
  background-color: ${themefy({
    light: colors.light.background,
    dark: colors.dark.background,
  })};
  color: ${themefy({
    light: colors.light.tertiary,
    dark: colors.dark.tertiary,
  })};
  font-family: ${fontsFamily.bold};
  font-size: 16px;
  font-weight: ${fontsWeight.bold};
`;

export const Justification = styled.Text`
  background-color: ${themefy({
    light: colors.light.background,
    dark: colors.dark.background,
  })};
  color: ${themefy({
    light: colors.light.text,
    dark: colors.dark.text,
  })};
  font-family: ${fontsFamily.regular};
  font-size: 16px;
  font-weight: ${fontsWeight.regular};
  line-height: 24px;
`;

export const TotalPriceContainer = styled.View`
  margin: 15px 0;
`;

export const AmountContainer = styled.View`
  flex-direction: row;
  justify-content: space-between;
  margin: 5px 0;
`;

export const TotalPriceTitle = styled.Text`
  color: ${themefy({
    light: colors.light.text,
    dark: colors.dark.text,
  })};
  font-family: ${fontsFamily.regular};
  font-size: 20px;
  font-weight: ${fontsWeight.regular};
`;

export const TotalPriceItemDetails = styled.View`
  display: flex;
  flex-flow: column;
`;

export const TotalPriceItemName = styled.Text`
  color: ${themefy({
    light: colors.light.text,
    dark: colors.dark.text,
  })};
  font-family: ${fontsFamily.regular};
  font-size: 15px;
  font-weight: ${fontsWeight.regular};
`;

export const TotalPriceItemQuantity = styled.Text`
  color: ${themefy({
    light: colors.light.text3,
    dark: colors.dark.text3,
  })};
  font-family: ${fontsFamily.regular};
  font-weight: ${fontsWeight.regular};
  padding-top: 3px;
`;

export const TotalPriceBold = styled.Text`
  color: ${themefy({
    light: colors.light.text,
    dark: colors.dark.text,
  })};
  font-family: ${fontsFamily.bold};
  font-size: 15px;
  font-weight: ${fontsWeight.bold};
`;

export const Amount = styled.Text`
  color: ${themefy({
    light: colors.light.text3,
    dark: colors.dark.text3,
  })};
  font-family: ${fontsFamily.regular};
  font-size: 15px;
  font-weight: ${fontsWeight.regular};
`;
