import { yupResolver } from '@hookform/resolvers/yup';
import { observer } from 'mobx-react-lite';
import { Fragment, useEffect, useState } from 'react';
import { useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { Keyboard, View } from 'react-native';
import * as yup from 'yup';

import { BlockButton, IconButton } from '@ioupie/components/buttons';
import { ErrorSnackbar, Loader } from '@ioupie/components/custom';
import { TextInput } from '@ioupie/components/inputs';
import { HelperText, Text, Title } from '@ioupie/components/typography';
import { useAddressStore, useDeliveryStore, useNavigationStore, useShopsStore } from '@ioupie/hooks';
import { debounceOneSecond } from '@ioupie/shared/utils';

import { ZipCodeForm } from './zip-code-form.models';
import * as S from './zip-code-form.styles';

/**
 * @function ZipCodeFormContainer
 */
export default observer(() => {
  const [t] = useTranslation();
  const addressStore = useAddressStore();
  const navigationStore = useNavigationStore();
  const deliveryStore = useDeliveryStore();
  const shopsStore = useShopsStore();

  const zipCodeSchema: yup.SchemaOf<ZipCodeForm> = yup.object().shape({
    zipCode: yup
      .string()
      .required(t('forms.zip-code-form.zip-code.errors.required'))
      .length(8, ({ length }) => t('forms.zip-code-form.zip-code.errors.length', { length })),
    streetAddress: yup
      .string()
      .required(t('forms.zip-code-form.street-address.errors.required'))
      .min(1, ({ min }) => t('forms.zip-code-form.street-address.errors.min', { min })),
    houseNumber: yup
      .string()
      .required(t('forms.zip-code-form.house-number.errors.required'))
      .min(1, ({ min }) => t('forms.zip-code-form.house-number.errors.min', { min })),
    addressAddon: yup.string().notRequired(),
  });

  const {
    register,
    handleSubmit,
    setValue,
    getValues,
    watch,
    formState: { errors, isValid },
  } = useForm<ZipCodeForm>({
    defaultValues: {
      streetAddress: '',
      zipCode: '',
      houseNumber: '',
      addressAddon: '',
    },
    resolver: yupResolver(zipCodeSchema),
  });

  const [showConfirmationForm, setShowConfirmationForm] = useState(false);
  const [isLoadingDeliveryProviders, setIsLoadingDeliveryProviders] = useState(false);
  const [neighborhood, setNeighborhood] = useState('');
  const [city, setCity] = useState('');
  const [state, setState] = useState('');

  useEffect(() => {
    register('streetAddress');
    register('zipCode');
    register('houseNumber');
    register('addressAddon');
  }, [register]);

  useEffect(() => {
    const {
      streetAddress = '',
      addressAddon = '',
      neighborhood: zipNeighborhood = '',
      district: zipDistrict = '',
      state: zipState = '',
    } = addressStore.zipCodeInfo ?? {};
    setValue('streetAddress', streetAddress);
    setValue('addressAddon', addressAddon);

    // if invalid zip code, clear fields
    if (!addressStore.zipCodeInfo) {
      setValue('streetAddress', '');
      setValue('houseNumber', '');
      setValue('addressAddon', '');
      setNeighborhood('');
      setCity('');
      setState('');
    } else {
      setNeighborhood(zipNeighborhood);
      setCity(zipDistrict);
      setState(zipState);
    }
  }, [addressStore.zipCodeInfo]);

  useEffect(() => {
    if (addressStore.zipCodeInfo?.zipCode) {
      setValue('zipCode', addressStore.zipCodeInfo?.zipCode);
    }
  }, [addressStore.zipCodeInfo?.zipCode]);

  const onSubmitValidForm = async (form: ZipCodeForm) => {
    Keyboard.dismiss();

    if (!addressStore.zipCodeInfo) return;

    await addressStore.persistZipCodeInfo({
      ...addressStore.zipCodeInfo,
      ...form,
    });

    // Use case: coming from Basket screen
    if (shopsStore.selectedProviderId) {
      setIsLoadingDeliveryProviders(true);
      await deliveryStore.fetchAllDeliveryProviders();
      shopsStore.selectShopProvider(shopsStore.selectedProviderId);
      setIsLoadingDeliveryProviders(false);
      navigationStore.dispatchGoBack();
    }

    navigationStore.dispatchGoBack();

    setValue('zipCode', '');
    setValue('streetAddress', '');
    setValue('houseNumber', '');
    setValue('addressAddon', '');
    setNeighborhood('');
    setCity('');
    setState('');
    setShowConfirmationForm(false);
  };

  const onSearchZipCode = async () => {
    if (!errors.zipCode) {
      const { zipCode } = getValues();
      await addressStore.fetchZipCodeInfo(zipCode);
      if (addressStore.errors.length === 0) {
        setShowConfirmationForm(true);
      }
    }
  };

  const onUseLocationGPS = async () => {
    await addressStore.getAddressFromGPS();
    setShowConfirmationForm(true);
  };

  const RetrieveFromLocationOption = (
    <S.ZipCodeRetrievalOption>
      <S.ZipCodeRetrievalOptionText>
        <S.ZipCodeRetrievalOptionTitle>
          {t('containers.location.add-address.location.title')}
        </S.ZipCodeRetrievalOptionTitle>
        <Text>{t('containers.location.add-address.location.description')}</Text>
      </S.ZipCodeRetrievalOptionText>
      <S.ZipCodeButtonBack>
        <IconButton
          iconColor="black"
          disabled={addressStore.loading}
          size={40}
          icon="crosshairs-gps"
          onPress={onUseLocationGPS}
        />
      </S.ZipCodeButtonBack>
    </S.ZipCodeRetrievalOption>
  );

  const RetrieveFromZipCodeOption = (
    <S.ZipCodeRetrievalOption>
      <S.ZipCodeRetrievalOptionText>
        <S.ZipCodeRetrievalOptionTitle>
          {t('containers.location.add-address.zipCode.title')}
        </S.ZipCodeRetrievalOptionTitle>
        <S.ZipCodeInput
          maxLength={9}
          autoCorrect={false}
          autoCapitalize="none"
          autoComplete="postal-code"
          keyboardType="numeric"
          returnKeyType="done"
          error={Boolean(errors.zipCode)}
          label={t('forms.zip-code-form.zip-code.label')}
          placeholder={t('forms.zip-code-form.zip-code.placeholder')}
          onChangeText={debounceOneSecond((text: string) => {
            setValue('zipCode', text.replace('-', ''), { shouldValidate: true });
          })}
          onSubmitEditing={onSearchZipCode}
        />
      </S.ZipCodeRetrievalOptionText>
      <S.ZipCodeButtonBack>
        <IconButton
          iconColor="black"
          disabled={addressStore.loading || Boolean(errors.zipCode) || getValues().zipCode === ''}
          size={40}
          icon="home-search-outline"
          onPress={onSearchZipCode}
        />
      </S.ZipCodeButtonBack>
    </S.ZipCodeRetrievalOption>
  );

  const ZipCodeInput = (
    <Fragment>
      <S.ZipCodeContainer>
        <Title>{t('containers.location.add-address.title')}</Title>
        <S.ZipCodeContainerDescription>{t('containers.location.add-address.label')}</S.ZipCodeContainerDescription>
        {RetrieveFromLocationOption}
        <S.ZipCodeRetrievalOptionDivider>
          <S.ZipCodeRetrievalOptionDividerText>
            {t('containers.location.add-address.dividerText')}
          </S.ZipCodeRetrievalOptionDividerText>
        </S.ZipCodeRetrievalOptionDivider>
        {RetrieveFromZipCodeOption}
      </S.ZipCodeContainer>
      <HelperText type="error" visible={Boolean(errors.zipCode)}>
        {errors.zipCode?.message ?? ''}
      </HelperText>
      <Loader show={addressStore.loading} message={t('containers.location.add-address.loading')} />
    </Fragment>
  );

  const StreetNameInput = (
    <Fragment>
      <TextInput
        id="streetName"
        autoComplete="street-address"
        error={Boolean(errors.streetAddress)}
        label={t('forms.zip-code-form.street-address.label')}
        placeholder={t('forms.zip-code-form.street-address.placeholder')}
        value={getValues('streetAddress')}
        onChangeText={(text: string) => {
          setValue('streetAddress', text, { shouldValidate: true });
        }}
      />
      {Boolean(errors.streetAddress) && (
        <HelperText type="error" visible={Boolean(errors.streetAddress)}>
          {errors.streetAddress?.message ?? ''}
        </HelperText>
      )}
    </Fragment>
  );

  const StreetNumberInput = (
    <Fragment>
      <TextInput
        id="streetNumber"
        keyboardType="numeric"
        returnKeyType="done"
        error={Boolean(errors.houseNumber)}
        label={t('forms.zip-code-form.house-number.label')}
        placeholder={t('forms.zip-code-form.house-number.placeholder')}
        value={getValues('houseNumber')}
        onChangeText={(text: string) => {
          setValue('houseNumber', text, { shouldValidate: true });
        }}
      />
      {Boolean(errors.houseNumber) && (
        <HelperText type="error" visible={Boolean(errors.houseNumber)}>
          {errors.houseNumber?.message ?? ''}
        </HelperText>
      )}
    </Fragment>
  );

  const AddressAddon = (
    <View>
      <TextInput
        id="addOn"
        autoCorrect={false}
        autoCapitalize="none"
        error={Boolean(errors.addressAddon)}
        label={t('forms.zip-code-form.address-addon.label')}
        placeholder={t('forms.zip-code-form.address-addon.placeholder')}
        onChangeText={(text: string) => {
          setValue('addressAddon', text, { shouldValidate: true });
        }}
      />
    </View>
  );

  const ZipCodeFormAction = (
    <BlockButton
      disabled={!isValid || isLoadingDeliveryProviders}
      loading={addressStore.loading || isLoadingDeliveryProviders}
      text={t('forms.zip-code-form.submit')}
      onPress={handleSubmit(onSubmitValidForm)}
    />
  );

  const ZipCodeData = (
    <S.EditableZipCodeTextInput>
      <TextInput
        disabled
        id="zipCode"
        focusable={false}
        label={t('forms.zip-code-form.address-zipcode.label')}
        value={watch('zipCode')}
      />
      <IconButton size={32} icon="pencil" onPress={() => setShowConfirmationForm(false)} />
    </S.EditableZipCodeTextInput>
  );

  const NeighborhoodData = (
    <View>
      <TextInput
        disabled
        id="neighborhood"
        focusable={false}
        label={t('forms.zip-code-form.address-neighborhood.label')}
        value={neighborhood}
      />
      <S.CityAndStateContainer>
        <S.CityAndStateItem
          disabled
          id="city"
          focusable={false}
          label={t('forms.zip-code-form.address-city.label')}
          value={city}
        />
        <S.CityAndStateItem
          disabled
          id="state"
          focusable={false}
          label={t('forms.zip-code-form.address-state.label')}
          value={state}
        />
      </S.CityAndStateContainer>
    </View>
  );

  const ZipCodeFormContainer = (
    <S.ZipCodeContainer>
      <Title>{t('forms.zip-code-form.title')}</Title>
      {ZipCodeData}
      {StreetNameInput}
      {StreetNumberInput}
      {AddressAddon}
      {NeighborhoodData}
      {ZipCodeFormAction}
    </S.ZipCodeContainer>
  );

  return (
    <Fragment>
      <S.ScreenContainer>
        {!showConfirmationForm ? ZipCodeInput : <Fragment />}
        {addressStore.zipCodeInfo && showConfirmationForm ? ZipCodeFormContainer : <Fragment />}
      </S.ScreenContainer>
      <ErrorSnackbar errors={addressStore.errors} onDismiss={() => addressStore.clearErrors()} />
    </Fragment>
  );
});
