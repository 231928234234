import { observer } from 'mobx-react-lite';
import { Fragment, useCallback, useEffect } from 'react';
import { useTranslation } from 'react-i18next';

import { Drawer } from '@ioupie/components/custom';
import { useAuthStore, useNavigationStore, useSettingsStore, useShopsStore, useUserStore } from '@ioupie/hooks';
import { routes } from '@ioupie/shared/constants';
import { DrawerContentProps } from '@ioupie/shared/models';
import { safeArrayLookup } from '@ioupie/shared/utils';

import * as S from './menu-drawer.styles';

/**
 * @function MenuDrawerContainer
 */
export default observer(({ state }: DrawerContentProps) => {
  const [t] = useTranslation();
  const navigationStore = useNavigationStore();
  const currentRoute = safeArrayLookup(state.routeNames, state.index);

  const settingsStore = useSettingsStore();
  const authStore = useAuthStore();
  const shopsStore = useShopsStore();
  const userStore = useUserStore();

  const switchDarkMode = useCallback(async () => {
    const darkMode = !settingsStore.darkMode;
    settingsStore.setDarkMode(darkMode);
    await settingsStore.storeUserSettings();
  }, []);

  const switchLanguage = useCallback(async () => {
    const language = settingsStore.language === 'en' ? 'pt' : 'en';
    await settingsStore.changeLanguage(language);
    await settingsStore.storeUserSettings();
  }, []);

  useEffect(() => {
    if (authStore.haveAuthorizationData) {
      // eslint-disable-next-line no-void
      void userStore.fetchUserData();
    }
  }, [authStore.haveAuthorizationData]);

  useEffect(() => {
    if (!__DEV__) {
      // eslint-disable-next-line no-void
      void settingsStore.checkForAvailableUpdates();
    }
  }, []);

  return (
    <Fragment>
      <S.LogoContainer>
        <S.LogoBanner />
      </S.LogoContainer>
      <Drawer.Scroll contentContainerStyle={S.styles.scrollContent}>
        {settingsStore.updatesAvailable && (
          <Drawer.Section title={t('containers.menu-drawer.update.title')}>
            <Drawer.Item
              label={t('containers.menu-drawer.update.action')}
              icon="update"
              active={true}
              onPress={() => settingsStore.updateAndReloadApp()}
            />
          </Drawer.Section>
        )}
        <Drawer.Section title={t('containers.menu-drawer.orders.title')}>
          <Drawer.Item
            label={t('containers.menu-drawer.orders.delivery')}
            icon="truck-outline"
            active={currentRoute === routes.stacks.orders}
            onPress={() => {
              navigationStore.dispatchNavigation({
                stack: routes.stacks.orders,
                screen: routes.pages.orders.home,
                params: { screen: routes.tabs.home.delivery },
              });
              shopsStore.clearSelectedProducts();
            }}
          />
          <Drawer.Item
            label={t('containers.menu-drawer.orders.lockers')}
            icon="locker"
            onPress={() => {
              navigationStore.dispatchNavigation({
                stack: routes.stacks.orders,
                screen: routes.pages.orders.home,
                params: { screen: routes.tabs.home.lockers },
              });
              shopsStore.clearSelectedProducts();
            }}
          />
        </Drawer.Section>
        <Drawer.Section title={t('containers.menu-drawer.help.title')}>
          <Drawer.Item
            label={t('containers.menu-drawer.help.contact')}
            icon="whatsapp"
            active={currentRoute === routes.stacks.help}
            onPress={() => navigationStore.dispatchNavigation({ stack: routes.stacks.help })}
          />
        </Drawer.Section>
        <Drawer.Section title={t('containers.menu-drawer.configurations.title')}>
          {authStore.haveAuthorizationData && (
            <Drawer.Item
              label={t('containers.menu-drawer.orders.orders')}
              icon="file-multiple-outline"
              onPress={() => {
                navigationStore.dispatchNavigation({
                  stack: routes.stacks.orders,
                  screen: routes.pages.orders.home,
                  params: { screen: routes.tabs.home.my_orders },
                });
                shopsStore.clearSelectedProducts();
              }}
            />
          )}
          <Drawer.Item
            label={t('containers.menu-drawer.configurations.address')}
            icon="map-marker-radius-outline"
            active={currentRoute === routes.stacks.address}
            onPress={() => navigationStore.dispatchNavigation({ stack: routes.stacks.address })}
          />
          {authStore.haveAuthorizationData && (
            <Fragment>
              <Drawer.Item
                label={t('containers.menu-drawer.configurations.my-profile')}
                icon="account-edit-outline"
                active={currentRoute === routes.stacks.profile}
                onPress={() => navigationStore.dispatchNavigation({ stack: routes.stacks.profile })}
              />
              <Drawer.Item
                label={t('containers.menu-drawer.configurations.cards')}
                icon="credit-card-outline"
                active={currentRoute === routes.stacks.payment}
                onPress={() => navigationStore.dispatchNavigation({ stack: routes.stacks.payment })}
              />
            </Fragment>
          )}
          <Drawer.Item
            label={t('containers.menu-drawer.configurations.settings')}
            icon="cog-outline"
            active={currentRoute === routes.stacks.settings}
            onPress={() => navigationStore.dispatchNavigation({ stack: routes.stacks.settings })}
          />
          {authStore.haveAuthorizationData ? (
            <Drawer.Item
              label={t('containers.menu-drawer.configurations.logout')}
              icon="logout-variant"
              active={currentRoute === routes.stacks.auth}
              onPress={() => navigationStore.dispatchNavigation({ stack: routes.stacks.auth })}
            />
          ) : (
            <Drawer.Item
              label={t('containers.menu-drawer.configurations.login')}
              icon="login-variant"
              active={currentRoute === routes.stacks.auth}
              onPress={() => navigationStore.dispatchNavigation({ stack: routes.stacks.auth })}
            />
          )}
        </Drawer.Section>
        {__DEV__ && (
          <Drawer.Section title="Dev">
            <Drawer.Item label="Toggle dark mode" icon="theme-light-dark" onPress={switchDarkMode} />
            <Drawer.Item label="Toggle language" icon="translate" onPress={switchLanguage} />
          </Drawer.Section>
        )}
      </Drawer.Scroll>
    </Fragment>
  );
});
