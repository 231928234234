import { memo } from 'react';

import { MenuButtonProps } from './menu-button.models';
import * as S from './menu-button.styles';

/**
 * @function MenuButtonComponent
 */
export default memo((props: MenuButtonProps) => {
  return <S.HeaderAction {...props} icon="menu" />;
});
