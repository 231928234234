export enum CHIP_COLOR {
  RED = 'RED',
  YELLOW = 'YELLOW',
  GREEN = 'GREEN',
  GREY = 'GREY',
}

export const CONTAINER_COLOR: Record<CHIP_COLOR, string> = {
  [CHIP_COLOR.RED]: '#ffe6e4',
  [CHIP_COLOR.GREEN]: '#d8f2d7',
  [CHIP_COLOR.YELLOW]: '#fff1b3',
  [CHIP_COLOR.GREY]: '#eee',
} as const;

export const TEXT_COLOR: Record<CHIP_COLOR, string> = {
  [CHIP_COLOR.RED]: '	#e51012',
  [CHIP_COLOR.GREEN]: '#5fb205',
  [CHIP_COLOR.YELLOW]: '#ae8c34',
  [CHIP_COLOR.GREY]: '#000',
} as const;
