export enum LOCKER_STATUS {
  AVAILABLE = 'AVAILABLE',
  INTERNAL_TESTING = 'INTERNAL_TESTING',
  COMING_SOON = 'COMING_SOON',
  HEARTBEAT_UNAVAILABLE = 'HEARTBEAT_UNAVAILABLE',
  MAINTENANCE = 'MAINTENANCE',
  PERMANENTLY_SHUTDOWN = 'PERMANENTLY_SHUTDOWN',
}

export enum COMPARTMENT_TYPE {
  DOOR = 'DOOR',
  SHOWCASE = 'SHOWCASE',
}

export const MAXIMUM_ACCEPTABLE_DISTANCE_FROM_LOCKER_IN_METERS = 20;
