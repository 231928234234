import { observer } from 'mobx-react-lite';
import { Fragment, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Keyboard, Platform, TouchableWithoutFeedback } from 'react-native';

import { BlockButton } from '@ioupie/components/buttons';
import { ErrorSnackbar } from '@ioupie/components/custom';
import { ScrollView } from '@ioupie/components/layout';
import { useHeaderStore, useLockersStore, useNavigationStore, useOrdersStore } from '@ioupie/hooks';
import { routes, THANK_TYPE } from '@ioupie/shared/constants';

import { Compartment } from './compartment';
import * as S from './unlock-locker.styles';
import { LaundryDetails } from '../laundry-details';
import { ProductsDetails } from '../products-details';
import { ScheduleDetails } from '../schedule-details';

/**
 * @function UnlockLockerContainer
 */
export default observer(() => {
  const [t] = useTranslation();
  const headerStore = useHeaderStore();
  const ordersStore = useOrdersStore();
  const lockersStore = useLockersStore();
  const navigationStore = useNavigationStore();

  const { selectedOrderHistory, currentCompartment, loading } = ordersStore;
  const { inboundDetails } = selectedOrderHistory ?? {};
  const { lockerId = '', compartment } = inboundDetails ?? {};
  const { identifier: historyIdentifier } = compartment ?? {};
  const { identifier: currentIdentifier } = currentCompartment ?? {};

  const [laundryCaseNumber, setLaundryCaseNumber] = useState('');
  const [disableSubmit, setDisableSubmit] = useState(true);
  const [isTyping, setIsTyping] = useState(false);

  // @todo FIXME quick fix :)
  headerStore.onClickBack(() => {
    ordersStore.clearCurrentCompartment();
    navigationStore.dispatchGoBack();
  });

  useEffect(() => {
    // eslint-disable-next-line no-void
    void lockersStore.selectLockerProvider(lockerId);
  }, [lockerId]);

  const handleOnChangeText = (text: string): void => {
    const caseNumber = text.replace(/\D/g, '');
    setLaundryCaseNumber(caseNumber);
    setDisableSubmit(caseNumber.length < 3);
  };

  const handleSubmit = (): void => {
    // eslint-disable-next-line no-void
    void ordersStore.activateTicket(laundryCaseNumber);
    ordersStore.changeThankType(THANK_TYPE.TICKET_ACTIVATED);
    navigationStore.dispatchNavigation({
      stack: routes.stacks.orders,
      screen: routes.pages.orders.thank,
    });
  };

  const LaundryCaseNumberSection = (
    <Fragment>
      <S.LockerTextView>
        <S.LockerText>{t('containers.orders.track-order.unlock-locker.insert-number')}</S.LockerText>
      </S.LockerTextView>
      <S.LaundryCaseNumberInput
        value={`${laundryCaseNumber}`}
        onChangeText={handleOnChangeText}
        onFocus={(): void => setIsTyping(true)}
        onBlur={(): void => setIsTyping(false)}
        keyboardType="number-pad"
        maxLength={3}
        placeholder="000"
      />

      {disableSubmit ? (
        <S.LaundryCaseNumberInfo>
          {t('containers.orders.track-order.unlock-locker.inform-track')}
        </S.LaundryCaseNumberInfo>
      ) : (
        <S.LaundryCaseNumberInfo>{t('containers.orders.track-order.unlock-locker.lock-bag')}</S.LaundryCaseNumberInfo>
      )}
    </Fragment>
  );

  const compartmentNumber = historyIdentifier ?? currentIdentifier ?? 0;

  return (
    <ScrollView>
      <TouchableWithoutFeedback onPress={Keyboard.dismiss}>
        <S.AvoidView behavior={Platform.OS === 'ios' ? 'padding' : 'height'}>
          <S.UnlockLockerContainer>
            {!isTyping && <Compartment />}
            {compartmentNumber !== 0 && (
              <Fragment>
                {LaundryCaseNumberSection}
                <BlockButton
                  text={t('containers.orders.track-order.unlock-locker.submit')}
                  disabled={disableSubmit}
                  loading={loading}
                  onPress={handleSubmit}
                />
              </Fragment>
            )}

            {!isTyping ? (
              <Fragment>
                <LaundryDetails />
                <ProductsDetails />
                <ScheduleDetails />
              </Fragment>
            ) : undefined}
          </S.UnlockLockerContainer>
        </S.AvoidView>
      </TouchableWithoutFeedback>
      <ErrorSnackbar errors={ordersStore.errors} onDismiss={() => ordersStore.clearErrors()} />
    </ScrollView>
  );
});
