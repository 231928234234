import { useLocalObservable } from 'mobx-react-lite';

import { container } from '@ioupie/injection';
import { STORE_TYPES } from '@ioupie/shared/constants';
import { ShopsStore } from '@ioupie/stores';

export const useShopsStore = (): ShopsStore => {
  return useLocalObservable<ShopsStore>(() => {
    return container.get(STORE_TYPES.SHOPS);
  });
};
