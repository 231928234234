import { observer } from 'mobx-react-lite';
import { Fragment, ReactElement } from 'react';

import { useShopsStore } from '@ioupie/hooks';
import { PRODUCT_METADATA_TYPES, SHOP_STEPS } from '@ioupie/shared/constants';
import { ProductMetadata } from '@ioupie/shared/models';
import { safeObjectLookup } from '@ioupie/shared/utils';

import { CarpetCustom } from './carpet';
import { CurtainCustom } from './curtain';

// JS functions are hoisted
export default observer(CustomStep);

function CustomStep() {
  const shopsStore = useShopsStore();

  const onProductSizeChosen = (metadata: ProductMetadata): void => {
    shopsStore.addCustomProduct(metadata);
    shopsStore.changeStep(SHOP_STEPS.PRODUCTS);
  };

  // sanity check - if no product selected for customize then just bypass
  if (!shopsStore.customizingProduct) {
    return <Fragment />;
  }

  const metadataFragments: Record<PRODUCT_METADATA_TYPES, ReactElement> = {
    [PRODUCT_METADATA_TYPES.CARPET]: (
      <CarpetCustom customizingCarpet={shopsStore.customizingProduct} onSendCarpetSize={onProductSizeChosen} />
    ),
    [PRODUCT_METADATA_TYPES.CURTAIN]: (
      <CurtainCustom customizingCurtain={shopsStore.customizingProduct} onSendCurtainSize={onProductSizeChosen} />
    ),
  };

  return (
    <Fragment>
      {safeObjectLookup(metadataFragments, shopsStore.customizingProduct.customizable) ?? <Fragment />}
    </Fragment>
  );
}
