import { memo } from 'react';

import { IoupieLogoProps } from './ioupie-logo.models';
import LogoImage from './ioupie-logo.png';
import * as S from './ioupie-logo.styles';

/**
 * @function IoupieLogoComponent
 */
export default memo((props: IoupieLogoProps) => {
  return <S.Logo {...props} source={LogoImage} resizeMode="contain" />;
});
