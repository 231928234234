import { observer } from 'mobx-react-lite';
import { Fragment, useCallback, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { FlatList, ListRenderItemInfo, ScrollViewProps } from 'react-native';

import { ErrorSnackbar, Loader, SegmentBox } from '@ioupie/components/custom';
import { useAddressStore, useLockersStore, useNavigationStore, useSegmentsStore, useShopsStore } from '@ioupie/hooks';
import { BUSINESS_AREA_OPTIONS, routes, BUSINESS_SEGMENT_TYPE_TRANSLATIONS } from '@ioupie/shared/constants';
import { BusinessProvider, BusinessSegment } from '@ioupie/shared/models';

import * as S from './business-segments.styles';

/**
 * @function BusinessSegmentsContainer
 */
export default observer(() => {
  const [t] = useTranslation();

  const addressStore = useAddressStore();
  const shopsStore = useShopsStore();
  const segmentsStore = useSegmentsStore();
  const lockersStore = useLockersStore();
  const navigationStore = useNavigationStore();

  useEffect(() => {
    if (addressStore.addressLocationAsText === '') {
      return;
    }
    // eslint-disable-next-line no-void
    void segmentsStore.fetchSegments({
      userProvidedAddress: addressStore.userProvidedAddress,
      currentLocation: addressStore.currentLocation,
      filters: { segments: [] },
    });
  }, [addressStore.addressLocationAsText]);

  const selectTypeAndNavigate = useCallback((provider: BusinessProvider) => {
    shopsStore.setShopBusinessArea(provider.area);
    shopsStore.setShopSegment(provider.segment);

    // still have to choose where to buy from
    if (provider.area === BUSINESS_AREA_OPTIONS.LOCKER) {
      lockersStore.selectLockerProvider(provider.id);
      navigationStore.dispatchNavigation({
        stack: routes.stacks.orders,
        screen: routes.pages.orders.providers,
      });
    } else {
      shopsStore.selectShopProvider(provider.id);
      navigationStore.dispatchNavigation({
        stack: routes.stacks.orders,
        screen: routes.pages.orders.catalog,
      });
    }
  }, []);

  const segmentsKeyExtractor = useCallback((provider: BusinessProvider) => provider.id, []);

  const renderSegmentsScroll = useCallback(
    (props: ScrollViewProps) => (
      // eslint-disable-next-line react/jsx-props-no-spreading
      <S.SegmentsSlider {...props} />
    ),
    [],
  );

  const renderSegmentListItem = useCallback((info: ListRenderItemInfo<BusinessProvider>) => {
    const { item: segment } = info;
    return <SegmentBox name={segment.name} image={segment.image} onPress={() => selectTypeAndNavigate(segment)} />;
  }, []);

  const renderCarousel = useCallback(
    (segment: BusinessSegment) => (
      <Fragment key={segment.type}>
        <S.ViewLabel>
          <S.LabelTitle>{t(BUSINESS_SEGMENT_TYPE_TRANSLATIONS[segment.type])}</S.LabelTitle>
        </S.ViewLabel>
        <FlatList<BusinessProvider>
          horizontal
          data={segment.providers}
          keyExtractor={segmentsKeyExtractor}
          renderItem={renderSegmentListItem}
          renderScrollComponent={renderSegmentsScroll}
        />
      </Fragment>
    ),
    [],
  );

  const SegmentsDisplay = Object.values(segmentsStore.businessSegmentMap)
    .filter((segment) => segment.providers.length > 0)
    .map(renderCarousel);

  const NoContentMessage = segmentsStore.loading ? (
    <Fragment />
  ) : addressStore.userProvidedAddress === '' ? (
    <Fragment>
      <S.LabelTitle>{t('containers.catalog.business-segments.no-address.title')}</S.LabelTitle>
      <S.NoDataText>{t('containers.catalog.business-segments.no-address.description')}</S.NoDataText>
    </Fragment>
  ) : (
    <Fragment>
      <S.NoDataTitle>{t('containers.catalog.business-segments.no-content.title')}</S.NoDataTitle>
      <S.NoDataText>{t('containers.catalog.business-segments.no-content.description')}</S.NoDataText>
    </Fragment>
  );

  return (
    <Fragment>
      <S.CarouselContainer>
        {segmentsStore.hasProviders ? SegmentsDisplay : NoContentMessage}
        <Loader show={segmentsStore.loading} message={t('containers.catalog.business-segments.loading')} />
      </S.CarouselContainer>
      <ErrorSnackbar errors={segmentsStore.errors} onDismiss={() => segmentsStore.clearErrors()} />
    </Fragment>
  );
});
