import { IOUPIE_HEADERS } from '@ioupie/models-commons';
import axios, { AxiosAdapter } from 'axios';
import { cacheAdapterEnhancer, retryAdapterEnhancer, throttleAdapterEnhancer } from 'axios-extensions';
import Constants from 'expo-constants';
import { compose } from 'lodash/fp';
import { Platform } from 'react-native';
import DeviceInfo from 'react-native-device-info';
import UserAgent from 'react-native-user-agent';

import { RequestHeaders } from '@ioupie/shared/models';

// not supported on web mode
const userAgentHeader = Platform.OS === 'web' ? undefined : UserAgent.getUserAgent();

/**
 * The headers that will be included by default,
 * in any http client generated.
 */
export const defaultHeaders: RequestHeaders = {
  ...axios.defaults.headers.common,
  Accept: 'application/json',
  'Accept-Encoding': 'gzip, deflate, br',
  'Content-Type': 'application/json',
  'X-Requested-With': 'XMLHttpRequest',
  'Cache-Control': 'no-cache',
  Pragma: 'no-cache',
  Expires: '0',
  'User-Agent': userAgentHeader,
  [IOUPIE_HEADERS.APP_VERSION]: Constants.expoConfig?.version,
  [IOUPIE_HEADERS.APP_SESSION]: Constants.sessionId,
  [IOUPIE_HEADERS.APP_ID]: DeviceInfo.getUniqueIdSync(),
  [IOUPIE_HEADERS.APP_OS]: Platform.OS,
} as const;

export const enhancedAdapter = compose(
  (adapter: AxiosAdapter) => throttleAdapterEnhancer(adapter, { threshold: 0 }),
  (adapter: AxiosAdapter) => cacheAdapterEnhancer(adapter, { enabledByDefault: false }),
  (adapter: AxiosAdapter) => retryAdapterEnhancer(adapter, { times: 0 }),
  axios.getAdapter,
)(axios.defaults.adapter);

export const restClient = axios.create({
  headers: defaultHeaders,
  adapter: enhancedAdapter,
  withCredentials: false,
});
