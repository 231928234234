import { getDistance } from 'geolib';
import { inject, injectable, postConstruct } from 'inversify';
import { action, computed, makeObservable, observable, runInAction } from 'mobx';
import { isPresent } from 'ts-is-present';
import { v4 as uuidV4 } from 'uuid';

import {
  ErrorService,
  LocationService,
  RestService,
  type AnalyticsService,
  type StorageService,
} from '@ioupie/services';
import {
  AnalyticsEvents,
  MAXIMUM_ACCEPTABLE_DISTANCE_FROM_LOCKER_IN_METERS,
  QUERY_PARAMS,
  SERVICE_TYPES,
  STORAGE_KEYS,
  STORE_TYPES,
  endpoints,
} from '@ioupie/shared/constants';
import type {
  ErrorMessages,
  Locker,
  Optional,
  PersistedZipCodeInfo,
  PortariaZipFetch,
  UrlEndpoint,
  UserAddressData,
  UserPosition,
  UserZipCodeInfo,
  ZipCodeInfo,
} from '@ioupie/shared/models';
import type { CEPNotFound, ZipCodeInfoResponsePayload } from '@ioupie/shared/payloads';
import { isObject, safeObjectLookup } from '@ioupie/shared/utils';
import { AuthStore } from './auth.store';

@injectable()
export class AddressStore {
  private static readonly DEFAULT_COUNTRY = 'BR';

  @inject(SERVICE_TYPES.STORAGE.ASYNC_STORAGE)
  private readonly storageService: StorageService;
  @inject(SERVICE_TYPES.LOCATION)
  private readonly locationService: LocationService;
  @inject(SERVICE_TYPES.REST)
  private readonly restService: RestService;
  @inject(SERVICE_TYPES.ERROR)
  private readonly errorService: ErrorService;
  @inject(SERVICE_TYPES.ANALYTICS.COMPOSITE)
  private readonly analyticsService: AnalyticsService;
  @inject(STORE_TYPES.AUTH)
  private readonly authStore: AuthStore;

  @observable public userAddressData: UserAddressData = {
    selectedAddress: '',
    addressesMap: {},
  };

  @observable public addressCherryPick?: PersistedZipCodeInfo;
  @observable public zipCodeInfo?: ZipCodeInfo;

  @observable public isDeleteDialogOpen: boolean = false;
  @observable public loadingUserAddress: boolean = true;

  @observable public loading: boolean = false;
  @observable public errors: ErrorMessages = [];

  @postConstruct()
  public init(): void {
    makeObservable(this);
  }

  @action
  public openDeleteDialog(info: PersistedZipCodeInfo): void {
    this.addressCherryPick = info;
    this.isDeleteDialogOpen = true;
  }

  @action
  public closeDeleteDialog(): void {
    this.addressCherryPick = undefined;
    this.isDeleteDialogOpen = false;
  }

  @action
  public clearErrors(): void {
    this.errors = [];
  }

  @action
  public clearZipCodeInfo(): void {
    this.zipCodeInfo = undefined;
  }

  @action
  public pushExternalErrors(errors: unknown): void {
    this.errors = this.errorService.wrapApiError(errors);
  }

  @action.bound
  public checkZipCodeNotFound(response: ZipCodeInfoResponsePayload): response is CEPNotFound {
    const casted = response as CEPNotFound;
    // eslint-disable-next-line no-null/no-null
    return casted.erro !== undefined && casted.erro !== null;
  }

  @action
  public async getAddressFromGPS(): Promise<void> {
    this.loading = true;

    try {
      const currentPosition = await this.locationService.getUserCurrentPosition();

      const { latitude = 0, longitude = 0 } = currentPosition ?? {};

      this.analyticsService.trackEvent(AnalyticsEvents.ADDRESS_FETCH_GPS, {
        latitude: latitude.toString(),
        longitude: longitude.toString(),
      });

      const urlEndpoint: UrlEndpoint = {
        url: endpoints.portaria.address.geocode,
        queryParams: {
          [QUERY_PARAMS.ADDRESS_POSITION]: {
            lat: latitude,
            lng: longitude,
            country: AddressStore.DEFAULT_COUNTRY,
            username: this.authStore.username,
          },
        },
      };

      const response = await this.restService.get<PortariaZipFetch>(urlEndpoint);

      const firstAddress = response.addresses[0] ?? {};
      const zipCode = firstAddress.postalCode.replace(/\D/gi, '');

      runInAction(() => {
        this.zipCodeInfo = {
          zipCode,
          streetAddress: firstAddress.street,
          neighborhood: firstAddress.neighborhood,
          district: firstAddress.city,
          state: firstAddress.state,
          addressAddon: '',
        };
        this.loading = false;
      });

      this.analyticsService.trackEvent(AnalyticsEvents.ADDRESS_FETCH_ZIPCODE, { zipCode });
    } catch (error) {
      runInAction(() => {
        this.errors = this.errorService.wrapApiError(error);
        this.zipCodeInfo = undefined;
        this.loading = false;
      });
    }
  }

  @action
  public async fetchZipCodeInfo(zipCode: string): Promise<void> {
    this.loading = true;

    try {
      const urlEndpoint: UrlEndpoint = {
        url: endpoints.portaria.address.geocode,
        queryParams: {
          [QUERY_PARAMS.ADDRESS_POSITION]: {
            zipCode,
            country: AddressStore.DEFAULT_COUNTRY,
            username: this.authStore.username,
          },
        },
      };

      const response = await this.restService.get<PortariaZipFetch>(urlEndpoint);

      const firstAddress = response.addresses[0] ?? {};

      runInAction(() => {
        this.zipCodeInfo = {
          zipCode: firstAddress.postalCode.replace('-', '').trim(),
          streetAddress: firstAddress.street,
          neighborhood: firstAddress.neighborhood,
          district: firstAddress.city,
          state: firstAddress.state,
          addressAddon: '',
        };
        this.loading = false;
      });

      this.analyticsService.trackEvent(AnalyticsEvents.ADDRESS_FETCH_ZIPCODE, { zipCode: firstAddress.postalCode });
    } catch (error) {
      runInAction(() => {
        this.errors = this.errorService.wrapApiError(error);
        this.zipCodeInfo = undefined;
        this.loading = false;
      });
    }
  }

  @action
  public async selectMainAddress(address: PersistedZipCodeInfo): Promise<void> {
    // nothing to do here
    if (this.userAddressData.selectedAddress === address.id) {
      return;
    }

    this.loading = true;
    try {
      const newAddressData: UserAddressData = {
        selectedAddress: address.id,
        addressesMap: this.userAddressData.addressesMap,
      };

      await this.storageService.persistData<UserAddressData>(STORAGE_KEYS.ADDRESS, newAddressData);

      runInAction(() => {
        this.userAddressData = newAddressData;
        this.loading = false;
      });

      this.analyticsService.trackEvent(AnalyticsEvents.ADDRESS_SELECT_MAIN_ADDRESS, { zipCode: address.zipCode });
    } catch (error) {
      runInAction(() => {
        this.errors = this.errorService.wrapApiError(error);
        this.loading = false;
      });
    }
  }

  @action
  public async restoreSavedZipCodeInfo(): Promise<void> {
    this.loading = true;
    this.loadingUserAddress = true;
    try {
      const previousAddressData = await this.storageService.retrieveData<UserAddressData>(STORAGE_KEYS.ADDRESS);

      // retro compatibility (previously the address was of another type)
      const addressData =
        previousAddressData && isObject(previousAddressData) && !Array.isArray(previousAddressData)
          ? previousAddressData
          : { selectedAddress: '', addressesMap: {} };

      const { selectedAddress = '', addressesMap = {} } = addressData ?? {};
      const currentAddress = safeObjectLookup(addressesMap, selectedAddress);

      if (currentAddress) {
        this.analyticsService.trackEvent(AnalyticsEvents.ADDRESS_SELECT_MAIN_ADDRESS, {
          zipCode: currentAddress.zipCode,
        });
      }

      runInAction(() => {
        this.userAddressData = addressData;
        this.loading = false;
        this.loadingUserAddress = false;
      });
    } catch (error) {
      runInAction(() => {
        this.errors = this.errorService.wrapApiError(error);
        this.loading = false;
        this.loadingUserAddress = false;
      });
    }
  }

  @action
  public async persistZipCodeInfo(userZipInfo: UserZipCodeInfo): Promise<void> {
    this.loading = true;
    try {
      const newZipInfo: PersistedZipCodeInfo = {
        ...userZipInfo,
        id: uuidV4(),
      };

      const newAddressData: UserAddressData = {
        selectedAddress: newZipInfo.id,
        addressesMap: {
          ...this.userAddressData.addressesMap,
          [newZipInfo.id]: newZipInfo,
        },
      };

      await this.storageService.persistData<UserAddressData>(STORAGE_KEYS.ADDRESS, newAddressData);

      runInAction(() => {
        this.userAddressData = newAddressData;
        this.loading = false;
      });
    } catch (error) {
      runInAction(() => {
        this.errors = this.errorService.wrapApiError(error);
        this.zipCodeInfo = undefined;
        this.loading = false;
      });
    }
  }

  @action
  public async removeZipCodeInfo(zipCodeId: string): Promise<void> {
    this.loading = true;
    try {
      const newAddressesMap = {
        ...this.userAddressData.addressesMap,
        [zipCodeId]: undefined,
      };

      const newSelectedAddress =
        this.userAddressData.selectedAddress === zipCodeId ? '' : this.userAddressData.selectedAddress;

      const newAddressData: UserAddressData = {
        selectedAddress: newSelectedAddress,
        addressesMap: newAddressesMap,
      };

      await this.storageService.persistData<UserAddressData>(STORAGE_KEYS.ADDRESS, newAddressData);

      runInAction(() => {
        this.userAddressData = newAddressData;
        this.loading = false;
      });
    } catch (error) {
      runInAction(() => {
        this.errors = this.errorService.wrapApiError(error);
        this.zipCodeInfo = undefined;
        this.loading = false;
      });
    }
  }

  @action
  public async isNextToLocker(locker?: Locker): Promise<boolean> {
    this.loading = true;

    try {
      const coords = await this.locationService.getUserCurrentPosition();

      const userLocation: UserPosition = { latitude: coords?.latitude || 0, longitude: coords?.longitude || 0 };
      const lockerPosition: UserPosition = { latitude: locker?.latitude ?? 0, longitude: locker?.longitude ?? 0 };
      const lockerMaxDistance = locker?.dist ?? MAXIMUM_ACCEPTABLE_DISTANCE_FROM_LOCKER_IN_METERS;

      runInAction(() => {
        this.loading = false;
      });

      const dist = getDistance(userLocation, lockerPosition) < lockerMaxDistance;

      return dist;
    } catch (error) {
      runInAction(() => {
        this.errors = this.errorService.wrapApiError(error);
        this.loading = false;
      });

      return false;
    }
  }

  @computed
  public get allAddresses(): readonly PersistedZipCodeInfo[] {
    const { addressesMap = {} } = this.userAddressData ?? {};
    return Object.values(addressesMap).filter(isPresent);
  }

  @computed
  public get selectedAddressInfo(): Optional<PersistedZipCodeInfo> {
    const { selectedAddress = '', addressesMap = {} } = this.userAddressData ?? {};
    return safeObjectLookup(addressesMap, selectedAddress);
  }

  @computed
  public get addressLocationAsText(): string {
    const currentAddress = this.selectedAddressInfo;

    if (!currentAddress) {
      return '';
    }

    const { streetAddress = '', houseNumber = '', district = '', state = '' } = currentAddress;

    return `${streetAddress}, ${houseNumber} - ${district}/${state}`;
  }

  @computed
  public get addressStreetAndNumberAsText(): string {
    const currentAddress = this.selectedAddressInfo;

    if (!currentAddress) {
      return '';
    }

    const { streetAddress = '', houseNumber = '' } = currentAddress;

    return `${streetAddress}, ${houseNumber}`;
  }
}
