import styled from 'styled-components/native';

import { colors } from '@ioupie/shared/constants';
import { themefy } from '@ioupie/shared/utils';

declare type Elevated = {
  readonly elevated?: boolean;
};

export default styled.SafeAreaView<Elevated>`
  background-color: ${themefy({
    light: colors.light.background,
    dark: colors.dark.background,
  })};
  border-top-color: ${themefy({
    light: colors.light.primary,
    dark: colors.dark.primary,
  })};
  border-top-width: 1px;
  elevation: ${({ elevated }) => {
    return elevated ? 10 : 0;
  }};
  flex: 1;
  flex-grow: 1;
  height: auto;
  min-height: auto;
  padding: 16px;
`;
