import { observer } from 'mobx-react-lite';
import { Children, Fragment, useEffect } from 'react';

import { useSettingsStore } from '@ioupie/hooks';

import { TranslationProps } from './translation.props';

/**
 * @function TranslationProvider
 */
export default observer(({ children }: TranslationProps) => {
  const settingsStore = useSettingsStore();

  useEffect(() => {
    if (!settingsStore.settingsLoaded) {
      // eslint-disable-next-line no-void
      void settingsStore.bootstrapSettings();
    }
  }, [settingsStore.settingsLoaded]);

  if (settingsStore.settingsLoaded) {
    return <Fragment>{Children.toArray(children)}</Fragment>;
  } else {
    return <Fragment />;
  }
});
