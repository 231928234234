import { observer } from 'mobx-react-lite';
import { Fragment, useEffect } from 'react';
import { useTranslation } from 'react-i18next';

import { RadioButton } from '@ioupie/components/custom';
import { useAuthStore, useOrdersStore, useTheme } from '@ioupie/hooks';
import { colors } from '@ioupie/shared/constants';

import * as S from './installments.styles';

/**
 * @function InstallmentContainer
 */
export default observer(() => {
  const [t] = useTranslation();
  const theme = useTheme();

  const ordersStore = useOrdersStore();
  const authStore = useAuthStore();

  useEffect(() => {
    if (authStore.haveAuthorizationData) {
      // eslint-disable-next-line no-void
      void ordersStore.fetchInstallmentOptions();
    }

    // return () => ordersStore.clearInstallmentsData();
  }, [ordersStore.orderPrice, authStore.haveAuthorizationData]);

  if (!ordersStore.installmentsAvailable) {
    return <Fragment />;
  }

  return (
    <S.InstallmentContainer>
      <S.InstallmentHeader onPress={() => ordersStore.toggleShowInstallmentOptions()}>
        <S.InstallmentTitle>{t('containers.orders.ordering.amount.installments.title')}</S.InstallmentTitle>
        <S.ShowInstallmentsIcon
          color={theme.mode === 'dark' ? colors.dark.text : colors.light.text}
          size={20}
          name={ordersStore.showInstallmentOptions ? 'chevron-up' : 'chevron-down'}
        />
      </S.InstallmentHeader>
      {ordersStore.showInstallmentOptions && (
        <RadioButton.Group
          onValueChange={(installments) => ordersStore.setInstallments(installments)}
          value={ordersStore.installments}>
          {Object.values(ordersStore.installmentOfferings).map((offer) => {
            return (
              <RadioButton.Item
                key={offer.installments}
                mode="android"
                value={offer.installments.toString()}
                label={t('containers.orders.ordering.amount.installments.option', {
                  number: offer.installments,
                  price: offer.installmentAmount.amount.toFixed(2),
                })}
              />
            );
          })}
        </RadioButton.Group>
      )}
    </S.InstallmentContainer>
  );
});
