import styled from 'styled-components/native';

import { Text } from '@ioupie/components/typography';

export const ThankStepContainer = styled.View`
  background-color: rgba(255, 255, 255, 0.85);
  flex: 1;
`;

export const Background = styled.ImageBackground`
  flex: 1;
`;

export const ThankMessage = styled.View`
  align-items: center;
  flex-direction: column;
  height: 85%;
  justify-content: center;
  min-height: 85%;
  margin: 10px;
`;

export const TitleMessage = styled(Text)`
  font-size: 24px;
  font-weight: bold;
  margin-bottom: 32px;
`;

export const TextMessage = styled(Text)`
  font-size: 20px;
  margin: 0 40px 20px;
  text-align: center;
`;
