'use strict';

// import 'expo/build/Expo.fx';

// import { registerRootComponent } from 'expo';
// import { activateKeepAwake } from 'expo-keep-awake';
// import withExpoRoot from 'expo/build/launch/withExpoRoot';
// import { createRoot } from 'react-dom/client';
// import { Platform } from 'react-native';

// import App from './App';

// if (__DEV__) {
//   activateKeepAwake();
// }

// if (Platform.OS === 'web') {
//   const rootTag = createRoot(document.getElementById('root') ?? document.getElementById('main'));

//   const RootComponent = withExpoRoot(App);
//   // eslint-disable-next-line react/jsx-filename-extension
//   rootTag.render(<RootComponent />);
// } else {
//   // registerRootComponent calls AppRegistry.registerComponent('main', () => App);
//   // It also ensures that whether you load the app in the Expo client or in a native build,
//   // the environment is set up appropriately
//   registerRootComponent(App);
// }

import { registerRootComponent } from 'expo';

import App from './App';

// registerRootComponent calls AppRegistry.registerComponent('main', () => App);
// It also ensures that whether you load the app in the Expo client or in a native build,
// the environment is set up appropriately
registerRootComponent(App);
