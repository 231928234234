import { injectable, postConstruct } from 'inversify';
import { action, makeObservable, observable } from 'mobx';

import type { Optional } from '@ioupie/shared/models';

@injectable()
export class HeaderStore {
  @observable public title: string = '';
  @observable public titleMap?: Record<string, unknown>;

  @observable public subtitle: string = '';
  @observable public subtitleMap?: Record<string, unknown>;

  @observable public back: boolean = true;
  @observable public menu: boolean = true;
  @observable public noShadow: boolean = false;

  @observable public goBackAction: Optional<CallableFunction> = undefined;

  @postConstruct()
  public init(): void {
    makeObservable(this);
  }

  @action
  public changeTitle(title: string, titleMap?: Record<string, unknown>): void {
    this.title = title;
    this.titleMap = titleMap;
  }

  @action
  public changeSubtitle(subtitle: string, subtitleMap?: Record<string, unknown>): void {
    this.subtitle = subtitle;
    this.subtitleMap = subtitleMap;
  }

  @action
  public allowNavigateBack(allow: boolean): void {
    this.back = allow;
  }

  @action
  public allowInteractMenu(allow: boolean): void {
    this.menu = allow;
  }

  @action
  public removeHeaderShadow(remove: boolean): void {
    this.noShadow = remove;
  }

  @action
  public onClickBack(fn: Optional<CallableFunction>): void {
    this.goBackAction = fn;
  }
}
