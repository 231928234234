import styled from 'styled-components/native';

import { IconButton } from '@ioupie/components/buttons';
import { TextInput } from '@ioupie/components/inputs';

export const FilterContainer = styled.View`
  flex-direction: row;
  align-items: center;
  margin: 6px 20px;
`;

export const SearchTextInput = styled(TextInput)`
  width: 90%;
`;

export const SearchIconButton = styled(IconButton)`
  right: 0;
  bottom: 15px;
  width: 10%;
`;
