import { Children, memo, useMemo } from 'react';
import { Keyboard, Platform } from 'react-native';

import { KeyboardViewProps } from './keyboard-view.models';
import * as S from './keyboard-view.styles';

/**
 * @function KeyboardViewComponent
 */
export default memo(({ children }: KeyboardViewProps) => {
  const onPress = useMemo(() => {
    return Platform.OS === 'web' ? () => undefined : Keyboard.dismiss;
  }, []);

  const behavior = useMemo(() => {
    return Platform.OS === 'ios' ? 'padding' : 'height';
  }, []);

  return (
    <S.TouchView onPress={onPress}>
      <S.TypingView behavior={behavior} keyboardVerticalOffset={100}>
        {Children.toArray(children)}
      </S.TypingView>
    </S.TouchView>
  );
});
