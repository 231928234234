import { BaseEntity } from './base-entity.models';

export declare type Locker = BaseEntity & {
  readonly serviceProviders: readonly ServiceProviderToLockerAssignment[];
  readonly isRestricted?: boolean;
  readonly simCardNumber?: string;
  readonly imei?: string;
  readonly status: LockerStatus;
  readonly maxAcceptableDistance?: number;
  readonly compartments?: readonly Compartment[];
  readonly tags?: readonly string[];
  readonly segment?: string;

  // Legacy attributes - to be removed
  readonly laundryId?: string;
  readonly drawerMap?: readonly [
    {
      readonly size: LockerDrawerSize;
      readonly doors: readonly number[];
    },
  ];
};

export declare type ServiceProviderToLockerAssignment = {
  readonly id: string;
  readonly name: string;
  readonly unavailable?: boolean;
};

export declare type CompartmentData = {
  readonly identifier: string;
  readonly capacity: number;
  readonly unavailable?: boolean;
};

export declare type Compartment = {
  readonly type: CompartmentType;
  readonly compartments: readonly CompartmentData[];
};

export enum CompartmentType {
  DOOR = 'DOOR',
  SHOWCASE = 'SHOWCASE',
  CARPET_HOLDER = 'CARPET_HOLDER',
}

export enum LockerStatus {
  AVAILABLE = 'AVAILABLE',
  INTERNAL_TESTING = 'INTERNAL_TESTING',
  COMING_SOON = 'COMING_SOON',
  HEARTBEAT_UNAVAILABLE = 'HEARTBEAT_UNAVAILABLE',
  MAINTENANCE = 'MAINTENANCE',
  PERMANENTLY_SHUTDOWN = 'PERMANENTLY_SHUTDOWN',
}

export enum LockerDrawerSize {
  SMALL = 1,
  MEDIUM = 1.2,
  LARGE = 1.8,
  XLARGE = 2,
  ACCESSIBILITY = 3,
}
