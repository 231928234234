import { observer } from 'mobx-react-lite';
import { Fragment } from 'react';
import { useTranslation } from 'react-i18next';

import { Divider } from '@ioupie/components/layout';
import { Text } from '@ioupie/components/typography';
import { useAddressStore, useLockersStore, useNavigationStore, useShopsStore } from '@ioupie/hooks';
import { BUSINESS_AREA_OPTIONS, BUSINESS_SEGMENTS_OPTIONS, routes } from '@ioupie/shared/constants';

import * as S from './address.styles';

/**
 * @function AddressContainer
 */
export default observer(() => {
  const [t] = useTranslation();
  const shopsStore = useShopsStore();
  const lockersStore = useLockersStore();
  const addressStore = useAddressStore();
  const navigationStore = useNavigationStore();

  const isLocker = shopsStore.shopBusinessArea === BUSINESS_AREA_OPTIONS.LOCKER;
  const isDelivery = shopsStore.shopBusinessArea === BUSINESS_AREA_OPTIONS.DELIVERY;

  const { lockerProvider } = lockersStore ?? {};
  const { name: lockerName = '', address = '' } = lockerProvider ?? {};
  const {
    streetAddress = '',
    houseNumber = '',
    district = '',
    neighborhood = '',
    state = '',
    addressAddon = '',
  } = addressStore.selectedAddressInfo ?? {};

  const hasAddress = streetAddress !== '';
  const street = isLocker ? address : `${streetAddress}, ${houseNumber}`;

  const handleAddNewAddress = (): void => {
    navigationStore.dispatchNavigation({
      stack: routes.stacks.address,
      screen: routes.pages.address.add_new_address,
    });
  };

  const addressTitleKey =
    shopsStore.shopProvider?.segment === BUSINESS_SEGMENTS_OPTIONS.GOODS ? 'title-goods' : 'title';

  return (
    <Fragment>
      <S.AddressContainer>
        <S.AddressTitle>{t(`containers.orders.ordering.address.${addressTitleKey}`)}</S.AddressTitle>
        {!hasAddress && isDelivery ? (
          <Fragment>
            <Text>{t('containers.orders.ordering.address.no-address-registered')}</Text>
            <S.AddAddressButton mode="text" onPress={handleAddNewAddress}>
              {t('containers.orders.ordering.address.add-address')}
            </S.AddAddressButton>
          </Fragment>
        ) : (
          <Fragment>
            {isLocker && (
              <S.AddressStreet>{t('containers.orders.ordering.address.locker-name', { lockerName })}</S.AddressStreet>
            )}
            <S.AddressStreet>{street}</S.AddressStreet>
            {isDelivery && (
              <Fragment>
                <S.AddressNeighborhood>
                  {neighborhood}, {district}/{state}
                </S.AddressNeighborhood>
                {addressAddon !== '' && <S.AddressAddon>{addressAddon}</S.AddressAddon>}
              </Fragment>
            )}
          </Fragment>
        )}
      </S.AddressContainer>
      <Divider />
    </Fragment>
  );
});
